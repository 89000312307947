import React from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeInDown } from "./Animate";
import { getAugmentedStyle } from "../utils/augmentedStyles";
import BackgroundImg from "../assets/bg-effect.png";

// // ----------------------------------------------------------------------

const AccountNotificationBar = () => {
  const { message, isOpen } = useSelector((state) => state.accountNotifications);
  const theme = useTheme();
  return (
    <AnimatePresence>
      {isOpen && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translate3d(-50%, 0, 0)",
            width: { xxs: 335, xs: 393, sm: 503, md: 583, lg: 640 },
            minHeight: { xxs: 30, xs: 33 },
            zIndex: (theme) => theme.zIndex.modal + 1
          }}>
          <Box
            component={motion.div}
            style={getAugmentedStyle({
              color: theme.palette.primary.main,

              bl1: "6px"
            })}
            data-augmented-ui="br-clip bl-clip-y border"
            sx={{
              minHeight: { xxs: 30, xs: 33 },
              m: 0,
              py: 0.5,
              px: 1.25,

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: (theme) => theme.zIndex.modal + 10,

              backgroundImage: `url(${BackgroundImg})`,
              backgroundSize: "100px",
              backgroundColor: (theme) => theme.palette.grey[900]
            }}
            {...varFadeInDown}>
            <Typography fontSize={12} fontWeight={400} textAlign="center">
              {message}
            </Typography>
          </Box>
        </Box>
      )}
    </AnimatePresence>
  );
};

export const FormNotificationBar = () => {
  const { message, isOpen } = useSelector((state) => state.accountNotifications);
  const theme = useTheme();
  return (
    <AnimatePresence>
      <Box
        sx={{
          width: { xxs: 275, md: 400 },
          position: { xxs: "absolute", xs: "relative" },
          minHeight: { xxs: 30, xs: 33 }
        }}>
        {isOpen && (
          <Box
            component={motion.div}
            style={getAugmentedStyle({
              color: theme.palette.primary.main,

              bl1: "6px"
            })}
            data-augmented-ui="br-clip bl-clip-y border"
            sx={{
              minHeight: { xxs: 30, xs: 33 },
              m: 0,
              py: 0.5,
              px: 1.25,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: (theme) => theme.zIndex.modal + 10,
              backgroundImage: `url(${BackgroundImg})`,
              backgroundSize: "100px",
              backgroundColor: (theme) => theme.palette.grey[900]
            }}
            {...varFadeInDown}>
            <Typography fontSize={12} fontWeight={400} textAlign="center">
              {message}
            </Typography>
          </Box>
        )}
      </Box>
    </AnimatePresence>
  );
};
export default AccountNotificationBar;
