import { alpha } from "@mui/material";

export default function Button({ theme }) {
  const { primary, secondary, grey, action } = theme.palette;
  return {
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          transition: "backgroundColor ease 0.3s",
          fontWeight: 400
        },

        startIcon: {
          color: "inherit"
        },
        sizeLarge: {
          height: 51,
          fontWeight: "700"
        },
        sizeMedium: {
          height: 30
        },
        sizeSmall: {
          height: 26
        },

        //CONTAINED WITH PRIMARY COLOR
        containedPrimary: {
          color: grey[900],
          backgroundColor: alpha(primary.main, 1),

          "&:hover": {
            backgroundColor: alpha(primary.main, 1),
            opacity: 0.8
          },
          "&.Mui-disabled": {
            cursor: "not-allowed",
            pointerEvents: "all",
            opacity: 1,
            color: "#000",
            "&:hover": {
              opacity: 1,
              backgroundColor: alpha(primary.main, 0.5)
            }
          }
        },

        containedSecondary: {
          color: grey[900],
          backgroundColor: "white",

          "&:hover": {
            backgroundColor: "white",
            opacity: 0.8,
          },

          "&.Mui-disabled": {
            cursor: "not-allowed",
            color: "black",
            backgroundColor: "rgba(255, 255, 255, 0.5);"
          },
        },

        outlinedPrimary: {
          color: primary.main,
          backgroundColor: "inherit",
          "&.Mui-disabled": {
            cursor: "not-allowed",
            // color: primary.lighter,
            // borderColor: primary.lighter,
            opacity: "0.7",
            backgroundColor: "none"
          },

          "&:hover": {
            backgroundColor: alpha(primary.main, 0.2),
            outline: "none"
          }
        },

        //OUTLINED WITH SECONDARY COLOR
        outlinedSecondary: {
          color: secondary.main,
          // borderColor: secondary.light,
          backgroundColor: "inherit",

          "&.Mui-disabled": {
            cursor: "not-allowed",
            backgroundColor: "none",
            // color: primary.lighter,
            // borderColor: primary.lighter,
            opacity: "0.7"
          },
          "&:hover": {
            backgroundColor: alpha(secondary.main, 0.2),
            outline: "none"
          }
        },

        //CONTAINED WITH INHERIT COLOR
        containedInherit: {
          color: grey[900],
          "&:hover": {
            backgroundColor: grey[400]
          }
        },

        // OUTLINED GENERAL STYLES
        outlined: {
          position: "relative",
          zIndex: 4,
          borderRadius: 0

          // "&:hover": {
          //   borderColor: "inherit"
          // }
        },

        textInherit: {
          "&:hover": {
            backgroundColor: action.hover
          }
        }
      }
    }
  };
}
