import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Fade } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Container from "../../components/Container";
import DepositDialog from "../../components/Dialogs/DepositDialog";
import LotteryTitle from "../../views/Dashboard/Lottery/LotteryTitle";
import { PATH_LOTTERY } from "../../routes/paths";
import { CONTRACT_ADDRESS } from "../../constants/contracts";
import useBreakpoints from "../../hooks/useBreakpoints";
import { getBalance, getStakeConfig, getLimitedPoolsInfo } from "../../redux/slices/stake";
import { getTicketOverview, getTicketReferralOverview, setReferrerCode } from "../../redux/slices/lottery";
import CoverImg from "../../assets/lottery-cover.png";
import ReferralIntro from "../../views/Dashboard/Lottery/ReferralIntro";

const activeStyle = {
  opacity: 1,
  "--border-opacity": 1,
  fontWeight: 500
};

function Tab({ to = "", title }) {
  const upXs = useBreakpoints("up", "xs");

  return (
    <Box
      activeStyle={activeStyle}
      sx={{
        "--border-opacity": 0,
        borderBottom: "0px solid transparent",
        color: "#fff",
        textDecoration: "none",
        textAlign: "center",
        fontSize: "16px",
        fontWeight: { xxs: "600", xs: "300" },
        py: "10px",
        ml: "20px",
        position: "relative",
        mr: { xxs: 1.5, xs: 1 },
        opacity: 0.7,
        transition: "all 0.3s ease",

        "&:before": {
          content: `""`,
          position: "absolute",
          width: "100%",
          height: "3px",
          bottom: "0",
          background: (theme) => theme.palette.primary.main,
          opacity: "var(--border-opacity)"
        }
      }}
      to={to}
      component={NavLink}>
      {title}
    </Box>
  );
}

const LotteryLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const params = new URLSearchParams(search);
  const code = params.get("referrer");
  const isReferralTab = pathname === '/lottery/referral'

  const loadData = async () => {
    await dispatch(getTicketReferralOverview());
    await dispatch(getTicketOverview());
    setIsLoading(false)
  }

  useEffect(() => {
    if (code) {
      dispatch(setReferrerCode(code))
    }
  }, [])

  useEffect(() => {
    dispatch(getBalance(user?.wallets.ethWallet, CONTRACT_ADDRESS.LP_TOKEN));
    loadData()
  }, [dispatch, user]);

  return (
    <Container
      sx={{
        fontFamily: "Chakra Petch, sans-serif",
        flexShrink: 0,
        width: { xxs: "100vw", sm: "100%" },
        minHeight: "100%",
        pb: 0,
        display: "flex",
        overflowX: { xxs: "initial", sm: 'hidden'},
        flexFlow: "column nowrap",
        mx: { xxs: "-20px", sm: 0 },
        pb: { xxs: "0", sm: "50px" }
      }}>

      <Box
        sx={{
          display: "flex",
          position: "relative",
          flexFlow: "row nowrap",
          gap: { xxs: "15px", sm: "78px" },
          mt: { xxs: 0, xs: 0 },
          mb: { xxs: "16px", sm: "58px" },
        }}>
        <Box sx={{ flex: 622, display: { xxs: "none", sm: "block" } }}></Box>
        <Box sx={{ flex: 498 }}>
        {/* <Tab to={PATH_LOTTERY.pool} title="Pool" /> */}
        <Tab to={PATH_LOTTERY.myTickets} title="My Tickets" />
        {/* <Tab to={PATH_LOTTERY.referral} title="Referral" /> */}
        </Box>
        <Box sx={{
          height: "0.5px", background: "rgba(255, 255, 255, 0.5)", position: "absolute",  bottom: "-9px",
          left: {xxs: "0", xs: "-100vw"},
          width: { xxs: "100vw", xs: "300vw" },
        }}></Box>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            mt: 7.5,
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <Fade in={true}>
          <Box sx={{
            display: "flex",
            gap: { xxs: "15px", sm: "118px" },
            flexDirection: { xxs: "column", xs: "row" }
          }}>
            <LotteryTitle sx={{
              px: "20px",
              pt: "10px",
              maxWidth: "300px",
              display: { xxs: "initial", xs: "none" }
            }} />
            { isReferralTab ?
            <Box
              sx={{
                flex: 622,
                display: { xxs: 'none', xs: 'block' }
              }}
            >
              <Box sx={{
                maxWidth: "498px",
                margin: "0 auto",
                background: `rgba(255, 255, 255, 0.10)`,
                padding: `20px 40px`,
              }}>
                <Box
                  sx={{
                    mb: "8px",
                    color: `var(--yellow, #FAE232)`,
                    fontSize: `16px`,
                    fontWeight: 500,
                  }}
                >Referral Rules</Box>
                <ReferralIntro />
              </Box>
            </Box>
             : <Box sx={{
              flex: 622,
              minWidth: "300px",
              aspectRatio: "1/1",
              backgroundImage: `url(${CoverImg})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat"
            }}></Box> }
            <Box sx={{
              flex: 498, width: "100%", px: { xxs: "20px", sm: "0" }
            }}
            >{children}</Box>
          </Box>
        </Fade>
      )}
      <DepositDialog />
    </Container>
  );
};

export default LotteryLayout;
