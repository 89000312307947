import { createSlice } from "@reduxjs/toolkit";
import { setAccountNotification } from "./accountNotifications";
import { setUser, setAuthenticator } from "./auth";
import axios from "../../utils/axios";
import { config } from "../../config";
import { DISCORD_REDIRECT_PARAMETER } from "../../routes/paths";
// ----------------------------------------------------------------------
const base_url = config.SSO_API_URL;

function loadBindGoogle2FASecret () {
  try {
    return JSON.parse(localStorage.getItem('bindGoogle2FASecret')) || {}
  } catch (error) {}
  return {}
}

const initialState = {
  isLoading: false,
  currentFlow: null,
  currentDialogStep: '',
  bindGoogle2FADialogOpen: false,
  bindGoogle2FASecret: loadBindGoogle2FASecret(),
  bindGoogle2FACallback: 'transfer',
  turnOffGoogle2FADialogOpen: false,
  formDialogOpen: false,
  UnbindWalletDialogOpen: false
};

const slice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCurrentFlow(state, action) {
      state.currentFlow = action.payload;
    },
    setCurrentDialogStep(state, action) {
      state.currentDialogStep = action.payload;
    },
    setFormDialogOpen(state, action) {
      state.formDialogOpen = action.payload;
    },
    setBindGoogle2FADialogOpen(state, action) {
      state.bindGoogle2FADialogOpen = action.payload;
    },
    setBindGoogle2FASecret(state, action) {
      state.bindGoogle2FASecret = action.payload;
      localStorage.setItem('bindGoogle2FASecret', JSON.stringify(action.payload))
    },
    setBindGoogle2FACallback(state, action) {
      state.bindGoogle2FACallback = action.payload;
    },
    setTurnOffGoogle2FADialogOpen(state, action) {
      state.turnOffGoogle2FADialogOpen = action.payload;
    },
    setUnbindWalletDialogOpen(state, action) {
      state.UnbindWalletDialogOpen = action.payload;
    },
    resetAccountDialogs(state) {
      state.UnbindWalletDialogOpen = false;
      state.formDialogOpen = false;
      state.currentFlow = null;
    }
  }
});

// Reducer
export default slice.reducer;
export const {
  setIsLoading,
  setCurrentFlow,
  setCurrentDialogStep,
  setFormDialogOpen,
  resetAccountDialogs,
  setBindGoogle2FADialogOpen,
  setBindGoogle2FASecret,
  setBindGoogle2FACallback,
  setTurnOffGoogle2FADialogOpen,
  setUnbindWalletDialogOpen
} = slice.actions;

function getVerifyErrorMessage(error) {
  if (!error) return

  switch (error.code) {
    case 404104:
      return "Verification code not found. Please request a new code and try again.";
    case 400501:
      return "Verification failed. Incorrect Email Code, please re-enter to verify.";
    case 400505:
      return "Verification failed. Incorrect Google Authenticator Code, please re-enter to verify.";
    default:
      return "Operation failed."
  }
}

export function verifyCodeEmail({ email, verificationCode }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const { data } = await axios.get(`/me/email/verify-email-code`, {
        params: { email, verificationCode },
        baseURL: base_url
      });
      dispatch(slice.actions.setIsLoading(false));
      return data?.isValid;
    } catch (error) {
      dispatch(slice.actions.setIsLoading(false));
      dispatch(setAccountNotification(error));
    }
  };
}

export function getGoogle2FASecret() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/authenticator/generate`, {
        baseURL: base_url
      });
      dispatch(setBindGoogle2FASecret(data));
      return data;
    } catch (error) {
      dispatch(setAccountNotification(error));
    }
  };
}

export function reqBindGoogle2FA(emailVerificationCode, authenticatorVerificationCode) {
  return async (dispatch) => {
    try {
      const token = await window.grecaptcha.enterprise.execute('6LdEl6spAAAAALNRawKjPMPTnlWydKTNJsg6piif', { action: '/authenticator/bind' })
      await axios.post('/authenticator/bind', {
        emailVerificationCode,
        authenticatorVerificationCode,
        token
      }, {
        baseURL: base_url,
      });
      const patch = { bound: true, enabled: true, bound_at: Math.floor(Date.now() / 1000) }
      dispatch(
        setUser({ authenticator: patch })
      );
      return patch;
    } catch (error) {
      if (error) dispatch(setAccountNotification(getVerifyErrorMessage(error)));
      return error || {}
    }
  };
}

export function reqEnableGoogle2FA() {
  return async (dispatch) => {
    try {
      await axios.post('/authenticator/enable', {}, {
        baseURL: base_url,
      });
      dispatch(setAuthenticator({ enabled: true, bound_at: Math.floor(Date.now() / 1000) }));
      return {}
    } catch (error) {
      dispatch(setAccountNotification(getVerifyErrorMessage(error)));
      return error || {}
    }
  };
}

export function reqDisableGoogle2FA(authenticatorVerificationCode) {
  return async (dispatch) => {
    try {
      const token = await window.grecaptcha.enterprise.execute('6LdEl6spAAAAALNRawKjPMPTnlWydKTNJsg6piif', { action: '/authenticator/disable' })
      await axios.post('/authenticator/disable', {
        authenticatorVerificationCode,
        token
      },{
        baseURL: base_url,
      });
      dispatch(setAuthenticator({ enabled: false, bound_at: Math.floor(Date.now() / 1000) }));
      dispatch(setTurnOffGoogle2FADialogOpen(false));
      dispatch(setAccountNotification('Verification successful. Your Google Authenticator has been turned off.'));
      return {}
    } catch (error) {
      console.log('reqDisableGoogle2FA error', error)
      dispatch(setAccountNotification(getVerifyErrorMessage(error)));
      return error || {}
    }
  };
}

export function requestCodeEmail({ email }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    const token = await window.grecaptcha.enterprise.execute('6LdEl6spAAAAALNRawKjPMPTnlWydKTNJsg6piif', { action: '/me/email/request_email_code' })
    try {
      await axios.post(
        `/me/email/request-email-code`,
        { email, token },
        {
          baseURL: base_url
        }
      );
      dispatch(slice.actions.setIsLoading(false));
      return true;
    } catch (error) {
      dispatch(setAccountNotification(error));
      dispatch(slice.actions.setIsLoading(false));
    }
  };
}
export function updateEmail({ email, verificationCode }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const token = await window.grecaptcha.enterprise.execute('6LdEl6spAAAAALNRawKjPMPTnlWydKTNJsg6piif', { action: '/me/email/update' })
      await axios.put(
        `/me/email/update`,
        { email, verificationCode: parseInt(verificationCode), token },
        {
          baseURL: base_url
        }
      );
      dispatch(slice.actions.setCurrentFlow(null));
      dispatch(slice.actions.setFormDialogOpen(false));
      dispatch(slice.actions.setIsLoading(false));

      return true;
    } catch (error) {
      dispatch(setAccountNotification(error));
      dispatch(slice.actions.setIsLoading(false));
    }
  };
}
export function verifyCodePassword({ email, verificationCode }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const { data } = await axios.get(`/me/password/verify-email-code`, {
        params: { email, verificationCode },
        baseURL: base_url
      });
      dispatch(slice.actions.setIsLoading(false));
      return data?.isValid;
    } catch (error) {
      dispatch(slice.actions.setIsLoading(false));
      dispatch(setAccountNotification(error));
    }
  };
}
export function requestCodePassword({ email }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const token = await window.grecaptcha.enterprise.execute('6LdEl6spAAAAALNRawKjPMPTnlWydKTNJsg6piif', { action: '/me/password/request_email_code' })
      await axios.post(`/me/password/request-email-code`, { email, token }, { baseURL: base_url });
      dispatch(slice.actions.setIsLoading(false));
      return true;
    } catch (error) {
      dispatch(setCurrentFlow(null));
      dispatch(setAccountNotification(error));
      dispatch(slice.actions.setIsLoading(false));
    }
  };
}
export function updatePassword({ newPassword, newPasswordConfirm }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const token = await window.grecaptcha.enterprise.execute('6LdEl6spAAAAALNRawKjPMPTnlWydKTNJsg6piif', { action: '/me/password/update' })
      await axios.put(
        `/me/password/update`,
        { newPassword, newPasswordConfirm, token },
        { baseURL: base_url }
      );
      dispatch(slice.actions.setCurrentFlow(null));
      dispatch(slice.actions.setFormDialogOpen(false));
      dispatch(slice.actions.setIsLoading(false));
      return true;
    } catch (error) {
      dispatch(setAccountNotification(error));
      dispatch(slice.actions.setIsLoading(false));
      dispatch(slice.actions.setCurrentFlow(null));
    }
  };
}

export function discordAuthoriseURL() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/oauth2/discord/authorize-url`, {
        params: { redirectUri: DISCORD_REDIRECT_PARAMETER },
        baseURL: base_url
      });
      return data?.url;
    } catch (error) {}
  };
}
export function discordLogin({ code, requestNonce, redirectUri }) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `/oauth2/discord/login`,
        { code, requestNonce, redirectUri },
        { baseURL: base_url }
      );
      return data;
    } catch (error) {}
  };
}
export function discordBind({ authKey, requestNonce, redirectUri }) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `/discord/bind`,
        { authKey, requestNonce, redirectUri },
        { baseURL: base_url }
      );
      return data;
    } catch (error) {
      window.location.href = "/settings";
    }
  };
}
export function discordUnbind() {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(`/discord/unbind`, { baseURL: base_url });
      dispatch(slice.actions.setUnbindWalletDialogOpen(false));
      return data;
    } catch (error) {
      dispatch(setAccountNotification(error));
    }
  };
}
