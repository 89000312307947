import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { discordBind, discordLogin } from "../../redux/slices/account";
import { setAccountNotification } from "../../redux/slices/accountNotifications";
import { DISCORD_REDIRECT_PARAMETER } from "../../routes/paths";
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function DiscordBindView(props) {
  let query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const [discordAuth, setDiscordAuth] = useState(null);

  useEffect(() => {
    setDiscordAuth({
      code: query.get("code"),
      state: query.get("state")
    });
  }, []);

  useEffect(() => {
    //checks to see if there is an error with the discord redirect and redirects the user accordinly

    if (query.get("error")) {
      history.push("/settings");
      return;
    }

    if (discordAuth) {
      if (!discordAuth.code) {
        return;
      }
      if (!discordAuth.state) {
        return;
      }
      dispatch(
        discordLogin({
          code: discordAuth?.code,
          requestNonce: discordAuth?.state,
          redirectUri: DISCORD_REDIRECT_PARAMETER
        })
      ).then((res) => {
        if (res.requireAccount) {
          dispatch(
            discordBind({
              authKey: res.account.authKey,
              requestNonce: discordAuth?.state,
              redirectUri: DISCORD_REDIRECT_PARAMETER
            })
          ).then((bindRes) => {
            dispatch(
              setAccountNotification(
                {
                  message: "You have successfully bound your discord."
                },
                10000
              )
            );
            history.push("/settings");
          });
        } else {
          dispatch(
            setAccountNotification(
              {
                message: "That discord is already associated with an existing account."
              },
              10000
            )
          );
          history.push("/settings");
        }
      });
    }
  }, [discordAuth]);

  return <div>Loading, please wait</div>;
}

export default DiscordBindView;
