import { Typography } from "@mui/material";
import React from "react";

function FormHeader({ title, body = null, Body = null }) {
  return (
    <>
      <Typography
        fontSize={{ xxs: 18, md: 24 }}
        fontWeight={700}
        mt={{ xs: 3.875, md: 7 }}
        mb={{ xxs: 4, md: 5 }}>
        {title}
      </Typography>
      {(body || Body) && (
        <Typography fontSize={{ xxs: 12, md: 16 }} mb={{ xxs: 4, md: 5 }}>
          {body ? body : Body ? <Body /> : ""}
        </Typography>
      )}
    </>
  );
}

export default FormHeader;
