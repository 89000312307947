import axios from "axios";
import { PATH_EXTERNAL } from "../routes/paths";
import { isWalletBrowser } from "./isMobile";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

// inject redux state to avoid circular dependency issue
let store;
export const injectStore = (_store) => {
  store = _store;
};

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    return Promise.reject(err.response && err.response.data);
  }
);

// Add a response interceptor
axiosInstance.interceptors.request.use((config) => {
  const globalState = store.getState();
  const { user } = globalState.auth;
  if (user?.token && !config.baseURL.includes("ark")) {
    config.headers["authorization"] = `Bearer ${user.token}`;
    config.headers["dely-jwt"] = `Bearer ${user.token}`;
    // config.withCredentials = true;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.code === 401 ||
      error?.message?.includes("authorization") ||
      error?.message?.toLowerCase().includes("jwt")
    ) {
      isWalletBrowser()
        ? window.location.assign(PATH_EXTERNAL.sso.login)
        : window.open(PATH_EXTERNAL.sso.login, "_self");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
