import { Box, styled, Tooltip, Typography } from "@mui/material";
import { ReactComponent as ToolTipIcon } from "../../../assets/icons/question_tooltip.svg";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip enterTouchDelay={0} {...props} classes={{ tooltip: className }} />
);
const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  color: theme.palette.grey[900]
}));

export default function CustomToolTip({ Content }) {
  return (
    <StyledTooltip
      sx={{ zIndex: 10000, background: "transparent", ml: 1 }}
      //Use to control the distance between the anchor and the tooltip
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: { offset: [4, -30] }
          }
        ]
      }}
      placement={"right-start"}
      title={
        <Box
          style={getAugmentedStyle({ tl: "12px", bl1: "0px" })}
          sx={{ background: (theme) => theme.palette.grey[300] }}
          data-augmented-ui="tl-scoop tr-clip bl-clip">
            <Content />
        </Box>
      }>
      <ToolTipIcon style={{ marginLeft: "8px", marginBottom: "1px" }} />
    </StyledTooltip>
  );
}
