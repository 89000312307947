import { useEffect } from "react";

import { useSelector } from "react-redux";
import { WALLET_CONFIG } from "../redux/slices/wallet";

export function useEagerConnect() {
  const { selectedWallet } = useSelector((state) => state.wallet);

  useEffect(() => {
    WALLET_CONFIG[selectedWallet].connector.connectEagerly().catch(() => {
      console.log("failed to connect eagerly");
    });
  }, []);
}
