import {
  CircularProgress,
  Divider,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyText from "../../../components/CopyText";
import { Columns, EmptyRecords } from "./Table";
import { formatDate } from "../../../utils/time";
import { Box } from "@mui/system";
import useBreakpoints from "../../../hooks/useBreakpoints";
import Pagination from "../../../components/Pagination";
import { getReferrals } from "../../../redux/slices/history";

const COLUMNS = ["Time", "Email", "DMA Amount"];
const EMPTY_RECORDS = "No Referral Record";

const flexCellSx = {
  display: "flex",
  alignItems: "center",
  border: "none"
};

const copyTextSx = {
  width: "18px",
  height: "18px",
  "& svg": {
    transform: "scale(0.75)"
  },
  ml: 0.75
};

const ReferralRecordCard = ({ record }) => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.grey[10],
        my: 0.5,
        width: "100%",
        p: 1.5
      }}>
      <Box sx={{ display: "flex", flexFlow: "row nowrap", justifyContent: "space-between" }}>
        <Typography variant="caption" color="textSecondary">
          {formatDate(record.updated_at)}
        </Typography>
        <Typography sx={flexCellSx} variant="caption" color="textSecondary">
          {record.email}
          <CopyText buttonSx={copyTextSx} label="" textToCopy={record.email} />
        </Typography>
      </Box>

      <Divider sx={{ mt: 1.5 }} />

      <Typography variant="h3" sx={{ width: "100%", textAlign: "center", mt: 3, mb: 1.5 }}>
        {record.dma_amount} DMA
      </Typography>
    </Box>
  );
};

function Referral() {
  const PAGE_SIZE = 10;
  const dispatch = useDispatch();
  const upXs = useBreakpoints("up", "xs");
  const { referrals, isLoading } = useSelector((state) => state.history);
  const isEmpty = !isLoading && referrals.data?.length === 0;
  const [page, setPage] = useState(1);

  function handleChangePage(e, value) {
    setPage(value);
  }

  useEffect(() => {
    dispatch(getReferrals({ page_size: PAGE_SIZE, page }));
  }, [dispatch, page]);

  return (
    <>
      {upXs ? (
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="Referral Table">
            <Columns columnList={COLUMNS} />
            <TableBody>
              {!isLoading &&
                referrals?.data?.map((row) => {
                  return (
                    <TableRow>
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {formatDate(row.updated_at)}
                      </TableCell>

                      <TableCell sx={flexCellSx}>
                        {row.email}
                        <CopyText buttonSx={copyTextSx} label="" textToCopy={row.email} />
                      </TableCell>

                      <TableCell>{row.dma_amount}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          my={2.5}
          minHeight={!isLoading && !isEmpty && referrals?.data?.length < 4 && "65vh"}>
          {!isLoading &&
            referrals?.data?.map((row) => {
              return <ReferralRecordCard record={row} />;
            })}
        </Box>
      )}
      {isLoading && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      )}
      {isEmpty && (
        <EmptyRecords
          sx={{ mt: 0, minHeight: { xxs: "60vh", xs: "initial" } }}
          title={EMPTY_RECORDS}
        />
      )}
      {!isEmpty && !isLoading && (
        <Pagination onChange={handleChangePage} count={referrals?.total_page} page={page} />
      )}
    </>
  );
}

export default Referral;
