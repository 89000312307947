import React from "react";
import { varFadeInUp } from "../Animate";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog, DialogContent } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import CloseButton from "../Buttons/CloseButton";

function CustomDialog({
  hasCloseButton = true,
  closeButtonType = 'frame',
  children,
  width = "md",
  open,
  setClose,
  fullWidth = false,
  PaperProps,
  ...props
}) {
  const theme = useTheme();
  const styles = getAugmentedStyle({
    color: theme.palette.primary.main,
    bl1: "calc(var(--aug-bl1) * 3)",
    bl: "13px"
  });

  return (
    <AnimatePresence>
      <Dialog
        open={open}
        maxWidth={width}
        onClose={setClose}
        fullWidth={fullWidth}
        PaperComponent={motion.div}
        {...props}
        PaperProps={{
          ...varFadeInUp,
          "data-augmented-ui": " bl-clip-y br-clip border",
          ...PaperProps,
          style: { ...styles, ...PaperProps?.style }
        }}>
        <DialogContent sx={{ ...props?.sx }}>
          {children}
          {hasCloseButton && <CloseButton type={closeButtonType} onClick={setClose} />}
        </DialogContent>
      </Dialog>
    </AnimatePresence>
  );
}

export default CustomDialog;
