import { TextField } from "@mui/material";
import React, { useState } from "react";

function EmailField({
  value,
  handleChange,
  errors = false,
  touched = false,
  handleBlur = () => {},
  sx = {}
}) {
  const [selected, setSelected] = useState(false);
  return (
    <TextField
      onFocus={() => setSelected(true)}
      onBlur={(e) => {
        setSelected(false);
        handleBlur(e);
      }}
      error={(value.length > 0 || touched) && !selected && Boolean(errors)}
      helperText={(value.length > 0 || touched) && !selected && errors ? errors : " "}
      FormHelperTextProps={{
        sx: {
          mt: { xxs: 0.5, md: 1 }
        }
      }}
      sx={{ ...sx }}
      label="Email Address"
      placeholder="youremail@mail.com"
      variant="standard"
      color="primary"
      fullWidth
      name={"email"}
      value={value}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true,
        sx: {
          fontSize: { xxs: 14, md: 16 },
          color: (theme) => (value.length > 0 ? theme.palette.grey[60] : theme.palette.grey[0])
        }
      }}
      InputProps={{
        sx: { fontSize: { xxs: 14, md: 16 }, fontWeight: 400 }
      }}
    />
  );
}

export default EmailField;
