import { PATH_DASHBOARD, PATH_HISTORY, PATH_LOTTERY, PATH_LIMITED_STAKE, PATH_STAKE } from "./paths";
import { DMAView, SettingsView, SuperReward } from "../views/Dashboard";
import MainLayout from "../layouts/MainLayout";
import ActivityHistoryLayout from "../layouts/ActivityHistoryLayout";
import StakeLayout from "../layouts/StakeLayout";
import LotteryLayout from "../layouts/LotteryLayout";
import LimitedStakeLayout from "../layouts/LimitedStakeLayout";
import { Referral, Rewards, Transfer, ReferralDetails } from "../views/Dashboard/History";
import { Pools, MyPositions } from "../views/Dashboard/Stake";
import { LimitedPools, LimitedMyPositions } from "../views/Dashboard/LimitedStake";
import { LotteryPool, LotteryMyTickets, LotteryReferral } from "../views/Dashboard/Lottery";
import { Redirect } from "react-router-dom";
import LoyaltyProgram from "../views/Dashboard/LoyaltyProgram";

const DashboardRoutes = {
  layout: MainLayout,
  routes: [
    {
      layout: ActivityHistoryLayout,
      path: PATH_HISTORY.root,
      routes: [
        {
          path: PATH_HISTORY.referral,
          exact: true,
          component: () => <Referral />
        },
        {
          path: PATH_HISTORY.rewards,
          exact: true,
          component: () => <Rewards />
        },
        {
          path: PATH_HISTORY.transfer,
          exact: true,
          component: () => <Transfer />
        },
        {
          path: PATH_HISTORY.referralDetails,
          exact: true,
          component: () => <ReferralDetails />
        },
        {
          component: () => <Redirect to={PATH_HISTORY.rewards} />
        }
      ]
    },
    {
      layout: StakeLayout,
      path: PATH_STAKE.root,
      routes: [
        {
          path: PATH_STAKE.pools,
          exact: true,
          component: () => <Pools />
        },
        {
          path: PATH_STAKE.myPositions,
          exact: true,
          component: () => <MyPositions />
        },
        {
          component: () => <Redirect to={PATH_STAKE.pools} />
        }
      ]
    },
    // {
    //   layout: LotteryLayout,
    //   path: PATH_LOTTERY.root,
    //   routes: [
    //     {
    //       path: PATH_LOTTERY.pool,
    //       exact: true,
    //       component: () => <LotteryPool />
    //     },
    //     {
    //       path: PATH_LOTTERY.myTickets,
    //       exact: true,
    //       component: () => <LotteryMyTickets />
    //     },
    //     {
    //       path: PATH_LOTTERY.referral,
    //       exact: true,
    //       component: () => <LotteryReferral />
    //     },
    //     {
    //       component: () => <Redirect to={PATH_LOTTERY.myTickets} />
    //     }
    //   ]
    // },
    {
      layout: LimitedStakeLayout,
      path: PATH_LIMITED_STAKE.root,
      routes: [
        {
          path: PATH_LIMITED_STAKE.pools,
          exact: true,
          component: () => <LimitedPools />
        },
        {
          path: PATH_LIMITED_STAKE.myPositions,
          exact: true,
          component: () => <LimitedMyPositions />
        },
        {
          component: () => <Redirect to={PATH_LIMITED_STAKE.pools} />
        }
      ]
    },
    {
      path: PATH_DASHBOARD.dmaView,
      exact: true,
      component: () => <DMAView />
    },
    {
      path: PATH_DASHBOARD.settingsView,
      exact: true,
      component: () => <SettingsView />
    },
    {
      path: PATH_DASHBOARD.loyaltyProgram,
      exact: true,
      component: () => <LoyaltyProgram />
    },
    {
      path: PATH_DASHBOARD.superReward,
      exact: true,
      component: () => <SuperReward />
    },
    {
      component: () => <Redirect to={PATH_DASHBOARD.dmaView} />
    }
  ]
};

export default DashboardRoutes;
