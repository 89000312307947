import React from "react";
import useSound from "use-sound";
import { easeInOutQuint, easeOutExpo } from "easing-utils";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useState from "react-usestateref";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { Box, Dialog, Button, DialogContent, duration, Tooltip } from "@mui/material";
import { Wheel } from "spin-wheel/dist/spin-wheel-esm";
import {
  setWheelDialogOpen,
  setBalance,
  getRewardsBalance,
  spinToWin,
  spinToWinTest
} from "../../redux/slices/DMA";
import { setAccountNotification } from "../../redux/slices/accountNotifications";
import { varFadeIn } from "../../components/Animate";
import useBreakpoints from "../../hooks/useBreakpoints";
import { displayNum, withComma } from "../../utils/strings";
import { currencies } from "../../constants/currencies";

import mobileBgUrl from "../../assets/wheel/mobile-bg.png";
import desktopBgUrl from "../../assets/wheel/desktop-bg.png";
import spinWheelUrl from "../../assets/wheel/wheel.png";
import spinWheelCenterUrl from "../../assets/wheel/wheel-center.png";
import spinArrowUrl from "../../assets/wheel/arrow.png";
import bigWinUrl from "../../assets/wheel/big-win.png";
import exitUrl from "../../assets/wheel/exit.png";
import exitMobileUrl from "../../assets/wheel/exit-mobile.png";
import playUrl from "../../assets/wheel/play.png";
import playMobileUrl from "../../assets/wheel/play-mobile.png";
import respinUrl from "../../assets/wheel/respin.png";
import respinMobileUrl from "../../assets/wheel/respin-mobile.png";
import upUrl from "../../assets/wheel/up.png";
import downUrl from "../../assets/wheel/down.png";
import addUrl from "../../assets/wheel/add.png";
import minusUrl from "../../assets/wheel/minus.png";
import skipUrl from "../../assets/wheel/skip.png";
import historyUrl from "../../assets/wheel/history.png";
import amountBgUrl from "../../assets/wheel/amount-bg.png";
import soundOffUrl from "../../assets/wheel/SoundOFF.png";
import soundOnUrl from "../../assets/wheel/SoundON.png";
import agiBalanceUrl from "../../assets/wheel/AGIBalance_PC.png";
import agiBalanceMobileUrl from "../../assets/wheel/AGIBalance_Mobile.png";
import pointBalanceUrl from "../../assets/wheel/PointBalance.png";
import pointBalanceMobileUrl from "../../assets/wheel/PointBalance-mobile.png";
import totalBetUrl from "../../assets/wheel/TotalBet_PC.png";
import totalBetMobileUrl from "../../assets/wheel/TotalBet_Mobile.png";
import winTimesUrl from "../../assets/wheel/WinTimes.png";
import winTimesMobileUrl from "../../assets/wheel/WinTimesMobile.png";
import totalAGIWonUrl from "../../assets/wheel/TotalAGIWon.png";
import totalAGIWonMobileUrl from "../../assets/wheel/TotalAGIWonMobile.png";
import controlBgMobileUrl from "../../assets/wheel/controlBgMobile.png";

import bgAudioUrl from "../../assets/wheel/audios/bg.mp3";
import spinAudioUrl from "../../assets/wheel/audios/spin.wav";
import exitAudioUrl from "../../assets/wheel/audios/exit.wav";
import smallWinAudioUrl from "../../assets/wheel/audios/smallWin.wav";
import bigWinAudioUrl from "../../assets/wheel/audios/bigWin.wav";

import animatedBigWinWebm from "../../assets/wheel/videos/AnimatedBigWin.webm";
import animatedBigWinMov from "../../assets/wheel/videos/AnimatedBigWin.mov";
import animatedBigWinUrl from "../../assets/wheel/AnimatedBigWin.png";

const spinDuration = 3000;
const logoUrl = "https://storage.delysium.com/ui/delysium_logo.png";

const timesList = [1, 10, 100];

const amountList = [10, 20, 50, 100, 200, 500, 1000, 10000];

const primaryButtonSx = {
  width: { xxs: "100px", sm: "189px" },
  aspectRatio: { xxs: "204/76", sm: "189/38" }
};

let pendingResults = null;
let initResultTimer = null;
let nextResultTimer = null;

function ImageButton({ onClick, sx, children }) {
  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: "pointer",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        ...sx,

        "&:hover": {
          opacity: 0.9
        },

        "&:active": {
          transform: `translateY(1px)`
        }
      }}>
      {children}
    </Box>
  );
}

function ScreenSpinWheelViewport({ wheel, wheelContainer }) {
  return (
    <Box
      component={motion.div}
      {...varFadeIn}
      sx={{
        height: "100%"
      }}>
      <Box
        sx={{
          aspectRatio: "1/1",
          position: "relative",
          height: "100%",
          overflow: "hidden"
        }}
        ref={wheelContainer}
        className={"wheelContainer"}>
        <Box
          sx={{
            backgroundImage: `url(${spinArrowUrl})`,
            width: "10.1%",
            height: "10.1%",
            backgroundSize: "cover",
            position: "absolute",
            left: "50%",
            top: "6%",
            transform: "translate3d(-50%, 0, 0)"
          }}></Box>
        <Box
          sx={{
            backgroundImage: `url(${spinWheelCenterUrl})`,
            width: "59%",
            height: "59%",
            backgroundSize: "contain",
            position: "absolute",
            zIndex: -1,
            left: "50%",
            top: "50%",
            transform: "translate3d(-50%, -50%, 0)"
          }}></Box>
        <Box
          sx={{
            display: "none",
            backgroundImage: `url(${spinWheelUrl})`,
            width: "100%",
            height: "100%",
            backgroundSize: "cover"
          }}></Box>
      </Box>
    </Box>
  );
}

function ScreenBigWinViewport() {
  function supportsHEVCAlpha() {
    const navigator = window.navigator;
    const ua = navigator.userAgent.toLowerCase();
    const hasMediaCapabilities = !!(
      navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo
    );
    const isSafari =
      ua.indexOf("safari") != -1 && !(ua.indexOf("chrome") != -1) && ua.indexOf("version/") != -1;
    return isSafari && hasMediaCapabilities;
  }

  const isSafari = supportsHEVCAlpha();

  return (
    <Box
      component={motion.div}
      {...varFadeIn}
      sx={{
        aspectRatio: "1/1",
        position: "absolute",
        zIndex: 2,
        width: { xxs: "100vw", sm: "600px" },
        margin: { xxs: "-12vw 0 0 -15vw", sm: "-120px 0 0 -136px" }
      }}>
      <Box
        component={isSafari ? "img" : "video"}
        muted
        autoPlay
        playsInline
        src={isSafari ? animatedBigWinUrl : animatedBigWinWebm}
        style={{
          width: "100%",
          height: "100%"
        }}></Box>
    </Box>
  );
}

function SkipAnimation({ sx, needSkip, setNeedSkip }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "4px",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        marginTop: { xxs: "6px", sm: "0" },
        ...sx
      }}
      onClick={() => setNeedSkip(!needSkip)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #AF6AF5",
          width: "12px",
          height: "12px",
          marginTop: "1.5px"
        }}>
        <Box
          sx={{
            transform: `scale(${needSkip ? 1 : 0})`,
            background: "#AF6AF5",
            transition: "216ms",
            width: "6px",
            height: "6px"
          }}
        />
      </Box>
      <Box
        sx={{
          fontSize: "16px",
          lineHeight: 1,
          color: "#AF6AF5"
        }}>
        Skip Animation
      </Box>
    </Box>
  );
}

function PanelTopControl({ sx, isSoundOn, setIsSoundOn, handleSkipButtonClick }) {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const handleHistoryClick = () => {
    dispatch(setWheelDialogOpen(false));
    push("/history");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "11px",
        ...sx
      }}>
      <ImageButton
        onClick={handleHistoryClick}
        sx={{
          aspectRatio: "86/26",
          width: "86px",
          backgroundImage: `url(${historyUrl})`
        }}
      />
      <ImageButton
        onClick={() => handleSkipButtonClick()}
        sx={{
          aspectRatio: "46/26",
          width: "46px",
          backgroundImage: `url(${skipUrl})`
        }}
      />
      <ImageButton
        onClick={() => setIsSoundOn(!isSoundOn)}
        sx={{
          aspectRatio: "36/26",
          width: "36px",
          backgroundImage: `url(${isSoundOn ? soundOnUrl : soundOffUrl})`
        }}
      />
    </Box>
  );
}

function WinTimes({ result, isBigWin, isSmallWin }) {
  const needAnimation = isBigWin || isSmallWin;
  const textSx = {
    fontSize: { xxs: "40px", sm: "72px" },
    fontWeight: 700,
    "letter-spacing": { xxs: "-0.8px", sm: "-1.44px" },
    background: "linear-gradient(180deg, #FCFCBA 0%, #FFA800 100%)",
    "background-clip": "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    "-webkit-text-stroke": "1.5px rgba(0, 32, 98, 1)"
  };
  return (
    <Box
      sx={{
        position: { xxs: "absolute", sm: "static" },
        top: "-42px",
        right: "26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: { xxs: `url(${winTimesMobileUrl})`, sm: `url(${winTimesUrl})` },
        width: { xxs: "102px", sm: "208px" },
        aspectRatio: { xxs: "102/64", sm: "208/118" },
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        paddingTop: { xxs: "4px", sm: "6px" }
      }}>
      <Box
        sx={{
          "@keyframes bounce": {
            from: {
              transform: "scale(1)"
            },
            "20%": {
              transform: "scale(1.2)"
            },
            "35%": {
              transform: "scale(1)"
            },
            "45%": {
              transform: "scale(1.2)"
            },
            "55%": {
              transform: "scale(1)"
            },
            to: {
              transform: "scale(1)"
            }
          },
          animation: needAnimation ? "bounce 1s linear both 0.3s" : "none",
          ...textSx
        }}>
        {result.win_multiplier}X
      </Box>
      {needAnimation && (
        <Box
          sx={{
            "@keyframes fadeOut": {
              from: {
                transform: "scale(2)",
                opacity: "0.5"
              },
              to: {
                transform: "scale(1)",
                opacity: "0"
              }
            },
            animation: "fadeOut 0.8s both",
            position: "absolute",
            zIndex: 2,
            ...textSx
          }}>
          {result.win_multiplier}X
        </Box>
      )}
    </Box>
  );
}

function ScreenSpinWheelPanel({
  result,
  isSmallWin,
  isBigWin,
  handleExitClick,
  handleSpinClick,
  handleRespinClick,
  handleChangeAmountClick,
  handleChangeTimesClick,
  times,
  amount,
  hasPoints,
  nativeBalance,
  totalBet,
  topControlProps,
  needSkip,
  setNeedSkip
}) {
  function AmountSection({ sx, title, children }) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: { xxs: "4px", sm: "8px" },
          "letter-spacing": { xxs: "-0.56px", sm: "-0.72px" },
          fontSize: { xxs: "28px", sm: "36px" },
          color: `#FCFCBA`,
          gap: "8px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          ...sx
        }}>
        {children}
      </Box>
    );
  }

  function InnerSection({ sx, title, children }) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          ...sx
        }}>
        <Box
          sx={{
            color: { xxs: "#eddefd", sm: "#AF6AF5" },
            fontSize: { xxs: "14px", sm: "16px" },
            lineHeight: "1.05"
          }}>
          {title}
        </Box>
        {children}
      </Box>
    );
  }

  function NumberControl({ value, onMinusClick, onPlusClick }) {
    return (
      <Box
        sx={{
          display: "flex",
          gap: "8px"
        }}>
        <ImageButton
          onClick={onMinusClick}
          sx={{
            aspectRatio: "36/26",
            width: "36px",
            backgroundImage: `url(${minusUrl})`
          }}
        />
        <Box
          sx={{
            textAlign: "center",
            aspectRatio: "96/26",
            width: "96px",
            fontSize: "24px",
            lineHeight: "25px",
            backgroundImage: `url(${amountBgUrl})`,
            backgroundSize: "contain"
          }}>
          {value}
        </Box>
        <ImageButton
          onClick={onPlusClick}
          sx={{
            aspectRatio: "36/26",
            width: "36px",
            backgroundImage: `url(${addUrl})`
          }}
        />
      </Box>
    );
  }

  function FormatAmount({ value, needFixed = true }) {
    return (
      <Box
        sx={{
          fontSize: { xxs: "28px", sm: "36px" },
          lineHeight: 0.6
        }}>
        {needFixed ? displayNum(value) : withComma(value)}
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          height: { xxs: "auto", sm: "390px" }
        }}>
        <Box
          style={{
            gridTemplateColumns: `1fr 1fr 1fr`,
            gridTemplateRows: `1fr 1fr 1fr`,
            gridTemplate: `
                        'a a e'
                        'b b .'
                        'c d .'
                      `
          }}
          sx={{
            display: { xxs: "flex", sm: "flex" },
            flexDirection: "column",
            gap: { xxs: "6px", sm: "20px" }
          }}>
          <PanelTopControl
            {...topControlProps}
            sx={{
              display: { xxs: "none", sm: "flex" }
            }}
          />
          <Tooltip
            leaveTouchDelay={0}
            enterTouchDelay={0}
            title={
              hasPoints &&
              `Prioritize using KOKORO, and the winning portion will be transferred to AGI. 1 KOKORO = 1 AGI`
            }>
            <Box>
              <AmountSection
                title="AGI Balance:"
                sx={{
                  position: "relative",
                  marginTop: { xxs: 0, sm: "10px" },
                  backgroundImage: {
                    xxs: `url(${hasPoints ? pointBalanceMobileUrl : agiBalanceMobileUrl})`,
                    sm: `url(${hasPoints ? pointBalanceUrl : agiBalanceUrl})`
                  },
                  width: { xxs: "184px", sm: "208px" },
                  aspectRatio: { xxs: "184/48", sm: "208/72" },
                  gridArea: "c"
                }}>
                <FormatAmount value={nativeBalance} needFixed={false} />
              </AmountSection>
            </Box>
          </Tooltip>
          <AmountSection
            title="Total Bet:"
            sx={{
              marginTop: { xxs: "10px", sm: "-7px" },
              backgroundImage: { xxs: `url(${totalBetMobileUrl})`, sm: `url(${totalBetUrl})` },
              width: { xxs: "184px", sm: "208px" },
              aspectRatio: { xxs: "184/48", sm: "208/72" },
              gridArea: "d"
            }}>
            <FormatAmount value={totalBet} needFixed={false} />
          </AmountSection>
          {result ? (
            <WinTimes result={result} isBigWin={isBigWin} isSmallWin={isSmallWin} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xxs: "10px", sm: "20px" },
                backgroundImage: { xxs: `url(${controlBgMobileUrl})`, sm: "none" },
                backgroundRepeat: "no-repeat",
                position: { xxs: "absolute", sm: "static" },
                width: { xxs: "308px", sm: "100%" },
                aspectRatio: { xxs: "308/127", sm: "initial" },
                paddingTop: { xxs: "8px", sm: "0" },
                top: "-160px",
                left: "50%",
                transform: { xxs: "translateX(-50%)", sm: "none" },
                backgroundSize: "contain"
              }}>
              <InnerSection
                title="Single Spin Amount:"
                sx={{
                  gridArea: "a"
                }}>
                <NumberControl
                  value={amount}
                  onPlusClick={() => handleChangeAmountClick(true)}
                  onMinusClick={() => handleChangeAmountClick(false)}
                />
              </InnerSection>
              <InnerSection
                title="Single Times:"
                sx={{
                  gridArea: "b",
                  marginBottom: { xxs: "6px", sm: "0" }
                }}>
                <NumberControl
                  value={times}
                  onPlusClick={() => handleChangeTimesClick(true)}
                  onMinusClick={() => handleChangeTimesClick(false)}
                />
              </InnerSection>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xxs: "flex-start", sm: "center" },
          position: { xxs: "absolute", sm: "static" },
          right: "26px",
          top: "32px"
        }}>
        {!result ? (
          <ImageButton
            onClick={handleSpinClick}
            sx={{
              ...primaryButtonSx,
              mb: { xxs: "11px", sm: "17px" },
              backgroundImage: { xxs: `url(${playMobileUrl})`, sm: `url(${playUrl})` }
            }}
          />
        ) : (
          <ImageButton
            onClick={handleRespinClick}
            sx={{
              ...primaryButtonSx,
              mb: { xxs: "11px", sm: "17px" },
              backgroundImage: { xxs: `url(${respinMobileUrl})`, sm: `url(${respinUrl})` }
            }}
          />
        )}
        <ImageButton
          onClick={handleExitClick}
          sx={{
            ...primaryButtonSx,
            backgroundImage: { xxs: `url(${exitMobileUrl})`, sm: `url(${exitUrl})` }
          }}
        />
      </Box>
    </Box>
  );
}

function TotalAGIWon({ hasPoints, result }) {
  if (!result) return null;

  return (
    <Box
      component={motion.div}
      // {...varFadeIn}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: { xxs: "11px", sm: "12px" },
        position: "absolute",
        top: { xxs: "min(74vw, 340px)", sm: "376px" },
        left: { xxs: "50%", sm: "36%" },
        transform: "translate3d(-50%, 0, 0)",
        backgroundImage: { xxs: `url(${totalAGIWonMobileUrl})`, sm: `url(${totalAGIWonUrl})` },
        width: { xxs: "331px", sm: "374px" },
        aspectRatio: { xxs: "331/104.5", sm: "374/115" },
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        zIndex: 2
      }}>
      <Box
        sx={{
          fontSize: { xxs: "50px", sm: "56px" },
          fontWeight: 700,
          "letter-spacing": { xxs: "-0.8px", sm: "-2px" },
          background: "linear-gradient(180deg, #FFFBDD 28.65%, #FFDA15 100%)",
          "background-clip": "text",
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
          "-webkit-text-stroke": "1px rgba(166, 40, 0, 1)"
        }}>
        {hasPoints ? result.reward : result.totalReward || result.reward}{" "}
        {hasPoints && result.win_multiplier < 1 ? "KOKORO" : "$AGI"}
      </Box>
    </Box>
  );
}

function ScreenViewports({ isBigWin, hasPoints, result, wheelContainer, wheel }) {
  return (
    <Box>
      <TotalAGIWon result={result} hasPoints={hasPoints} />
      {isBigWin && <ScreenBigWinViewport />}
      <ScreenSpinWheelViewport wheelContainer={wheelContainer} wheel={wheel} />
    </Box>
  );
}

function ScreenPanels({
  spinPanelProps,
  resultPanelProps,
  topControlProps,
  isSpinning,
  history,
  result,
  handleExitClick,
  handleRespinClick,
  handleSpinClick,
  wheel
}) {
  return (
    <>
      {false ? (
        <ScreenResultPanel
          {...resultPanelProps}
          topControlProps={topControlProps}
          history={history}
          result={result}
          handleRespinClick={handleRespinClick}
          handleExitClick={handleExitClick}
          handleSpinClick={handleSpinClick}
        />
      ) : (
        <ScreenSpinWheelPanel
          {...spinPanelProps}
          result={result}
          topControlProps={topControlProps}
          isSpinning={isSpinning}
          handleExitClick={handleExitClick}
          handleRespinClick={handleRespinClick}
          handleSpinClick={handleSpinClick}
          wheel={wheel}
        />
      )}
    </>
  );
}

function ScreenSpinWheel({
  spinPanelProps,
  resultPanelProps,
  topControlProps,
  isSpinning,
  isVertical,
  isBigWin,
  wheelContainer,
  wheel,
  history,
  result,
  handleSpinClick,
  handleRespinClick,
  handleExitClick
}) {
  return (
    <Box component={motion.div} {...varFadeIn}>
      <PanelTopControl
        {...topControlProps}
        sx={{
          position: "absolute",
          width: "100%",
          right: "29px",
          top: "-36px",
          justifyContent: "flex-end",
          display: { xxs: "flex", sm: "none" }
        }}
      />
      <ScreenLayout
        isVertical={isVertical}
        viewport={
          <ScreenViewports
            {...spinPanelProps}
            result={result}
            isBigWin={isBigWin}
            wheelContainer={wheelContainer}
            wheel={wheel}
          />
        }
        panel={
          <ScreenPanels
            topControlProps={topControlProps}
            resultPanelProps={resultPanelProps}
            spinPanelProps={spinPanelProps}
            isSpinning={isSpinning}
            history={history}
            result={result}
            handleRespinClick={handleRespinClick}
            handleExitClick={handleExitClick}
            handleSpinClick={handleSpinClick}
            wheel={wheel}
          />
        }
      />
    </Box>
  );
}

const isBigNum = (num) => {
  return [10, 100, 1000].includes(num);
};

function HistoryTableColumn({ history, size }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        fontSize: "12px",
        flex: 1,

        "& > *": {
          display: "flex",
          height: "17px",
          lineHeight: "16px",

          "&:nth-child(odd)": {
            background: `rgba(52, 0, 77, 0.5)`
          },

          "&:nth-child(even)": {
            background: `rgba(175, 106, 245, 0.30)`
          },

          "& > *:first-child": {
            textAlign: "center",
            flex: 35
          },

          "& > *:last-child": {
            textAlign: "center",
            flex: 50
          }
        }
      }}>
      <Box>
        <Box>Round</Box>
        <Box>Win Times</Box>
      </Box>
      {[...Array(size)].map((_, i) => {
        const el = history[i] || {
          round: "",
          win_multiplier: ""
        };

        return (
          <Box>
            <Box>{el.round}</Box>
            <Box
              sx={{
                color: isBigNum(el.win_multiplier) ? "#eed84d" : "#fff"
              }}>
              {el.win_multiplier}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

function HistoryTable({ history, pageSize, pageIndex, setPageIndex }) {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const offset = pageSize * pageIndex;
  const currentPageHistory = [...history].slice(offset, pageSize * (pageIndex + 1));

  const handlePageClick = (isUp) => {
    if (isUp) {
      if (pageIndex <= 0) return;
      setPageIndex(pageIndex - 1);
    } else {
      if (currentPageHistory.length === 0) return;
      setPageIndex(pageIndex + 1);
    }
  };

  const handleHistoryClick = () => {
    dispatch(setWheelDialogOpen(false));
    push("/history");
  };
  const midIndex = Math.floor(pageSize / 2);
  const firstColumn = currentPageHistory.slice(0, midIndex);
  const secondColumn = currentPageHistory.slice(midIndex);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: { xxs: "183px", sm: "100%" },
          border: "0.5px solid #AF6AF5"
        }}>
        <HistoryTableColumn history={firstColumn} size={midIndex} />
        <HistoryTableColumn history={secondColumn} size={midIndex} />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          marginTop: "14px"
        }}>
        <ImageButton
          onClick={() => handlePageClick(true)}
          sx={{
            aspectRatio: "36/26",
            width: "36px",
            backgroundImage: `url(${upUrl})`
          }}
        />
        <ImageButton
          onClick={() => handlePageClick(false)}
          sx={{
            aspectRatio: "36/26",
            width: "36px",
            backgroundImage: `url(${downUrl})`
          }}
        />
        <ImageButton
          onClick={handleHistoryClick}
          sx={{
            aspectRatio: "96/26",
            width: "96px",
            backgroundImage: `url(${historyUrl})`
          }}
        />
      </Box>
    </Box>
  );
}

function ScreenResultPanel({
  history,
  pageSize,
  pageIndex,
  setPageIndex,
  result,
  handleExitClick,
  handleRespinClick
}) {
  return (
    <Box>
      <Box
        sx={{
          height: { xxs: "auto", sm: "320px" }
        }}>
        <HistoryTable
          history={history}
          pageSize={pageSize}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
        <Box
          sx={{
            position: { xxs: "absolute", sm: "static" },
            right: "35px",
            top: "0px",
            display: "flex",
            flexDirection: "column",
            minWidth: "100px",
            alignItems: { xxs: "center  ", sm: "center" },
            gap: "12px",
            color: "#FAE232",
            mt: "24px"
          }}>
          <Box
            sx={{
              fontSize: { xxs: "16px", sm: "18px" },
              lineHeight: "12px",
              fontWeight: 400,
              color: "#FFF",
              // "-webkit-text-stroke-width": "0.4px",
              // "-webkit-text-stroke-color": "rgba(175, 106, 245, 1)",
              "text-shadow": `-0.5px -0.5px 0 rgba(175, 106, 245, 1),
                0   -0.5px 0 rgba(175, 106, 245, 1),
                0.5px -0.5px 0 rgba(175, 106, 245, 1),
                0.5px  0   0 rgba(175, 106, 245, 1),
                0.5px  0.5px 0 rgba(175, 106, 245, 1),
                0    0.5px 0 rgba(175, 106, 245, 1),
                -0.5px  0.5px 0 rgba(175, 106, 245, 1),
                -0.5px  0   0 rgba(175, 106, 245, 1)`
            }}>
            TOTAL $AGI WON
          </Box>
          <Box
            sx={{
              fontSize: { xxs: "24px", sm: "32px" },
              lineHeight: { xxs: "12px", sm: "18px" }
            }}>
            {withComma(result.reward)}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          position: { xxs: "absolute", sm: "static" },
          right: "35px",
          top: "78px"
        }}>
        <ImageButton
          onClick={handleRespinClick}
          sx={{
            ...primaryButtonSx,
            mb: { xxs: "11px", sm: "17px" },
            backgroundImage: { xxs: `url(${respinMobileUrl})`, sm: `url(${respinUrl})` }
          }}
        />
        <ImageButton
          onClick={handleExitClick}
          sx={{
            ...primaryButtonSx,
            backgroundImage: { xxs: `url(${exitMobileUrl})`, sm: `url(${exitUrl})` }
          }}
        />
      </Box>
    </Box>
  );
}

function ScreenBigWin({ isVertical }) {
  return (
    <Box component={motion.div} {...varFadeIn}>
      <ScreenLayout
        isVertical={isVertical}
        viewport={<ScreenBigWinViewport />}
        panel={<ScreenResultPanel />}
      />
    </Box>
  );
}

function ScreenLayout({ isVertical, viewport, panel }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isVertical ? "column" : "row",
        padding: isVertical ? "0" : "0 0 0 152px"
      }}>
      <Box
        className={"viewport"}
        sx={{
          alignSelf: "flex-start",
          width: isVertical ? "72vw" : "366px",
          margin: isVertical ? "3.589vw 0 0 15.42vw" : "18px 0 0 0",
          aspectRatio: "1/1"
        }}>
        {viewport}
      </Box>
      <Box
        className={"panel"}
        sx={{
          position: "relative",
          margin: isVertical ? "41vw 0 0 0" : "0 0 0 125px",
          padding: isVertical ? "20px 32px 20px 32px" : "43px 38px 68px 38px",
          borderLeft: isVertical ? "none" : "none",
          borderTop: isVertical ? "none" : "none",
          height: isVertical ? "auto" : "548px",
          minHeight: isVertical ? "300px" : "0",
          background: isVertical ? "none" : "none"
        }}>
        {panel}
      </Box>
    </Box>
  );
}

function ScreenManager({ isVertical, handleClose }) {
  const dispatch = useDispatch();
  const downXs = useBreakpoints("down", "xs");
  const { balance, wheelDialogOpen } = useSelector((state) => state.DMA);
  const [isSpinning, setIsSpinning] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("spinWheel");
  const [needSkip, setNeedSkip] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [amount, setAmount] = useState(100);
  const [times, setTimes] = useState(1);
  const [currentTimeIndex, setCurrentTimeIndex, currentTimeIndexRef] = useState(0);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [history, setHistory, historyRef] = useState([
    // {round: 1, win_multiplier: 0.5, reward: 10 }, {round: 2, win_multiplier: 10, reward: 100 }, {round: 3, win_multiplier: 0.1, reward: 1 }
  ]);
  const [results, setResults, resultsRef] = useState([
    // {win_multiplier: 0.5, reward: 10 }, {win_multiplier: 10, reward: 100 }, {win_multiplier: 0.1, reward: 1 }
  ]);
  const currentResult = resultsRef.current[currentTimeIndexRef.current] || null;
  const isBigWin = [10, 100, 1000].includes(currentResult?.win_multiplier);
  const isSmallWin = [1.5, 2, 3].includes(currentResult?.win_multiplier) && !currentResult?.showed;
  const hasPoints = balance.find((el) => el.currency === currencies.POINT)?.amount >= 10;
  const nativeBalance = Number(
    balance.find((item) => item.currency === (hasPoints ? currencies.POINT : currencies.native))
      ?.amount || 0
  );
  const totalBet = amount * times;
  const [playBg, { stop: stopBg, duration, sound }] = useSound(bgAudioUrl, {
    loop: true,
    volume: 0.7,
    soundEnabled: isSoundOn
  });
  const [playSpin] = useSound(spinAudioUrl, {
    soundEnabled: isSoundOn
  });
  const [playExit] = useSound(exitAudioUrl, {
    soundEnabled: isSoundOn
  });
  const [playBigWin] = useSound(bigWinAudioUrl, {
    soundEnabled: isSoundOn
  });
  const [playSmallWin] = useSound(smallWinAudioUrl, {
    soundEnabled: isSoundOn
  });

  const [wheel, setWheel] = useState();
  const wheelContainer = useRef(null);

  const wheelItems = [
    {
      value: 1000
    },
    {
      value: 0.2
    },
    {
      value: 1.5
    },
    {
      value: 0.3
    },
    {
      value: 100
    },
    {
      value: 0.8
    },
    {
      value: 2
    },
    {
      value: 0.5
    },
    {
      value: 10
    },
    {
      value: 1.2
    },
    {
      value: 3
    },
    {
      value: 0.1
    }
  ];

  useEffect(() => {
    if (!wheelContainer || wheel) return;
    setWheel(
      new Wheel(wheelContainer.current, {
        isInteractive: false,
        image: spinWheelUrl,
        rotation: 0,
        radius: 1,
        pointerAngle: 15,
        itemBackgroundColors: ["transparent"],
        lineColor: "transparent",
        borderColor: "transparent",
        items: wheelItems
      })
    );
  }, [wheelContainer]);

  useEffect(() => {
    if (wheelDialogOpen && duration) {
      playBg();
    } else {
      stopBg();
    }

    return () => {
      stopBg();
    };
  }, [wheelDialogOpen, duration]);

  useEffect(() => {
    if (!sound) return;
    sound.mute(!isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    if (!isBigWin && wheel) {
      wheelContainer.current.append(wheel.canvas);
    }
    if (isBigWin && !needSkip) {
      playBigWin();
    }
  }, [currentTimeIndex, needSkip, isBigWin]);

  useEffect(() => {
    if (isSmallWin && !needSkip) {
      playSmallWin();
    }
  }, [currentTimeIndex, needSkip, isSmallWin]);

  if (wheel) {
    wheel.onSpin = () => {
      // console.log('changed')
    };
  }

  const setBalanceByManual = (amount) => {
    const bal = [
      {
        currency: hasPoints ? currencies.POINT : currencies.native,
        amount
      }
    ];
    dispatch(setBalance(bal));
  };

  const playNextResult = (initDuration = spinDuration) => {
    if (nextResultTimer) {
      clearTimeout(nextResultTimer);
    }
    const resetResults = [...resultsRef.current];
    resetResults[currentTimeIndexRef.current].showed = true;
    setResults(resetResults);
    setHistory([
      ...historyRef.current,
      { ...resetResults[currentTimeIndexRef.current], round: historyRef.current.length + 1 }
    ]);
    const nextIndex = currentTimeIndexRef.current + 1;
    const nextResult = resultsRef.current[nextIndex];
    const duration = needSkip ? 0 : initDuration;
    if (!nextResult) {
      dispatch(getRewardsBalance());
      pendingResults = null;
      setNeedSkip(false);
      setIsSpinning(false);
      return;
    }
    spinToItem(nextResult, duration);

    if (!duration) {
      setBalanceByManual(nativeBalance + resetResults[currentTimeIndexRef.current].reward);
      setCurrentTimeIndex(nextIndex);
      playNextResult(0);
      return;
    }

    nextResultTimer = setTimeout(() => {
      setBalanceByManual(nativeBalance + resetResults[currentTimeIndexRef.current].reward);
      setCurrentTimeIndex(nextIndex);
      setTimeout(() => playNextResult(), needSkip ? 10 : 450);
    }, duration + 0);
  };

  const setInitResultTimer = (results, duration) => {
    if (initResultTimer) {
      clearTimeout(initResultTimer);
    }

    initResultTimer = setTimeout(() => {
      // setResults([{win_multiplier: 1.5, reward: 10 }, {win_multiplier: 2, reward: 10 }, {win_multiplier: 10, reward: 100 }, {win_multiplier: 0.1, reward: 1 }])
      // setResults([{win_multiplier: 10, reward: 10 }])
      setResults(results);
      if (resultsRef.current.length === 1) {
        dispatch(getRewardsBalance());
        setHistory([
          ...historyRef.current,
          { ...results[0], round: historyRef.current.length + 1 }
        ]);
        setIsSpinning(false);
        pendingResults = null;
      } else {
        setCurrentTimeIndex(0);
        setBalanceByManual(nativeBalance + results[0].reward);
        playNextResult(duration);
      }
      console.log("currentResults", resultsRef.current, currentResult);
    }, duration + 50);
  };

  const spinToItem = (result, duration) => {
    const index = wheelItems.findIndex((el) => el.value == result?.win_multiplier);
    wheel.spinToItem(index, duration, true, 2, 1, easeOutExpo);
  };

  const handleSpinClick = async () => {
    if (nativeBalance < totalBet) {
      dispatch(setAccountNotification("Insufficient balance"));
      return;
    }

    if (isSpinning) return;
    let res;

    playSpin();
    setIsSpinning(true);
    setResults([]);
    const duration = needSkip ? 0 : spinDuration;
    try {
      if (!needSkip) {
        wheel.spinTo(72000, 300000, (t) => t);
      }
      setBalanceByManual(nativeBalance - totalBet);
      res = await dispatch(spinToWin({ agi_to_spend: String(amount), spin_number: times }));
      res.map((el, i) => {
        const lastEl = res[i - 1];
        el.reward = Number(el.reward);
        el.totalReward = Number(el.reward) + (lastEl?.totalReward || lastEl?.reward || 0);
        el.win_multiplier = Number(el.win_multiplier);
        return el;
      });
      spinToItem(res[0], duration);
      pendingResults = res;
      setInitResultTimer(res, duration);
    } catch (error) {
      setIsSpinning(false);
      wheel.stop();
    }
  };

  const handleExitClick = () => {
    playExit();
    handleClose();
  };

  const handleRespinClick = () => {
    if (isSpinning) {
      dispatch(setAccountNotification("Wheel is spinning"));
      return;
    }
    setResults([]);
  };

  const handleChangeAmountClick = (isAdd) => {
    const i = amountList.findIndex((el) => el === amount);
    if (isAdd) {
      const nextAmount = amountList[i + 1];
      if (nextAmount) setAmount(nextAmount);
    } else {
      const nextAmount = amountList[i - 1];
      if (nextAmount) setAmount(nextAmount);
    }
  };

  const handleChangeTimesClick = (isAdd) => {
    const i = timesList.findIndex((el) => el === times);
    if (isAdd) {
      const nextVal = timesList[i + 1];
      if (nextVal) setTimes(nextVal);
    } else {
      const nextVal = timesList[i - 1];
      if (nextVal) setTimes(nextVal);
    }
  };

  const handleSkipButtonClick = () => {
    if (!isSpinning || !pendingResults) return;
    wheel.stop();
    const nextIndex = currentTimeIndexRef.current + 1;
    const nextResult = resultsRef.current[nextIndex];
    if (nextResult) {
      setNeedSkip(true);
      playNextResult(0);
    } else {
      spinToItem(pendingResults[0], 0);
      setInitResultTimer(pendingResults, 0);
    }
  };

  const topControlProps = {
    isSoundOn,
    setIsSoundOn,
    handleSkipButtonClick
  };

  const spinPanelProps = {
    times,
    amount,
    hasPoints,
    nativeBalance,
    totalBet,
    needSkip,
    isBigWin,
    isSmallWin,
    needSkip,
    setNeedSkip,
    handleChangeAmountClick,
    handleChangeTimesClick
  };

  const resultPanelProps = {
    pageIndex,
    setPageIndex,
    pageSize: downXs ? 10 : 20
  };

  return (
    <AnimatePresence initial={false}>
      <Box
        sx={{
          position: "relative",
          height: "100%",

          "& > *": {
            position: "absolute",
            inset: 0
          }
        }}>
        {currentScreen === "spinWheel" && (
          <ScreenSpinWheel
            topControlProps={topControlProps}
            spinPanelProps={spinPanelProps}
            resultPanelProps={resultPanelProps}
            isSpinning={isSpinning}
            isBigWin={isBigWin}
            isSmallWin={isSmallWin}
            history={history}
            result={currentResult}
            handleSpinClick={handleSpinClick}
            handleRespinClick={handleRespinClick}
            handleExitClick={handleExitClick}
            isVertical={isVertical}
            setCurrentScreen={setCurrentScreen}
            wheel={wheel}
            wheelContainer={wheelContainer}
          />
        )}
        {currentScreen === "bigWin" && <ScreenBigWin isVertical={isVertical} />}
      </Box>
    </AnimatePresence>
  );
}

function DesktopRender({ handleClose }) {
  return (
    <Box
      sx={{
        aspectRatio: "900/550",
        width: "900px",
        backgroundImage: `url(${desktopBgUrl})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        border: "1px solid #AF6AF5"
      }}>
      <ScreenManager handleClose={handleClose} />
    </Box>
  );
}

function MobileRender({ handleClose }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100dvh"
      }}>
      <Box
        src={logoUrl}
        component="img"
        onClick={() => window.open("https://delysium.com/", "_blank")}
        sx={{
          cursor: "pointer",
          ml: "20px",
          mr: "6px",
          mt: "10px",
          mb: "6px",
          width: "70px",
          aspectRatio: "70/34.35",
          display: "flex"
        }}
      />
      <Box
        sx={{
          aspectRatio: "1125/1785",
          width: "100vw",
          backgroundImage: `url(${mobileBgUrl})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat"
        }}>
        <ScreenManager isVertical handleClose={handleClose} />
      </Box>
    </Box>
  );
}

export function WheelDialog(props) {
  const dispatch = useDispatch();
  const { wheelDialogOpen } = useSelector((state) => state.DMA);
  const downXs = useBreakpoints("down", "xs");

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    dispatch(setWheelDialogOpen(false));
  };

  return (
    <Dialog
      fullWidth={downXs}
      maxWidth={"100vw"}
      open={wheelDialogOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          backgroundImage: "none",
          boxShadow: "none",
          marginTop: downXs ? 0 : "16px",
          marginBottom: downXs ? 0 : "16px",
          maxHeight: "100dvh"
        },
        sx: {
          ".MuiDialogContent-root": {
            padding: downXs ? 0 : "24px",
            fontFamily: "Pixel Operator"
          }
        }
      }}
      BackdropProps={{
        style: {
          backgroundColor: downXs ? "rgba(0,0,0,1)" : "rgba(0,0,0,0.8)"
        }
      }}>
      <DialogContent sx={{ ...props?.sx, overflowX: "hidden" }}>
        {downXs ? (
          <MobileRender handleClose={handleClose} />
        ) : (
          <DesktopRender handleClose={handleClose} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default WheelDialog;
