export const getAugmentedStyle = ({
  color = "#FFFFFF",
  borderSize = "1px",
  bottomBorderSize = null,
  tl = "7px",
  tr = "7px",
  bl = "7px",
  br = "7px",
  //if bl clip doesnt work change bl1 to 1px good luck :)
  bl1 = "calc(var(--aug-bl1) * 6)",
  backgroundColor = ""
}) => {
  return {
    backgroundColor: backgroundColor,
    border: "none",
    "--aug-tl": tl,
    "--aug-tr": tr,
    "--aug-bl": bl,
    "--aug-br": br,
    "--aug-border-all": borderSize,
    "--aug-border-bg": color,
    "--aug-border-bottom": bottomBorderSize ? bottomBorderSize : borderSize,
    "--aug-bl-inset1": bl1,
    "--aug-b-extend1": "37%",
    "--aug-t-extend1": "17.5%",
    "--aug-t-center": "31.32%",
    "--aug-t": "14px",
    "--aug-b": "14px"
  };
};
