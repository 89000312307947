import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getAugmentedStyle } from "../utils/augmentedStyles";
import CustomButton from "./Buttons/Button";
import { InfoCardSx } from "./InfoCard";
import CopyText from "./CopyText";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import InvitationCardDialog from "../views/Dashboard/DMA/InvitationCardDialog";
import { useState } from "react";
import { config } from "../config";
import useBreakpoints from "../hooks/useBreakpoints";

const ReferralCodeBox = () => {
  const theme = useTheme();
  const downMd = useBreakpoints("down", "md");
  const { referral } = useSelector((state) => state.DMA);
  const [openInvitationCard, setOpenInvitationCard] = useState(false);

  return (
    <Box position="relative">
      <Box
        src={
          downMd
            ? "https://storage.delysium.com/ui/dm_series/referral_coins_mobile.png"
            : "https://storage.delysium.com/ui/dm_series/referral_coins_web.png"
        }
        component="img"
        alt="A picture of Des Token and USDT token coins in the air"
        sx={{
          position: "absolute",
          bottom: 0,
          left: { xxs: "50%", md: 0 },
          top: { xxs: "-15%", md: "initial" },
          zIndex: 10,
          width: { xxs: "initial", md: "45%" },
          height: { xxs: "75%", md: "auto" },
          transform: { xxs: "translateX(-50%)", md: "initial" }
        }}
      />
      <Box
        data-augmented-ui="tl-clip br-clip"
        style={getAugmentedStyle({
          color: theme.palette.primary.main
        })}
        sx={{
          ...InfoCardSx,
          mt: 6.5,
          minHeight: { xxs: "309px", md: "190px" },
          display: "flex",
          flexFlow: { xxs: "column nowrap", md: "row nowrap" },
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: (theme) => theme.palette.primary.main
        }}>
        {/* This box is fake (placeholder) wto leave space for the absolute positioned coin image */}
        <Box sx={{ width: { xxs: "100%", md: "45%" } }} />
        <Box
          sx={{
            display: "flex",
            flexFlow: "column nowrap",
            width: { xxs: "100%", md: "55%" },
            maxWidth: "310px"
          }}>
          <Typography color="black" variant="caption" textTransform="capitalize">
            Your Referral Code
          </Typography>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "space-between",
              alignItems: "flex-end"
            }}>
            {referral.code ? (
              <CopyText
                label={referral.code}
                textToCopy={referral.code}
                color="black"
                captionSx={{ ml: 0.1 }}
                iconStyle={{ marginLeft: "7px" }}
                buttonSx={{
                  fontWeight: 500,
                  color: "black",
                  mr: -0.25,
                  fontSize: { xxs: "40px", md: "50px" },
                  fontFamily: "Teko"
                }}
              />
            ) : (
              <Typography color="black" variant="caption" width="125px" fontWeight={700}>
                Buy DMA to obtain your referral code
              </Typography>
            )}

            <CustomButton
              augmented="tl-clip br-clip"
              size="small"
              handleClick={() => {
                if (referral.code) setOpenInvitationCard(true);
                else window.location.assign(`${config.MARKETPLACE_CLIENT_URL}dma`);
              }}
              sx={{
                height: { xxs: "28px", md: "32px" },
                width: { xxs: "90px", md: "102px" },
                backgroundColor: "black",
                fontWeight: 400,
                color: (theme) => theme.palette.primary.main,
                "&:hover": {
                  opacity: 0.9,
                  backgroundColor: "black"
                }
              }}>
              {referral.code ? "Invite" : "Buy DMA"}
            </CustomButton>
          </Box>
          <Typography color="black" variant="caption" sx={{ mt: 2 }}>
            Share your referral code to others to get referral rewards. The rewards can be directly
            claimed to your wallet.
          </Typography>
        </Box>
      </Box>
      <InvitationCardDialog
        open={openInvitationCard}
        setClose={() => setOpenInvitationCard(false)}
      />
    </Box>
  );
};

export default ReferralCodeBox;
