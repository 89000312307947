import DiscordBindView from "../views/Discord/Bind";
import { PATH_DISCORD } from "./paths";

const DiscordRoutes = {
  path: PATH_DISCORD.bind,
  exact: true,
  component: () => <DiscordBindView />
};

export default DiscordRoutes;
