import { Box } from "@mui/system";
import React from "react";
import { NavLink } from "react-router-dom";
import Container from "../../components/Container";
import { PageHeading } from "../../components/PageHeading";
import { PATH_HISTORY } from "../../routes/paths";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import useBreakpoints from "../../hooks/useBreakpoints";

const activeStyle = {
  background: "#fff",
  color: "#000",
  opacity: 1
};

const activeStyleMobile = {
  opacity: 1
};

function Tab({ to = "", title }) {
  const upXs = useBreakpoints("up", "xs");
  const augmentedStyles = getAugmentedStyle({ color: "#fff" });

  return (
    <Box
      data-augmented-ui={!upXs ? "" : "tr-clip border"}
      style={!upXs ? {} : { ...augmentedStyles, "--aug-border-bottom": "0px" }}
      activeStyle={!upXs ? activeStyleMobile : activeStyle}
      sx={{
        borderBottom: "0px solid transparent",
        fontFamily: "Chakra Petch",
        color: "#fff",
        whiteSpace: "nowrap",
        padding: { xxs: "0", xs: "8px 24px" },
        textDecoration: "none",
        width: { xs: "143px" },
        textAlign: "center",
        fontSize: "14px",
        fontWeight: { xxs: "600", xs: "normal" },
        mr: { xxs: 1.5, xs: 1 },
        opacity: 0.7,
        transition: "all 0.3s ease"
      }}
      to={to}
      component={NavLink}>
      {title}
    </Box>
  );
}

const ActivityHistoryLayout = ({ children }) => {
  return (
    <Container
      sx={{ width: "100%", height: "100%", pb: 0, display: "flex", flexFlow: "column nowrap" }}>
      <PageHeading sx={{ display: { xxs: "none", xs: "initial" } }} title="Activity History" />
      <Box sx={{ display: "flex", flexFlow: "row nowrap", mt: { xxs: 0, xs: 4 } }}>
        <Tab to={PATH_HISTORY.rewards} title="Rewards" />
        <Tab to={PATH_HISTORY.transfer} title="Transfer" />
        <Tab to={PATH_HISTORY.referral} title="DMA Referral" />
        <Tab to={PATH_HISTORY.referralDetails} title="Referral Details" />
      </Box>
      {children}
    </Container>
  );
};

export default ActivityHistoryLayout;
