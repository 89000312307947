import { Divider, Typography, alpha } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import WalletLogin from "./WalletLogin";
import { useSelector } from "react-redux";
import { ReactComponent as QuestMarkIcon } from "../../../assets/icons/question_mark.svg";
import CustomDialog from "../Dialog";
import { PATH_EXTERNAL } from "../../../routes/paths";
import { WALLET_CONFIG } from "../../../redux/slices/wallet";

const WalletDialog = ({ handleClose, ...props }) => {
  const { walletDialogOpen } = useSelector((state) => state.wallet);

  return (
    <CustomDialog
      BackdropProps={{
        sx: {
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7)
        }
      }}
      PaperProps={{
        sx: {
          width: {
            xxs: 335,
            md: "384px"
          },
          px: 2.25,
          pt: 1,
          pb: 0.5
        }
      }}
      setClose={handleClose}
      open={walletDialogOpen}
      {...props}>
      <>
        <Typography component="h2" variant="h5" fontWeight="bold">
          Connect Your Wallet
        </Typography>
        {Object.values(WALLET_CONFIG).map((wallet) => (
          <Box key={wallet.name}>
            <WalletLogin closeDialog={handleClose} wallet={wallet} />
            <Divider />
          </Box>
        ))}

        <Box my={2} display="flex" flexWrap="nowrap" alignItems="center">
          <Box width={20}>
            <QuestMarkIcon />
          </Box>
          <Typography
            color="textSecondary"
            variant="caption"
            sx={{ ml: 2, width: { xs: "100%", sm: "70%" } }}>
            Get help connecting your wallet <br /> Chat with us on{" "}
            <Typography
              variant="caption"
              color="primary"
              component="a"
              target="_blank"
              href={PATH_EXTERNAL.discord.invite}>
              Discord
            </Typography>
          </Typography>
        </Box>
      </>
    </CustomDialog>
  );
};

export default WalletDialog;
