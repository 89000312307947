import { useEffect, useState } from "react";
import { alpha, Box, TextField, Typography, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import OutlinedButton from "../Buttons/OutlinedButton";
import CustomDialog from "./Dialog";
import CustomButton from "../Buttons/Button";
import { PATH_DASHBOARD } from "../../routes/paths";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import { openNewTab } from "../../utils/redirect";
import useBreakpoints from "../../hooks/useBreakpoints";
import { GoogleAuthenticatorTitle } from '../../views/Dashboard/settings'
import SplitByDigitInput from "../SplitByDigitInput";

const google2FAHelpLink = 'https://support.google.com/accounts/answer/1066447'

function StepVerify ({
  google2FACode,
  setGoogle2FACode,
  google2FACodeError,
  setGoogle2FACodeError,
}) {
  return (
    <Box>
      <Box sx={{
        mb: { xxs: "32px", xs: "40px" },
        fontSize: { xxs: "14px", xs: "16px" }
      }}>Enter the 6-digit code from Delysium / Google Authenticator</Box>
      <SplitByDigitInput
        name="google2FACode"
        code={google2FACode}
        setCode={setGoogle2FACode}
        isError={google2FACodeError}
        setIsError={setGoogle2FACodeError}
        inputsSx={{
          width: { xxs: "287px", md: "100%" },
          justifyContent: "space-between"
        }}
        autoFocus={true}
      />
    </Box>
  )
}

function StepLayout ({ LayoutFooter, children }) {
  return (
    <Box sx={{ mt: { xxs: "32px", xs: "40px" } }} >
      <Box>
        { children }
      </Box>
      <LayoutFooter />
    </Box>
  )
}
export function Google2FAVerifyDialog({
  title,
  open,
  setClose,
  onPrimaryClick
}) {
  const dispatch = useDispatch();
  const downXs = useBreakpoints("down", "xs");
  const { user } = useSelector((state) => state.auth);
  const [isProcessing, setIsProcessing] = useState(false);

  const [google2FACode, setGoogle2FACode] = useState('');
  const [google2FACodeError, setGoogle2FACodeError] = useState(true);
  const isPrimaryDisabled = (
    google2FACode.length !== 6 ||
    google2FACodeError
  )

  useEffect(() => {
    if (open) {
      setIsProcessing(false)
      setGoogle2FACode('')
      setGoogle2FACodeError(false)
    }
  }, [open])

  const handlePrimaryButtonClick = async () => {
    let res
    if (isProcessing) return
    setIsProcessing(true)
    try {
      res = await onPrimaryClick(Number(google2FACode))
    } catch (error) {
      res = error
    }
    const code = res?.code
    if (code === 400505 || code === 103009) {
      setGoogle2FACodeError(true)
    }
    setIsProcessing(false)
  }

  function LayoutFooter () {
    return (
      <Box>
        <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: { xxs: "57px", xs: "40px" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            gap: { xxs: 2.5, sm: 0 }
          }}
        >
          <OutlinedButton
            label="Cancel"
            classname="augmented-button-primary"
            color="primary"
            handleClick={setClose}
            sx={{
              display: { xxs: "none", xs: "block" },
              flex: "1 0 auto",
              width: { xxs: "auto", sm: 188 },
              height: { xxs: 32, sm: 37 },
              mr: { xxs: "0", sm: 3 },
              fontSize: { xxs: 12, sm: 16 }
            }}
          />
          <CustomButton
            augmented="tl-clip br-clip"
            isLoading={isProcessing}
            disabled={isPrimaryDisabled}
            sx={{
              mt: 0,
              flex: "1 0 auto",
              flexShrink: 0,
              width: { xxs: "auto", sm: 188 },
              height: { xxs: 32, sm: 37 },
              fontSize: { xxs: 12, sm: 16 }
            }}
            handleClick={handlePrimaryButtonClick}>
            { 'Verify' }
          </CustomButton>
        </Box>
      </Box>
      <Box
        sx={{
          mt: "10px",
          fontSize: "12px",
          lineHeight: "16px",
          cursor: "pointer",
          color: "rgba(255, 255, 255, 0.6)",
          textDecoration: "underline",
        }}
        onClick={ () => openNewTab(google2FAHelpLink) }
      >
        Security Verification Unavailable?
      </Box>
    </Box>
    )
  }

  return (
    <CustomDialog
      hasCloseButton={downXs}
      BackdropProps={{
        sx: {
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7)
        }
      }}
      PaperProps={{
        sx: {
          width: {
            xxs: 335,
            xs: 357.5,
            md: 520
          },
          height: {
            xxs: 450,
            md: 481
          },
          ".MuiDialogContent-root":  {
            display: "flex",
            justifyContent: "center",
            alignItems: { xxs: "flex-start", xs: "center" },
          }
        },
      }}
      setClose={setClose}
      open={open}>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pt: { xxs: 0, sm: 2 },
        pb: { xxs: 0, sm: 4 }, px: { xxs: 0, sm: 5 } }
      }>
        <Box sx={{
          maxWidth: "400px",
          mt: { xxs: "30px", xs: "0" }
        }}>
          <GoogleAuthenticatorTitle text={ title } isHeadline={true} />
          <StepLayout
            LayoutFooter={LayoutFooter}
          >
            <StepVerify
              google2FACode={google2FACode}
              setGoogle2FACode={setGoogle2FACode}
              google2FACodeError={google2FACodeError}
              setGoogle2FACodeError={setGoogle2FACodeError}
            />
          </StepLayout>
        </Box>
      </Box>
    </CustomDialog>
  )
}

export default Google2FAVerifyDialog;
