import React, { useEffect, useState } from "react";
import { Typography, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { COINBASE, METAMASK, WALLETCONNECT } from "../../../utils/wallet";

import OutlinedButton from "../../Buttons/OutlinedButton";
import { setNotification } from "../../../redux/slices/notifications";
import {
  bind,
  bindChallenge,
  isBound,
  setSelectedWallet,
  setIsLoading,
  WALLET_CONNECTION_TYPES
} from "../../../redux/slices/wallet";
import { setAccountNotification } from "../../../redux/slices/accountNotifications";
import { isWalletBrowser } from "../../../utils/isMobile";

function WalletLogin({ closeDialog, wallet }) {
  const { name, hook, connector, Icon } = wallet;
  const dispatch = useDispatch();
  const { isLoading, connectionType } = useSelector((state) => state.wallet);
  const { user } = useSelector((state) => state.auth);
  const { useAccounts, useIsActive, useProvider } = hook;
  const accounts = useAccounts();
  const isActive = useIsActive();
  const provider = useProvider();
  const signer = provider?.getSigner();
  const [activated, setActivated] = useState(false);

  const login = () => {
    dispatch(isBound({ walletAddress: accounts[0] })).then((isBoundRes) => {
      if (isBoundRes) {
        closeDialog();
        dispatch(
          setAccountNotification({
            message: "This wallet is already associated with an existing account."
          })
        );
      } else {
        dispatch(bindChallenge({ email: user?.email, walletAddress: accounts[0] })).then(
          (bindChallengeRes) => {
            signer
              .signMessage(bindChallengeRes.message)
              .then((signature) => {
                dispatch(bind({ walletAddress: accounts[0], signature })).then((response) => {
                  if (response) {
                    closeDialog();
                    dispatch(
                      setAccountNotification({
                        message: "You successfully connect to your wallet!"
                      })
                    );
                  }
                });
              })
              .catch((error) => {
                dispatch(setIsLoading(false));
                closeDialog();
                dispatch(setAccountNotification({ code: 4001 }));
              });
          }
        );
      }
    });
  };

  useEffect(() => {
    if (isActive && activated && connectionType === WALLET_CONNECTION_TYPES.bind) {
      login();
    } else if (isActive && activated && connectionType === WALLET_CONNECTION_TYPES.connect)
      closeDialog();
  }, [isActive, activated, connectionType]);

  return (
    <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" flexWrap="nowrap" alignItems="center">
        <Icon />
        <Typography sx={{ ml: 2, textTransform: "capitalize" }}>{wallet.name}</Typography>
      </Box>

      <OutlinedButton
        disabled={isLoading}
        label={activated ? <CircularProgress color="primary" size={8} /> : "connect"}
        classname="augmented-button-primary"
        color="primary"
        handleClick={() => {
          try {
            // if (isActive) login();
            if (window.ethereum || name === WALLETCONNECT) {
              connector
                .activate()
                .then(() => {
                  setActivated(true);
                  dispatch(setSelectedWallet(name));
                })
                .catch((e) => console.error(e));
            } else if (!window.ethereum) {
              const { host, pathname } = window.location;
              const pathToWalletApp = `${host}/${pathname}`;

              if (name === COINBASE) {
                isWalletBrowser()
                  ? window.location.assign(`https://go.cb-w.com/dapp?cb_url=${pathToWalletApp}`)
                  : window.open(`https://go.cb-w.com/dapp?cb_url=${pathToWalletApp}`);
              } else if (name === METAMASK) {
                isWalletBrowser()
                  ? window.location.assign(`https://metamask.app.link/dapp/${pathToWalletApp}`)
                  : window.open(`https://metamask.app.link/dapp/${pathToWalletApp}`);
              }
            }
          } catch (error) {
            setNotification(error);
          }
        }}
        sx={{
          height: "20px",
          width: "75px",
          fontWeight: "normal",
          backgroundImage: "none",
          color: "text.secondary",
          fontSize: "12px"
        }}
      />
    </Box>
  );
}

export default WalletLogin;
