// ----------------------------------------------------------------------

export default function IconButton({ theme }) {
  return {
    MuiIconButton: {
      variants: [{ props: { color: "default" } }, { props: { color: "inherit" } }],

      styleOverrides: {
        root: {}
      }
    }
  };
}
