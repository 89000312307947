import React, { useEffect, useState } from "react";
import { Button, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import CustomPopover from "../../../components/Popover";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy-gold.svg";
import copyTextToClipboard from "copy-to-clipboard";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { WALLET_CONFIG } from "../../../redux/slices/wallet";

// ----------------------------------------------------------------------

WalletPopover.propTypes = {
  isWalletOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object
};

// ----------------------------------------------------------------------

function WalletPopover({ isWalletOpen, anchorElWal, handleClose }) {
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useAccount } = WALLET_CONFIG[selectedWallet].hook;
  const account = useAccount();
  const [copied, setCopied] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <CustomPopover height="114px" open={isWalletOpen} anchorEl={anchorElWal} onClose={handleClose}>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        {copied && (
          <Typography
            variant="caption"
            textTransform="uppercase"
            align="center"
            color="primary"
            sx={{
              width: "100%",
              position: "absolute",
              top: "10%",
              left: 0,
              transform: "translate(0,-50%)"
            }}>
            copied
          </Typography>
        )}

        <Button
          disableRipple
          variant="outlined"
          color="primary"
          style={{ ...getAugmentedStyle({ color: theme.palette.primary.main }) }}
          data-augmented-ui="tl-clip br-clip border"
          onClick={() => {
            setCopied(true);
            copyTextToClipboard(account);
          }}
          sx={{
            color: (theme) => theme.palette.primary.main
          }}
          endIcon={<CopyIcon sx={{ cursor: "pointer" }} />}>
          {`${account?.substr(0, 6)}...${account?.substr(account?.length - 4, 4)}`}
        </Button>

        <Button
          disableRipple
          variant="outlined"
          color="warning"
          style={{ ...getAugmentedStyle({ color: theme.palette.warning.main }) }}
          data-augmented-ui="tl-clip br-clip border"
          onClick={() => {
            handleClose();
            if (WALLET_CONFIG[selectedWallet].connector?.deactivate)
              WALLET_CONFIG[selectedWallet].connector?.deactivate();
            else WALLET_CONFIG[selectedWallet].connector?.resetState();
          }}>
          Disconnect
        </Button>
      </Box>
    </CustomPopover>
  );
}

export default WalletPopover;
