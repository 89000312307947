import { useState } from "react";
import { Toolbar, AppBar, Container, IconButton, Button } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as WalletIcon } from "../../../assets/icons/wallet/wallet.svg";
import { ReactComponent as WalletWhiteIcon } from "../../../assets/icons/wallet/wallet_white.svg";
import { ReactComponent as UserWhiteIcon } from "../../../assets/icons/user_white.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { AnimatePresence, motion } from "framer-motion";
import BurgerToggleIcon from "./BurgerToggleIcon";
import { varFadeIn } from "../../../components/Animate";
import { PAGES } from "../../../constants/pages";
import { isWalletBrowser } from "../../../utils/isMobile";
import AccountPopover from "../Navbar/AccountPopover";
import WalletPopover from "../Navbar/WalletPopover";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";
import { WALLET_CONFIG } from "../../../redux/slices/wallet";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { __STAGING__ } from "../../../utils/dev";

const CDN_ASSETS = {
  LOGO: __STAGING__
    ? "https://storage.delysium.com/ui/delysium_logo_staging.png"
    : "https://storage.delysium.com/ui/delysium_logo.png"
};

const useStyles = (theme) => {
  return {
    iconButton: {
      "--aug-border-bg": theme.palette.text.primary,
      "--aug-border-all": "1px",
      "--aug-br": "6px",
      "--aug-tl": "6px"
    }
  };
};

const appBarSX = {
  position: "relative",
  width: "initial",
  mt: 1.5,
  backgroundColor: "transparent",
  backgroundImage: "none",
  color: (theme) => theme.palette.text.primary
};

const fullScreenNavSX = {
  position: "fixed",
  paddingTop: "52px",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  zIndex: -1,
  width: "100vw",
  left: 0,
  top: 0,
  minHeight: "100vh",
  background: "#000"
};

const navLinkSx = {
  ml: 4,
  position: "relative",
  display: "block",
  height: "100%",
  padding: "7px",
  margin: (theme) => `0 ${theme.spacing(2)}`,
  textDecoration: "none",
  textTransform: "uppercase",
  transition: "all 0.4s ease"
};

const activeNavLinkSx = (isActive) => {
  if (!isActive) return;
  return {
    "&:hover": {
      background: "none"
    },

    "&::before": {
      content: "''",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: "7px",
      borderLeft: (theme) => `2px solid ${theme.palette.text.primary}`,
      height: "17px",
      width: "1px"
    }
  };
};

const MobileNavbar = () => {
  const theme = useTheme();
  const location = useLocation();
  const { push } = useHistory();
  const classes = useStyles(theme);
  const { primary, text } = theme.palette;
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useIsActive } = WALLET_CONFIG[selectedWallet].hook;
  const [isOpen, setIsOpen] = useState(false);
  const isActive = useIsActive();
  const [accountPopoverOpen, setAccountPopoverOpen] = useState(false);
  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const [anchorElWal, setAnchorElWal] = useState(null);
  const [isWalletPopoverOpen, setIsWalletPopoverOpen] = useState(false);

  function handleWalletPopover(event, status) {
    if (status) setAnchorElWal(status ? event.currentTarget : null);
    setIsWalletPopoverOpen(status);
  }
  function handleAccountPopover(event, status) {
    if (status) setAnchorElAccount(status ? event.currentTarget : null);
    setAccountPopoverOpen(status);
  }

  return (
    <Box
      sx={{
        fontFamily: "Chakra Petch, sans-serif",
        position: "sticky",
        top: 0,
        zIndex: (theme) => theme.zIndex.modal - 1,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "space-between"
      }}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "flex-end"
        }}>
        <AppBar sx={appBarSX} position="static">
          <Container sx={{ ml: "0", p: { xxs: 0, xs: "0 24px" } }} maxWidth="xl">
            <Toolbar style={{ height: { xxs: 40, xs: 53 }, minHeight: "40px" }} disableGutters>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}>
                <BurgerToggleIcon onChange={() => setIsOpen(!isOpen)} open={isOpen} />

                <Box
                  onClick={() =>
                    isWalletBrowser()
                      ? window.location.assign("https://delysium.com/")
                      : window.open("https://delysium.com/", "_blank")
                  }
                  sx={{
                    background: `url(${CDN_ASSETS.LOGO})`,
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    cursor: "pointer",
                    mr: "6px",
                    ml: 2,
                    width: 87,
                    height: 40,
                    display: "flex"
                  }}
                />

                <AnimatePresence>
                  {isOpen && (
                    <Box
                      {...varFadeIn}
                      component={motion.ul}
                      onClick={() => setIsOpen(false)}
                      sx={fullScreenNavSX}>
                      {PAGES.map(({ title, url = "#", active }, index) => (
                        <Box
                          key={index}
                          onClick={() => {
                            push(url);
                            setIsOpen(false);
                          }}
                          sx={{
                            ...navLinkSx,
                            ...activeNavLinkSx(active(location.pathname))
                          }}>
                          <Button
                            disableRipple
                            to={url}
                            sx={{
                              textTransform: "uppercase",
                              color: (theme) => theme.palette.text.primary,
                              height: "100%",
                              fontSize: 16,
                              fontWeight: 400,
                              "&:hover": { background: "none" }
                            }}>
                            {title}
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  )}
                </AnimatePresence>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>

        <Box
          sx={{
            flexGrow: 0,
            zIndex: (theme) => theme.zIndex.modal + 1,
            mr: { xxs: 2.5, xs: 5 },
            mb: "5px",
            gap: "8px",
            alignItems: "center",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-evenly"
          }}>
          {isActive && (
            <IconButton
              disableRipple
              variant="outlined"
              color="primary"
              size="small"
              sx={{
                mr: 1,
                ml: "0.5rem",
                p: "3px",
                transition: "none",
                borderRadius: 0,
                height: "26px",
                width: "26px"
              }}
              data-augmented-ui="tl-clip br-clip border"
              className={classes.iconButton}
              style={{
                ...getAugmentedStyle({
                  color: isWalletPopoverOpen ? primary.main : text.primary,
                  br: "6px",
                  tl: "6px"
                })
              }}
              onClick={(e) => {
                e.preventDefault();
                handleWalletPopover(e, true);
              }}>
              {isWalletPopoverOpen ? <WalletIcon /> : <WalletWhiteIcon />}
            </IconButton>
          )}
          <IconButton
            disableRipple
            variant="outlined"
            color="primary"
            size="small"
            data-augmented-ui="tl-clip br-clip border"
            sx={{
              transition: "none",
              borderRadius: 0,
              padding: "3px",
              height: "26px",
              width: "26px"
            }}
            style={{
              ...getAugmentedStyle({
                color: accountPopoverOpen ? primary.main : text.primary,
                bl1: "0px",
                br: "6px",
                tl: "6px"
              })
            }}
            className={classes.iconButton}
            onClick={(e) => {
              e.preventDefault();
              handleAccountPopover(e, true);
            }}>
            {accountPopoverOpen ? <UserIcon /> : <UserWhiteIcon />}
          </IconButton>
        </Box>
      </Box>
      <AccountPopover
        isOpen={accountPopoverOpen}
        anchorEl={anchorElAccount}
        handleClose={() => handleAccountPopover(null, false)}
      />

      <WalletPopover
        isWalletOpen={isWalletPopoverOpen}
        anchorElWal={anchorElWal}
        handleClose={() => handleWalletPopover(null, false)}
      />
    </Box>
  );
};
export default MobileNavbar;
