//@TODO: refactor this and walletpopver to reusable one component
import React from "react";
import { Button, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import CustomPopover from "../../../components/Popover";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { PATH_DASHBOARD, PATH_HISTORY } from "../../../routes/paths";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/slices/auth";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";
import { useHistory } from "react-router-dom";

// ----------------------------------------------------------------------

AccountPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object
};

// ----------------------------------------------------------------------

function CustomButton({ children, onClick, borderColor = "#FAE232", ...props }) {
  return (
    <Button
      disableRipple
      variant="outlined"
      color="primary"
      data-augmented-ui="tl-clip br-clip border"
      style={getAugmentedStyle({ borderSize: "1px", bl1: "0px", color: borderColor })}
      onClick={onClick}
      {...props}
      sx={{
        whiteSpace: "nowrap",
        color: (theme) => theme.palette.primary.main,
        ...props?.sx
      }}>
      {children}
    </Button>
  );
}
function AccountPopover({ isOpen, anchorEl, handleClose }) {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { push } = useHistory();
  return (
    <CustomPopover open={isOpen} anchorEl={anchorEl} onClose={handleClose}>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <CustomButton
          onClick={() => {
            ReactGA.event(`enter_dashboard_setting`);
            push(PATH_DASHBOARD.settingsView);
            handleClose();
          }}>
          Account Settings
        </CustomButton>

        <CustomButton
          onClick={() => {
            ReactGA.event(`enter_dashboard_dma`);
            push(PATH_HISTORY.rewards);
            handleClose();
          }}>
          DMA Rewards
        </CustomButton>

        <CustomButton
          data-augmented-ui="tl-clip br-clip border"
          color="warning"
          borderColor="#FF7C60"
          sx={{ color: (theme) => theme.palette.warning.main }}
          onClick={() => {
            ReactGA.event(`logout`);
            dispatch(logout(token));
            handleClose();
          }}>
          Logout
        </CustomButton>
      </Box>
    </CustomPopover>
  );
}

export default AccountPopover;
