import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  InputAdornment
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as USDTLogo } from "../../assets/icons/usdt.svg";
import { ReactComponent as RefreshIcon } from "../../assets/refresh.svg";
import { ReactComponent as AGIBlueLogo } from "../../assets/icons/agi-blue.svg";
import LOADING_IMAGE_URL from "../../assets/icons/processing-icon-transparent.gif";
import { currencies } from "../../constants/currencies";
import NumberTextField from "../NumberTextField";
import CustomDialog from "./Dialog";
import OutlinedButton from "../Buttons/OutlinedButton";
import CustomButton from "../Buttons/Button";
import { setDepositDialogOpen } from "../../redux/slices/DMA";
import { useWeb3React } from "@web3-react/core";
import {
  NOTIF_FAIL,
  NOTIF_PROGRESSING,
  NOTIF_SUCCESS,
  setNotification
} from "../../redux/slices/notifications";
import {
  getTicketOverview,
  lockTicketByAGI,
  buyDMASuccess,
  setReferrerCode,
  setStakeProcessing,
} from "../../redux/slices/lottery";
import notifications from "../../constants/notifications";
import { PropTypes } from "prop-types";
import { PATH_EXTERNAL } from "../../routes/paths";
import useBreakpoints from "../../hooks/useBreakpoints";
import CloseButton from "../Buttons/CloseButton";
import LockUpPeriod from "../LockUpPeriod";
import { setWalletConnectionOpen } from "../../redux/slices/wallet";
import { CHAINS, changeNetwork, DMA_CORRECT_CHAIN } from "../../utils/chains";
import { __DEBUG__, __PROD__ } from "../../utils/dev";
import { useLocation } from "react-router";
import { withComma } from "../../utils/strings";
import { motion } from "framer-motion";

// ----------------------------------------------------------------------
TicketCheckoutDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  isPublicSale: PropTypes.bool,
  isPreSale: PropTypes.bool
};
// ----------------------------------------------------------------------

const ButtonLoadingIcon = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  const styles = {
    display: "inline-block",
    verticalAlign: "middle",
    width: "18px",
    height: "18px",
    backgroundImage: `url(${LOADING_IMAGE_URL})`,
    backgroundSize: "contain",
    mixBlendMode: "difference",
    marginRight: "7px"
  };

  return <CircularProgress thickness={2.5} size={14} sx={{ color: 'black', mr: "4px" }} />
};

const BYBIT_CODE = "BYBIT";

function ExternalLink({ to, title, ...props }) {
  return (
    <Typography
      component="a"
      variant="body2"
      target="_blank"
      fontSize="inherit"
      lineHeight="inherit"
      href={to}
      color="textSecondary"
      rel="noreferrer noopener"
      {...props}
      sx={{ textDecoration: "underlined", ...props?.sx }}>
      {title}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      display: "flex",
      flexFlow: "column nowrap",
      // height: "668px",
      width: "710px",
      position: "relative",
      padding: "0 12px",
      "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        display: "none"
      },
      [theme.breakpoints.down("md")]: {
        width: "101%",
        height: "95vh",
        maxHeight: "560px",
        position: "absolute",
        left: -2,
        bottom: -2,
        background: "#181818",
        padding: 0,
        margin: 0,
        "--aug-tr1": "12px",
        "--aug-tr-inset2": "calc(var(--aug-tr1) * 14.5)"
      }
    },
    buyButtonContainer: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        padding: "16px 20px 12px 16px",
        position: "fixed",
        left: 0,
        bottom: 0,
        backgroundColor: "#181818"
      },

      "& button": {
        width: "100%",
        height: "43px",
        margin: "1rem auto",
        textTransform: "uppercase",

        [theme.breakpoints.down("md")]: {
          height: "38px",
          margin: "0"
        }
      }
    }
  };
});

const INCREASE_RATE = 25;
const BASE_PRICE = 1000;

function TicketCheckoutDialog({ isOpen, setClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const downMd = useBreakpoints("down", "md");
  const { purchase } = notifications;
  const [lockMonths, setLockMonths] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [bybitId, setBybitId] = useState("");
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedConditions, setCheckedConditions] = useState(false);
  const {
    balance,
  } = useSelector((state) => state.DMA);
  const {
    DMA,
    stakeProcessing,
    isValidCode,
    ticketOverview,
    validatingReferralCode,
    referrerCode,
    dmaPrice,
    submittedReferralCode,
    isRefreshingPrice
  } = useSelector((state) => state.lottery);
  const { library: provider, active } = useWeb3React();
  const availableSupply = 10000;
  const isBuyButtonDisabled = [
    () => stakeProcessing,
    () => lockMonths === 0,
    () => quantity.length === 0 || quantity === 0,
    () => isRefreshingPrice,
    () => quantity > MAX_PURCHASE_ALLOWED
  ];
  const isReferralInputDisabled = [() => submittedReferralCode || validatingReferralCode];
  const nativeBalance = Number(
    balance.find((item) => item.currency === currencies.native)?.amount || 0
  );
  const MAX_PURCHASE_ALLOWED = ticketOverview.unlocked_tickets;

  async function handleAGIBuy(disableCode) {
    if (stakeProcessing) return;

    try {
      dispatch(setStakeProcessing(true));
      const body = {
        amount: quantity,
        locked_period: ticketOverview.valid_lock_period[lockMonths]
      }
      if (!disableCode) {
        body.code = referrerCode
      }
      const signResponse = await dispatch(
        lockTicketByAGI({
          ...body
        })
      )
      dispatch(
        setNotification({
          title: "Transaction Submitted",
          type: NOTIF_SUCCESS,
          description: 'Your boost has been successful.'
        })
      );
      setClose()
    } catch (error) {
      if (error.code === 103011) {
        dispatch(setReferrerCode(''))
        dispatch(setStakeProcessing(false));
        handleAGIBuy(true)
        return
      }
      dispatch(
        setNotification({
          title: "Boost Failed",
          type: NOTIF_FAIL,
          description: error.message || 'Your boost has been failed, please try again later.'
        })
      );
      console.error(error);
      dispatch(setStakeProcessing(false));
    }

    dispatch(setStakeProcessing(false));
  }

  return (
    <CustomDialog
      closeButtonType="crossLine"
      PaperProps={{
        "data-augmented-ui": downMd ? "tr-clip-x border" : "bl-clip-y br-clip border",
        className: classes.paper,
        sx: {
          fontFamily: "Chakra Petch, sans-serif",
        }
      }}
      setClose={setClose}
      open={isOpen}>
      <Box sx={{ pt: { xxs: 0, sm: 2 }, pb: { xxs: 0, sm: 4 }, px: { xxs: 0, sm: 10 } }}>

      <Typography component="h1" sx={{ py: 1.5, fontSize: "28px", fontWeight: "600" }}>
        Lock Flexible Tickets
      </Typography>

      <Box sx={{ overflowY: "scroll", overflowX: "visible", flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "flex-start",
            py: 1,
            justifyContent: "space-between"
          }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "flex-start"
            }}>
            <Typography
              sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
              color="textSecondary"
              variant="body2">
              Quantity{" "}
              {quantity >= MAX_PURCHASE_ALLOWED && (
                <Typography variant="body2" color="#FFA3A3CC">
                  {MAX_PURCHASE_ALLOWED} max
                </Typography>
              )}
            </Typography>
            <NumberTextField
              maxValue={
                MAX_PURCHASE_ALLOWED > availableSupply ? availableSupply : MAX_PURCHASE_ALLOWED
              }
              value={quantity}
              setValue={setQuantity}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "flex-start",
            pb: 2,
            justifyContent: "space-between"
          }}>
          <Typography
              color="textSecondary"
              variant="body2">
              Your Flexible Ticket Amount: { ticketOverview.unlocked_tickets }
            </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            mb: { xs: 0.75, md: 2 },
            borderBottom: "1px solid #ffffff20"
          }}>

        </Box>
      </Box>
      <LockUpPeriod
        lockMonths={lockMonths}
        setLockMonths={setLockMonths}
      />

        <Box
          display="flex"
          flexWrap="nowrap"
          justifyContent="flex-end"
          sx={{
            gap: "10px",
            flexDirection: { xxs: "column-reverse", sm: "row" },
            mt: "26px"
          }}>
          <CustomButton
            isLoading={stakeProcessing}
            disabled={isBuyButtonDisabled.some((fn) => fn())}
            handleClick={() => handleAGIBuy()}
            sx={{ fontSize: "16px", width: { xxs: "100%", sm: "144px" }, height: "37px", mt: 0 }}
            augmented="tl-clip br-clip">
            Boost Now
          </CustomButton>
        </Box>
        </Box>
    </CustomDialog>
  );
}

export default TicketCheckoutDialog;
