import { useEffect, useState } from "react";
import { alpha, Box, TextField, Typography, Slider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import BigNumber from "bignumber.js";
import CustomDialog from "./Dialog";
import OutlinedButton from "../Buttons/OutlinedButton";
import CustomButton from "../Buttons/Button";
import WalletHandler from "../WalletHandler";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import { WALLET_CONFIG, setWalletDialogOpen } from "../../redux/slices/wallet";
import { getStakeOverview, signStakeSingle, stakeSingle, setStakeSingleDialogOpen, getBalance, getAprs } from "../../redux/slices/stake";
import { getRewardsBalance, setDepositDialogOpen } from "../../redux/slices/DMA";
import useBreakpoints from "../../hooks/useBreakpoints";
import { ReactComponent as AgiIcon } from "../../assets/icons/agi.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";
import RefreshIconUrl from "../../assets/refresh.png";
import { CONTRACT_ADDRESS } from "../../constants/contracts";
import { isWalletBrowser } from "../../utils/isMobile";
import { currencies } from "../../constants/currencies";
import { toFixed } from "../../utils/strings";
import { getStakePhaseInfo } from "../../utils/stake";

const InputTextSx = {
  color: (theme) => theme.palette.grey[0],
  fontSize: { xxs: 18, xs: 24 },
  fontWeight: 600,
  py: 1
};

const InputSx = {
  height: { xxs: "40px", xs: "51px" },
  input: { ...InputTextSx },
  "& input[type=number]": {
    MozAppearance: "textfield"
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none"
    }
  }
};

function AmountInputField({
  value,
  setValue,
  placeholder,
  type = "number",
  maxValue,
  minValue = 0
}) {
  function handleChangeQuantity(value) {
    if (value.length === 0) setValue("");
    else {
      const floatValue = parseFloat(value);

      if (value < minValue && value.length !== 0) setValue((prevState) => prevState);
      else setValue((prevState) => (floatValue <= maxValue ? floatValue : prevState));
    }
  }

  const augStyle = getAugmentedStyle({ color: "rgba(255, 255, 255, 0.3)", borderSize: "1px" });

  const valueOptionSx = (isActive) => ({
    flex: 1,
    height: "30px",
    lineHeight: "30px",
    textAlign: "center",
    cursor: "pointer",
    fontSize: { xxs: "14px", sm: "14px" },
    color: (theme) => (isActive ? theme.palette.primary.main : "white"),
    "--aug-border-bg": (theme) => isActive && `${alpha(theme.palette.primary.main, 1)} !important`
  });

  const handleOptionClick = (ratio) => {
    setValue(ratio * maxValue);
  };

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" width="100%">
      <TextField
        fullWidth
        type={type}
        placeholder={placeholder}
        variant="outlined"
        value={value}
        onChange={(e) => handleChangeQuantity(e.target.value)}
        data-augmented-ui="tl-clip br-clip border"
        InputProps={{
          inputProps: { max: maxValue, step: "0.01" }
        }}
        sx={InputSx}
        style={augStyle}
      />
      <Box mt="12px" display="flex" flexWrap="nowrap" justifyContent="space-between" gap="10px">
        <Box
          sx={valueOptionSx(value && value === maxValue * 0.25)}
          data-augmented-ui="tl-clip border"
          style={augStyle}
          onClick={() => handleOptionClick(0.25)}>
          25%
        </Box>
        <Box
          sx={valueOptionSx(value && value === maxValue * 0.5)}
          data-augmented-ui="border"
          style={augStyle}
          onClick={() => handleOptionClick(0.5)}>
          50%
        </Box>
        <Box
          sx={valueOptionSx(value && value === maxValue * 0.75)}
          data-augmented-ui="border"
          style={augStyle}
          onClick={() => handleOptionClick(0.75)}>
          75%
        </Box>
        <Box
          sx={valueOptionSx(value && value === maxValue)}
          data-augmented-ui="br-clip border"
          style={augStyle}
          onClick={() => handleOptionClick(1)}>
          MAX
        </Box>
      </Box>
    </Box>
  );
}

function LockMonthsControl({ value, setValue, phaseInfo }) {
  const [isCustom, setIsCustom] = useState(false);

  let minValue = 6;
  let maxValue = 24;
  if (phaseInfo.isPhase1) {
    maxValue = 12
  }
  if (phaseInfo.isPhase2) {
    minValue = 1
    maxValue = Math.min(12, phaseInfo.remainingMonthAmount)
  }
  const valueOptionSx = (isActive) => ({
    flex: 1,
    height: "30px",
    lineHeight: "30px",
    maxWidth: { xss: "100%", sm: "105px" },
    textAlign: "center",
    cursor: "pointer",
    fontSize: { xxs: "12px", sm: "14px" },
    color: (theme) => (isActive ? "black" : "white"),
    background: (theme) => isActive && `${alpha(theme.palette.primary.main, 1)} !important`,
    "--aug-border-bg": (theme) => isActive && `${alpha(theme.palette.primary.main, 0.3)} !important`
  });

  const augStyle = getAugmentedStyle({ color: "rgba(255, 255, 255, 0.3)", borderSize: "1px" });

  const handleOptionClick = (months) => {
    setIsCustom(false);
    setValue(months);
  };

  const handleCustomMonthsClick = () => {
    setIsCustom(true);
  };

  return (
    <Box>
      <Box mt="12px" display="flex" flexWrap="nowrap" justifyContent="flexStart" gap="10px">
        { phaseInfo.isPhase2 &&
          <Box
            sx={valueOptionSx(value === 3 && !isCustom)}
            data-augmented-ui="tl-clip border"
            style={augStyle}
            onClick={() => handleOptionClick(3)}>
            3 Months
          </Box>
        }
        <Box
          sx={valueOptionSx(value === 6 && !isCustom)}
          data-augmented-ui="tl-clip border"
          style={augStyle}
          onClick={() => handleOptionClick(6)}>
          6 Months
        </Box>
        <Box
          sx={valueOptionSx(value === 12 && !isCustom)}
          data-augmented-ui="border"
          style={augStyle}
          onClick={() => handleOptionClick(12)}>
          12 Months
        </Box>
        <Box
          sx={valueOptionSx(isCustom)}
          data-augmented-ui="br-clip border"
          style={augStyle}
          onClick={() => handleCustomMonthsClick()}>
          Custom
        </Box>
      </Box>
      {isCustom && (
        <Slider
          sx={{
            m: "0 auto",
            width: { xxs: "80%", sm: "100%" },
            mt: "48px",
            display: { xxs: "block", sm: "inline-block" }
          }}
          value={value}
          step={1}
          min={1}
          max={maxValue}
          size="small"
          valueLabelDisplay="on"
          valueLabelFormat={(val) => `${val} Month${val > 1 ? "s" : ""}`}
          onChange={(e, val) => {
            setValue(val);
          }}
        />
      )}
    </Box>
  );
}

function InfoRow({ title, Icon = null, info, rowSx, needTransparent }) {
  return (
    <Box
      sx={{
        mt: 4,
        mb: "12px",
        ...rowSx
      }}>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: 14,
          minWidth: 140,
          color: (theme) => theme.palette.grey[60]
        }}>
        {Icon && <Icon style={{ marginRight: "5px" }} />}
        {title}
      </Typography>

      <Typography
        sx={{
          fontSize: 12,
          mt: "12px",
          display: "flex",
          alignItems: "flex-end",
          color: (theme) => theme.palette.grey[60]
        }}>
        {info}
      </Typography>
    </Box>
  );
}

function BalanceInfoRow({ title, Icon = null, balance, rowSx }) {
  const dispatch = useDispatch()
  const [ needTransparent, setNeedTransparent ] = useState(false)

  const handleRefreshClick = async () => {
    if (needTransparent) return
    setNeedTransparent(true)
    await dispatch(getRewardsBalance())
    setNeedTransparent(false)
  }

  return (
    <Box
      sx={{
        mt: 4,
        mb: "12px",
        ...rowSx
      }}>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: 14,
          minWidth: 140,
          color: (theme) => theme.palette.grey[60]
        }}>
        {Icon && <Icon style={{ marginRight: "5px" }} />}
        {title}
      </Typography>

      <Box
        sx={{
          fontSize: 12,
          mt: "12px",
          display: "flex",
          alignItems: "center",
          color: (theme) => theme.palette.grey[60]
        }}>
        Balance: &nbsp;<Box sx={{
          transition: "318ms",
          opacity: needTransparent ? 0 : 1
        }}>
          { balance }
        </Box>&nbsp;$AGI
        <Box
          sx={{
            cursor: "pointer",
            marginLeft: "6px",
            backgroundImage: `url(${RefreshIconUrl})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            aspectRatio: '1/1',
            width: { xxs: "16px", sm: "20px" }
          }}
          onClick={ () => handleRefreshClick() }
        />
      </Box>
    </Box>
  );
}

const strip = (num, precision = 12) => {
  return +parseFloat(num.toPrecision(precision));
};

const getEstimatedAPR = (aprs, month) => {
  const apr = aprs[month - 1];

  if (typeof apr !== 'undefined') return Number(apr);

  if (!month) return 0;

  if (month <= 12) {
    return 207 + 18.92 * (month - 5);
  }

  return 320.52 + 21.67 * (month - 11);
};

export function StakeSingleDialog() {
  const dispatch = useDispatch();
  const downXs = useBreakpoints("down", "xs");
  const { stakeSingleDialogOpen, stakeSingleProcessing, stakeOverview, stakeConfig, poolsInfo, aprs } = useSelector(
    (state) => state.stake
  );
  const { balance } = useSelector(
    (state) => state.DMA
  );
  const nativeBalance = Number(
    balance.find((item) => item.currency === currencies.native)?.amount || 0
  );
  const { user } = useSelector((state) => state.auth);
  const setClose = () => dispatch(setStakeSingleDialogOpen(false));
  const [amount, setAmount] = useState(0);
  const [lockMonths, setLockMonths] = useState(0);
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useProvider, useAccount, useIsActive } = WALLET_CONFIG[selectedWallet].hook;
  const provider = useProvider();
  const account = useAccount();
  const isActive = useIsActive();
  const estimatedAPR = `${!lockMonths ? 0 : strip(Number(toFixed(stakeOverview.apr_by_months[lockMonths - 1]) || 0))}%`;
  const estimatedAPRInfo = `The tokens will be locked for ${lockMonths} months. At the end of the Lock-up Period, you will be able to withdraw them along with the staking rewards. By clicking 'Stake', you acknowledge that you fully understand the staking mechanism, smart contract, and all risks associated with this behavior.`;
  const phaseInfo = getStakePhaseInfo(stakeConfig)

  const isStakeDisabled = amount <= 0 || amount > nativeBalance || lockMonths <= 0;

  const handleGetLPClick = () => {
    isWalletBrowser()
      ? window.location.assign(CONTRACT_ADDRESS.LP_TOKEN_MARKET)
      : window.open(CONTRACT_ADDRESS.LP_TOKEN_MARKET, "_blank");
  };

  const handleDepositClick = () => {
    if (!isActive) {
      dispatch(setWalletDialogOpen({ open: true }));
      return;
    }
    dispatch(setDepositDialogOpen(true));
  }

  useEffect(() => {
    if (stakeSingleDialogOpen) {
      setAmount(0)
      setLockMonths(0)
      dispatch(getRewardsBalance());
      dispatch(getStakeOverview());
    }
  }, [stakeSingleDialogOpen]);

  const handleStakeClick = () => {
    if (stakeSingleProcessing) {
      return;
    }

    dispatch(
      signStakeSingle(user?.wallets?.ethWallet, new BigNumber(amount).toString(), lockMonths)
    )
  };

  return (
    <CustomDialog
      hasCloseButton
      closeButtonType="crossLine"
      BackdropProps={{
        sx: {
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7)
        }
      }}
      PaperProps={{
        sx: {
          width: { xxs: "101%", sm: 710 },
          height: { xxs: "90vh", xs: "initial" },
          padding: { xxs: 0, xs: "0 6px" },
          margin: { xxs: 0, xs: 2 },
          left: -2,
          bottom: -2,
          fontFamily: "Chakra Petch, sans-serif",
          background: { xxs: "#181818", xs: "black" },

          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            display: "none"
          }
        },
        style: {
          position: downXs ? "absolute" : "relative",
          "--aug-tr1": "12px",
          "--aug-tr-inset2": "calc(var(--aug-tr1) * 14.5)"
        },
        "data-augmented-ui": downXs ? "tr-clip-x border" : "bl-clip-y br-clip border"
      }}
      setClose={setClose}
      open={stakeSingleDialogOpen}>
      <Box sx={{ pt: { xxs: 0, sm: 2 }, pb: { xxs: 0, sm: 4 }, px: { xxs: 0, sm: 10 } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: { xxs: "-20px", sm: 0 }
          }}>
          <AgiIcon
            style={{
              width: downXs ? "24px" : "32px"
            }}
          />
          <Typography
            sx={{
              ml: "10px",
              fontWeight: { xxs: 600, sm: 700 },
              fontSize: { xxs: "18px", sm: "24px" },
              lineHeight: 23 / 18
            }}>
            $AGI Pool
          </Typography>
          <Typography
            sx={{
              ml: "10px",
              fontWeight: { xxs: 400, sm: 400 },
              fontSize: { xxs: "16px", sm: "20px" },
              opacity: 0.5,
              position: "relative",
              top: "1px"
            }}>
            { phaseInfo.phaseText }
          </Typography>
        </Box>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end"
        }}>
          <BalanceInfoRow title="Amount" balance={toFixed(nativeBalance)} />
          <OutlinedButton
            label="Deposit"
            classname="augmented-button-primary"
            color="primary"
            handleClick={() => handleDepositClick()}
            sx={{
              width: { xxs: 83, sm: 94 },
              height: { xxs: 28, sm: 28 },
              mb: "12px",
              fontSize: { xxs: 12, sm: 12 }
            }}
          />
        </Box>
        <AmountInputField value={amount} setValue={setAmount} maxValue={nativeBalance} />
        <InfoRow title="Lock-up period" />
        <LockMonthsControl value={lockMonths} phaseInfo={phaseInfo} setValue={setLockMonths} />
        <InfoRow title={`Est.APR: ${estimatedAPR}`} Icon={CautionIcon} info={estimatedAPRInfo} />
        <Box
          display="flex"
          flexWrap="nowrap"
          justifyContent="flex-end"
          sx={{
            gap: "10px",
            flexDirection: { xxs: "column-reverse", sm: "row" },
            mt: "26px"
          }}>
          <CustomButton
            isLoading={stakeSingleProcessing}
            disabled={isStakeDisabled}
            handleClick={() => handleStakeClick()}
            sx={{ fontSize: "16px", width: { xxs: "100%", sm: "144px" }, height: "37px", mt: 0 }}
            augmented="tl-clip br-clip">
            Stake
          </CustomButton>
        </Box>
      </Box>
    </CustomDialog>
  );
}

export default StakeSingleDialog;
