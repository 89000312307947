import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { discordUnbind } from "../../redux/slices/account";
import { setAccountNotification } from "../../redux/slices/accountNotifications";
import CustomButton from "../Buttons/Button";
import OutlinedButton from "../Buttons/OutlinedButton";
import ConfirmationDialog from "./ConfirmationDialog";

function UnbindWalletDialog({ open, setClose }) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  return (
    <ConfirmationDialog open={open} closeDialog={setClose}>
      <Box>
        <Typography fontSize={{ xxs: 14, sm: 16 }} textAlign="center">
          Do you confirm to unbind your current Discord account?
        </Typography>
        <Box display="flex" justifyContent="center" mt={{ xxs: 2.5, sm: 3.75 }}>
          <OutlinedButton
            label="Cancel"
            classname="augmented-button-primary"
            color="primary"
            handleClick={setClose}
            sx={{
              width: { xxs: 83, sm: 104 },
              height: { xxs: 28, sm: 30 },
              mr: { xxs: 2.5, sm: 3.75 },
              fontSize: { xxs: 12, sm: 14 }
            }}
          />
          <CustomButton
            augmented="tl-clip br-clip"
            sx={{
              mt: 0,
              width: { xxs: 83, sm: 104 },
              height: { xxs: 28, sm: 30 },
              fontSize: 14
            }}
            handleClick={() => {
              dispatch(discordUnbind(user?.token)).then((r) => {
                dispatch(
                  setAccountNotification({ message: "You successfully unbound your Discord!" })
                );
              });
            }}>
            Confirm
          </CustomButton>
        </Box>
      </Box>
    </ConfirmationDialog>
  );
}
export default UnbindWalletDialog;
