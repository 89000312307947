import { alpha } from "@mui/material";

export default function Table({ theme }) {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: 8,
            height: 6,
            backgroundColor: alpha(theme.palette.divider, 0.3)
          },
          "&::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb": {
            border: "none",
            borderRadius: 8,
            backgroundColor: alpha(theme.palette.grey[600], 0.48)
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(odd)": {
            border: "none",
            backgroundColor: "rgba(255,255,255,0.1)"
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderTop: `1px solid ${alpha(theme.palette.text.primary, 0.5)}`,
          borderBottom: `1px solid ${alpha(theme.palette.text.primary, 0.5)}`,
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`
        }
      }
    }
  };
}
