import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { Typography, CircularProgress, Fade } from "@mui/material";
import CustomButton from "../../../components/Buttons/Button";
import { ReactComponent as AgiUsdtIcon } from "../../../assets/icons/agi-usdt-token.svg";
import { ReactComponent as AgiIcon } from "../../../assets/icons/agi.svg";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";
import { getPoolsInfo, setStakeDialogOpen, setStakeSingleDialogOpen } from "../../../redux/slices/stake";
import { setWalletDialogOpen, WALLET_CONNECTION_TYPES } from "../../../redux/slices/wallet";
import { setUser, isWalletBound } from "../../../redux/slices/auth";
import { toFixed } from "../../../utils/strings";
import { getStakePhaseInfo } from "../../../utils/stake";
import AmountCard from "./AmountCard";

function StackButton({ sx, onClick }) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const $isWalletBound = useMemo(() => isWalletBound(user), [user]);

  const handleClick = () => {
    if (!$isWalletBound) {
      dispatch(setWalletDialogOpen({ open: true, type: WALLET_CONNECTION_TYPES.bind }));
      return;
    }

    onClick()
  };

  return (
    <CustomButton
      handleClick={handleClick}
      sx={{
        fontSize: "16px",
        height: "37px",
        mt: "10px",
        px: "24px",
        width: { xss: "100%", sm: "auto" },
        minWidth: "129px",
        ...sx
      }}
      augmented="tl-clip br-clip">
      {!$isWalletBound ? "Connect Wallet" : "Stake"}
    </CustomButton>
  );
}

function Heading() {
  const downXs = useBreakpoints("down", "xs");
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        height: "37px",
        mt: { xxs: '49px', sm: '60px' },
        mb: { xxs: "16px", sm: "36px" }
      }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}>
        <AgiUsdtIcon
          style={{
            width: downXs ? "50px" : "66px"
          }}
        />
        <Typography
          sx={{
            ml: "10px",
            fontWeight: { xxs: 400, sm: 500 },
            fontSize: { xxs: "16px", sm: "20px" },
            lineHeight: "21px"
          }}>
          $AGI / USDT Pool
        </Typography>
      </Box>

      <StackButton
        onClick={() => dispatch(setStakeDialogOpen(true))}
        sx={{
          display: { xxs: "none", sm: "flex" }
        }}
      />
    </Box>
  );
}

function SingleHeading() {
  const downXs = useBreakpoints("down", "xs");
  const dispatch = useDispatch();
  const { stakeConfig } = useSelector((state) => state.stake);
  const phaseInfo = getStakePhaseInfo(stakeConfig)

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        height: "37px",
        mb: { xxs: "16px", sm: "36px" }
      }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}>
        <AgiIcon
          style={{
            width: downXs ? "24px" : "32px"
          }}
        />
        <Typography
          sx={{
            ml: "10px",
            fontWeight: { xxs: 400, sm: 500 },
            fontSize: { xxs: "16px", sm: "20px" },
            lineHeight: "21px"
          }}>
          $AGI Pool
        </Typography>
      </Box>

      <StackButton
        onClick={() => dispatch(setStakeSingleDialogOpen(true))}
        sx={{
          display: { xxs: "none", sm: "flex" }
        }}
      />
    </Box>
  );
}

function PoolMetaCards() {
  const { poolsInfo } = useSelector((state) => state.stake);

  if (!poolsInfo) return;

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        flexDirection: { xxs: "column", sm: "row" }
      }}>
      <AmountCard
        title="Total Staked"
        meta={`≈ ${poolsInfo.total_deposited_usd} USD`}
        amount={`${poolsInfo.total_deposited}`}
        sx={{ flex: 2 }}
      />
      <AmountCard title="APR" amount={`${poolsInfo.apr}%`} sx={{ flex: 1 }} />
      <AmountCard title="TVL" amount={`$${poolsInfo.tvl}`} sx={{ flex: 1 }} />
    </Box>
  );
}

function SinglePoolMetaCards() {
  const { singlePoolsInfo } = useSelector((state) => state.stake);

  if (!singlePoolsInfo) return;

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        flexDirection: { xxs: "column", sm: "row" }
      }}>
      <AmountCard
        title="Total Staked"
        meta={`≈ ${singlePoolsInfo.total_deposited_usd} USD`}
        amount={`${singlePoolsInfo.total_deposited}`}
        sx={{ flex: 2 }}
      />
      <AmountCard title="Est. APR" amount={`${singlePoolsInfo.apr.substr(0, 8)}%`} sx={{ flex: 1 }} />
      <AmountCard title="TVL" amount={`$${singlePoolsInfo.tvl}`} sx={{ flex: 1 }} />
    </Box>
  );
}

function Pools() {
  const dispatch = useDispatch();

  const { isLoadingPoolsInfo } = useSelector((state) => state.stake);

  return (
    <Box>
      <SingleHeading />
      {isLoadingPoolsInfo ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            mt: 7.5,
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <Fade in={true}>
          <Box
            sx={{
              mb: { xxs: "6vh", sm: 0 }
            }}
          >
            <SinglePoolMetaCards />
            <StackButton
              onClick={() => dispatch(setStakeSingleDialogOpen(true))}
              sx={{
                display: { xxs: "block", sm: "none" }
              }}
            />
          </Box>
        </Fade>
      )}
      <Heading />
      {isLoadingPoolsInfo ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            mt: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <Fade in={true}>
          <Box>
            <PoolMetaCards />
            <StackButton
              onClick={() => dispatch(setStakeDialogOpen(true))}
              sx={{
                display: { xxs: "block", sm: "none" }
              }}
            />
          </Box>
        </Fade>
      )}
    </Box>
  );
}

export default Pools;
