import {
  CircularProgress,
  Divider,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyText from "../../../components/CopyText";
import { Columns, EmptyRecords } from "./Table";
import { formatDate } from "../../../utils/time";
import { Box } from "@mui/system";
import useBreakpoints from "../../../hooks/useBreakpoints";
import Pagination from "../../../components/Pagination";
import { getReferralDetails } from "../../../redux/slices/history";
import { setRewardMethodSelecting } from "../../../redux/slices/DMA";
import { REWARD_TYPES, REWARD_TYPES_VALUE } from "../../../constants/rewards";

const COLUMNS = ["Email", "Date", "Reward Method", ""];
const EMPTY_RECORDS = "No Referral Details Record";

const flexCellSx = {
  display: "flex",
  alignItems: "center",
  border: "none"
};

const copyTextSx = {
  width: "18px",
  height: "18px",
  "& svg": {
    transform: "scale(0.75)"
  },
  ml: 0.75
};

const ReferralDetailsCard = ({ record }) => {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.grey[10],
        my: 0.5,
        width: "100%",
        p: 1.5
      }}>
      <Box sx={{ display: "flex", flexFlow: "row nowrap", justifyContent: "space-between" }}>
        <Typography variant="caption" color="textSecondary">
          {formatDate(record?.created_at)}
        </Typography>
        <Typography sx={flexCellSx} variant="caption" color="textSecondary">
          {record.email}
          <CopyText buttonSx={copyTextSx} label="" textToCopy={record.email} />
        </Typography>
      </Box>

      <Divider sx={{ mt: 1.5 }} />

      <Typography sx={{ fontSize: 14, width: "100%", textAlign: "center", mt: 3, mb: 1.5 }}>
        {record.referrer_selective_reward_type === 2 ? (
          <Typography
            onClick={() =>
              dispatch(
                setRewardMethodSelecting({
                  dialogOpen: true,
                  id: record.dma_id,
                  cashbackAmount: record.rewards_usdt_amount,
                  loyaltyScoreAmount: record.rewards_loyalty_score_amount,
                  increasePercentage: record.estimate_agi_increased_rate_tomorrow
                })
              )
            }
            color="primary"
            sx={{
              fontSize: "inherit",
              cursor: "pointer",
              textDecoration: "underline",
              fontWeight: 600
            }}>
            Select Rewards
          </Typography>
        ) : (
          REWARD_TYPES[record.referrer_selective_reward_type]
        )}
      </Typography>
    </Box>
  );
};

function ReferralDetails() {
  const PAGE_SIZE = 10;
  const dispatch = useDispatch();
  const upXs = useBreakpoints("up", "xs");
  const { referralDetails, isLoading } = useSelector((state) => state.history);
  const isEmpty = !isLoading && referralDetails.data?.length === 0;
  const [page, setPage] = useState(1);

  function handleChangePage(e, value) {
    setPage(value);
  }

  useEffect(() => {
    dispatch(getReferralDetails({ page_size: PAGE_SIZE, page }));
  }, [dispatch, page]);

  return (
    <>
      {upXs ? (
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="Referral Table">
            <Columns columnList={COLUMNS} />
            <TableBody>
              {!isLoading &&
                referralDetails?.data?.map((row) => {
                  return (
                    <TableRow>
                      <TableCell sx={flexCellSx}>
                        {row.email}
                        <CopyText buttonSx={copyTextSx} label="" textToCopy={row.email} />
                      </TableCell>

                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {formatDate(row.created_at)}
                      </TableCell>

                      <TableCell>{REWARD_TYPES[row.referrer_selective_reward_type]}</TableCell>
                      <TableCell>
                        {row.referrer_selective_reward_type === REWARD_TYPES_VALUE["-"] && (
                          <Typography
                            onClick={() =>
                              dispatch(
                                setRewardMethodSelecting({
                                  dialogOpen: true,
                                  id: row.dma_id,
                                  cashbackAmount: row.rewards_usdt_amount,
                                  loyaltyScoreAmount: row.rewards_loyalty_score_amount,
                                  increasePercentage: row.estimate_agi_increased_rate_tomorrow
                                })
                              )
                            }
                            color="primary"
                            sx={{
                              cursor: "pointer",
                              fontSize: 14,
                              textDecoration: "underline",
                              fontWeight: 600
                            }}>
                            Select Rewards
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          my={2.5}
          minHeight={!isLoading && !isEmpty && referralDetails?.data?.length < 4 && "65vh"}>
          {!isLoading &&
            referralDetails?.data?.map((row) => {
              return <ReferralDetailsCard record={row} />;
            })}
        </Box>
      )}
      {isLoading && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      )}
      {isEmpty && (
        <EmptyRecords
          sx={{ mt: 0, minHeight: { xxs: "60vh", xs: "initial" } }}
          title={EMPTY_RECORDS}
        />
      )}
      {!isEmpty && !isLoading && (
        <Pagination onChange={handleChangePage} count={referralDetails?.total_page} page={page} />
      )}
    </>
  );
}

export default ReferralDetails;
