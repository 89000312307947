import { Box, Typography } from "@mui/material";
import ConfirmationDialog from "./ConfirmationDialog";
import OutlinedButton from "../Buttons/OutlinedButton";
import CustomButton from "../Buttons/Button";

export function BindConfirmDialog({ open, setClose, tips, confirmHandler }) {
  return (
    <ConfirmationDialog open={open} width="470px" height={{ xxs: "160px", xs: "240px" }} closeDialog={setClose}>
      <Box>
        <Typography fontSize={{ xxs: 14, sm: 16 }} textAlign="center">
           { tips }
        </Typography>
        <Box display="flex" justifyContent="center" mt={{ xxs: 2.5, sm: 3 }} mb={1}>
          <OutlinedButton
            label="Cancel"
            classname="augmented-button-primary"
            color="primary"
            handleClick={setClose}
            sx={{
              width: { xxs: 83, sm: 104 },
              height: { xxs: 28, sm: 30 },
              mr: { xxs: 2.5, sm: 3.75 },
              fontSize: { xxs: 12, sm: 14 }
            }}
          />
          <CustomButton
            augmented="tl-clip br-clip"
            sx={{
              mt: 0,
              width: { xxs: 83, sm: 104 },
              height: { xxs: 28, sm: 30 },
              fontSize: { xxs: 12, sm: 14 }
            }}
            handleClick={confirmHandler}>
            Bind Now
          </CustomButton>
        </Box>
      </Box>
    </ConfirmationDialog>
  );
}

export default BindConfirmDialog
