import { Box, Typography, alpha, useTheme } from "@mui/material";
import CustomButton from "./Buttons/Button";
import OutlinedButton from "./Buttons/OutlinedButton";
import CopyText from "./CopyText";
import { getAugmentedStyle } from "../utils/augmentedStyles";

const sharedButtonSx = {
  mt: 0,
  width: { xxs: 85, sm: 94, lg: 102 },
  height: { xxs: 28, lg: 32 },
  fontSize: 12,
  fontWeight: 400,
  padding: "6px 8px"
};

export const InfoCardSx = {
  width: "100%",
  py: { xxs: 2.3125, xs: 1.8125, sm: 1.875, md: 3.125, lg: 3.75 },
  backgroundColor: (theme) => theme.palette.grey[10],
  px: { xxs: 3.25, xs: 5 },
  mb: 0.625
};

export default function InfoCard({
  title,
  cardSx = {},
  ToolTipComponent = null,
  BodyStatic = null,
  bodyStatic = null,
  copyText = false,
  bodyStaticSX = {},
  bodyDynamic = 0,
  bodyDynamicSx = {},
  bodyDynamicAlert = null,
  bodyDynamicUnit = null,
  bodyDynamicUnitSx = {},
  secondaryButtonText = null,
  secondaryButtonDisabled = false,
  handleSecondaryClick = () => {},
  primaryButtonText = null,
  handlePrimaryClick = () => {},
  primaryButtonDisabled = false,
  verticalAlignment = false
}) {
  const theme = useTheme();
  const augmentedStyle = getAugmentedStyle({
    color: theme.palette.grey[900]
  });

  return (
    <Box
      data-augmented-ui="tl-clip br-clip border"
      style={augmentedStyle}
      sx={{ ...InfoCardSx, ...cardSx }}>
      {title && (
        <Box
          display="flex"
          mb={1.875}
          justifyContent={verticalAlignment && "center"}
          alignItems="center">
          <Typography
            color={alpha("#fff", 0.5)}
            mr={0.5}
            fontSize={{ xxs: 12, sm: 16, lg: 18 }}
            fontWeight={500}>
            {title}
          </Typography>

          {ToolTipComponent}
        </Box>
      )}

      <Box
        display="flex"
        flexDirection={verticalAlignment && "column"}
        justifyContent="space-between"
        alignItems="center"
        mt={verticalAlignment && 0.625}>
        {BodyStatic ? (
          <BodyStatic />
        ) : bodyStatic ? (
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                color: (theme) => theme.palette.grey[60],
                maxWidth: { xs: 225, sm: 310, md: 380, lg: 423 },
                ...bodyStaticSX
              }}>
              {bodyStatic}
            </Typography>
          </Box>
        ) : (
          <Box
            display="flex"
            mb={verticalAlignment && 5}
            flexDirection={verticalAlignment && "column"}
            alignItems={verticalAlignment ? "center" : "flex-start"}>
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  color: ({ palette }) => palette.text.primary,
                  display: "inline-flex",
                  fontFamily: "Teko, sans-serif",
                  fontSize: { xxs: 40, md: 50, lg: 60 },
                  fontWeight: 400,
                  lineHeight: { xxs: 1, sm: 0.9 },
                  ...bodyDynamicSx
                }}>
                {bodyDynamic.toLocaleString("en")}
              </Typography>

              {copyText && <CopyText label="" textToCopy={bodyDynamic} />}
            </Box>
            {bodyDynamicUnit && (
              <Typography
                display="inline-flex"
                lineHeight={1.2}
                fontSize={{ xxs: 16, lg: 18 }}
                fontWeight={700}
                ml={0.625}
                color="textPrimary"
                sx={bodyDynamicUnitSx}>
                {bodyDynamicUnit}
              </Typography>
            )}
            {bodyDynamicAlert && (
              <Typography
                sx={{
                  ml: 0.6875,
                  mb: 0.6875,
                  alignSelf: "flex-end",
                  color: (theme) => theme.palette.grey[50]
                }}>
                {bodyDynamicAlert}
              </Typography>
            )}
          </Box>
        )}
        <Box display="flex" alignItems="center" pl={1}>
          {/* @TODO: add tietery button */}
          {secondaryButtonText && (
            <OutlinedButton
              disabled={secondaryButtonDisabled}
              sx={sharedButtonSx}
              color="primary"
              label={secondaryButtonText}
              handleClick={handleSecondaryClick}
            />
          )}
          {primaryButtonText && (
            <CustomButton
              disabled={primaryButtonDisabled}
              augmented="tl-clip br-clip"
              sx={{
                ...sharedButtonSx,
                ml: secondaryButtonText && 2.5,
                mb: { xxs: "18px", sm: "16px", md: "6px", lg: 0 }
              }}
              handleClick={handlePrimaryClick}>
              {primaryButtonText}
            </CustomButton>
          )}
        </Box>
      </Box>
    </Box>
  );
}
