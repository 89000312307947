import { useEffect, useState } from "react";
import { alpha, Box, TextField, Typography, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import OutlinedButton from "../Buttons/OutlinedButton";
import CustomDialog from "./Dialog";
import CustomButton from "../Buttons/Button";
import CopyText from "../CopyText";
import { PATH_DASHBOARD } from "../../routes/paths";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import { openNewTab } from "../../utils/redirect";
import { censorEmail } from "../../utils/strings";
import {
  setBindGoogle2FADialogOpen,
  getGoogle2FASecret,
  reqBindGoogle2FA
} from "../../redux/slices/account";
import {
  setTransferDialogOpen,
} from "../../redux/slices/DMA";
import useBreakpoints from "../../hooks/useBreakpoints";
import { GoogleAuthenticatorTitle } from '../../views/Dashboard/settings'
import { ReactComponent as LeftArrow } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as RectSuccess } from "../../assets/icons/rect-success.svg";
import SplitByDigitInput from "../SplitByDigitInput";

const google2FAiOSAppUrl = 'https://apps.apple.com/us/app/google-authenticator/id388497605'
const google2FAAndroidAppUrl = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'

const stepListSx = {
  display: "flex",
  flexDirection: { xxs: "column", xs: "row" },
  gap: "16px"
}

const muteTextSx = {
  color: "rgba(255, 255, 255, 0.5)",
  fontSize: { xxs: "12px", xs: "14px" },
  lineHeight: 18/14
}

function StepCard ({
  order = 0,
  desc = "",
  sx,
  children
}) {
  return (
    <Box
      data-augmented-ui="tl-clip border"
      style={getAugmentedStyle({
        color: "rgba(255, 255, 255, 0.2)"
      })}
      sx={{
        flex: "1 0 50%",
        flexShrink: 0,
        minWidth: 0,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: { xxs: "16px 20px", xs: "20px 14px" },
        ...sx
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
      <Box sx={{
        color: (theme) => theme.palette.primary.main,
        mb: "16px"
      }}>Step {order}</Box>
      <Box sx={{
        ...muteTextSx,
        px: { xxs: "0", xs: "10px" },
        textAlign: "center",
        mb: "16px",
        color: "rgba(255, 255, 255, 0.8)",
        whiteSpace: { xxs: "initial",  xs: "pre-line" }
      }}
      >{desc}</Box>
      </Box>
      { children }
    </Box>
  )
}

function StepVerify ({
  handleResendCodeClick,
  coolDownTimer,
  emailCode,
  setEmailCode,
  emailCodeError,
  setEmailCodeError,
  google2FACode,
  setGoogle2FACode,
  google2FACodeError,
  setGoogle2FACodeError,
}) {
  const { user } = useSelector((state) => state.auth);
  const downXs = useBreakpoints("down", "xs");

  return (
    <Box sx={{ ...stepListSx }}>
      <StepCard
        order={3}
        desc={`Enter the 6-digit code \nsent to ${censorEmail(user.email)}`}
      >
        <SplitByDigitInput
          scale={ downXs ? 1 : 0.769}
          sx={{ my: { xxs: "-2px", xs: "0"}, ml: { xxs: "28px", xs: "0" } }}
          width="330px"
          code={emailCode}
          setCode={setEmailCode}
          isError={emailCodeError}
          setIsError={setEmailCodeError}
        />
        <CustomButton
          augmented="tl-clip"
          color="secondary"
          disabled={coolDownTimer > 0}
          sx={{
            width: { xxs: "86%", xs: "100%"},
            height: { xxs: 24, sm: 30 },
            mt: "20px",
            fontSize: 14,
            textTransform: "inherit"
          }}
          handleClick={handleResendCodeClick}
        >
          Resend Code {coolDownTimer !== 0 ? `(${coolDownTimer}S)` : ""}
        </CustomButton>
      </StepCard>
      <StepCard
        order={4}
        desc={`Enter the 6-digit code \nfrom Google Authenticator`}
      >
        <SplitByDigitInput
          name="google2FACode"
          scale={ downXs ? 1 : 0.769}
          sx={{ my: { xxs: "-2px", xs: "0"}, ml: { xxs: "28px", xs: "0" } }}
          width="330px"
          code={google2FACode}
          setCode={setGoogle2FACode}
          isError={google2FACodeError}
          setIsError={setGoogle2FACodeError}
          autoFocus={false}
        />
        <Box sx={{
          ...muteTextSx,
          textAlign: "center",
          mt: "12px",
          mb: { xxs: "7px", xs: "0"},
          px: "2px",
          fontSize: "12px" }}
        >
          *After scanning the QR code from Step 2, the app will display a 6-digit code that you can enter above.
        </Box>
      </StepCard>
    </Box>
  )
}

function StepLayout ({ currentIndex, StepScan, LayoutFooter, children }) {
  return (
    <Box sx={{ mt: "24px" }} >
      <Box>
        { currentIndex === 0 ? <StepScan /> : children }
      </Box>
      <LayoutFooter />
    </Box>
  )
}

export function BindGoogle2FADialog() {
  const dispatch = useDispatch();
  const downXs = useBreakpoints("down", "xs");
  const {
    bindGoogle2FADialogOpen,
    bindGoogle2FACallback,
    bindGoogle2FASecret
  } = useSelector(
    (state) => state.account
  );
  const { user } = useSelector((state) => state.auth);
  const setClose = () => dispatch(setBindGoogle2FADialogOpen(false));
  const steps = [ 'scan', 'verify' ]
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasBound, setHasBound] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [coolDownTimer, setCoolDownTimer] = useState(60);
  const [emailCode, setEmailCode] = useState('');
  const [emailCodeError, setEmailCodeError] = useState(false);
  const [google2FACode, setGoogle2FACode] = useState('');
  const [google2FACodeError, setGoogle2FACodeError] = useState(true);
  const isPrimaryDisabled = currentIndex !== 0 && (
    emailCode.length !== 6 ||
    google2FACode.length !== 6 ||
    emailCodeError ||
    google2FACodeError
  )

  const loadData = async () => {
    setIsLoading(true)
    await dispatch(getGoogle2FASecret())
    setIsLoading(false)
  }

  useEffect(() => {
    if (bindGoogle2FADialogOpen) {
      setHasBound(false)
      setCurrentIndex(0)
      setEmailCode('')
      setEmailCodeError(false)
      setGoogle2FACode('')
      setGoogle2FACodeError(false)
      loadData()
    }
  }, [bindGoogle2FADialogOpen])

  useEffect(() => {
    if (!coolDownTimer) return;
    const intervalId = setInterval(() => {
      setCoolDownTimer(coolDownTimer - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [coolDownTimer]);

  const handleResendCodeClick = async () => {
    if (coolDownTimer !== 0 || isLoading) return
    await loadData()
    setCoolDownTimer(60)
  }

  const handlePrimaryButtonClick = async () => {
    if (isProcessing) return

    if (currentIndex === 0) {
      setCurrentIndex(1)
      return
    }

    setIsProcessing(true)

    const { bound, code } = await dispatch(reqBindGoogle2FA(Number(emailCode), Number(google2FACode)))
    if (bound) {
      setHasBound(true)
    }
    if (code === 400501) {
      setEmailCodeError(true)
    }
    if (code === 400505) {
      setGoogle2FACodeError(true)
    }
    setIsProcessing(false)
  }

  function BoundResult () {
    const { push } = useHistory();
    const { pathname } = useLocation();

    const handleButtonClick = () => {
      setClose()
      if (!bindGoogle2FACallback) return
      if (bindGoogle2FACallback === 'transfer') {
        if (pathname !== PATH_DASHBOARD.dmaView) push(PATH_DASHBOARD.dmaView);
        dispatch(setTransferDialogOpen(true));
        return
      }
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "50px",
          pt: "55px"
        }}
      >
        <RectSuccess />
        <Box>
          <GoogleAuthenticatorTitle text="Google Authenticator" isHeadline={true} />
          <Box sx={{ fontWeight: 700, fontSize: "24px" }}>
            Binding is Now <Box component="span" sx={{ color: "#82FFA2" }}>Successful</Box>
          </Box>
        </Box>
          <CustomButton
            augmented="tl-clip br-clip"
            sx={{
              mt: 0,
              width: { xxs: 83, sm: 188 },
              height: { xxs: 28, sm: 37 },
              fontSize: 16
            }}
            handleClick={handleButtonClick}>
            OK
          </CustomButton>
      </Box>
    )
  }

  function StepScan () {
    return (
      <Box sx={{ ...stepListSx }}>
        <StepCard
          order={1}
          desc="Please install Google Authenticator on your phone. "
          sx={{
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
              mb: "8px"
            }}
          >
            <Box sx={{ ...muteTextSx }}>Download Google Authenticator app:</Box>
            <Box sx={{
              display: "flex",
              flexDirection: { xxs: "row", xs: "column" },
              gap: "16px",
              width: "100%",
            }}>
            <CustomButton
              augmented="tl-clip"
              color="secondary"
              sx={{
                width: "100%",
                height: { xxs: 30, sm: 30 },
                fontSize: 14,
                textTransform: "inherit"
              }}
              handleClick={() => openNewTab(google2FAiOSAppUrl)}>
              iOS
            </CustomButton>
            <CustomButton
              augmented="tl-clip"
              color="secondary"
              sx={{
                width: "100%",
                height: { xxs: 30, sm: 30 },
                fontSize: 14
              }}
              handleClick={() => openNewTab(google2FAAndroidAppUrl)}>
              Android
            </CustomButton>
            </Box>
          </Box>
        </StepCard>
        <StepCard
          order={2}
          desc="Scan with Google Authenticator"
        >
          <QrCode />
        </StepCard>
      </Box>
    )
  }

  function QrCode () {
    const copyTextSx = {
      color: "white",
      fontSize: "12px",
      "& svg": {
        transform: "scale(0.6)"
      },
      mt: "4px",
      ml: { xxs: 0, xs: 0.75 }
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xxs: "row", xs: "column" },
          gap: "10px",
          alignItems: { xxs: "center", xs: "center" }
        }}
      >
        <Box
          data-augmented-ui="tl-clip border"
          style={getAugmentedStyle({
            color: "rgba(255, 255, 255, 0.3)"
          })}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100px",
            height: "100px",
            mb: { xxs: "0", xs: "16px" },
            padding: "10px"
          }}
        >
          { isLoading ? <CircularProgress color="primary" size={30} /> : <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundImage: `url(${bindGoogle2FASecret.qrcode})`
            }}
          >
          </Box>}
        </Box>
        <Box>
          <Box sx={{ ...muteTextSx }}>
            OR Enter this text code instead:
          </Box>
          <CopyText
            buttonSx={copyTextSx}
            captionSx={{
              display: "none"
            }}
            label={bindGoogle2FASecret.secret}
            textToCopy={bindGoogle2FASecret.secret}
          />
        </Box>
      </Box>
    )
  }

  function LayoutFooter () {
    return (
      <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mt: { xxs: "20px", xs: "34px" }
      }}
    >
      { currentIndex === 0 ? <Box /> : <Box onClick={() => setCurrentIndex(0)} sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "underline",
        fontSize: { xxs: "10px", xs: "16px" },
      }}>
        <LeftArrow style={{ marginRight: { xxs: "4px", xs: "8px" } }} />
        Previous Step
      </Box> }
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <OutlinedButton
          label="Cancel"
          classname="augmented-button-primary"
          color="primary"
          handleClick={setClose}
          sx={{
            width: { xxs: 100, sm: 188 },
            height: { xxs: 28, sm: 37 },
            mr: { xxs: "16px", sm: 3 },
            fontSize: { xxs: 12, sm: 16 }
          }}
        />
        <CustomButton
          augmented="tl-clip br-clip"
          isLoading={isProcessing}
          disabled={isPrimaryDisabled}
          sx={{
            mt: 0,
            width: { xxs: 100, sm: 188 },
            height: { xxs: 28, sm: 37 },
            fontSize: { xxs: 14, sm: 16 }
          }}
          handleClick={handlePrimaryButtonClick}>
          { currentIndex ? 'Verify' : 'Next Step' }
        </CustomButton>
      </Box>
    </Box>
    )
  }

  return (
    <CustomDialog
      hasCloseButton={false}
      BackdropProps={{
        sx: {
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7)
        }
      }}
      PaperProps={{
        sx: {
          width: { xxs: "101%", sm: 710 },
          height: { xxs: "80vh", xs: "initial" },
          minHeight: "548px",
          padding: { xxs: 0, xs: "0 6px" },
          margin: { xxs: 0, xs: 2 },
          left: -2,
          bottom: -2,
          fontFamily: "Chakra Petch, sans-serif",
          background: { xxs: "#181818", xs: "black" },

          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            display: "none"
          }
        },
        style: {
          position: downXs ? "absolute" : "relative",
          "--aug-tr1": "12px",
          "--aug-tr-inset2": "calc(var(--aug-tr1) * 14.5)"
        },
        "data-augmented-ui": downXs ? "tr-clip-x border" : "bl-clip-y br-clip border"
      }}
      setClose={setClose}
      open={bindGoogle2FADialogOpen}>
      <Box sx={{ pt: { xxs: 0, sm: 2 }, pb: { xxs: 0, sm: 4 }, px: { xxs: 0, sm: 5 } }}>
        { !hasBound && <GoogleAuthenticatorTitle text="Bind with Google Authenticator" isHeadline={true} /> }
        { hasBound
          ? <BoundResult />
          : <StepLayout
            currentIndex={currentIndex}
            LayoutFooter={LayoutFooter}
            StepScan={StepScan}
          >
            <StepVerify
              handleResendCodeClick={handleResendCodeClick}
              coolDownTimer={coolDownTimer}
              bindGoogle2FASecret={bindGoogle2FASecret}
              emailCode={emailCode}
              setEmailCode={setEmailCode}
              emailCodeError={emailCodeError}
              setEmailCodeError={setEmailCodeError}
              google2FACode={google2FACode}
              setGoogle2FACode={setGoogle2FACode}
              google2FACodeError={google2FACodeError}
              setGoogle2FACodeError={setGoogle2FACodeError}
            />
          </StepLayout>
        }
      </Box>
    </CustomDialog>
  )
}

export default BindGoogle2FADialog;
