import React from "react";
import { alpha, Button, CircularProgress } from "@mui/material";
import { getAugmentedStyle } from "../../utils/augmentedStyles";

function CustomButton({
  augmented = "br-clip",
  type = "button",
  isLoading = false,
  disabled = false,
  color = "primary",
  variant = "contained",
  children,
  handleClick = () => {},
  fullWidth = true,
  ...props
}) {
  const augmentedStyle = getAugmentedStyle({ color: "" });

  return (
    <Button
      fullWidth={fullWidth}
      type={type}
      disableRipple
      disabled={isLoading || disabled}
      color={color}
      variant={variant}
      onClick={handleClick}
      data-augmented-ui={augmented}
      style={augmentedStyle}
      sx={{
        border: "none",
        fontSize: { xxs: 12, md: 18 },
        fontWeight: 400,
        height: { xxs: 27, md: 39 },

        "&.Mui-disabled": {
          pointerEvents: "all",
          cursor: "not-allowed",
          opacity: 1,
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.5),
          color: (theme) => theme.palette.grey[900]
        },
        mt: { xxs: 4, md: 5 },
        ...props?.sx
      }}
      {...props}>
      {isLoading ? (
        <CircularProgress size={16} sx={{ color: (theme) => theme.palette.grey[900] }} />
      ) : (
        children
      )}
    </Button>
  );
}

export default CustomButton;
