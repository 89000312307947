import React, { useState } from "react";
import CustomDialog from "./Dialogs/Dialog";
import { Box, Typography } from "@mui/material";
import CheckBoxField from "./Fields/Input/CheckBox";
import CustomButton from "./Buttons/Button";
import { NavLink, useHistory } from "react-router-dom";
import { PATH_HISTORY } from "../routes/paths";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRewardType,
  selectAllRewardTypes,
  setRewardMethodSelecting
} from "../redux/slices/DMA";
import { REWARD_TYPES, REWARD_TYPES_VALUE } from "../constants/rewards";
import ReactGA from "react-ga4";

const buttonSx = {
  fontSize: { xxs: 12, md: 16 },
  height: { xxs: 32, md: 36 }
};

const buttonSmallSx = {
  fontSize: 12,
  width: 95,
  whiteSpace: "nowrap",
  height: { xxs: 32, md: 36 }
};

const ONLY_SUPPORT_CASHBACK = true

function SelectRewardsView({ selected, setSelected }) {
  const dispatch = useDispatch();
  const {
    selectingRewardType: { id, cashbackAmount, loyaltyScoreAmount, increasePercentage }
  } = useSelector((state) => state.DMA);
  const labelSx = {
    fontSize: { xxs: 14, md: 16 },
    ml: { xxs: 1.375, md: 2 }
  };

  async function handleSelectReward() {
    ReactGA.event(`selected_${selected}_reward_method`);
    if (id === -1) dispatch(selectAllRewardTypes({ rewardType: REWARD_TYPES_VALUE[selected] }));
    else dispatch(selectRewardType({ id, rewardType: REWARD_TYPES_VALUE[selected] }));
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h4" mb={ ONLY_SUPPORT_CASHBACK ? 0 : 5} fontSize={{ xxs: 20, md: 24 }}>
        { ONLY_SUPPORT_CASHBACK ? "Confirm Your Rewards" : "Select Your Reward Method" }
      </Typography>
      { !ONLY_SUPPORT_CASHBACK &&
        <>
        <CheckBoxField
          handleChange={() => setSelected(REWARD_TYPES[1])}
          checked={REWARD_TYPES[1] === selected}
          labelSx={labelSx}
          label={`Receive ${loyaltyScoreAmount} Loyalty Score`}
        />
        <Typography
          mt={1}
          variant="caption"
          sx={{
            ml: { xxs: 1.375, md: 4 },
            color: ({ palette }) => palette.grey[50],
            maxWidth: { md: "75%" }
          }}>
          The $AGI you will receive tomorrow is expected to increase by {increasePercentage}%
        </Typography>
        </>
      }

      <CheckBoxField
        handleChange={() => setSelected(REWARD_TYPES[0])}
        checked={REWARD_TYPES[0] === selected}
        formSx={{ mt: 4.5 }}
        labelSx={labelSx}
        label={`One-time Cashback of ${cashbackAmount} USDT`}
      />
      <Typography
        mt={1}
        variant="caption"
        sx={{
          ml: { xxs: 3.4, md: 4 },
          mb: { xxs: 0, md: 8 },
          color: ({ palette }) => palette.grey[50],
          maxWidth: { md: "75%" }
        }}>
        {cashbackAmount} USDT Cashback will be added to your balance.
      </Typography>

      <Box
        sx={{
          mt: 7.5,
          columnGap: { xxs: 1, md: 3 },
          display: "flex",
          flexFlow: "row nowrap"
        }}>
        <CustomButton
          sx={buttonSx}
          variant="outlined"
          augmented="tl-clip br-clip border"
          handleClick={() => dispatch(setRewardMethodSelecting({ dialogOpen: false }))}>
          Cancel
        </CustomButton>
        <CustomButton
          handleClick={handleSelectReward}
          sx={buttonSx}
          augmented="tl-clip br-clip border"
          variant="contained">
          Confirm
        </CustomButton>
      </Box>
      { !ONLY_SUPPORT_CASHBACK &&
      <Typography sx={{ fontSize: 12, color: (theme) => theme.palette.grey[50], mt: 3 }}>
        You can set later in the{" "}
        <NavLink color="inherit" to={PATH_HISTORY.referral}>
          <Typography
            onClick={() => dispatch(setRewardMethodSelecting({ dialogOpen: false }))}
            sx={{ fontSize: "inherit", textDecoration: "underline", display: "inline-block" }}
            color="primary">
            Activity History — DMA Referral
          </Typography>
        </NavLink>
      </Typography>
      }
    </Box>
  );
}

function LoyaltyCompleted() {
  const dispatch = useDispatch();
  const {
    selectingRewardType: { loyaltyScoreAmount }
  } = useSelector((state) => state.DMA);
  return (
    <Box display="flex" flexDirection="column">
      <Typography textAlign="center" variant="h4" fontSize={{ xxs: 20, md: 24 }}>
        Transaction Completed
      </Typography>

      <Typography
        color="textPrimary"
        sx={{
          textAlign: "center",
          fontSize: { xxs: 14, md: 16 },
          mt: 2.5,
          mb: 4,
          maxWidth: "350px"
        }}>
        <Typography sx={{ fontSize: "inherit", display: "inline-block" }} color="primary">
          {loyaltyScoreAmount} Loyalty Score
        </Typography>{" "}
        has been added to your account.
      </Typography>

      <CustomButton
        sx={{ ...buttonSmallSx, width: 93, margin: "0 auto" }}
        variant="outlined"
        augmented="tl-clip br-clip border"
        handleClick={() => dispatch(setRewardMethodSelecting({ dialogOpen: false }))}>
        Close
      </CustomButton>
    </Box>
  );
}

function CashbackCompleted() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const {
    selectingRewardType: { cashbackAmount }
  } = useSelector((state) => state.DMA);
  return (
    <Box display="flex" flexDirection="column">
      <Typography textAlign="center" variant="h4" fontSize={{ xxs: 20, md: 24 }}>
        Transaction Completed
      </Typography>

      <Typography
        color="textPrimary"
        sx={{
          textAlign: "center",
          fontSize: { xxs: 14, md: 16 },
          mt: 2.5,
          mb: 4,
          maxWidth: "350px"
        }}>
        <Typography sx={{ fontSize: "inherit", display: "inline-block" }} color="primary">
          {cashbackAmount} USDT Cashback
        </Typography>{" "}
        has been added to your balance.
      </Typography>

      <Box
        sx={{
          columnGap: { xxs: 1, md: 3 },
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "center"
        }}>
        <CustomButton
          sx={buttonSmallSx}
          variant="outlined"
          augmented="tl-clip br-clip border"
          handleClick={() => dispatch(setRewardMethodSelecting({ dialogOpen: false }))}>
          Close
        </CustomButton>

        <CustomButton
          handleClick={() => {
            ReactGA.event("loyalty_program_tab_opened");
            dispatch(setRewardMethodSelecting({ dialogOpen: false }));
            push(PATH_HISTORY.referral);
          }}
          sx={buttonSmallSx}
          augmented="tl-clip br-clip border"
          variant="contained">
          View Details
        </CustomButton>
      </Box>
    </Box>
  );
}

const RewardMethodDialog = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(REWARD_TYPES[0]);
  const {
    selectingRewardType: { dialogOpen, successDialog }
  } = useSelector((state) => state.DMA);
  const isOnSelect = successDialog === -1;

  return (
    <CustomDialog
      setClose={() => dispatch(setRewardMethodSelecting({ dialogOpen: false }))}
      open={dialogOpen}
      PaperProps={{
        sx: {
          px: { xxs: 0, md: isOnSelect ? 20 : 7.5 },
          pt: { xxs: isOnSelect ? 3.875 : 2.5, md: isOnSelect ? 10 : 2.5 },
          pb: { xxs: isOnSelect ? 7 : 2.5, md: isOnSelect ? 10 : 2.5 }
        }
      }}>
      {isOnSelect && (
        <SelectRewardsView selected={selectedOption} setSelected={setSelectedOption} />
      )}
      {successDialog === REWARD_TYPES_VALUE["Loyalty Score"] && <LoyaltyCompleted />}
      {successDialog === REWARD_TYPES_VALUE.Cashback && <CashbackCompleted />}
    </CustomDialog>
  );
};

export default RewardMethodDialog;
