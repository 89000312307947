import spacetime from "spacetime";

export const TIME_ZONE = "asia/shanghai";

export const DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

export const decomposeTimeLeft = (timeLeft) => {
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  return [days, hours, minutes, seconds];
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDate(input, needSeconds = true) {
  if (!input) return "";
  const stamp = spacetime(input * 1000, TIME_ZONE);
  const month = stamp.monthName().substring(0, 3);
  const day = stamp.date();
  const year = stamp.year();

  let time = `${stamp.hour() < 10 ? `0${stamp.hour()}` : stamp.hour()}:${
    stamp.minute() < 10 ? `0${stamp.minute()}` : stamp.minute()
  }`;

  if (needSeconds) {
    time += `:${stamp.second() < 10 ? `0${stamp.second()}` : stamp.second()}`;
  }

  return `${capitalizeFirstLetter(month)} ${day}, ${year} ${time}`;
}
