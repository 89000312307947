import { Typography, CircularProgress, Divider, alpha } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeInDown } from "../../../components/Animate";
import incentiveRulePNG from "../../../assets/incentive_rules.png";
import incentiveRuleMobilePNG from "../../../assets/incentive_rules_mobile.png";
import MiniGameButton from "../../../assets/wheel/MiniGameButton.png";
import { ReactComponent as WheelIcon } from "../../../assets/icons/wheel.svg";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert.svg";
import { ReactComponent as ReferralPointsIcon } from "../../../assets/icons/referral-points.svg";
import { ReactComponent as AGIIcon } from "../../../assets/icons/agi.svg";
import { ReactComponent as POINTIcon } from "../../../assets/icons/point.svg";
import { ReactComponent as USDTIcon } from "../../../assets/icons/usdt.svg";
import { ReactComponent as LoyaltyIcon } from "../../../assets/icons/loyalty.svg";
import { PATH_DASHBOARD, PATH_EXTERNAL, PATH_HISTORY } from "../../../routes/paths";
import { isWalletBrowser } from "../../../utils/isMobile";
import { getCorrectChain } from "../../../utils/chains";
import { NETWORK_TYPE, networks } from "../../../constants/network";
import {
  getDMAInfo,
  getTransparencyIndex,
  getWithdrawStatus,
  setDepositUsdtDialogOpen,
  setDepositDialogOpen,
  setSelectedCurrency,
  setWheelDialogOpen,
  setTxReminderDialogOpen,
  setTransferDialogOpen,
  setWithdrawalDialogOpen,
  setTransparencyDialogOpen,
  handleChangeNetwork
} from "../../../redux/slices/DMA";
import TransparencyIndexDialog from "../../../components/Dialogs/TransparencyIndexDialog";
import WheelDialog from "../../../components/Dialogs/WheelDialog";
import DepositDialog from "../../../components/Dialogs/DepositDialog";
import DepositUsdtDialog from "../../../components/Dialogs/DepositUsdtDialog";
import WithdrawalDialog from "../../../components/Dialogs/WithdrawalDialog";
import TransferDialog, { TransferSuccessDialog } from "../../../components/Dialogs/TransferDialog";
import { PageHeading } from "../../../components/PageHeading";
import Container from "../../../components/Container";
import { isWalletBound } from "../../../redux/slices/auth";
import {
  setBindWalletNotice,
  setWalletDialogOpen,
  WALLET_CONFIG
} from "../../../redux/slices/wallet";
import TransactionReminderDialog from "../../../components/Dialogs/TransactionReminderDialog";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";
import { config } from "../../../config";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../components/Buttons/Button";
import OutlinedButton from "../../../components/Buttons/OutlinedButton";
import { displayNum, withComma } from "../../../utils/strings";
import { currencies } from "../../../constants/currencies";
import { getFailedTx } from "../../../redux/slices/history";
import useBreakpoints from "../../../hooks/useBreakpoints";
import InfoCard, { InfoCardSx } from "../../../components/InfoCard";
import ReferralCodeBox from "../../../components/ReferralCodeBox";
import { SectionTitle } from "../../../components/SectionTitle";
import { wrongNetworkAlert } from "../../../redux/slices/wallet";
import { getLoyaltyStats } from "../../../redux/slices/loyalty";

const containerSx = {
  width: "100%",
  display: "flex",
  flexFlow: { xxs: "column nowrap", md: "row nowrap" },
  alignItems: "flex-start",
  justifyContent: "center"
};

const balanceContainerSx = {
  width: "100%",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: { xxs: "flex-start", md: "center" },
  justifyContent: { xxs: "flex-start", md: "center" }
};

const balanceSx = {
  lineHeight: { xxs: "32px", md: "45px" },
  flexGrow: 1,
  textAlign: { xxs: "left", md: "center" },
  width: { md: "100%" },
  minWidth: "170px",
  mt: { xxs: 2, md: "44px" },
  mb: { xxs: 0, md: "12px" },
  fontSize: { xxs: "40px", md: "60px" },
  fontFamily: "Teko"
};

function DMAView() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useIsActive, useChainId } = WALLET_CONFIG[selectedWallet].hook;
  const chainId = useChainId();
  const isActive = useIsActive();
  const {
    isLoading,
    stats,
    bonusStats,
    referral,
    balance,
    withdraw,
    isEnableWheel,
    txReminderDialogOpen,
    transferDialogOpen,
    transparencyVoted,
    transparencyIndex,
    withdrawalDialogOpen
  } = useSelector((state) => state.DMA);
  const { totalReferred, rewards } = referral;
  const { quantity, pendingQuantity } = stats;
  const upXS = useBreakpoints("up", "md");
  const { failedTx } = useSelector((state) => state.history);

  const nativeBalance = Number(
    balance.find((item) => item.currency === currencies.native)?.amount || 0
  );

  const pointBalance = Number(
    balance.find((item) => item.currency === currencies.POINT)?.amount || 0
  );
  const USDTBalance = Number(
    balance.find((item) => item.currency === currencies.USDT)?.amount || 0
  );

  const DESRewards = rewards.find((item) => item.currency === "DES")?.amount || 0;
  const USDTRewards = rewards.find((item) => item.currency === currencies.USDT)?.amount || 0;
  const LoyaltyScore = rewards.find((item) => item.currency === "LoyaltyScore")?.amount || 0;
  const TotalBonus = bonusStats.new_sale_points_amount || 0;
  const BonusRewards = bonusStats.total_agi_rewards_amount || 0;

  const withdrawUSDTDisabled = USDTBalance === 0 || !withdraw.usdt;

  const withdrawDESDisabled = nativeBalance === 0 || !withdraw.des;

  const transferDisabled = nativeBalance === 0;
  const canVoted = !transparencyVoted || ((new Date().getTime() - transparencyVoted)/(1000 * 3600 * 24) > 14)

  useEffect(() => {
    dispatch(getTransparencyIndex());
    dispatch(getLoyaltyStats());
    dispatch(getDMAInfo());
    dispatch(getFailedTx());
    dispatch(getWithdrawStatus());
  }, [dispatch]);

  function handleClickWithdraw(initialCurrency = currencies.USDT) {
    dispatch(setSelectedCurrency(initialCurrency));
    dispatch(getFailedTx());
    if (!isWalletBound(user)) {
      dispatch(setBindWalletNotice(true));
      return;
    }
    if (!isActive) {
      dispatch(setWalletDialogOpen({ open: true }));
      return;
    }
    if (failedTx) {
      dispatch(setTxReminderDialogOpen(true));
      return;
    }

    dispatch(setWithdrawalDialogOpen(true));
  }

  async function handleClickDeposit () {
    const correctChain = getCorrectChain(NETWORK_TYPE.BSC);
    const isWrongNetwork = correctChain.id !== chainId;

    if (!isWalletBound(user)) {
      dispatch(setBindWalletNotice(true));
      return;
    }
    if (!isActive) {
      dispatch(setWalletDialogOpen({ open: true }));
      return;
    }

    if (isWrongNetwork) {
      const switchSuccess = await handleChangeNetwork(NETWORK_TYPE.BSC);
      if (!switchSuccess) {
        dispatch(wrongNetworkAlert(networks[NETWORK_TYPE.BSC], "deposit"));
      }
      return;
    }


    dispatch(setDepositDialogOpen(true));
  }

  async function handleClickDepositUsdt () {
    const correctChain = getCorrectChain(NETWORK_TYPE.BSC);
    const isWrongNetwork = correctChain.id !== chainId;

    if (!isWalletBound(user)) {
      dispatch(setBindWalletNotice(true));
      return;
    }
    if (!isActive) {
      dispatch(setWalletDialogOpen({ open: true }));
      return;
    }

    if (isWrongNetwork) {
      const switchSuccess = await handleChangeNetwork(NETWORK_TYPE.BSC);
      if (!switchSuccess) {
        dispatch(wrongNetworkAlert(networks[NETWORK_TYPE.BSC], "deposit"));
      }
      return;
    }


    dispatch(setDepositUsdtDialogOpen(true));
  }

  function handleClickTransfer() {
    ReactGA.event("try_dmatransferAGI");
    dispatch(setTransferDialogOpen(true));
  }

  const handleClickAcquirePoint = () => {
    window.open("https://delysium.gitbook.io/delysium-multiverse-product/delysium-multiverse-accelerator/delysium-mini-game-guide/point-system", "_blank")
    console.log('@TODO: handleClickAcquirePoint')
  }

  return (
    <Container>
      <PageHeading
        title="Delysium Multiverse Accelerator"
        subtitle="Buy DMA to earn enormous reward!"
      />
      {isLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            mt: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <AnimatePresence>
          <Box component={motion.div} {...varFadeInDown}>
            <SectionTitle color="primary" label="Get Rewards through referral" />
            <ReferralCodeBox />

            <Box
              data-augmented-ui="tl-clip border"
              style={getAugmentedStyle({
                color: "rgb(80 80 80)"
              })}
              sx={{
                marginTop: { xxs: '20px', sm: "32px" },
                padding: { xxs: '20px', sm: "40px" },
                paddingBottom: { xxs: '60px', sm: "40px" }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap:  { xxs: '16px', sm: "16px" }
                }}>
                  <SectionTitle label="Transparency Index:" sx={{
                    marginTop: "0 !important",
                    marginBottom: "0 !important",
                    flexShrink: 0,
                  }} />
                  <Typography sx={{
                    ...balanceSx,
                    textAlign: "left",
                    marginTop: "0 !important",
                    marginBottom: "0 !important",
                    fontSize: { xxs: '44px', sm: "72px" },
                  }} color="primary">
                      {transparencyIndex}
                    </Typography>
                </Box>
                <CustomButton
                    handleClick={() => dispatch(setTransparencyDialogOpen(true))}
                    augmented="tl-clip br-clip"
                    disabled={!canVoted}
                    sx={{
                      position: { xxs: `absolute !important`, sm: 'static !important' },
                      bottom: `20px`,
                      right: `20px`,
                      fontWeight: 400,
                      mt: { xxs: 1.5, md: 4 },
                      height: { xxs: "28px", md: "28px" },
                      width: { xxs: "90px", md: "102px" },
                      fontSize: "12px",
                      alignSelf: { xxs: "flex-end", md: "initial" },
                    }}>
                    Vote
                  </CustomButton>
              </Box>
              <Box sx={{
                marginTop: { xxs: "16px", sm: "24px" },
                opacity: 0.5,
                fontSize: { xxs: "12px", md: "12px" }
              }}>
              The average score is calculated by using “truncated mean”. It means the highest and lowest 5% of scores are being removed and we calculate the average score using arithmetic mean.
              </Box>
              {!canVoted && <Box
                sx={{
                  opacity: 0.4,
                  marginTop: { xxs: "16px", sm: "20px" },
                  fontSize: { xxs: "12px", md: "12px" }
                }}
              >
                  You have already voted. Transparency Index will be refreshed every 2 weeks, and everyone can only vote 1 time during this period.
              </Box> }


            </Box>

            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
            mt={{ xxs: 2.5, md: 7.5 }}
            mb={{ xxs: 1.875, md: 3 }}
            >
              <SectionTitle label="your balance" sx={{
                marginTop: "0 !important",
                marginBottom: "0 !important"
              }} />
              { isEnableWheel &&
              <OutlinedButton
                handleClick={() => dispatch(setWheelDialogOpen(true))}
                color="primary"
                startIcon={<Box sx={{
                  backgroundImage: `url(${MiniGameButton})`,
                  width: 87,
                  height: 16,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  marginLeft: "12px"
                }} />}
                augmented="tl-clip br-clip border"
                sx={{
                  height: { xxs: "28px", md: "28px" },
                  width: { xxs: "131px", md: "131px" },
                  fontSize: "12px"
                }}
              />}
            </Box>
            <Box
              data-augmented-ui="tl-clip br-clip border"
              style={getAugmentedStyle({
                color: "rgb(26 26 26)"
              })}
              sx={{
                display: "flex",
                flexFlow: "column nowrap",
                alignItems: "center",
                ...InfoCardSx,
                px: { xxs: "24px", md: "30px" },
                py: { xxs: "24px", md: "60px" }
              }}>
              <Box sx={containerSx}>
                <Box sx={balanceContainerSx}>
                  <Box
                    sx={{
                      display: "flex",
                      flexFlow: { xxs: "row nowrap", md: "column nowrap", alignItems: "center" }
                    }}>
                    <USDTIcon style={{ height: "auto", width: upXS ? "36px" : "20px" }} />
                    <Typography
                      sx={{ mt: { xxs: "0", md: 1.25 }, ml: { xxs: 1, md: 0.5 }, fontWeight: 500 }}
                      color="textPrimary">
                      {currencies.USDT}
                    </Typography>
                  </Box>

                  <Typography sx={balanceSx} color="primary">
                    {displayNum(USDTBalance)}
                  </Typography>
                  <Box sx={{
                      display: "flex",
                      flexDirection: { xxs: "row", md: "column"},
                      alignSelf: { xxs: "flex-end", md: "initial" },
                      alignItems: "flex-end",
                      gap: { xxs: "12px", md: 0 },
                  }}>
                  <OutlinedButton
                    label={"Withdraw"}
                    handleClick={() => handleClickWithdraw(currencies.USDT)}
                    augmented="tl-clip br-clip"
                    disabled={withdrawUSDTDisabled}
                    needCircular={false}
                    sx={{
                      fontWeight: 400,
                      mt: { xxs: 1.5, md: 4 },
                      height: { xxs: "28px", md: "32px" },
                      width: { xxs: "100px", md: "100px" },
                      fontSize: "12px",
                      alignSelf: { xxs: "flex-end", md: "initial" }
                    }}>
                  </OutlinedButton>
                  <Box>
                    <CustomButton
                      handleClick={() => handleClickDepositUsdt()}
                      augmented="tl-clip br-clip"
                      sx={{
                        fontWeight: 400,
                        mt: { xxs: "12px", md: "12px" },
                        height: { xxs: "28px", md: "32px" },
                        width: { xxs: "90px", md: "100px" },
                        fontSize: "12px"
                      }}>
                      Deposit
                    </CustomButton>
                  </Box>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    mx: { md: 2 },
                    my: { xxs: 3, md: 0 },
                    width: { xxs: "100%", md: "1px" },
                    height: { xxs: "1px", md: "242px" }
                  }}
                  orientation={upXS ? "vertical" : "horizontal"}
                />
                <Box sx={balanceContainerSx}>
                  <Box
                    sx={{
                      display: "flex",
                      flexFlow: { xxs: "row nowrap", md: "column nowrap", alignItems: "center" }
                    }}>
                    <AGIIcon style={{ height: "auto", width: upXS ? "36px" : "20px" }} />
                    <Typography
                      sx={{ mt: { xxs: "0", md: 1.25 }, ml: { xxs: 1, md: 0.5 }, fontWeight: 500 }}
                      color="textPrimary">
                      {currencies.native}
                    </Typography>
                  </Box>
                  <Typography sx={balanceSx} color="primary">
                    {displayNum(nativeBalance)}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xxs: "row", md: "column"},
                      alignSelf: { xxs: "flex-end", md: "initial" },
                      alignItems: "flex-end",
                      width: { xxs: "100%", sm: "212px" },
                      mt: { xxs: 1.5, md: 4 },
                      gap: { xxs: "12px", md: 0 },
                    }}>
                    <OutlinedButton
                      label="Transfer"
                      handleClick={handleClickTransfer}
                      augmented="tl-clip br-clip border"
                      disabled={transferDisabled}
                      needCircular={false}
                      sx={{
                        height: { xxs: "28px", md: "32px" },
                        width: { xxs: "90px", md: "100%" },
                        fontSize: "12px"
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex"
                      }}
                    >
                    <CustomButton
                      handleClick={() => handleClickDeposit()}
                      augmented="tl-clip br-clip"
                      sx={{
                        fontWeight: 400,
                        mt: { xxs: "12px", md: "12px" },
                        height: { xxs: "28px", md: "32px" },
                        width: { xxs: "90px", md: "100px" },
                        fontSize: "12px"
                      }}>
                      Deposit
                    </CustomButton>
                    <CustomButton
                      handleClick={() => handleClickWithdraw(currencies.native)}
                      augmented="tl-clip br-clip"
                      disabled={withdrawDESDisabled}
                      sx={{
                        fontWeight: 400,
                        mt: { xxs: "12px", md: "12px" },
                        ml: "12px",
                        height: { xxs: "28px", md: "32px" },
                        width: { xxs: "90px", md: "100px" },
                        fontSize: "12px"
                      }}>
                      Withdraw
                    </CustomButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <InfoCard
              cardSx={{ py: { xxs: 2, md: 5 }, px: { xxs: 3, md: 6 } }}
              BodyStatic={() => (
                <Box sx={{
                  width: "100%"
                }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexFlow: { xxs: "row nowrap", alignItems: "center" }
                    }}>
                    <POINTIcon style={{ height: "auto", width: upXS ? "36px" : "20px" }} />
                    <Typography
                      sx={{ mt: { xxs: "0" }, ml: { xxs: 1, md: 2 }, fontWeight: 500, fontSize: { xxs: 16, md: 18 } }}
                      color="textPrimary">
                      {'KOKORO'}
                    </Typography>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                    <Typography sx={{...balanceSx, textAlign: 'left' }} color="primary">
                      {withComma(pointBalance)}
                    </Typography>
                    <CustomButton
                      handleClick={() => handleClickAcquirePoint()}
                      augmented="tl-clip br-clip"
                      sx={{
                        fontWeight: 400,
                        mt: { xxs: "14px", md: "50px" },
                        ml: "12px",
                        mr: { xxs: "-8px", md: "0" },
                        height: { xxs: "28px", md: "32px" },
                        width: { xxs: "90px", md: "100px" },
                        fontSize: "12px"
                      }}>
                      Acquire
                    </CustomButton>
                  </Box>
                </Box>
              )}
            />
            <InfoCard
              cardSx={{ py: 2, px: 3 }}
              BodyStatic={() => (
                <Typography
                  sx={{
                    fontSize: { xxs: 10, md: 12 },
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center"
                  }}>
                  <AlertIcon style={{ marginRight: "6px" }} />
                  <Typography
                    variant="caption"
                    sx={{ fontSize: { xxs: "10px", md: "12px" }, ml: 1.125 }}
                    color="textPrimary">
                    All withdrawal and transfer details are recorded in the "Activity History".
                    <Typography
                      variant="caption"
                      color="primary"
                      sx={{
                        display: { xxs: "inline-block", md: "block" },
                        fontSize: { xxs: "10px", md: "12px" },
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        ReactGA.event("activity_history_tab_opened");
                        push(`${PATH_HISTORY.transfer}`);
                      }}>
                      Click Here to View.
                    </Typography>
                  </Typography>{" "}
                </Typography>
              )}
            />
            <SectionTitle label="your dma details" />
            <InfoCard
              title="Quantity"
              bodyDynamic={quantity}
              bodyDynamicAlert={pendingQuantity > 0 ? `( ${pendingQuantity} DMA Pending )` : null}
              secondaryButtonText="Learn More"
              handleSecondaryClick={() =>
                isWalletBrowser()
                  ? window.location.assign(PATH_EXTERNAL.gitbook.dma)
                  : window.open(PATH_EXTERNAL.gitbook.dma, "_blank")
              }
            />

            <InfoCard
              title="Total Incentives"
              bodyDynamic={displayNum(stats.rewards?.amount)}
              bodyDynamicUnit={currencies.native}
            />
            <InfoCard
              title="Yesterday Incentives"
              bodyDynamic={displayNum(stats.yesterdayRewards?.amount)}
              bodyDynamicUnit={currencies.native}
            />

            <Box
              data-augmented-ui="tl-clip br-clip"
              style={getAugmentedStyle({})}
              sx={{
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "space-between",
                py: 3.75,
                px: 3,
                backgroundColor: `rgba(255, 255, 255, 0.07)`
              }}>
              <Typography
                sx={{
                  color: ({ palette }) => palette.grey[50],
                  fontSize: { xxs: 14, sm: 16, lg: 18 },
                  fontWeight: 500
                }}>
                {currencies.native} Incentive Rules
              </Typography>
              <Box
                width="100%"
                height="auto"
                alt="dma incentive rules graph"
                component="img"
                my={{ xxs: 3.75, md: 7.25 }}
                src={upXS ? incentiveRulePNG : incentiveRuleMobilePNG}
              />
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  alignItems: "center",
                  mt: 2,
                  px: { md: 1 }
                }}>
                <AlertIcon style={{ width: !upXS && "38px", height: "auto" }} />
                <Typography
                  variant="caption"
                  sx={{ fontSize: { xxs: "10px", md: "12px" }, ml: 1.125 }}
                  color="textPrimary">
                  The amount of {currencies.native} you will receive is proportional to the
                  percentage of DMAs you hold.{" "}
                  <Typography
                    variant="caption"
                    color="primary"
                    component="a"
                    sx={{
                      display: { xxs: "inline-block", md: "block" },
                      fontSize: { xxs: "10px", md: "12px" },
                      textDecoration: "underline",
                      cursor: "pointer"
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${config.DELYSIUM_CLIENT_URL}/dma`}>
                    Want to Buy More DMA?
                  </Typography>
                </Typography>
              </Box>
            </Box>

            <Box width="100%" display="flex" justifyContent="space-between" alignItems="flex-end">
              <SectionTitle label="Referral System" />
              <Typography
                component="a"
                target="_blank"
                href={PATH_EXTERNAL.gitbook.referral}
                sx={{
                  mt: { xxs: 2.5, md: 7.5 },
                  mb: { xxs: 1.875, md: 3 },
                  fontSize: { xxs: 10, md: 14 },
                  color: ({ palette }) => palette.grey[50]
                }}>
                Learn More
              </Typography>
            </Box>

            <Box display="flex" sx={{
              gap: { xxs: 0.5, sm: 0.5 }
            }}>
              <Box
                style={getAugmentedStyle({ color: "rgb(26 26 26)", bl1: "1px" })}
                data-augmented-ui="tl-clip br-clip border"
                sx={{ ...InfoCardSx }}>
                <Box display="flex" mb={1.875} justifyContent="flex-start" alignItems="center">
                  {!upXS && (
                    <ReferralPointsIcon style={{ height: "auto", width: "20px", marginRight: "4px" }} />
                  )}
                  <Typography
                    color={alpha("#fff", 0.5)}
                    mr={0.5}
                    fontSize={{ xxs: 12, sm: 16, lg: 18 }}
                    fontWeight={500}>
                    Referral Points
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  {upXS && <ReferralPointsIcon style={{ height: "auto", width: "48px", marginTop: "-7px" }} />}
                  <Typography
                    sx={{
                      fontFamily: "Teko, sans-serif",
                      fontSize: { xxs: 40, md: 60 },
                      fontWeight: 400,
                      ml: 1,
                      lineHeight: { xxs: 1, sm: 0.9 }
                    }}
                    color="primary">
                    {displayNum(LoyaltyScore)}
                  </Typography>
                </Box>
              </Box>
              {/* <InfoCard
                title="Bonus"
                bodyDynamic={`+${displayNum(TotalBonus)}`}
                bodyDynamicSx={{
                  color: (theme) => theme.palette.primary.main,
                  fontSize: { xxs: 40, md: 60 },
                }}
              /> */}
            </Box>

            <InfoCard
              primaryButtonText="View History"
              handlePrimaryClick={() => push(PATH_HISTORY.referral)}
              title="Your Referral"
              bodyDynamic={totalReferred}
              bodyDynamicUnit="DMA"
            />

            <InfoCard
              title="Your USDT Rewards from DMA Referral"
              bodyDynamic={displayNum(USDTRewards)}
              bodyDynamicUnit="USDT"
            />
            <InfoCard
              title={`Your ${currencies.native} from Referral System`}
              bodyDynamic={displayNum(DESRewards)}
              bodyDynamicUnit={currencies.native}
            />
            {/* <InfoCard
              title={`Your Bonus ${currencies.native}`}
              bodyDynamic={`+${displayNum(BonusRewards)}`}
              bodyDynamicUnit={currencies.native}
              bodyDynamicSx={{
                color: (theme) => theme.palette.primary.main
              }}
              bodyDynamicUnitSx={{
                color: (theme) => theme.palette.primary.main
              }}
            /> */}

            <InfoCard
              cardSx={{ py: 2, px: 3 }}
              BodyStatic={() => (
                <Typography
                  sx={{
                    fontSize: { xxs: 10, md: 12 },
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center"
                  }}>
                  <AlertIcon style={{ marginRight: "6px" }} /> The Referral System rewards do not apply to campaign participants.
                </Typography>
              )}
            />
          </Box>
        </AnimatePresence>
      )}
      <WheelDialog />
      <TransparencyIndexDialog />
      <WithdrawalDialog
        open={withdrawalDialogOpen}
        setClose={() => dispatch(setWithdrawalDialogOpen(false))}
      />
      <DepositUsdtDialog />
      <DepositDialog />
      <TransferDialog
        open={transferDialogOpen}
        setClose={() => dispatch(setTransferDialogOpen(false))}
      />
      <TransferSuccessDialog />
      <TransactionReminderDialog
        open={txReminderDialogOpen}
        setClose={() => dispatch(setTxReminderDialogOpen(false))}
      />
    </Container>
  );
}
export default DMAView;
