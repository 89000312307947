import { useState } from "react";
import { useEffect } from "react";

import { Typography, Box, Button, Grid } from "@mui/material";
import SplitByDigitInput from "../SplitByDigitInput";
import CustomButton from "../Buttons/Button";
import { useSelector } from "react-redux";
import FormHeader from "./FormHeader";

export default function VerificationCodeForm({
  email,
  handleSubmit = () => {},
  handleResendCode = () => {}
}) {
  const [coolDownTimer, setCoolDownTimer] = useState(60);
  const [code, setCode] = useState("");
  const { isLoading } = useSelector((state) => state.account);
  useEffect(() => {
    if (!coolDownTimer) return;
    const intervalId = setInterval(() => {
      setCoolDownTimer(coolDownTimer - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [coolDownTimer]);

  return (
    <form onSubmit={(e) => handleSubmit(e, code)}>
      <Box>
        <FormHeader
          title="Verify Your Identity"
          Body={() => (
            <>
              To confirm your identity we&apos;ll send you a verification code to{" "}
              <Typography
                component="span"
                color="primary"
                sx={{ fontSize: { xxs: 14, md: 16 }, display: "inline-flex" }}>
                {email}
              </Typography>
            </>
          )}
        />

        <Grid item xs={12}>
          <SplitByDigitInput code={code} setCode={setCode} />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => coolDownTimer === 0 && handleResendCode(email, setCoolDownTimer)}
            sx={{
              p: 0,
              mt: { xs: 1.25, md: 2 },
              fontSize: 12,
              fontWeight: 400,
              color: "#FFFFFF",
              textDecoration: "underline",
              "&:hover": {
                textDecoration: "underline",
                backgroundColor: " #000000"
              }
            }}>
            Resend Code {coolDownTimer !== 0 ? `(${coolDownTimer}S)` : ""}
          </Button>
        </Grid>

        <CustomButton isLoading={isLoading} disabled={code.length < 6} type="submit" fullWidth>
          VERIFY
        </CustomButton>
      </Box>
    </form>
  );
}
