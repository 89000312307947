import { configureStore } from "@reduxjs/toolkit";
import settingsReducer from "./slices/settings";
import notificationsReducer from "./slices/notifications";
import authReducer from "./slices/auth";
import walletReducer from "./slices/wallet";
import DMAReducer from "./slices/DMA";
import accountNotificationsReducer from "./slices/accountNotifications";
import accountReducer from "./slices/account";
import historyReducer from "./slices/history";
import stakeReducer from "./slices/stake";
import loyaltyReducer from "./slices/loyalty";
import lotteryReducer from "./slices/lottery";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    accountNotifications: accountNotificationsReducer,
    settings: settingsReducer,
    notifications: notificationsReducer,
    auth: authReducer,
    wallet: walletReducer,
    DMA: DMAReducer,
    history: historyReducer,
    stake: stakeReducer,
    lottery: lotteryReducer,
    loyalty: loyaltyReducer
  }
});
