import { useEffect, useState } from "react";
import { alpha, Box, TextField, Typography, Slider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import CustomDialog from "./Dialog";
import CustomButton from "../Buttons/Button";
import WalletHandler from "../WalletHandler";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import { WALLET_CONFIG } from "../../redux/slices/wallet";
import { signStake, stake, setStakeDialogOpen, getBalance, getAprs } from "../../redux/slices/stake";
import { setDepositDialogOpen, signDeposit, deposit } from "../../redux/slices/DMA";
import useBreakpoints from "../../hooks/useBreakpoints";
import { ReactComponent as AgiUsdtIcon } from "../../assets/icons/agi-usdt-token.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import { CONTRACT_ADDRESS } from "../../constants/contracts";
import { isWalletBrowser } from "../../utils/isMobile";
import { currencies } from "../../constants/currencies";
import { shortenString } from "../../utils/strings";
import { toFixed } from "../../utils/strings";

const InputTextSx = {
  color: (theme) => theme.palette.grey[0],
  fontSize: { xxs: 18, xs: 24 },
  fontWeight: 600,
  py: 1
};

const InputSx = {
  height: { xxs: "40px", xs: "51px" },
  input: { ...InputTextSx },
  "& input[type=number]": {
    MozAppearance: "textfield"
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none"
    }
  }
};

function AmountInputField({
  value,
  setValue,
  placeholder,
  type = "number",
  maxValue,
  minValue = 0
}) {
  function handleChangeQuantity(value) {
    if (value.length === 0) setValue("");
    else {
      const floatValue = parseFloat(value);

      if (value < minValue && value.length !== 0) setValue((prevState) => prevState);
      else setValue((prevState) => (floatValue <= maxValue ? floatValue : prevState));
    }
  }

  const augStyle = getAugmentedStyle({ color: "rgba(255, 255, 255, 0.3)", borderSize: "1px" });

  const valueOptionSx = (isActive) => ({
    flex: 1,
    height: "30px",
    lineHeight: "30px",
    textAlign: "center",
    cursor: "pointer",
    fontSize: { xxs: "14px", sm: "14px" },
    color: (theme) => (isActive ? theme.palette.primary.main : "white"),
    "--aug-border-bg": (theme) => isActive && `${alpha(theme.palette.primary.main, 1)} !important`
  });

  const handleOptionClick = (ratio) => {
    setValue(ratio * maxValue);
  };

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" width="100%" position={"relative"}>
      <TextField
        fullWidth
        type={type}
        placeholder={placeholder}
        variant="outlined"
        value={value}
        onChange={(e) => handleChangeQuantity(e.target.value)}
        data-augmented-ui="tl-clip br-clip border"
        InputProps={{
          inputProps: { max: maxValue, step: "0.01" }
        }}
        sx={InputSx}
        style={augStyle}
      />
      <Typography
        color="primary"
        variant="caption"
        onClick={() => handleOptionClick(1)}
        sx={{
          textDecoration: "underline", fontSize: 14, lineHeight: 1, cursor: "pointer",
          position: "absolute",
          right: "19px",
          top: { xxs: "13px", sm: "19px" },
        }}>
        Max
      </Typography>
    </Box>
  );
}

function LockMonthsControl({ value, setValue }) {
  const [isCustom, setIsCustom] = useState(false);

  const maxValue = 24;
  const valueOptionSx = (isActive) => ({
    flex: 1,
    height: "30px",
    lineHeight: "30px",
    textAlign: "center",
    cursor: "pointer",
    fontSize: { xxs: "12px", sm: "14px" },
    color: (theme) => (isActive ? "black" : "white"),
    background: (theme) => isActive && `${alpha(theme.palette.primary.main, 1)} !important`,
    "--aug-border-bg": (theme) => isActive && `${alpha(theme.palette.primary.main, 0.3)} !important`
  });

  const augStyle = getAugmentedStyle({ color: "rgba(255, 255, 255, 0.3)", borderSize: "1px" });

  const handleOptionClick = (months) => {
    setIsCustom(false);
    setValue(months);
  };

  const handleCustomMonthsClick = () => {
    setIsCustom(true);
  };

  return (
    <Box>
      <Box mt="12px" display="flex" flexWrap="nowrap" justifyContent="space-between" gap="10px">
        <Box
          sx={valueOptionSx(value === 6 && !isCustom)}
          data-augmented-ui="tl-clip border"
          style={augStyle}
          onClick={() => handleOptionClick(6)}>
          6 Months
        </Box>
        <Box
          sx={valueOptionSx(value === 12 && !isCustom)}
          data-augmented-ui="border"
          style={augStyle}
          onClick={() => handleOptionClick(12)}>
          12 Months
        </Box>
        <Box
          sx={valueOptionSx(value === 24 && !isCustom)}
          data-augmented-ui="border"
          style={augStyle}
          onClick={() => handleOptionClick(24)}>
          24 Months
        </Box>
        <Box
          sx={valueOptionSx(isCustom)}
          data-augmented-ui="br-clip border"
          style={augStyle}
          onClick={() => handleCustomMonthsClick()}>
          Custom
        </Box>
      </Box>
      {isCustom && (
        <Slider
          sx={{
            m: "0 auto",
            width: { xxs: "80%", sm: "100%" },
            mt: "48px",
            display: { xxs: "block", sm: "inline-block" }
          }}
          value={value}
          step={1}
          min={6}
          max={maxValue}
          size="small"
          valueLabelDisplay="on"
          valueLabelFormat={(val) => `${val} Month${val > 1 ? "s" : ""}`}
          onChange={(e, val) => {
            setValue(val);
          }}
        />
      )}
    </Box>
  );
}

function InfoRow({ title, Icon = null, info, rowSx }) {
  return (
    <Box
      sx={{
        mt: 4,
        mb: "12px",
        ...rowSx
      }}>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: 14,
          minWidth: 140,
          color: (theme) => theme.palette.grey[60]
        }}>
        {Icon && <Icon style={{ marginRight: "5px" }} />}
        {title}
      </Typography>

      <Typography
        sx={{
          fontSize: 12,
          mt: "12px",
          display: "flex",
          alignItems: "flex-end",
          color: (theme) => theme.palette.grey[60]
        }}>
        {info}
      </Typography>
    </Box>
  );
}

function FieldRow({ title, Icon = null, info, rowSx }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: "20px",
        ...rowSx
      }}>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: 14,
          minWidth: 140,
          color: (theme) => theme.palette.grey[60]
        }}>
        {Icon && <Icon style={{ marginRight: "5px" }} />}
        {title}
      </Typography>

      <Typography
        sx={{
          fontSize: 14,
          display: "flex",
          alignItems: "flex-end",
          color: "white"
        }}>
        {info}
      </Typography>
    </Box>
  );
}

const strip = (num, precision = 12) => {
  return +parseFloat(num.toPrecision(precision));
};

const getEstimatedAPR = (aprs, month) => {
  const apr = aprs[month - 1];

  if (typeof apr !== 'undefined') return Number(apr);

  if (!month) return 0;

  if (month <= 12) {
    return 207 + 18.92 * (month - 5);
  }

  return 320.52 + 21.67 * (month - 11);
};

function useBalance(
  provider,
  accounts
) {
  const [balances, setBalances] = useState([])

  useEffect(() => {
    if (provider && accounts?.length) {
      let stale = false

      void Promise.all(accounts.map((account) => provider.getBalance(account))).then((balances) => {
        if (stale) return
        setBalances(balances)
      })

      return () => {
        stale = true
        setBalances(undefined)
      }
    }
  }, [provider, accounts])

  return balances && balances[0]
}

export function DepositDialog() {
  const dispatch = useDispatch();
  const downXs = useBreakpoints("down", "xs");
  const { stakeDialogOpen, stakeProcessing, poolsInfo, aprs } = useSelector(
    (state) => state.stake
  );
  const { balance, depositDialogOpen } = useSelector(
    (state) => state.DMA
  );
  const { user } = useSelector((state) => state.auth);
  const setClose = () => dispatch(setDepositDialogOpen(false));
  const [walletBalance, setWalletBalance] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [amount, setAmount] = useState(0);
  const [lockMonths, setLockMonths] = useState(0);
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useProvider, useAccount } = WALLET_CONFIG[selectedWallet].hook;
  const provider = useProvider();
  const account = useAccount();
  // const walletBalance = useBalance(provider, [CONTRACT_ADDRESS.AGI_BNB])
  const estimatedAPR = `${strip(getEstimatedAPR(aprs, lockMonths))}%`;
  const estimatedAPRInfo = `The tokens will be locked for ${lockMonths} months. At the end of the Lock-up Period, you will be able to withdraw them along with the staking rewards. By clicking 'Stake', you acknowledge that you fully understand the staking mechanism, smart contract, and all risks associated with this behavior.`;
  const nativeBalance = Number(
    balance.find((item) => item.currency === currencies.native)?.amount || 0
  );
  const isDepositDisabled = amount <= 0 || amount > walletBalance;
  const receiveAmount = amount * 1
  const processingFee = amount * 0

  const handleGetLPClick = () => {
    isWalletBrowser()
      ? window.location.assign(CONTRACT_ADDRESS.LP_TOKEN_MARKET)
      : window.open(CONTRACT_ADDRESS.LP_TOKEN_MARKET, "_blank");
  };

  const loadData = async () => {
    const token = new ethers.Contract(CONTRACT_ADDRESS.AGI_BNB, ['function balanceOf(address) external view returns (uint256)'], provider)
    const walletBalance = await token.balanceOf(account);
    if (walletBalance) {
      setWalletBalance(ethers.utils.formatEther(walletBalance))
    }
  }

  useEffect(() => {
    if (depositDialogOpen) {
      setAmount(0)
      loadData()
      dispatch(getAprs());
    }
  }, [depositDialogOpen]);

  const handleDepositClick = async () => {
    if (processing) {
      return;
    }

    setProcessing(true)
    // @TODO: Handle deposit request

    dispatch(
      signDeposit({
        currency: 'DES',
        chain: 'BSC',
        amount: new BigNumber(amount).toString(),
        wallet_address: account
      })
    ).then((signData) => {
      dispatch(deposit({ provider, signData,
        callback: () => {
          setProcessing(false)
          setClose()
        },
        onError: () => {
          setProcessing(false)
        }
       }))
    }).catch(error => {
      console.log('handleDepositClick', error)
      setProcessing(false)
    })

    // try {
    //   const signData = await dispatch(
    //     signDeposit({
    //       currency: 'DES',
    //       chain: 'BSC',
    //       amount: new BigNumber(amount).toString(),
    //       wallet_address: account
    //     })
    //   )
    //   await dispatch(deposit({ provider, signData }));
    //   setClose()
    // } catch (error) {
    //   console.log('handleDepositClick', error)
    // }
  };

  return (
    <CustomDialog
      hasCloseButton
      closeButtonType="crossLine"
      BackdropProps={{
        sx: {
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7)
        }
      }}
      PaperProps={{
        sx: {
          width: { xxs: "101%", sm: 710 },
          height: { xxs: "90vh", xs: "initial" },
          padding: { xxs: 0, xs: "0 6px" },
          margin: { xxs: 0, xs: 2 },
          left: -2,
          bottom: -2,
          fontFamily: "Chakra Petch, sans-serif",
          background: { xxs: "#181818", xs: "black" },

          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            display: "none"
          }
        },
        style: {
          position: downXs ? "absolute" : "relative",
          "--aug-tr1": "12px",
          "--aug-tr-inset2": "calc(var(--aug-tr1) * 14.5)"
        },
        "data-augmented-ui": downXs ? "tr-clip-x border" : "bl-clip-y br-clip border"
      }}
      setClose={setClose}
      open={depositDialogOpen}>
      <Box sx={{ pt: { xxs: 0, sm: 2 }, pb: { xxs: 0, sm: 4 }, px: { xxs: 0, sm: 10 } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: { xxs: "-20px", sm: 0 }
          }}>
          <Typography
            sx={{
              fontWeight: { xxs: 600, sm: 700 },
              fontSize: { xxs: "18px", sm: "24px" },
              lineHeight: 23 / 18
            }}>
            Deposit
          </Typography>
        </Box>
        <InfoRow title="Amount" info={`Wallet Balance: ${walletBalance} $AGI`} />
        <AmountInputField value={amount} setValue={setAmount} maxValue={walletBalance} />
        <FieldRow title={`Receive Amount`} info={`${toFixed(receiveAmount)} $AGI`} />
        <FieldRow title={`Deposit from`} info={shortenString(account)} />
        <FieldRow title={`Network`} info={`BNB Chain`} />
        <FieldRow title={`Processing Fee`} info={`$${toFixed(processingFee)}`} />
        <Box sx={{
          color: "#FF7C60",
          fontSize: { xxs: "12px", sm: "14px" },
          marginTop: "24px",
        }}>Please Note: On-chain transactions may experience delays.<Box component={"br"} sx={{
          display: { xxs: "block", sm: "none" }
        }} />The arrival of funds is expected to take a few minutes.</Box>
        <Box
          display="flex"
          flexWrap="nowrap"
          justifyContent="center"
          sx={{
            gap: "10px",
            flexDirection: { xxs: "column-reverse", sm: "row" },
            mt: "32px"
          }}>
          <WalletHandler
            Component={CustomButton}
            isLoading={processing}
            disabled={isDepositDisabled}
            handleClick={() => handleDepositClick()}
            sx={{ fontSize: "16px", width: { xxs: "100%", sm: "188px" }, height: "37px", mt: 0 }}
            augmented="tl-clip br-clip">
            Confirm
          </WalletHandler>
        </Box>
      </Box>
    </CustomDialog>
  );
}

export default DepositDialog;
