import { config } from "../config";

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  dashboard: "/",
  history: "/history",
  discord: "/discord",
  stake: "/stake",
  lottery: "/lottery",
  limitedStake: "/limited-time-stake",
  external: {
    sso: config.SSO_CLIENT_URL,
    marketplace: config.MARKETPLACE_CLIENT_URL,
    delysium: config.DELYSIUM_CLIENT_URL
  }
};

// Paths and routes external to marketplace
const SSO_REDIRECT_PARAMETER = `redirect_url=${window.location.href}`;
export const DISCORD_REDIRECT_PARAMETER = `${config.DASHBOARD_UI}discord/bind`;
export const PATH_EXTERNAL = {
  marketplace: {
    root: ROOTS.external.marketplace,
    dma: path(ROOTS.external.marketplace, "dma"),
    buy: path(ROOTS.external.marketplace, "buy")
  },
  sso: {
    root: ROOTS.external.sso,
    login: path(ROOTS.external.sso, `/login?${SSO_REDIRECT_PARAMETER}`),
    register: path(ROOTS.external.sso, `/register?${SSO_REDIRECT_PARAMETER}`)
  },
  delysium: {
    root: ROOTS.external.delysium,
    dma: path(ROOTS.external.delysium, "dma")
  },
  gitbook: {
    dma: "https://delysium.gitbook.io/delysium-multiverse-product/delysium-multiverse-accelerator/delysium-multiverse-accelerator",
    referral:
      "https://delysium.gitbook.io/delysium-multiverse-product/delysium-multiverse-accelerator/dma-referral-system"
  },
  discord: {
    invite: "https://discord.com/invite/delysium"
  }
};

export const PATH_ERROR = {
  NOT_FOUND: "*"
};

export const PATH_DASHBOARD = {
  root: ROOTS.dashboard,
  settingsView: path(ROOTS.dashboard, "settings"),
  dmaView: path(ROOTS.dashboard, "dma"),
  superReward: path(ROOTS.dashboard, "2022-super-reward"),
  loyaltyProgram: path(ROOTS.dashboard, "loyalty-program")
};

export const PATH_HISTORY = {
  root: ROOTS.history,
  rewards: path(ROOTS.history, "/rewards"),
  referral: path(ROOTS.history, "/referral"),
  transfer: path(ROOTS.history, "/transfer"),
  referralDetails: path(ROOTS.history, "/referral-details")
};

export const PATH_STAKE = {
  root: ROOTS.stake,
  pools: path(ROOTS.stake, "/pools"),
  myPositions: path(ROOTS.stake, "/my-positions")
};

export const PATH_LOTTERY = {
  root: ROOTS.lottery,
  pool: path(ROOTS.lottery, "/pool"),
  myTickets: path(ROOTS.lottery, "/my-tickets"),
  referral: path(ROOTS.lottery, "/referral")
};

export const PATH_LIMITED_STAKE = {
  root: ROOTS.limitedStake,
  pools: path(ROOTS.limitedStake, "/pools"),
  myPositions: path(ROOTS.limitedStake, "/my-positions")
};

export const PATH_DISCORD = {
  root: ROOTS.discord,
  bind: path(ROOTS.discord, "/bind")
};

export const isDashboardTab = (pathname) => {
  return !pathname.includes(PATH_STAKE.root) && !pathname.includes(PATH_LIMITED_STAKE.root) && !pathname.includes(PATH_LOTTERY.root);
};
