import { __PROD__ } from '../utils/dev';

export const NETWORK_TYPE = {
  ETH: 'ETH',
  BSC: "BSC"
}

export const BSC_NETWORK = {
  MAINNET: 'bsc',
  TESTNET: 'bscTestnet'
}

export const networks = {
  ETH: __PROD__ ? 'Ethereum Mainnet' : 'Sepolia Test Network',
  BSC: __PROD__ ? 'BNB Smart Chain' : 'BSC Testnet',
};
