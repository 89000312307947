import { Popover } from "@mui/material";
import React from "react";
import { getAugmentedStyle } from "../utils/augmentedStyles";

function CustomPopover({ open, anchorEl, onClose, children, height = "164px", ...props }) {
  return (
    <Popover
      {...props}
      PaperProps={{
        onMouseLeave: onClose,
        "data-augmented-ui": " bl-clip-y br-clip border",
        style: getAugmentedStyle({
          borderSize: "1px",
          color: "#FAE232",
          tl: "4px",
          bl: "10px"
        }),
        sx: {
          ...props.PaperProps?.sx,
          minWidth: "5rem",
          background: (theme) => theme.palette.background.default,
          backgroundImage: "initial",
          border: (theme) => `1px solid ${theme.palette.primary.main}`,
          borderRadius: 0,
          overflow: "visible",
          px: 2,
          py: 2.5,
          mt: 1,
          height: height,
          width: { xxs: "153px", md: "167px" },
          "& button": {
            marginBottom: "20px",
            padding: "5px 12px",
            fontWeight: 400,
            fontSize: { xxs: "12px", md: "14px" },
            width: { xxs: "113px", md: "127px" },
            height: { xxs: "26px", md: "28px" }
          }
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}>
      {children}
    </Popover>
  );
}
export default CustomPopover;
