import { Box } from "@mui/system";

const containerSx = {
  position: "relative",
  width: { xxs: 335, xs: 393, sm: 503, md: 583, lg: 640 },
  pb: 10,
  mt: 1,
  margin: "0 auto"
};

const Container = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ ...containerSx, ...props?.sx }}>
      {children}
    </Box>
  );
};

export default Container;
