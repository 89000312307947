import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../config";
import axios from "../../utils/axios";

const DMA_BASE_URL = config.DMA_API_URL;

const initialState = {
  isLoading: false,
  stats: {
    loyaltyScore: 0,
    agiRewards: 0,
    totalAgiAmount: 0,
    referrals: 0,
    galxeOat: 0,
  }

  // leaderboards: {
  //   total_page: 1,
  //   data: MOCK
  // }
};

export const slice = createSlice({
  name: "loyalty",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPage(state, action) {
      const { type, page } = action.payload;
      state[type] = { ...state[type], page: page };
    },
    // getLeaderboardsSuccess(state, action) {
    //   state.isLoading = false;
    //   const { leaderboards: data, total_page } = action.payload;
    //   state.leaderboards = { data, total_page };
    // },
    getLoyaltyStatsSuccess(state, action) {
      state.isLoading = false;
      state.stats = action.payload;
    }
  }
});

export const {} = slice.actions;

export function getLoyaltyStats() {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const { data } = await axios.get("/loyalty_score_v2/statistics", {
        baseURL: DMA_BASE_URL
      });
      dispatch(slice.actions.getLoyaltyStatsSuccess(data.data));
      dispatch(slice.actions.setIsLoading(false));
    } catch (error) {
      console.error(error);
    }
  };
}

export async function getLoyaltyFAQ() {
  try {
    const { data } = await axios.get("", { baseURL: config.LOYALTY_ARK_FAQ });
    return data.faqs;
  } catch (error) {
    console.error(error);
  }
}
export default slice.reducer;
