import { Box } from "@mui/system";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";

const cardSx = {
  color: "#000",
  minWidth: "300px",
  minHeight: { xxs: "90px", sm: "182px" }
}

const cardProps = {
  style: getAugmentedStyle({
    backgroundColor: "#C7CBE2"
  }),
  "data-augmented-ui": "tl-clip br-clip"
}

const cardTitleSx = {
  px: { xxs: "26px", sm: "40px" },
  py: { xxs: "10px", sm: "24px" },
  fontWeight: 500,
  fontSize: { xxs: "12px", sm: "18px" },
  lineHeight: 23/18,
}

const metaContainerSx = {
  display: "flex",
  alignItems: "flex-end",
  flexDirection: "column",
  px: { xxs: "26px", sm: "40px" },
  py: { xxs: "10px", sm: "28px" },
}

const metaSx = {
  opacity: 0.5,
  fontSize: {xxs: "12px", sm: "18px"},
  lineHeight: 23/18,
  mb: "4px"
}

const amountSx = {
  fontFamily: "Teko, sans-serif",
  fontWeight: 300,
  overflow: "hidden",
  backgroundColor: (theme) => theme.palette.primary.main,
  px: "3px",
  pt: { xxs: "5px", sm: "8px"},
  lineHeight: 0.7,
  fontSize: { xxs: "40px", sm: "64px" },
}

export default function ({
  title = "",
  meta = "\u00A0",
  amount = "",
  sx = {}
}) {

  return (
    <Box
    sx={{...cardSx, ...sx }}
    {...cardProps}
    >
      <Box sx={cardTitleSx}>{title}</Box>
      <Box sx={metaContainerSx}>
        <Box sx={metaSx}>{meta}</Box>
        <Box sx={amountSx}>{amount}</Box>
      </Box>
    </Box>
  )
}
