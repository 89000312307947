import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { Typography, CircularProgress, Fade } from "@mui/material";
import CustomButton from "../../../components/Buttons/Button";
import Pagination from "../../../components/Pagination";
import { ReactComponent as AgiUsdtIcon } from "../../../assets/icons/agi-usdt-token.svg";
import { ReactComponent as AgiIcon } from "../../../assets/icons/agi.svg";
import { ReactComponent as HexagonIcon } from "../../../assets/icons/hexagon.svg";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";
import { EmptyRecords } from "../History/Table";
import AmountCard from "./AmountCard";
import CustomToolTip from "./Tooltip";
import { getPositions, getLimitedSinglePositions, signHarvest, harvest } from "../../../redux/slices/stake";
import { WALLET_CONFIG } from "../../../redux/slices/wallet";
import { setWalletDialogOpen } from "../../../redux/slices/wallet";

import { formatDate } from "../../../utils/time";
import { currencies } from "../../../constants/currencies";
import isMobileDevice from "../../../utils/isMobile";

function Heading() {
  const downXs = useBreakpoints("down", "xs");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        height: "37px",
        mt: { xxs: "30px", sm: "36px" },
        mb: { xxs: "16px", sm: "36px" }
      }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}>
        <AgiUsdtIcon
          style={{
            width: downXs ? "50px" : "66px"
          }}
        />
        <Typography
          sx={{
            ml: "10px",
            fontWeight: { xxs: 400, sm: 500 },
            fontSize: { xxs: "16px", sm: "20px" },
            lineHeight: "21px"
          }}>
          $AGI / USDT Pool
        </Typography>
      </Box>
    </Box>
  );
}

function SingleHeading() {
  const downXs = useBreakpoints("down", "xs");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        height: "37px",
        mb: { xxs: "16px", sm: "36px" }
      }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}>
        <AgiIcon
          style={{
            width: downXs ? "24px" : "32px"
          }}
        />
        <Typography
          sx={{
            ml: "10px",
            fontWeight: { xxs: 400, sm: 500 },
            fontSize: { xxs: "16px", sm: "20px" },
            lineHeight: "21px"
          }}>
          $AGI Pool
        </Typography>
      </Box>
    </Box>
  );
}

function PoolMetaCards() {
  const { poolsInfo, positions } = useSelector((state) => state.stake);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        mb: "20px",
        flexDirection: { xxs: "column", sm: "row" }
      }}>
      <AmountCard
        title={`${currencies.native} Earned`}
        meta={`≈ ${positions.total_agi_earned_usd} USD`}
        amount={`${positions.total_agi_earned}`}
        sx={{ flex: 1 }}
      />
      <AmountCard
        title="Total Staked"
        amount={`$${positions.total_deposited_usd}`}
        sx={{ flex: 1 }}
      />
    </Box>
  );
}

function SinglePoolMetaCards() {
  const { poolsInfo, singlePositions } = useSelector((state) => state.stake);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        mb: "20px",
        flexDirection: { xxs: "column", sm: "row" }
      }}>
      <AmountCard
        title={`${currencies.native} Earned`}
        meta={`≈ ${singlePositions.total_agi_earned_usd} USD`}
        amount={`${singlePositions.total_agi_earned}`}
        sx={{ flex: 1 }}
      />
      <AmountCard
        title="Total Staked"
        amount={`$${singlePositions.total_deposited_usd}`}
        sx={{ flex: 1 }}
      />
    </Box>
  );
}

function PositionItem({ position }) {
  const dispatch = useDispatch();
  const downXs = useBreakpoints("down", "xs");
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useProvider } = WALLET_CONFIG[selectedWallet].hook;
  const { useAccount, useChainId, useIsActive } = WALLET_CONFIG[selectedWallet].hook;
  const isActive = useIsActive();
  const provider = useProvider();
  const editing = useSelector((state) => state.stake.positionEditing[position.id] || {});

  const handleHarvestButtonClick = async () => {
    if (!isActive) {
      dispatch(setWalletDialogOpen({ open: true }));
      return;
    }

    if (editing.processing) return;
    dispatch(signHarvest(position)).then((signData) => {
      dispatch(harvest({ id: position.id, provider, signData }));
    });
  };

  if (editing.closed) {
    return null;
  }

  function LockStatus() {
    const primaryColor = position.isLocked ? "#FF7C60" : "#4AD479";

    return (
      <Box
        sx={{
          position: "relative",
          pl: { xxs: "0", sm: position.isLocked ? "12px" : "0" },
          pr: { xxs: "0", sm: position.isLocked ? "0" : "10px" },
          flexShrink: 0,
          width: { xxs: "100px", sm: "106px" },
          height: { xxs: "50px", sm: "100px" }
        }}>
        <Box
          style={getAugmentedStyle({
            color: position.isLocked ? "#000" : "rgba(255, 255, 255, 0.2)"
          })}
          data-augmented-ui="tl-clip border"
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: position.isLocked ? "#1a1a1a" : "transparent",
            color: primaryColor,
            fontSize: { xxs: "12", sm: "16px" },
            "--aug-border-right": { xxs: "1px", sm: position.isLocked ? "1px" : "0px !important" },
            "--aug-border-bottom": {
              xxs: position.isLocked ? "1px" : "0px !important",
              sm: "1px !important"
            }
          }}>
          {position.isLocked ? "Locked" : "Unlocked"}
        </Box>

        {downXs ? (
          <Box
            sx={{
              position: "absolute",
              zIndex: 2,
              bottom: "0",
              left: "50%",
              transform: "translate3d(-50%, 50%, 0) rotate(90deg)"
            }}>
            <Box
              style={getAugmentedStyle({
                borderSize: position.isLocked ? "2px" : "1px",
                color: position.isLocked ? "#000" : "#333"
              })}
              data-augmented-ui="all-hex border"
              sx={{
                "--aug-all-height": "22px !important",
                backgroundColor: position.isLocked ? "#1a1a1a" : "#000"
              }}>
              {!position.isLocked && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    width: "50%",
                    height: "100%",
                    background: "#000"
                  }}></Box>
              )}
              <HexagonIcon
                style={{
                  position: "absolute",
                  zIndex: 3,
                  top: "50%",
                  left: "50%",
                  transform: "translate3d(-50%, -50%, 0)",
                  "--fill": primaryColor
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              position: "absolute",
              zIndex: 2,
              top: "50%",
              right: "0px",
              transform: "translate3d(50%, -50%, 0)"
            }}>
            <Box
              style={getAugmentedStyle({
                borderSize: position.isLocked ? "2px" : 0,
                color: position.isLocked ? "#000" : "transparent"
              })}
              data-augmented-ui="all-hex border"
              sx={{
                width: "10px",
                height: "10px",
                backgroundColor: position.isLocked ? "#1a1a1a" : "transparent"
              }}>
              <HexagonIcon
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate3d(-50%, -50%, 0)",
                  "--fill": primaryColor
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  }

  function HarvestButton({ sx }) {
    return (
      <CustomButton
        handleClick={handleHarvestButtonClick}
        augmented="tl-clip br-clip"
        isLoading={editing.processing}
        disabled={position.isLocked}
        sx={{
          fontSize: { xxs: "12px", sm: "16px" },
          height: { xxs: "28px", sm: "37px" },
          px: "24px",
          width: "auto",
          minWidth: { xxs: "90px", sm: "100px" },
          ...sx
        }}>
        Harvest
      </CustomButton>
    );
  }

  function DataCard({ title, TooltipContent, needAmountX, needDays, needPct, amount, meta, sx }) {
    return (
      <Box
        sx={{
          flexShrink: 0,
          height: "100%",
          ...sx
        }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            fontSize: { xxs: "12px", sm: "18px" }
          }}>
          {title}
          {TooltipContent && <CustomToolTip Content={TooltipContent} />}
        </Box>
        <Box
          sx={{
            display: "flex",
            fontFamily: "Teko, sans-serif",
            alignItems: "center",
            fontSize: { xxs: "24px", sm: "32px" },
            mb: { xxs: "6px", sm: "9px" },
            color: position.isLocked ? "rgba(255, 255, 255, 0.5);" : "white"
          }}>
          {amount}
          {needAmountX && (
            <Box
              sx={{
                fontWeight: "700",
                lineHeight: 1,
                ml: "5px",
                fontFamily: "Chakra Petch",
                fontSize: "0.75em"
              }}>
              ×
            </Box>
          )}
          {needPct && (
            <Box
              sx={{
                fontWeight: "700",
                ml: { xxs: "2px", sm: "5px" },
                mb: "0.15em",
                fontFamily: "Chakra Petch",
                fontSize: "0.7em"
              }}>
              %
            </Box>
          )}
          {needDays && (
            <Box
              sx={{
                fontWeight: "700",
                lineHeight: 1,
                ml: "5px",
                mb: "12px",
                fontFamily: "Chakra Petch",
                fontSize: "14px"
              }}>
              DAYS
            </Box>
          )}
        </Box>
        <Box
          sx={{
            fontSize: { xxs: "12px", sm: "16px" },
            color: "rgba(255, 255, 255, 0.3);"
          }}>
          {meta}
        </Box>
      </Box>
    );
  }

  function ItemBodyCard({ sx, children }) {
    const formattedUnlockDate = formatDate(position.unlock_at, false);

    function EarnedTooltipContent() {
      const titleSx = {
        fontWeight: 500
      };
      const dataSX = {
        fontWeight: 700,
        mt: "4px"
      };
      const formattedCallAction = formatDate(position.last_action_time, false);
      return (
        <Box
          sx={{
            minWidth: "180px",
            minHeight: "100px",
            padding: "16px"
          }}>
          <Box sx={titleSx}>Earned since your last action:</Box>
          <Box sx={dataSX}>{formattedCallAction}</Box>
          <Box sx={{ ...titleSx, mt: "10px" }}>Hourly Average:</Box>
          <Box sx={{ ...dataSX }}>
            &lt;{position.agi_earned_hourly_avg} $AGI (&lt;{position.agi_earned_hourly_avg_usd} USD)
          </Box>
        </Box>
      );
    }

    return (
      <Box
        style={getAugmentedStyle({
          color: position.isLocked ? "#000" : "rgba(255, 255, 255, 0.2)"
        })}
        data-augmented-ui="br-clip l-clip-y border"
        sx={{
          flex: 1,
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          backgroundColor: position.isLocked ? "#1a1a1a" : "transparent",
          justifyContent: "space-between",
          padding: { xxs: "20px 40px 20px 22px", sm: "20px 20px 20px 26px" },
          minHeight: { xxs: "110px", sm: "140px" },
          "--aug-l": { xxs: "0px!important", sm: "10px !important" },
          ...sx
        }}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            height: "100%",
            minHeight: "80px",
            gap: { xxs: "30px", sm: "60px" }
          }}>
          <DataCard
            title="AGI-USDT LP"
            amount={position.locked?.toLocaleString()}
            meta={`≈ ${position.locked_usd?.toLocaleString()} USD`}
            sx={{
              marginRight: { sm: "20px" }
            }}
          />
          <DataCard
            title={`${currencies.native} Earned`}
            TooltipContent={!isMobileDevice() && EarnedTooltipContent}
            amount={position.agi_earned?.toLocaleString()}
            meta={`≈ ${position.agi_earned_usd?.toLocaleString()} USD`}
            sx={{
              marginRight: { sm: "20px" }
            }}
          />
          <DataCard
            title="APR"
            amount={position.apr}
            needPct={true}
            sx={{
              marginRight: { sm: "20px" }
            }}
          />
          <DataCard
            title="DMA Boost"
            amount={`${Number(position.dma_boost).toFixed(2)}`}
            needAmountX={true}
            meta={`Owned ${position.owned_dma} DMA`}
          />
          {/* <DataCard
            title="Yield Boost"
            amount={`${position.yield_boost.toFixed(2)}`}
            needAmountX={true}
            meta={`Lock for ${position.locks_months} months`}
          /> */}
          {position.isLocked && (
            <DataCard
              title="Unlocks in"
              amount={`${position.unlocks_in_days}`}
              needDays={true}
              meta={`On ${formattedUnlockDate}`}
            />
          )}
        </Box>
        {children}
      </Box>
    );
  }

  function DesktopItem({ children }) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          overflowX: "auto"
        }}>
        <LockStatus />
        <ItemBodyCard sx={{ minWidth: "1100px" }}>
          <HarvestButton />
        </ItemBodyCard>
      </Box>
    );
  }

  function MobileItem({ children }) {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "6px"
          }}>
          <LockStatus />
          <HarvestButton />
        </Box>
        <Box
          sx={{
            overflowX: "auto",
            marginLeft: "-20px",
            marginRight: "-20px",
            "&::-webkit-scrollbar": {
              height: 0
            }
          }}>
          <ItemBodyCard
            sx={{
              marginLeft: "20px",
              marginRight: "20px",
              minWidth: position.isLocked ? "600px" : "450px"
            }}
          />
        </Box>
      </Box>
    );
  }

  const Item = downXs ? MobileItem : DesktopItem;

  return (
    <Box>
      <Item />
    </Box>
  );
}

function SinglePositionItem({ position }) {
  const dispatch = useDispatch();
  const downXs = useBreakpoints("down", "xs");
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useProvider } = WALLET_CONFIG[selectedWallet].hook;
  const provider = useProvider();
  const editing = useSelector((state) => state.stake.positionEditing[position.id] || {});

  const handleHarvestButtonClick = async () => {
    if (editing.processing) return;
    dispatch(signHarvest(position)).then((signData) => {
      dispatch(harvest({ id: position.id, provider, signData }));
    });
  };

  if (editing.closed) {
    return null;
  }

  function LockStatus() {
    const primaryColor = position.isLocked ? "#FF7C60" : "#4AD479";

    return (
      <Box
        sx={{
          position: "relative",
          pl: { xxs: "0", sm: position.isLocked ? "12px" : "0" },
          pr: { xxs: "0", sm: position.isLocked ? "0" : "10px" },
          flexShrink: 0,
          width: { xxs: "100px", sm: "106px" },
          height: { xxs: "50px", sm: "100px" }
        }}>
        <Box
          style={getAugmentedStyle({
            color: position.isLocked ? "#000" : "rgba(255, 255, 255, 0.2)"
          })}
          data-augmented-ui="tl-clip border"
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: position.isLocked ? "#1a1a1a" : "transparent",
            color: primaryColor,
            fontSize: { xxs: "12", sm: "16px" },
            "--aug-border-right": { xxs: "1px", sm: position.isLocked ? "1px" : "0px !important" },
            "--aug-border-bottom": {
              xxs: position.isLocked ? "1px" : "0px !important",
              sm: "1px !important"
            }
          }}>
          {position.isLocked ? "Staking" : "Vesting"}
        </Box>

        {downXs ? (
          <Box
            sx={{
              position: "absolute",
              zIndex: 2,
              bottom: "0",
              left: "50%",
              transform: "translate3d(-50%, 50%, 0) rotate(90deg)"
            }}>
            <Box
              style={getAugmentedStyle({
                borderSize: position.isLocked ? "2px" : "1px",
                color: position.isLocked ? "#000" : "#333"
              })}
              data-augmented-ui="all-hex border"
              sx={{
                "--aug-all-height": "22px !important",
                backgroundColor: position.isLocked ? "#1a1a1a" : "#000"
              }}>
              {!position.isLocked && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    width: "50%",
                    height: "100%",
                    background: "#000"
                  }}></Box>
              )}
              <HexagonIcon
                style={{
                  position: "absolute",
                  zIndex: 3,
                  top: "50%",
                  left: "50%",
                  transform: "translate3d(-50%, -50%, 0)",
                  "--fill": primaryColor
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              position: "absolute",
              zIndex: 2,
              top: "50%",
              right: "0px",
              transform: "translate3d(50%, -50%, 0)"
            }}>
            <Box
              style={getAugmentedStyle({
                borderSize: position.isLocked ? "2px" : 0,
                color: position.isLocked ? "#000" : "transparent"
              })}
              data-augmented-ui="all-hex border"
              sx={{
                width: "10px",
                height: "10px",
                backgroundColor: position.isLocked ? "#1a1a1a" : "transparent"
              }}>
              <HexagonIcon
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate3d(-50%, -50%, 0)",
                  "--fill": primaryColor
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  }

  function HarvestButton({ sx }) {
    return (
      <CustomButton
        handleClick={handleHarvestButtonClick}
        augmented="tl-clip br-clip"
        isLoading={editing.processing}
        disabled={position.isLocked}
        sx={{
          fontSize: { xxs: "12px", sm: "16px" },
          height: { xxs: "28px", sm: "37px" },
          px: "24px",
          width: "auto",
          minWidth: { xxs: "90px", sm: "100px" },
          ...sx
        }}>
        Harvest
      </CustomButton>
    );
  }

  function DataCard({ title, TooltipContent, needAmountX, needDays, needPct, amount, meta, sx }) {
    return (
      <Box
        sx={{
          flexShrink: 0,
          height: "100%",
          ...sx
        }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            fontSize: { xxs: "12px", sm: "18px" }
          }}>
          {title}
          {TooltipContent && <CustomToolTip Content={TooltipContent} />}
        </Box>
        <Box
          sx={{
            display: "flex",
            fontFamily: "Teko, sans-serif",
            alignItems: "center",
            fontSize: { xxs: "24px", sm: "32px" },
            mb: { xxs: "6px", sm: "9px" },
            color: position.isLocked ? "rgba(255, 255, 255, 0.5);" : "white"
          }}>
          {amount}
          {needAmountX && (
            <Box
              sx={{
                fontWeight: "700",
                lineHeight: 1,
                ml: "5px",
                fontFamily: "Chakra Petch",
                fontSize: "0.75em"
              }}>
              ×
            </Box>
          )}
          {needPct && (
            <Box
              sx={{
                fontWeight: "700",
                ml: { xxs: "2px", sm: "5px" },
                mb: "0.15em",
                fontFamily: "Chakra Petch",
                fontSize: "0.7em"
              }}>
              %
            </Box>
          )}
          {needDays && (
            <Box
              sx={{
                fontWeight: "700",
                lineHeight: 1,
                ml: "5px",
                mb: "12px",
                fontFamily: "Chakra Petch",
                fontSize: "14px"
              }}>
              DAYS
            </Box>
          )}
        </Box>
        <Box
          sx={{
            fontSize: { xxs: "12px", sm: "16px" },
            color: "rgba(255, 255, 255, 0.3);"
          }}>
          {meta}
        </Box>
      </Box>
    );
  }

  function ItemBodyCard({ sx, children }) {
    const formattedUnlockDate = formatDate(position.unlock_at, false);

    function EarnedTooltipContent() {
      const titleSx = {
        fontWeight: 500
      };
      const dataSX = {
        fontWeight: 700,
        mt: "4px"
      };
      const formattedCallAction = formatDate(position.last_action_time, false);
      return (
        <Box
          sx={{
            minWidth: "180px",
            minHeight: "100px",
            padding: "16px"
          }}>
          <Box sx={titleSx}>Earned since your last action:</Box>
          <Box sx={dataSX}>{formattedCallAction}</Box>
          <Box sx={{ ...titleSx, mt: "10px" }}>Hourly Average:</Box>
          <Box sx={{ ...dataSX }}>
            &lt;{position.agi_earned_hourly_avg} $AGI (&lt;{position.agi_earned_hourly_avg_usd} USD)
          </Box>
        </Box>
      );
    }

    return (
      <Box
        style={getAugmentedStyle({
          color: position.isLocked ? "#000" : "rgba(255, 255, 255, 0.2)"
        })}
        data-augmented-ui="br-clip l-clip-y border"
        sx={{
          flex: 1,
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          backgroundColor: position.isLocked ? "#1a1a1a" : "transparent",
          justifyContent: "space-between",
          padding: { xxs: "20px 40px 20px 22px", sm: "20px 20px 20px 26px" },
          minHeight: { xxs: "110px", sm: "140px" },
          "--aug-l": { xxs: "0px!important", sm: "10px !important" },
          ...sx
        }}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            height: "100%",
            minHeight: "80px",
            gap: { xxs: "30px", sm: "60px" }
          }}>
          <DataCard
            title="$AGI"
            amount={position.locked?.toLocaleString()}
            meta={`≈ ${position.locked_usd?.toLocaleString()} USD`}
            sx={{
              marginRight: { sm: "20px" }
            }}
          />
          {position.isLocked &&<DataCard
            title={`${currencies.native} Earned`}
            TooltipContent={!isMobileDevice() && EarnedTooltipContent}
            amount={position.agi_earned?.toLocaleString()}
            meta={`≈ ${position.agi_earned_usd?.toLocaleString()} USD`}
            sx={{
              marginRight: { sm: "20px" }
            }}
          />}
          {!position.isLocked &&<DataCard
            title={`${currencies.native} Released`}
            amount={position.agi_released?.toLocaleString()}
            meta={`≈ ${position.agi_released_usd?.toLocaleString()} USD`}
            sx={{
              marginRight: { sm: "20px" }
            }}
          />}
          {position.isLocked && <DataCard
            title="APR"
            amount={position.apr}
            needPct={true}
            sx={{
              marginRight: { sm: "20px" }
            }}
          />}
          {!position.isLocked && <DataCard
            title="Vesting Boost"
            amount={`${Number(position.vesting_boost).toFixed(2)}`}
            needAmountX={true}
          /> }
          {position.isLocked &&<DataCard
            title="DMA Boost"
            amount={`${Number(position.dma_boost).toFixed(2)}`}
            needAmountX={true}
            meta={`Owned ${position.owned_dma} DMA`}
          />}
          {position.isLocked &&<DataCard
            title="Loyalty Boost"
            amount={`${Number(position.loyalty_score_boost).toFixed(2)}`}
            needAmountX={true}
            meta={`Loyalty Score: ${Math.floor(position.loyalty_score)}`}
          />}
          {/* <DataCard
            title="Yield Boost"
            amount={`${position.yield_boost.toFixed(2)}`}
            needAmountX={true}
            meta={`Lock for ${position.locks_months} months`}
          /> */}
          {(
            <DataCard
              title="Unlocks in"
              amount={`${position.unlocks_in_days}`}
              needDays={true}
              meta={`On ${formattedUnlockDate}`}
            />
          )}
        </Box>
        {children}
      </Box>
    );
  }

  function DesktopItem({ children }) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          overflowX: "auto"
        }}>
        <LockStatus />
        <ItemBodyCard sx={{ minWidth: "1100px" }}>
        </ItemBodyCard>
      </Box>
    );
  }

  function MobileItem({ children }) {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "6px"
          }}>
          <LockStatus />
        </Box>
        <Box
          sx={{
            overflowX: "auto",
            marginLeft: "-20px",
            marginRight: "-20px",
            "&::-webkit-scrollbar": {
              height: 0
            }
          }}>
          <ItemBodyCard
            sx={{
              marginLeft: "20px",
              marginRight: "20px",
              minWidth: position.isLocked ? "600px" : "450px"
            }}
          />
        </Box>
      </Box>
    );
  }

  const Item = downXs ? MobileItem : DesktopItem;

  return (
    <Box>
      <Item />
    </Box>
  );
}

function SinglePositionsList() {
  const PAGE_SIZE = 10;
  const EMPTY_RECORDS = "No Positions";

  const dispatch = useDispatch();
  const { limitedSinglePositions, isLoadingSinglePositions } = useSelector((state) => state.stake);
  const isLoading = isLoadingSinglePositions;
  const isEmpty = !isLoading && limitedSinglePositions.list?.length === 0;
  const [page, setPage] = useState(1);

  function handleChangePage(e, value) {
    setPage(value);
  }

  useEffect(() => {
    dispatch(getLimitedSinglePositions({ page_size: PAGE_SIZE, page }));
  }, [dispatch, page]);

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xxs: "24px", sm: "20px" }
        }}>
        {!isLoading &&
          limitedSinglePositions?.list?.map((row) => {
            return <SinglePositionItem position={row} key={row.id} />;
          })}
      </Box>

      {isEmpty && (
        <EmptyRecords
          sx={{ mt: 0, minHeight: { xxs: "60vh", xs: "20vh" } }}
          title={EMPTY_RECORDS}
        />
      )}

      {!isEmpty && !isLoading && (
        <Pagination
          onChange={handleChangePage}
          count={limitedSinglePositions?.total_page}
          page={page}
          sx={{ my: { xxs: "22px", sm: "54px" } }}
        />
      )}
    </>
  );
}

function MyPositions() {
  const dispatch = useDispatch();

  const { isLoadingPoolsInfo } = useSelector((state) => state.stake);

  return (
    <Box>
      <SingleHeading />
      {isLoadingPoolsInfo ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            mt: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <Fade in={true}>
          <Box>
            <SinglePoolMetaCards />
            <SinglePositionsList />
          </Box>
        </Fade>
      )}
    </Box>
  );
}

export default MyPositions;
