import { Box } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as CloseIconBasic } from "../../assets/icons/close_basic.svg";
import useBreakpoints from "../../hooks/useBreakpoints";

function CloseButton({ onClick, type, Icon = null, ...props }) {
  const downSM = useBreakpoints("down", "sm");
  const isFrame = type === 'frame'

  return (
    <Box
      sx={{
        width: { xxs: 20, md: 24 },
        position: "absolute",
        right: "0.5rem",
        top: { xxs: isFrame ? "0.5rem" : "1.5rem", sm: "0.5rem" },
        opacity: "0.8",
        "&:hover": {
          opacity: 1,
          cursor: "pointer"
        },
        ...props?.sx
      }}
      onClick={onClick}>
      {Icon ? <Icon /> : (downSM && !isFrame) ? <CloseIconBasic style={{ width: "12px" }} /> : <CloseIcon />}
    </Box>
  );
}

export default CloseButton;
