// ----------------------------------------------------------------------

export default function Accordion({ theme }) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent"
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: 16,
          paddingRight: 8,
          "& .MuiTypography-root": {
            fontSize: "18px"
          }
        },
        expandIconWrapper: {
          color: "inherit",
          "&.Mui-expanded": {
            transform: "rotate(-90deg)"
          }
        }
      }
    }
  };
}
