import { Box, Typography, CircularProgress, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import EmailSubmitForm from "../../components/Forms/EmailSubmit";
import VerificationCodeForm from "../../components/Forms/VerificationCode";
import UnbindWalletDialog from "../../components/Dialogs/UnbindWalletDialog";
import WalletDialog from "../../components/Dialogs/WalletDialog";
import Google2FAVerifyDialog from "../../components/Dialogs/Google2FAVerifyDialog";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import CopyText from "../../components/CopyText";
import ResetPasswordForm from "../../components/Forms/ResetPassword";
import {
  discordAuthoriseURL,
  requestCodeEmail,
  requestCodePassword,
  resetAccountDialogs,
  setCurrentFlow,
  setCurrentDialogStep,
  setBindGoogle2FADialogOpen,
  setBindGoogle2FACallback,
  setTurnOffGoogle2FADialogOpen,
  setFormDialogOpen,
  setUnbindWalletDialogOpen,
  reqEnableGoogle2FA,
  reqDisableGoogle2FA,
  updateEmail,
  updatePassword,
  verifyCodeEmail,
  verifyCodePassword
} from "../../redux/slices/account";
import AccountNotificationBar from "../../components/AccountNotificationBar";
import { setAccountNotification } from "../../redux/slices/accountNotifications";
import {
  resetWalletDialog,
  setWalletDialogOpen,
  WALLET_CONNECTION_TYPES
} from "../../redux/slices/wallet";
import { updateUser, setAuthenticator } from "../../redux/slices/auth";
import FormHeader from "../../components/Forms/FormHeader";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeInDown } from "../../components/Animate";
import useBreakpoints from "../../hooks/useBreakpoints";
import { formatDate } from "../../utils/time";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import { PageHeading } from "../../components/PageHeading";
import Container from "../../components/Container";
import googleAuthenticatorIconUrl from '../../assets/google-authenticator.png'

const overFlowEllipsisSx = {
  textOverflow: "ellipsis",
  maxWidth: { xxs: 200, xs: 254, sm: 364, md: 444, lg: 501 },
  overflow: "hidden",
  whiteSpace: "nowrap"
};

const sharedTextSx = {
  fontSize: { xxs: 14, xs: 16 }
};

function BoxTitle({ title }) {
  return (
    <Typography color="primary" fontSize={{ xxs: 14, xs: 18 }} fontWeight={400}>
      {title}
    </Typography>
  );
}

function Google2FASwitch ({
  handleSwitchChange,
  isProcessing,
  enabled
}) {

  const itemSx = {
    width: "50px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    transition: "318ms",
    fontFamily: "Chakra Petch, sans-serif",
  }

  const activeItemSx = (isActive) => ({
    color: isActive ? "#000000" : "rgba(255, 255, 255, 0.5)"
  })

  return (
    <Box
      style={getAugmentedStyle({
        borderSize: "1px",
        color: "#808080",
        tl: "4px",
        br: "4px",
      })}
      data-augmented-ui="tl-clip br-clip border"
      sx={{
        padding: "4px",
        cursor: "pointer"
      }}
      onClick={handleSwitchChange}
    >
      <Box sx={{
        display: "flex",
        position: "relative"
      }}>
        <Box
          data-augmented-ui="tl-clip br-clip"
          style={getAugmentedStyle({
            tl: enabled ? "2px" : "0px",
            br: !enabled ? "2px" : "0px",
          })}
          sx={{
            position: "absolute !important",
            zIndex: "-1",
            width: "50%",
            height: "100%",
            transition: "318ms",
            transform: `translate3d(${enabled ? 0 : "100%"}, 0, 0)`,
            background: enabled ? "#68E094" : "#FF508F"
          }}
        >
        </Box>
        <Box sx={{ ...itemSx, ...activeItemSx(enabled) }}>ON</Box>
        <Box sx={{ ...itemSx, ...activeItemSx(!enabled) }}>{ isProcessing && !enabled ? <CircularProgress color="info" size={12} /> : "OFF" }</Box>
      </Box>
    </Box>
  )
}

export function GoogleAuthenticatorTitle ({ text = "Google Authenticator", isHeadline = false }) {
  const iconScale = isHeadline ? 1.2 : 1
  const textSx = isHeadline && {
    fontWeight: 700,
    fontSize: { xxs: "17px", xs: "24px" },
  }

  return (
    <Box display="flex" alignItems="center">
      <Box sx={{
        backgroundImage: `url(${googleAuthenticatorIconUrl})`,
        backgroundSize: "cover",
        aspectRatio: "1/1",
        width: "1em",
        height: "1em",
        fontSize: 20 * iconScale,
        marginRight: 0.8 * iconScale,
      }} />
      <Typography sx={{ ...sharedTextSx, ...textSx }}>
        { text }
      </Typography>
    </Box>
  )
}

function FieldLabel({ label, sx }) {
  return (
    <Typography
      sx={{
        mt: { xxs: 1.875, xs: 3 },
        fontSize: 12,
        color: (theme) => theme.palette.grey[50],
        ...sx
      }}>
      {label}
    </Typography>
  );
}

function SecurityVerification ({
  handleBindEmailClick
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { turnOffGoogle2FADialogOpen } = useSelector(
    (state) => state.account
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const enabled = user?.authenticator?.enabled
  const hasBound = user?.authenticator?.bound
  const tips = hasBound
    ? "For added security, please turn on authentication for your account."
    : "For added security, please bind Google Authenticator for your account."
  const boundAt = formatDate(user.authenticator?.bound_at, false)

  const handleBindClick = () => {
    if (!user?.email) {
      handleBindEmailClick()
      return
    }

    ReactGA.event('try_setting_2FA_bind')
    dispatch(setBindGoogle2FADialogOpen(true))
    dispatch(setBindGoogle2FACallback('setting'))
  }

  const handleSwitchChange = async () => {
    if (isProcessing) return

    if (enabled) {
      ReactGA.event('try_2FA_off')
      dispatch(setTurnOffGoogle2FADialogOpen(true))
      return
    }

    setIsProcessing(true)
    ReactGA.event('try_2FA_on')
    await dispatch(reqEnableGoogle2FA())
    setIsProcessing(false)
  }

  return (
    <Box mt={{ xxs: 3.75, xs: 7.5 }}>
      <Box>
        <BoxTitle title="Security Verification" />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mt: { xxs: "12px", xs: "24px" }
          }}
        >
          <GoogleAuthenticatorTitle />
          { hasBound
            ? <Google2FASwitch
              enabled={enabled}
              handleSwitchChange={handleSwitchChange}
              isProcessing={isProcessing}
              />
            : <OutlinedButton
              color="primary"
              sx={{ ml: 6 }}
              label={ "Bind" }
              handleClick={handleBindClick}
            />
          }
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <FieldLabel label={tips} sx={{ mt: "8px" }} />
          {hasBound && <FieldLabel label={`Since ${boundAt}`} sx={{ mt: "8px" }} /> }
        </Box>
      </Box>
    </Box>
  )
}

function Settings(props) {
  const { user, isUpdating } = useSelector((state) => state.auth);
  const { currentFlow, currentDialogStep, formDialogOpen, UnbindWalletDialogOpen, turnOffGoogle2FADialogOpen } = useSelector(
    (state) => state.account
  );
  const { walletDialogOpen } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const [email, setEmail] = useState();
  const [isUpdateEmail, setIsUpdateEmail] = useState(false);
  const [isDiscordBinding, setIsDiscordBinding] = useState(false);
  const downXs = useBreakpoints("down", "xs");

  const handlePasswordSubmit = (values) => {
    dispatch(
      updatePassword({
        newPassword: values.password,
        newPasswordConfirm: values.confirmPassword
      })
    ).then((response) => {
      if (response) {
        dispatch(
          setAccountNotification({
            message: "Your password has been changed successfully. "
          })
        );
      }
    });
  };

  const handleBindEmailClick = () => {
    !currentFlow && setIsUpdateEmail(false);
    !currentFlow && dispatch(setCurrentFlow("emailUpdate"));
    !user?.email && dispatch(setCurrentDialogStep("emailInput"));
  }

  const requestCode = (email, onSuccess) => {
    if (currentFlow === "emailUpdate")
      dispatch(requestCodeEmail({ email })).then((response) => {
        if (response) onSuccess();
      });
    else if (currentFlow === "passwordUpdate")
      dispatch(requestCodePassword({ email })).then((response) => {
        if (response) onSuccess();
      });
  };

  const handleEmailResendCode = (email, setCoolDownTimer) => {
    requestCode(email, () => setCoolDownTimer(60));
  };

  return (
    <Container>
      <PageHeading
        title="ACCOUNT SETTINGS"
        subtitle={
          !(user?.wallets?.ethWallet && user?.discord)
            ? " Let’s complete setting up your account!"
            : null
        }
      />

      {isUpdating ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            mt: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <AnimatePresence>
          <Box component={motion.div} {...varFadeInDown}>
            <Box mt={{ xxs: 3.75, xs: 7.5 }}>
              <BoxTitle title="Email Account" />
              <Box>
                <FieldLabel label="Email Address" />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    mt: { xxs: 0, xs: 1 },
                    pb: { xxs: 1.875, xs: 3 },
                    borderBottom: (theme) => `1px solid ${theme.palette.grey[30]}`
                  }}>
                  <Typography sx={{ ...overFlowEllipsisSx, ...sharedTextSx }}>
                    {user?.email}
                  </Typography>
                  <OutlinedButton
                    color="secondary"
                    sx={{ ml: 6 }}
                    disabled={currentFlow === "emailUpdate"}
                    label={ user?.email ? "Change Email" : "Bind Email"}
                    handleClick={handleBindEmailClick}
                  />
                </Box>
              </Box>
              { user?.email && <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  flexFlow: "row wrap",
                  mt: { xxs: 1.875, xs: 3 }
                }}>
                <Typography sx={sharedTextSx}>Password</Typography>
                <OutlinedButton
                  color="secondary"
                  disabled={currentFlow === "passwordUpdate"}
                  label="Reset Password"
                  handleClick={() => !currentFlow && dispatch(setCurrentFlow("passwordUpdate"))}
                />
              </Box> }
            </Box>

            <Box mt={{ xxs: 3.75, xs: 7.5 }}>
              {user?.wallets?.ethWallet ? (
                <Box>
                  <BoxTitle title="Wallet" />
                  <Box>
                    <FieldLabel label="Wallet Address" />
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        mt: { xxs: 0, xs: 1 }
                      }}>
                      <Typography sx={sharedTextSx}>
                        {downXs
                          ? `${user?.wallets.ethWallet.substr(
                              0,
                              6
                            )}...${user?.wallets.ethWallet.substr(
                              user?.wallets.ethWallet.length - 6,
                              6
                            )}`
                          : user?.wallets.ethWallet}
                      </Typography>
                      <CopyText label="" textToCopy={user?.wallets.ethWallet} />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <BoxTitle title="Wallet" />
                  <OutlinedButton
                    disabled={walletDialogOpen}
                    color="primary"
                    classname="augmented-button-primary"
                    label="Bind"
                    handleClick={() => {
                      dispatch(
                        setWalletDialogOpen({ open: true, type: WALLET_CONNECTION_TYPES.bind })
                      );
                      // dispatch(setIsUCBinding(true));
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box mt={{ xxs: 3.75, xs: 7.5 }}>
              {user?.discord ? (
                <Box>
                  <BoxTitle title="Discord" />
                  <Box>
                    <FieldLabel label="Discord Email Address" />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={{ xxs: 0, xs: 1 }}>
                      <Typography sx={{ ...overFlowEllipsisSx, ...sharedTextSx }}>
                        {user?.discord?.data.email}
                      </Typography>
                      <OutlinedButton
                        color="secondary"
                        label="Unbind"
                        handleClick={() => {
                          dispatch(setUnbindWalletDialogOpen(true));
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <BoxTitle title="Discord" />

                  <OutlinedButton
                    disabled={isDiscordBinding}
                    color="primary"
                    classname="augmented-button-primary"
                    label="Connect"
                    handleClick={() => {
                      setIsDiscordBinding(true);
                      dispatch(discordAuthoriseURL()).then((url) => (window.location.href = url));
                    }}
                  />
                </Box>
              )}
            </Box>
            <SecurityVerification handleBindEmailClick={handleBindEmailClick} />
            <UnbindWalletDialog
              open={UnbindWalletDialogOpen}
              setClose={() => {
                dispatch(setCurrentFlow(null));
                dispatch(setUnbindWalletDialogOpen(false));
              }}
            />
            <Google2FAVerifyDialog
              title={"Turn off Google Authenticator"}
              open={turnOffGoogle2FADialogOpen}
              setClose={() => {
                dispatch(setTurnOffGoogle2FADialogOpen(false));
              }}
              onPrimaryClick={(code) => {
                return dispatch(reqDisableGoogle2FA(code));
              }}
            />
            <WalletDialog
              handleClose={() => {
                dispatch(setCurrentFlow(null));
                dispatch(setWalletDialogOpen(false));
              }}
            />
          </Box>
        </AnimatePresence>
      )}
    </Container>
  );
}

export default Settings;
