export const currencies = {
  native: "$AGI",
  POINT: 'POINT',
  USDT: "USDT"
};

export function getBalance(balances, currency) {
  if (currency === 'DES') {
    currency = currencies.native
  }
  return balances?.find((item) => item.currency === currency);
}
