import React from "react";
import useBreakpoints from "../hooks/useBreakpoints";
import { getAugmentedStyle } from "../utils/augmentedStyles";
import { InfoCardSx } from "./InfoCard";
import { Box, Typography } from "@mui/material";
import { displayNum } from "../utils/strings";

const numSx = {
  lineHeight: { xxs: "32px", md: "45px" },
  flexGrow: 1,
  textAlign: { xxs: "left", md: "center" },
  minWidth: "170px",
  width: { xxs: "100%", md: "initial" },
  mt: 2,
  mb: { xxs: 0, md: "12px" },
  fontSize: { xxs: "40px", md: "60px" },
  fontFamily: "Teko"
};

export default function ShowcaseBox({
  noValueFormatting = false,
  amount = 0,
  title,
  Icon = () => <></>,
  subtitle,
  hideSubtitle,
  amountColor = "primary",
  dataAugmentedUI = "tl-clip br-clip border"
}) {
  const upXS = useBreakpoints("up", "md");
  return (
    <Box
      style={getAugmentedStyle({ color: "rgb(26 26 26)", bl1: "1px" })}
      data-augmented-ui={dataAugmentedUI}
      sx={{
        ...InfoCardSx,
        display: "flex",
        flexFlow: { xxs: "row wrap", md: "column nowrap", alignItems: "center" }
      }}>
      <Icon style={{ height: "auto", width: upXS ? "48px" : "20px", marginTop: hideSubtitle && upXS ? "16px" : 0 }} />
      <Typography
        sx={{
          ml: { xxs: 0.5, md: 1 },
          mt: { xxs: 0, md: 1 },
          fontSize: { xxs: 12, md: 16 },
          color: ({ palette }) => palette.grey[50]
        }}>
        {title}
      </Typography>
      <Typography sx={numSx} color={amountColor}>
        {noValueFormatting ? amount : displayNum(amount)}
      </Typography>
      <Typography
        display={ hideSubtitle ? "none" : "inline" }
        textAlign="center"
        mt={{ xxs: 1, md: 0 }}
        height={{ md: 36 }}
        sx={{ color: ({ palette }) => palette.grey[50] }}
        variant="caption">
        {subtitle}
      </Typography>
    </Box>
  );
}
