import { useDispatch, useSelector } from "react-redux";
import CustomButton from "./Buttons/Button";
import { getCorrectChain } from "../utils/chains";
import { equalsIgnoringCase } from "../utils/strings";
import { NETWORK_TYPE, networks } from "../constants/network";
import { WALLET_CONFIG, setWalletDialogOpen, wrongWalletAlert, wrongNetworkAlert } from "../redux/slices/wallet";
import { handleChangeNetwork } from "../redux/slices/DMA";

function WalletHandler({
  chain = NETWORK_TYPE.BSC,
  Component = CustomButton,
  children,
  handleClick = () => {},
  ...props
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useAccount, useChainId, useIsActive } = WALLET_CONFIG[selectedWallet].hook;
  const isActive = useIsActive();
  const chainId = useChainId();
  const correctChain = getCorrectChain(chain);
  const isWrongNetwork = correctChain.id !== chainId;
  const account = useAccount();
  const correctWallet = user?.wallets?.ethWallet;
  const isWrongWallet = account && !equalsIgnoringCase(correctWallet, account);

  const wrappedHandleClick = async () => {
    if (!isActive) {
      dispatch(setWalletDialogOpen({ open: true }));
      return;
    }

    if (isWrongWallet) {
      dispatch(wrongWalletAlert(correctWallet, "stake"));
      return;
    }

    if (isWrongNetwork) {
      const switchSuccess = await handleChangeNetwork(chain);
      if (!switchSuccess) {
        dispatch(wrongNetworkAlert(networks[chain], "stake"));
      }
      return;
    }

    handleClick();
  };

  return (
    <Component
      {...props}
      handleClick={wrappedHandleClick}
      sx={{
        ...props.sx
      }}>
      {children}
    </Component>
  );
}

export default WalletHandler;
