import React, { useState } from "react";
import { alpha, Backdrop } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import { AnimatePresence, motion } from "framer-motion";
import SideMenuOutline from "./SideMenuOutline";

export default function TabletSideMenu({ isSkeleton = false, isInventory, ...props }) {
  const theme = useTheme();
  const classes = getAugmentedStyle({
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[900]
  });
  const [hasAnimated, setHasAnimated] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      {menuOpen ? (
        <Backdrop
          open={menuOpen}
          sx={{
            pl: 1.75,
            pt: 1.5,
            justifyContent: "flex-start",
            zIndex: {
              xs: 1000000,
              md: (theme) => theme.zIndex.appBar - 1
            },

            backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.8)
          }}>
          <AnimatePresence>
            <motion.aside
              data-augmented-ui={hasAnimated ? "" : "tl-clip bl-clip-y br-clip "}
              style={hasAnimated ? {} : classes}
              initial={{ width: 0 }}
              animate={{
                width: 242,
                transition: { delay: 0, duration: 0.3 }
              }}
              onAnimationStart={() => setHasAnimated(false)}
              onAnimationComplete={() => setHasAnimated(true)}
              exit={{
                width: 0,
                transition: { delay: 0, duration: 0.2 }
              }}>
              <SideMenuOutline isOpen={menuOpen} setOpen={setMenuOpen} />
            </motion.aside>
          </AnimatePresence>
        </Backdrop>
      ) : (
        <SideMenuOutline isOpen={menuOpen} setOpen={setMenuOpen} />
      )}
    </>
  );
}
