import spacetime from "spacetime";

const TIME_ZONE = "asia/shanghai";

export const getStakePhaseInfo = (config) => {
  const defualtConfig = {
    "phase_1_end_at": 1698475683,
    "phase_1_start_at": 1697266083,
    "phase_2_end_at": 1744613283,
    "phase_2_start_at": 1698475683,
    "start_at": 1697266083
  }

  let phaseText = ''
  let remainingMonthAmount = 0

  config = {
    ...defualtConfig,
    ...config
  }

  const nowSt = spacetime(Date.now(), TIME_ZONE)
  const phase1StartSt = spacetime(config.phase_1_start_at * 1000, TIME_ZONE)
  const phase1EndSt = spacetime(config.phase_1_end_at * 1000, TIME_ZONE)
  const phase2StartSt = spacetime(config.phase_2_start_at * 1000, TIME_ZONE)
  const phase2EndSt = spacetime(config.phase_2_end_at * 1000, TIME_ZONE)

  const isPhase1 = nowSt.isAfter(phase1StartSt) && nowSt.isBefore(phase1EndSt)
  const isPhase2 = nowSt.isAfter(phase2StartSt) && nowSt.isBefore(phase2EndSt)
  const isEndAll = nowSt.isAfter(phase2EndSt)

  if (isPhase1) {
    phaseText = 'Phase 1'
    remainingMonthAmount = Math.abs(phase1EndSt.diff(nowSt, 'months'))
  }

  if (isPhase2) {
    phaseText = 'Phase 2'
    remainingMonthAmount = Math.abs(phase2EndSt.diff(nowSt, 'months'))
  }

  return {
    phaseText,
    isPhase1,
    isPhase2,
    isEndAll,
    remainingMonthAmount
  }
}
