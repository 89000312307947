import { Box, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmailSubmitForm from "../../components/Forms/EmailSubmit";
import VerificationCodeForm from "../../components/Forms/VerificationCode";
import FormDialog from "../../components/Dialogs/FormDialog";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import ResetPasswordForm from "../../components/Forms/ResetPassword";
import {
  requestCodeEmail,
  requestCodePassword,
  resetAccountDialogs,
  setCurrentDialogStep,
  setCurrentFlow,
  setFormDialogOpen,
  updatePassword,
  updateEmail,
  verifyCodeEmail,
  verifyCodePassword
} from "../../redux/slices/account";
import { setAccountNotification } from "../../redux/slices/accountNotifications";
import {
  resetWalletDialog,
} from "../../redux/slices/wallet";
import { updateUser } from "../../redux/slices/auth";
import { checkEmailValidation } from "../../redux/slices/DMA";
import FormHeader from "../../components/Forms/FormHeader";
import useBreakpoints from "../../hooks/useBreakpoints";

function SettingsFormDialog() {
  const { user } = useSelector((state) => state.auth);
  const { currentFlow, currentDialogStep, formDialogOpen, UnbindWalletDialogOpen } = useSelector(
    (state) => state.account
  );
  const { walletDialogOpen } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const [email, setEmail] = useState();
  const [isUpdateEmail, setIsUpdateEmail] = useState(false);
  const downXs = useBreakpoints("down", "xs");

  useEffect(() => {
    dispatch(resetAccountDialogs());
    dispatch(resetWalletDialog());
  }, []);

  useEffect(() => {
    if (!(formDialogOpen || UnbindWalletDialogOpen || walletDialogOpen)) {
      dispatch(setCurrentFlow(null));
      dispatch(updateUser({ user }));
    }
  }, [formDialogOpen, UnbindWalletDialogOpen, walletDialogOpen]);
  useEffect(() => {
    setEmail(user?.email);
  }, [user]);

  useEffect(() => {
    if (currentFlow && user?.email) {
      requestCode(user?.email, () => {
        dispatch(setCurrentDialogStep("verificationCode"));
        dispatch(setFormDialogOpen(true));
      });
    } else if (currentFlow) {
      dispatch(setCurrentDialogStep("emailInput"));
      dispatch(setFormDialogOpen(true));
    }
  }, [currentFlow]);

  const handlePasswordSubmit = (values) => {
    dispatch(
      updatePassword({
        newPassword: values.password,
        newPasswordConfirm: values.confirmPassword
      })
    ).then((response) => {
      if (response) {
        dispatch(
          setAccountNotification({
            message: "Your password has been changed successfully. "
          })
        );
      }
    });
  };
  const handleEmailSubmit = async (values, setSubmitting) => {
    const exist = await dispatch(checkEmailValidation(values.email));

    if (exist) {
      dispatch(setAccountNotification({
        message: "This email you entered is associated with an existing account."
      }))
      setSubmitting(false);
      return
    }

    requestCode(values.email, () => {
      setEmail(values.email);
      setIsUpdateEmail(true);
      dispatch(setCurrentDialogStep("verificationCode"));
      setSubmitting(false);
    }, () => {
      setSubmitting(false)
    });
  };

  const handleEmailCodeSubmit = (e, code) => {
    e.preventDefault();

    if (currentFlow === "emailUpdate")
      if (!isUpdateEmail) {
        dispatch(verifyCodeEmail({ email: user?.email, verificationCode: code })).then(
          (response) => {
            if (response) {
              dispatch(setCurrentDialogStep("emailInput"));
            }
          }
        );
      } else {
        dispatch(updateEmail({ email, verificationCode: code })).then((response) => {
          if (response) {
            dispatch(
              setAccountNotification({
                message: "Your email address has been changed successfully. "
              })
            );
          }
        });
      }
    else if (currentFlow === "passwordUpdate") {
      dispatch(verifyCodePassword({ email, verificationCode: code })).then((response) => {
        if (response) {
          dispatch(setCurrentDialogStep("passwordInput"));
        }
      });
    }
  };

  const requestCode = (email, onSuccess, onError) => {
    if (currentFlow === "emailUpdate")
      dispatch(requestCodeEmail({ email })).then((response) => {
        if (response) {
          return onSuccess()
        };
        onError && onError()
      });
    else if (currentFlow === "passwordUpdate")
      dispatch(requestCodePassword({ email })).then((response) => {
        if (response) onSuccess();
      });
  };

  const handleEmailResendCode = (email, setCoolDownTimer) => {
    requestCode(email, () => setCoolDownTimer(60));
  };

  const dialogFlow = {
    verificationCode: (
      <VerificationCodeForm
        handleResendCode={handleEmailResendCode}
        email={email}
        handleSubmit={handleEmailCodeSubmit}
      />
    ),
    emailInput: <EmailSubmitForm handleSubmit={handleEmailSubmit} />,
    passwordInput: (
      <>
        <FormHeader title="Enter Your New Password" />

        <ResetPasswordForm handleSubmit={handlePasswordSubmit} />
      </>
    )
  };

  return (
    <FormDialog
      open={formDialogOpen}
      closeDialog={() => {
        dispatch(setFormDialogOpen(false));
      }}>
      {dialogFlow[currentDialogStep]}
    </FormDialog>
  );
}

export default SettingsFormDialog;
