import { Box, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Navbar from "../Common/Navbar";
import PropTypes from "prop-types";
import useBreakpoints from "../../hooks/useBreakpoints";
import MobileNavbar from "../Common/MobileNavbar";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeInLeft } from "../../components/Animate";
import TabletSideMenu from "./TabletSideMenu";
import SideMenu from "./SideMenu";
import MobileMenu from "./MobileMenu";
import SettingsFormDialog from "../../components/Dialogs/SettingsFormDialog";
import BindGoogle2FADialog from "../../components/Dialogs/BindGoogle2FADialog";
import BindWalletDialog from "../../components/Dialogs/BindWalletDialog";
import { setWalletDialogOpen } from "../../redux/slices/wallet";
import WalletDialog from "../../components/Dialogs/WalletDialog";
import AccountNotificationBar from "../../components/AccountNotificationBar";
import NotificationBar from "../../components/NotificationBar";
import { useEagerConnect } from "../../hooks/useEagerConnect";
import { getInvitationCodeType, checkUnselectedRewards } from "../../redux/slices/DMA";
import { isDashboardTab } from "../../routes/paths";
import RewardMethodDialog from "../../components/RewardMethodDialog";

// ----------------------------------------------------------------------

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};
// ----------------------------------------------------------------------

const SCROLL_DIR = { up: "up", down: "down", none: null };

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search
    });
  }, [location]);
};

function MainLayout({ children }) {
  useEagerConnect();
  usePageTracking();
  const downMd = useBreakpoints("down", "md");
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [scrollDir, setScrollDir] = useState(SCROLL_DIR.none);
  const searchFilterIsShown =
    downMd && (scrollDir === SCROLL_DIR.up || scrollDir === SCROLL_DIR.none);
  const $isDashboardTab = isDashboardTab(pathname);
  const $isLotteryTab = pathname.includes('/lottery')

  useEffect(() => {
    if (downMd) {
      const threshold = 80;
      let lastScrollY = window.pageYOffset;
      let ticking = false;

      const updateScrollDir = () => {
        const scrollY = window.pageYOffset;
        if (Math.abs(scrollY - lastScrollY) < threshold) {
          ticking = false;
          return;
        }
        if (scrollY > lastScrollY) setScrollDir(SCROLL_DIR.down);
        else if (scrollY < lastScrollY) setScrollDir(SCROLL_DIR.up);
        else setScrollDir(SCROLL_DIR.none);
        lastScrollY = scrollY > 0 ? scrollY : 0;
        ticking = false;
      };

      const onScroll = () => {
        if (!ticking) {
          window.requestAnimationFrame(updateScrollDir);
          ticking = true;
        }
      };

      window.addEventListener("scroll", onScroll);

      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [scrollDir, downMd]);

  useEffect(() => {
    dispatch(getInvitationCodeType());
    dispatch(checkUnselectedRewards());
  }, [dispatch]);

  return (
    <>
      {downMd ? <MobileNavbar /> : <Navbar />}
      <Container
        maxWidth={false}
        sx={{
          overflow: "hidden",
          position: "relative",
          "&.MuiContainer-root": {
            py: { xxs: 0, xs: 2 },

            px: { xxs: 0, xs: 1.875 }
          }
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xxs: "column", xs: "row" },
            overflow: "hidden"
          }}>
          {$isDashboardTab && (
            <>
              <AnimatePresence>
                <Box
                  component={motion.div}
                  {...varFadeInLeft}
                  initial={{ opacity: 0 }}
                  animate={searchFilterIsShown ? varFadeInLeft.animate : varFadeInLeft.exit}
                  sx={{ display: { xxs: "initial", xs: "none" }, minWidth: 50 }}>
                  <MobileMenu />
                </Box>
              </AnimatePresence>
              <AnimatePresence>
                <Box
                  component={motion.div}
                  {...varFadeInLeft}
                  initial={{ opacity: 0 }}
                  animate={searchFilterIsShown ? varFadeInLeft.animate : varFadeInLeft.exit}
                  sx={{ display: { xxs: "none", xs: "initial", md: "none" }, minWidth: 50 }}>
                  <TabletSideMenu />
                </Box>
              </AnimatePresence>
              <Box sx={{ display: { xxs: "none", md: "initial" }, minWidth: 242 }}>
                <SideMenu />
              </Box>
            </>
          )}

          <Box
            display="flex"
            flexDirection="column"
            flexWrap="nowrap"
            flexGrow={1}
            sx={{
              pt: { xxs: 2.5, xs: 0 },
              px: { xxs: 2.5, xs: 7.5, sm: 12.5, md: 7.5, lg: 10 },
              pb: { xxs: $isLotteryTab ? 0 : 10, xs: 0 },
              maxHeight: { xxs: $isDashboardTab ? "85vh" : $isLotteryTab ? 'calc(100dvh - 52px)' : "95vh", sm: "85vh" },
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: 0
              }
            }}>
            {children}
          </Box>
        </Box>
        <SettingsFormDialog />
        <BindGoogle2FADialog />
        <BindWalletDialog />
        <RewardMethodDialog />
        <WalletDialog handleClose={() => dispatch(setWalletDialogOpen({ open: false }))} />
        <AccountNotificationBar />
        <NotificationBar />
      </Container>
    </>
  );
}

export default MainLayout;
