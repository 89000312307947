import { __PROD__ } from "../utils/dev";

export const CONTRACT_ADDRESS = {
  AGI_ETH: '0x7dA2641000Cbb407C329310C461b2cB9c70C3046',
  AGI_BNB: __PROD__ ? '0x818835503f55283cd51a4399f595e295a9338753' : '0x5B11a38D7b67D8280ec9cB295453b4e2edDaeDE5',
  USDT_BNB: __PROD__ ? '0x55d398326f99059ff775485246999027b3197955' : '0x22364b269b17a2818d8300204674c9089e1f388a',
  // @TODO: Config prod items
  LP_TOKEN: __PROD__ ? '0x27795f5310232b241934ab4E1EC520d17A8b16B9' : '0x04F6FeAbC9077eb5CDcC541C2929b2F6d533E160',
  LP_TOKEN_MARKET: __PROD__ ? 'https://pancakeswap.finance/v2/pair/0x55d398326f99059fF775485246999027B3197955/0x818835503F55283cd51A4399f595e295A9338753' : 'https://pancakeswap.finance/v2/add/0x337610d27c682E347C9cD60BD4b3b107C9d34dDd/0x5B11a38D7b67D8280ec9cB295453b4e2edDaeDE5?chain=bscTestnet'
}
