import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as VisibleFieldIcon } from "../../../assets/icons/field_visible.svg";
import { ReactComponent as HiddenFieldIcon } from "../../../assets/icons/field_hidden.svg";
import CheckBoxField from "./CheckBox";
import { ReactComponent as ValidIcon } from "../../../assets/icons/valid_condition.svg";
import { ReactComponent as InValidIcon } from "../../../assets/icons/invalid_condition.svg";
import { ReactComponent as NeutralIcon } from "../../../assets/icons/neutral_condition.svg";

const PasswordValidationField = ({ value, selected, validationCondition, validationText }) => {
  return (
    <CheckBoxField
      formSx={{ mt: { xxs: 0.625, xs: 1.125 }, cursor: "default" }}
      checked={validationCondition}
      handleChange={() => {}}
      label={
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 400,
            fontSize: { xxs: 12, xs: 14 },
            color: (theme) =>
              !value && selected
                ? theme.palette.grey[60]
                : validationCondition
                ? theme.palette.success.main
                : theme.palette.error.main
          }}>
          {validationText}
        </Typography>
      }
      CheckedIcon={ValidIcon}
      DefaultIcon={!value && selected ? NeutralIcon : InValidIcon}
    />
  );
};

function PasswordField({
  handleBlur = () => {},
  hasError = false,
  errors = false,
  setHasError = () => {},
  label,
  name = "",
  placeholder = "type your password here",
  value,
  handleChange,
  sx = {},
  validate = false,
  touched = false
}) {
  const [moreThan8LessThan25, setMoreThan8LessThan25] = useState(false);
  const [containsUpperLowerCaseAndNumber, setContainsUpperLowerCaseAndNumber] = useState(false);
  const [eyeIconToggle, setEyeIconToggle] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!validate) return;
    setMoreThan8LessThan25(value.length > 7 && value.length < 25);
    setContainsUpperLowerCaseAndNumber(containsUpperLowerAndNumber(value));
    if (value.length > 7 && value.length < 25 && containsUpperLowerAndNumber(value))
      setHasError(false);
    else setHasError(true);
  }, [value]);

  const containsUpperLowerAndNumber = (value) => {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    let validConditions = 0;
    const numberOfMustBeValidConditions = 3;
    const conditions = [hasLowerCase, hasUpperCase, hasNumber];
    conditions.forEach((condition) => (condition ? validConditions++ : null));
    if (validConditions >= numberOfMustBeValidConditions) {
      return true;
    }
    return false;
  };

  return (
    <>
      <TextField
        autoComplete={false}
        onFocus={() => setSelected(true)}
        onBlur={(e) => {
          setSelected(false);
          handleBlur(e);
        }}
        FormHelperTextProps={{
          sx: {
            mt: { xxs: 0.5, md: 1 }
          }
        }}
        sx={{
          mt: { xxs: 1.25, xs: 1.125 },
          ".MuiInputBase-root:has(> input:-webkit-autofill)": {
            backgroundColor: "blue !important"
          },
          // mr: 11,
          ...sx
        }}
        label={label}
        name={name}
        error={(value.length > 0 || touched) && !selected && (Boolean(errors) || hasError)}
        helperText={
          (value.length > 0 || touched) && !selected && Boolean(errors)
            ? errors
            : hasError && (touched || value.length > 0) && !selected
            ? "Invalid format"
            : " "
        }
        variant="standard"
        type={eyeIconToggle ? "text" : "password"}
        value={value}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
          sx: {
            fontSize: { xxs: 12, md: 16 },
            color: (theme) => (value.length > 0 ? theme.palette.grey[60] : theme.palette.grey[0])
          }
        }}
        placeholder={placeholder}
        InputProps={{
          form: {
            autocomplete: "off"
          },
          endAdornment: value ? (
            eyeIconToggle ? (
              <Box display="flex" alignItems="center">
                <VisibleFieldIcon onClick={() => value && setEyeIconToggle(false)} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <HiddenFieldIcon onClick={() => value && setEyeIconToggle(true)} />
              </Box>
            )
          ) : (
            <></>
          ),
          sx: { cursor: "pointer", fontSize: { xxs: 12, md: 16 }, fontWeight: 400 }
        }}
        fullWidth
      />

      {validate && (selected || ((value.length > 0 || touched) && hasError)) && (
        <Box mb={3.25}>
          <PasswordValidationField
            value={value}
            selected={selected}
            validationText="Password must be 8-25 characters long"
            validationCondition={moreThan8LessThan25}
          />
          <PasswordValidationField
            value={value}
            selected={selected}
            validationText="Password must contain at least 1uppercase, 1 lowercase and 1numeric"
            validationCondition={containsUpperLowerCaseAndNumber}
          />
        </Box>
      )}
    </>
  );
}

export default PasswordField;
