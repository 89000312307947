import { alpha, Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomDialog from "./Dialog";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import { formatDate } from "../../utils/time";
import { toFixed } from "../../utils/strings";
import OutlinedButton from "../Buttons/OutlinedButton";
import CustomButton from "../Buttons/Button";
import useBreakpoints from "../../hooks/useBreakpoints";
import { PATH_HISTORY } from "../../routes/paths";
import { currencies, getBalance } from "../../constants/currencies";
import {
  checkEmailValidation,
  transfer,
  setTransferSuccessDialogOpen
} from "../../redux/slices/DMA";
import { NOTIF_FAIL, setNotification } from "../../redux/slices/notifications";
import { setBindGoogle2FADialogOpen, setBindGoogle2FACallback, setCurrentFlow, setCurrentDialogStep } from "../../redux/slices/account";
import { setAccountNotification } from "../../redux/slices/accountNotifications";
import ConfirmationDialog from "./ConfirmationDialog";
import Google2FAVerifyDialog from "./Google2FAVerifyDialog";
import BindConfirmDialog from "./BindConfirmDialog";

function validateEmail(email) {
  const res =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
}

function InfoRow({ title, Icon = null, info, rowSx }) {
  return (
    <Box
      sx={{
        display: "flex",
        mt: 4,
        flexFlow: "row nowrap",
        ...rowSx
      }}>
      <Typography sx={{ fontSize: 14, minWidth: 140, color: (theme) => theme.palette.grey[60] }}>
        {title}
      </Typography>

      <Typography
        sx={{ fontSize: 14, display: "flex", alignItems: "flex-end", wordBreak: "break-word" }}>
        {Icon && <Icon />} {info}
      </Typography>
    </Box>
  );
}

const InputTextSx = {
  color: (theme) => theme.palette.grey[0],
  fontSize: { xxs: 18, xs: 24 },
  fontWeight: 600,
  py: 1
};

const RowContainerSx = {
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: { xxs: "flex-start", xs: "center" }
};

const InputSx = {
  height: { xxs: "40px", xs: "51px" },
  input: { ...InputTextSx },
  "& input[type=number]": {
    MozAppearance: "textfield"
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none"
    }
  }
};

function CustomInputField({
  value,
  setValue,
  placeholder,
  type = "number",
  helperText = "",
  maxValue,
  minValue = 0
}) {
  function handleChangeQuantity(value) {
    if (value.length === 0) setValue("");
    else {
      const floatValue = parseFloat(value);

      if (value < minValue && value.length !== 0) setValue((prevState) => prevState);
      else setValue((prevState) => (floatValue <= maxValue ? floatValue : prevState));
    }
  }

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" width="100%">
      <TextField
        fullWidth
        type={type}
        placeholder={placeholder}
        variant="outlined"
        value={value}
        onChange={(e) => handleChangeQuantity(e.target.value)}
        data-augmented-ui="tl-clip br-clip border"
        InputProps={{
          inputProps: { max: maxValue, step: "0.01" }
        }}
        sx={InputSx}
        style={getAugmentedStyle({ color: "grey", borderSize: "1.5px" })}
      />
      <Box mt={0.5} display="flex" flexWrap="nowrap" justifyContent="space-between">
        <Typography color="textSecondary" variant="caption">
          {helperText}
        </Typography>
        {type === "number" && (
          <Typography
            color="primary"
            variant="caption"
            onClick={() => handleChangeQuantity(maxValue)}
            sx={{ textDecoration: "underline", mr: 0.5, cursor: "pointer" }}>
            Max
          </Typography>
        )}
      </Box>
    </Box>
  );
}

function CustomEmailField({ value, setValue, placeholder }) {
  const EmailInputSx = {
    ...InputSx,
    input: { ...InputTextSx, fontWeight: 400 }
  };

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" width="100%">
      <TextField
        fullWidth
        type="email"
        placeholder={placeholder}
        variant="outlined"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        data-augmented-ui="tl-clip br-clip border"
        sx={EmailInputSx}
        style={getAugmentedStyle({ color: "grey", borderSize: "1.5px" })}
      />
    </Box>
  );
}

function TransferConfirmDialog({ open, setClose, confirmHandler, email, amount }) {
  return (
    <ConfirmationDialog open={open} width="470px" closeDialog={setClose}>
      <Box>
        <Typography
          fontSize={{ xxs: 18, sm: 20 }}
          fontWeight={600}
          mb={{ xxs: 1.5, sm: 2.5 }}
          textAlign="center">
          Transaction Confirmation
        </Typography>
        <Typography fontSize={{ xxs: 14, sm: 16 }} textAlign="center">
          {toFixed(amount)} {currencies.native} will be transferred to&nbsp;
          <Typography sx={{ color: (theme) => theme.palette.primary.main }}>{email}</Typography>
        </Typography>
        <Box display="flex" justifyContent="center" mt={{ xxs: 2.5, sm: 3 }} mb={1}>
          <OutlinedButton
            label="Cancel"
            classname="augmented-button-primary"
            color="primary"
            handleClick={setClose}
            sx={{
              width: { xxs: 83, sm: 104 },
              height: { xxs: 28, sm: 30 },
              mr: { xxs: 2.5, sm: 3.75 },
              fontSize: { xxs: 12, sm: 14 }
            }}
          />
          <CustomButton
            augmented="tl-clip br-clip"
            sx={{
              mt: 0,
              width: { xxs: 83, sm: 104 },
              height: { xxs: 28, sm: 30 },
              fontSize: 14
            }}
            handleClick={confirmHandler}>
            Confirm
          </CustomButton>
        </Box>
      </Box>
    </ConfirmationDialog>
  );
}

export function TransferSuccessDialog() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { transferSuccessDialogOpen, transferSuccessResult: transfer } = useSelector(
    (state) => state.DMA
  );
  const setClose = () => dispatch(setTransferSuccessDialogOpen(false));

  const handleNavigateClick = () => {
    setClose();
    push(PATH_HISTORY.transfer);
  };

  if (!transfer) {
    return;
  }

  const labelSx = {
    color: (theme) => theme.palette.grey[50],
    display: "inline-block",
    width: 84,
    mt: "12px"
  };

  return (
    <CustomDialog
      hasCloseButton={true}
      BackdropProps={{
        sx: {
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7)
        }
      }}
      PaperProps={{
        sx: {
          width: {
            xxs: "340px",
            sm: "500px"
          }
        }
      }}
      setClose={setClose}
      open={transferSuccessDialogOpen}>
      <Box sx={{ pt: { xxs: 0, sm: 2 }, px: { xxs: 0, sm: 4.5 } }}>
        <Typography
          fontSize={{ xxs: 18, sm: 20 }}
          fontWeight={600}
          align="center"
          mb={{ xxs: 3, sm: 5 }}>
          Transaction Completed
        </Typography>
        <Typography
          fontSize={{ xxs: 26, sm: 28 }}
          fontWeight={600}
          align="center"
          lineHeight={{ xxs: "31px", sm: "36.4px" }}
          sx={{ color: (theme) => theme.palette.primary.main }}
          mb={{ xxs: 1, sm: 5 }}>
          {toFixed(transfer.amount)} {currencies.native}
        </Typography>
        <Box sx={{ mt: { xxs: 1, sm: 4 }, mb: 1.5, fontSize: { xxs: 12, sm: 14, lg: 16 } }}>
          <Typography>
            <Typography sx={labelSx}>Recipient</Typography>
            {transfer.email}
          </Typography>
          <Typography>
            <Typography sx={labelSx}>Date</Typography>
            {formatDate(transfer.updated_at)}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexFlow: "column nowrap", alignItems: "center", width: "100%" }}
          mt={6}
          mb={2}>
          <Box display="flex" flexWrap="nowrap" justifyContent="center" width="100%">
            <OutlinedButton
              size="medium"
              sx={{ fontSize: { xxs: 12, sm: 16 }, width: "50%", height: "37px", mr: "20px" }}
              handleClick={setClose}
              label="Confirm"
              color="primary"
            />
            <CustomButton
              handleClick={handleNavigateClick}
              sx={{ fontSize: { xxs: 12, sm: 16 }, height: "37px", width: "50%", mt: 0 }}
              augmented="tl-clip br-clip">
              Check Transaction
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </CustomDialog>
  );
}

function TransferDialog({ open, setClose }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { balance, transferProcessing } = useSelector((state) => state.DMA);
  const [amount, setAmount] = useState(0);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isShowBind2FAConfirm, setIsShowBind2FAConfirm] = useState(false);
  const [isShowBindEmailConfirm, setIsShowBindEmailConfirm] = useState(false);
  const [isShowVerifyDialog, setIsShowVerifyDialog] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedCurrency] = useState(currencies.native);
  const downXs = useBreakpoints("down", "xs");
  const selectedBalance = getBalance(balance, selectedCurrency);

  useEffect(() => {
    if (open) {
      setEmail("");
      setAmount(0);
    }
  }, [open]);

  const isTransferDisabled = amount <= 0 || amount > balance || !validateEmail(email);

  async function handlePreTransferClick() {
    if (email === user.email) {
      dispatch(
        setAccountNotification({
          message: "Cannot transfer to self."
        })
      );
      return;
    }

    ReactGA.event('try_dmatransferAGI_confirm')
    setIsShowConfirm(true);
  }

  async function handleTransfer(code) {
    // @Case: No email
    if (!user.email) {
      setIsShowBindEmailConfirm(true)
      return
    };

    // @Case: No binding 2FA
    if (!user.authenticator.bound) {
      setIsShowBind2FAConfirm(true)
      return
    };

    // @Case: Bound 2FA & No verify code
    if (
      user.authenticator.bound &&
      user.authenticator.enabled &&
      !code
    ) {
      setIsShowVerifyDialog(true)
      return
    };

    setIsShowConfirm(false);

    const exist = await dispatch(checkEmailValidation(email));

    if (transferProcessing) {
      return;
    }

    if (!exist) {
      return dispatch(
        setNotification({
          title: "Transfer Failed",
          type: NOTIF_FAIL,
          description: `The email ${email} has not been registered before, please check again.`
        })
      );
    }

    // @FIXME: Require uniform code type at the BE
    return dispatch(transfer({ email, amount, code: String(code) }));
  }

  return (
    <CustomDialog
      open={open}
      hasCloseButton={false}
      setClose={setClose}
      PaperProps={{
        sx: {
          width: { xxs: "101%", sm: 710 },
          height: { xxs: "431px", xs: "initial" },
          padding: { xxs: 0, xs: "0 6px" },
          margin: { xxs: 0, xs: 2 },
          left: -2,
          bottom: -2,
          background: { xxs: "#181818", xs: "black" },

          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            display: "none"
          }
        },
        style: {
          position: downXs ? "absolute" : "relative",
          "--aug-tr1": "12px",
          "--aug-tr-inset2": "calc(var(--aug-tr1) * 14.5)"
        },
        "data-augmented-ui": downXs ? "tr-clip-x border" : "bl-clip-y br-clip border"
      }}>
      <Box sx={{ pt: { xs: 3 }, pl: { xs: 7 }, pr: { xs: 7 }, pb: { xs: 3 } }}>
        <Typography fontSize={24} fontWeight={700}>
          Transfer
        </Typography>
        <InfoRow title="Asset" info={`${currencies.native}`} />
        <Box mt={4} sx={RowContainerSx}>
          <InfoRow title="Recipient's Email" rowSx={{ mt: 0, fontWeight: 400 }} />
          <CustomEmailField placeholder="someone@example.com" value={email} setValue={setEmail} />
        </Box>
        <Box mt={4} sx={RowContainerSx}>
          <InfoRow title="Amount" rowSx={{ mt: 0 }} />
          <CustomInputField
            maxValue={selectedBalance?.amount}
            helperText={`${selectedBalance?.amount || 0} ${
              selectedBalance?.currency || ""
            } available`}
            value={amount}
            setValue={setAmount}
          />
        </Box>

        <Box
          sx={{ display: "flex", flexFlow: "column nowrap", alignItems: "center", width: "100%" }}
          mt={4}>
          <Box display="flex" flexWrap="nowrap" justifyContent="flex-start">
            <OutlinedButton
              size="medium"
              sx={{ fontSize: "16px", width: "116px", height: "37px", mr: 3 }}
              handleClick={setClose}
              label="Cancel"
              color="primary"
              disabled={transferProcessing}
              needCircular={false}
            />
            <CustomButton
              isLoading={transferProcessing}
              disabled={isTransferDisabled}
              handleClick={() => handlePreTransferClick()}
              sx={{ fontSize: "16px", width: "116px", height: "37px", mt: 0 }}
              augmented="tl-clip br-clip">
              Transfer
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <BindConfirmDialog
        open={isShowBindEmailConfirm}
        setClose={() => setIsShowBindEmailConfirm(false)}
        tips={<>
          For your account safety, <br/>please bind with&nbsp;
          <Box component="span" sx={{ color: (theme) => theme.palette.primary.main }}>Email</Box> first.
        </>}
        confirmHandler={() => {
          ReactGA.event('try_dmatransferAGI_email_bind')
          dispatch(setCurrentFlow("emailUpdate"));
          dispatch(setCurrentDialogStep("emailInput"));
          setIsShowBindEmailConfirm(false)
        }}
      />
      <BindConfirmDialog
        open={isShowBind2FAConfirm}
        setClose={() => setIsShowBind2FAConfirm(false)}
        tips={<>
          For your account safety, <br/>please bind with&nbsp;
          <Box component="span" sx={{ color: (theme) => theme.palette.primary.main }}>Google Authenticator.</Box>
        </>}
        confirmHandler={() => {
          ReactGA.event('try_dmatransferAGI_2FA_bind')
          dispatch(setBindGoogle2FADialogOpen(true))
          dispatch(setBindGoogle2FACallback('transfer'))
          setIsShowBind2FAConfirm(false)
        }}
      />
      <TransferConfirmDialog
        email={email}
        amount={amount}
        open={isShowConfirm}
        setClose={() => setIsShowConfirm(false)}
        confirmHandler={() => handleTransfer()}
      />
      <Google2FAVerifyDialog
        title={"Security Verification"}
        open={isShowVerifyDialog}
        setClose={() => {
          setIsShowVerifyDialog(false);
        }}
        onPrimaryClick={(code) => {
          return handleTransfer(code);
        }}
      />
    </CustomDialog>
  );
}

export default TransferDialog;
