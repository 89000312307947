export const REWARD_TYPES = {
  0: "Cashback",
  1: "Loyalty Score",
  2: "-"
};

export const REWARD_TYPES_VALUE = {
  Cashback: 0,
  "Loyalty Score": 1,
  "-": 2
};
