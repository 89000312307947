import { initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { WalletConnect } from "@web3-react/walletconnect-v2";
import { CHAINS, CORRECT_CHAIN } from "./chains";

import { COINBASE, METAMASK, WALLETCONNECT } from "./wallet";
export const [coinbaseWallet, coinbaseHooks] = initializeConnector(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: CORRECT_CHAIN.rpc,
        appName: "Delysium-SSO",
      },
    })
);

export const [metaMask, metaMaskHooks] = initializeConnector(
  (actions) => new MetaMask({ actions })
);

export const [walletConnect, walletConnectHooks] = initializeConnector(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        projectId: '7eaf4a7a703fe12845236ffa58e84b6f',
        showQrModal: true,
        chains: [56],
        optionalChains: [1, 137, 80001],
        rpc: {
          1: CHAINS[1].rpc,
          137: CHAINS[137].rpc,
          80001: CHAINS[80001].rpc,
        },
      },
    })
);
export function getConnectorName(connector) {
  if (connector instanceof MetaMask) return METAMASK;
  if (connector instanceof WalletConnect) return WALLETCONNECT;
  if (connector instanceof CoinbaseWallet) return COINBASE;
  return "Unknown";
}
