import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useIsWrongNetwork from "../../hooks/useIsWrongNetwork";
import { checkNetwork, handleChangeNetwork, withdraw, setSelectedNetwork, setSelectedCurrency } from "../../redux/slices/DMA";
import { NOTIF_FAIL, setNotification } from "../../redux/slices/notifications";
import { setWalletDialogOpen, WALLET_CONFIG, wrongWalletAlert } from "../../redux/slices/wallet";
import { getCorrectChain } from "../../utils/chains";
import CustomButton from "../Buttons/Button";
import OutlinedButton from "../Buttons/OutlinedButton";
import CustomDialog from "./Dialog";
import { ethers } from "ethers";
import { metaMaskHooks } from "../../utils/connectors";

function TransactionReminderDialog({ open, setClose }) {
  const dispatch = useDispatch();
  const correctChain = getCorrectChain();
  const isWrongNetwork = useIsWrongNetwork();
  const { failedTx } = useSelector((state) => state.history);
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { withdrawProcessing } = useSelector((state) => state.DMA);
  const { useProvider } = metaMaskHooks;
  const { useIsActive, useAccount } = WALLET_CONFIG[selectedWallet].hook;
  const connector = WALLET_CONFIG[selectedWallet].connector;
  const provider = useProvider();
  const isActive = useIsActive();
  const account = useAccount();
  const correctWallet = failedTx?.sign_data?.to_address;
  const isWrongWallet = correctWallet !== account;

  useEffect(() => {
    dispatch(setSelectedNetwork(failedTx?.chain))
    dispatch(setSelectedCurrency(failedTx?.currency))
  }, [failedTx])

  async function handleCompleteTransaction() {
    if (!isActive) {
      dispatch(setWalletDialogOpen({ open: true }));
      return;
    }

    if (isWrongWallet) {
      dispatch(wrongWalletAlert(correctWallet));
      return;
    }

    if (isWrongNetwork) {
      await handleChangeNetwork(failedTx.chain);
      return;
    }

    dispatch(withdraw({ provider, signData: failedTx }));
  }

  return (
    <CustomDialog
      PaperProps={{ sx: { width: { xxs: "95%", xs: 470 }, px: { xs: 4.5 }, py: 2 } }}
      open={open}
      setClose={setClose}>
      <Box>
        <Typography mb={2.5} textAlign="center" variant="h5">
          Unfinished Transaction
        </Typography>
        <Typography fontSize={{ xxs: 14, xs: 16 }} textAlign="center">
          You have an unfinished transaction in Activity History. Please finish that transaction
          before a new withdrawal.
        </Typography>
        <Box display="flex" justifyContent="center" mt={{ xxs: 2.5, xs: 5 }}>
          <OutlinedButton
            label="Back"
            classname="augmented-button-primary"
            color="primary"
            handleClick={setClose}
            sx={{
              width: 152,
              height: { xxs: 28, xs: 30 },
              mr: { xxs: 2.5, xs: 3.75 },
              fontSize: 12
            }}
          />
          <CustomButton
            isLoading={withdrawProcessing}
            augmented="tl-clip br-clip"
            sx={{
              mt: 0,
              width: 152,
              height: { xxs: 28, xs: 30 },
              fontSize: 12
            }}
            handleClick={handleCompleteTransaction}>
            Finish Transaction
          </CustomButton>
        </Box>
      </Box>
    </CustomDialog>
  );
}
export default TransactionReminderDialog;
