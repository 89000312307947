import { Network, TokenWithdrawal } from "@delysium/client";
import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../config";
import transNotifs from "../../constants/notifications";
import { NETWORK_TYPE, BSC_NETWORK } from "../../constants/network";
import { currencies } from "../../constants/currencies";
import withdrawNotifs from "../../constants/notifications";
import axios from "../../utils/axios";
import { CHAINS, changeNetwork, getCorrectChain } from "../../utils/chains";
import { __DEBUG__, __PROD__, __STAGING__ } from "../../utils/dev";
import { getFailedTx, setFailedTx } from "./history";
import { NOTIF_FAIL, NOTIF_PROGRESSING, NOTIF_SUCCESS, setNotification } from "./notifications";
import { setAccountNotification } from "./accountNotifications";
import { Typography } from "@mui/material";
import ReactGA from "react-ga4";

const DMA_BASE_URL = config.DMA_API_URL;

const initialState = {
  isLoading: false,
  wheelDialogOpen: false,
  isEnableWheel: true,
  depositUsdtDialogOpen: false,
  depositDialogOpen: false,
  txReminderDialogOpen: false,
  transferDialogOpen: false,
  transferProcessing: false,
  transferSuccessDialogOpen: false,
  transferSuccessResult: null,
  withdrawalDialogOpen: false,
  selectedCurrency: currencies.USDT,
  selectedNetwork: NETWORK_TYPE.BSC,
  withdrawProcessing: false,

  transparencyVoted: localStorage.getItem('transparencyVoted') || null,
  transparencyIndex: 0,
  transparencyDialogOpen: false,

  withdraw: {
    usdt: true,
    des: true
  },

  network: {
    eth: true,
    bsc: true
  },

  stats: {
    quantity: 0,
    pending: 3,
    rewards: { amount: 0, currency: currencies.native },
    yesterdayRewards: { amount: 0, currency: currencies.native }
  },

  bonusStats: {
    new_sale_points_amount: 0,
    total_agi_rewards_amount: 0
  },

  superReward: {
    tier: 1,
    tierName: "NONE",
    referralsNeeded: 0,
    referralsCompleted: 0,
    usdtReward: 0,
    desReward: 0,
    lockupTokens: []
  },

  balance: [],

  referral: {
    code: "",
    totalReferred: 0,
    rewards: [],
    type: 0
  },

  selectingRewardType: {
    dialogOpen: false,
    id: -1,
    cashbackAmount: 0,
    loyaltyScoreAmount: 0,
    increasePercentage: 0,
    successDialog: -1
  }
};

export const slice = createSlice({
  name: "DMA",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setWithdrawProcessing(state, action) {
      state.withdrawProcessing = action.payload;
    },

    setTransferProcessing(state, action) {
      state.transferProcessing = action.payload;
    },

    setWheelDialogOpen(state, action) {
      state.wheelDialogOpen = action.payload;
    },

    setWheelStatus(state, action) {
      state.isEnableWheel = action.payload;
    },

    setDepositUsdtDialogOpen(state, action) {
      state.depositUsdtDialogOpen = action.payload;
    },

    setDepositDialogOpen(state, action) {
      state.depositDialogOpen = action.payload;
    },

    setWithdrawalDialogOpen(state, action) {
      state.withdrawalDialogOpen = action.payload;
      state.selectedNetwork = NETWORK_TYPE.BSC;
    },

    setTransferDialogOpen(state, action) {
      state.transferDialogOpen = action.payload;
    },

    setRewardMethodSelecting(state, action) {
      if (action.payload.dialogOpen === false) {
        //if intention is to close the dialog, we reset the DMA ID to a non-existant one
        state.selectingRewardType.dialogOpen = false;
        state.selectingRewardType.id = -1;
      }
      //when opening the dialog, we reset the view to be on -1 (selection) and assign the DMA ID
      else
        state.selectingRewardType = {
          id: action.payload.id,
          cashbackAmount: action.payload.cashbackAmount,
          loyaltyScoreAmount:
            Math.round(parseFloat(action.payload.loyaltyScoreAmount) * 10000) / 10000,
          increasePercentage:
            Math.round(parseFloat(action.payload.increasePercentage) * 10000) / 100,
          dialogOpen: true,
          successDialog: -1
        };
    },

    setRewardMethodSuccess(state, action) {
      state.selectingRewardType = { ...state.selectingRewardType, successDialog: action.payload };
    },

    setTransferSuccessDialogOpen(state, action) {
      state.transferSuccessDialogOpen = action.payload;
    },

    setTxReminderDialogOpen(state, action) {
      state.txReminderDialogOpen = action.payload;
    },

    setBalance(state, action) {
      state.balance = action.payload.map((item) => {
        if (item.currency === "DES") {
          item.currency = currencies.native;
        }
        return item;
      });
    },

    setTransparencyIndex(state, action) {
      state.transparencyIndex = action.payload;
    },

    setTransparencyVoted(state, action) {
      localStorage.setItem('transparencyVoted', Date.now())
      state.transparencyVoted = Date.now();
    },

    setTransparencyDialogOpen(state, action) {
      state.transparencyDialogOpen = action.payload;
    },

    setSelectedCurrency(state, action) {
      state.selectedCurrency = action.payload;
    },

    setSelectedNetwork(state, action) {
      state.selectedNetwork = action.payload;
    },

    setReferralStats(state, action) {
      const { total_dma_referred, referral_code, rewards } = action.payload;
      state.referral = {
        ...state.referral,
        rewards,
        code: referral_code,
        totalReferred: total_dma_referred
      };
    },

    setBonusStats(state, action) {
      state.bonusStats = action.payload
    },

    setWithdrawStatus(state, action) {
      state.withdraw = action.payload;
    },

    setNetworkStatus(state, action) {
      state.network = action.payload;
    },
    withdrawSuccess(state, action) {
      state.txReminderDialogOpen = false;
      state.withdrawalDialogOpen = false;
      state.withdrawProcessing = false;
    },
    transferSuccess(state, action) {
      state.transferDialogOpen = false;
      state.transferProcessing = false;
      state.transferSuccessDialogOpen = true;
      state.transferSuccessResult = action.payload;
    },
    setDMAStats(state, action) {
      const { quantity, pending_quantity, rewards, yesterday_rewards } = action.payload;

      state.stats = {
        quantity,
        pendingQuantity: pending_quantity,
        yesterdayRewards: yesterday_rewards[0],
        rewards: rewards[0]
      };
    },
    setSuperRewardStats(state, action) {
      const {
        campaign_purchase_amount,
        number_cashback_purchase_needed,
        number_referral_needed,
        campaign_referral_amount,
        tier,
        tier_full_name,
        total_des_rewards,
        usdt_cashback,
        lockup_tokens
      } = action.payload;
      state.superReward = {
        tier,
        tierName: tier_full_name,
        referralsNeeded: number_referral_needed,
        referralsCompleted: campaign_referral_amount,
        usdtReward: usdt_cashback,
        desReward: total_des_rewards,
        lockupTokens: lockup_tokens,
        tokenRewardsUnlocked: campaign_referral_amount >= number_referral_needed,
        dmaCashbackUnlocked:
          campaign_purchase_amount >= number_cashback_purchase_needed &&
          campaign_referral_amount >= number_referral_needed
      };
    },
    getInvitationCodeTypeSuccess(state, action) {
      if (action.payload?.type) {
        state.referral = { ...state.referral, type: action.payload?.type };
      }
    }
  }
});

export const {
  setDepositUsdtDialogOpen,
  setDepositDialogOpen,
  setWheelDialogOpen,
  setTxReminderDialogOpen,
  setTransferDialogOpen,
  setTransferSuccessDialogOpen,
  setWithdrawalDialogOpen,
  setTransparencyDialogOpen,
  setSelectedCurrency,
  setSelectedNetwork,
  setIsLoading,
  setBalance,
  setTransferProcessing,
  setWithdrawProcessing,
  setRewardMethodSelecting,
  setRewardMethodSuccess
} = slice.actions;

export function getDMAInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      await Promise.all([
        dispatch(getReferralStats()),
        dispatch(getRewardsBalance()),
        dispatch(getBonusStats()),
        dispatch(getDMAStats()),
      ])
      dispatch(slice.actions.setIsLoading(false));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getWithdrawStatus() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("", { baseURL: config.DMA_ARK_CONFIG });
      dispatch(slice.actions.setWheelStatus(true || data.dashboard?.wheel));
      dispatch(slice.actions.setWithdrawStatus(data.dashboard?.withdraw || initialState.withdraw));
      dispatch(slice.actions.setNetworkStatus(data.dashboard?.network || initialState.network));
    } catch (e) {
      console.log(e);
    }
  };
}
export function getReferralStats() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/referral/statistics`, {
        baseURL: DMA_BASE_URL
      });

      dispatch(slice.actions.setReferralStats(data?.data.referral_details));
      return true;
    } catch (error) {}
  };
}
export function getBonusStats() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/new_sale_points/statistics`, {
        baseURL: DMA_BASE_URL
      });

      dispatch(slice.actions.setBonusStats(data?.data));
      return true;
    } catch (error) {}
  };
}

export function getTransparencyIndex() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/transparency_index/average", {
        baseURL: DMA_BASE_URL
      });
      dispatch(slice.actions.setTransparencyIndex(data.data?.average_transparency_index));
    } catch (e) {
      console.log(e);
    }
  };
}

export function submitTransparencyIndex(score, comment) {
  return async (dispatch) => {
    try {
      await axios.post("/transparency_index/submit", {
        score,
        comment
      }, {
        baseURL: DMA_BASE_URL,
      });
    } catch (e) {
      console.log(e);
    }
    dispatch(slice.actions.setTransparencyVoted(true))
  };
}

export function getRewardsBalance() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/reward/balance`, {
        baseURL: DMA_BASE_URL
      });

      dispatch(slice.actions.setBalance(data.data.balance));
      return true;
    } catch (error) {}
  };
}

export function checkEmailValidation(email) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `/reward/transactions/prerequisites`,
        { email },
        { baseURL: DMA_BASE_URL }
      );
      return data.data?.exist;
    } catch (error) {}
  };
}

export function getDMAStats() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/dma/statistics`, {
        baseURL: DMA_BASE_URL
      });
      dispatch(slice.actions.setDMAStats(data.data.dma_details));
      return true;
    } catch (error) {}
  };
}

export function getInvitationCodeType() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/invitation-code`, {
        baseURL: DMA_BASE_URL
      });

      dispatch(slice.actions.getInvitationCodeTypeSuccess(data.data));
      return true;
    } catch (error) {
      console.log(error);
    }
  };
}

export function getSuperRewardStats() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/campaigns/v1/users`, {
        baseURL: DMA_BASE_URL
      });
      dispatch(slice.actions.setSuperRewardStats(data.data));
      return true;
    } catch (error) {}
  };
}

export function signWithdraw(amount, currency, chain, code) {
  return async (dispatch) => {
    try {
      const { sdk } = withdrawNotifs;
      const { data } = await axios.post(
        "/reward/transactions",
        //@TODO: Cleanup the currency change
        { amount, currency: currency === currencies.native ? "DES" : currency, chain, code },
        { baseURL: DMA_BASE_URL }
      );
      dispatch(
        setNotification({
          title: "Transaction Processing",
          type: NOTIF_PROGRESSING,
          description: sdk.processing
        })
      );
      return data.data;
    } catch (error) {
      const { api, sdk } = withdrawNotifs;
      dispatch(getFailedTx());
      dispatch(setWithdrawProcessing(false));
      dispatch(
        setNotification({
          title: "Purchase Failed",
          type: NOTIF_FAIL,
          description: api[error?.code] || error?.message || sdk["failed"]
        })
      );
      throw error
    }
  };
}

export async function handleChangeNetwork(type) {
  const correctChain = getCorrectChain(type);
  return await changeNetwork(CHAINS[correctChain.id]);
}

export function transfer({ email, amount, code }) {
  return async (dispatch) => {
    try {
      dispatch(setTransferProcessing(true));
      const { data } = await axios.post(
        "/reward/transactions",
        { amount, currency: "DES", type: 1, to_email: email, code },
        { baseURL: DMA_BASE_URL }
      );
      dispatch(getRewardsBalance());
      dispatch(slice.actions.transferSuccess(data.data));
    } catch (error) {
      // dispatch(slice.actions.setTransferDialogOpen(false));
      console.log(`Transfer failed`, { cause: error });
      if (error.code === 103009) {
        error.code = 400505;
        dispatch(setAccountNotification(error));
      } else {
        dispatch(
          setNotification({
            title: "Transaction Failed",
            type: NOTIF_FAIL,
            description: error?.message
          })
        );
      }
      dispatch(setTransferProcessing(false));
    }
  };
}

export function withdraw({ provider, signData }) {
  return async (dispatch) => {
    const { sdk } = withdrawNotifs;
    const { sign_data: signInfo, signature } = signData;

    let contractAddress;
    let contractNetwork;

    if (signData.chain === NETWORK_TYPE.BSC) {
      contractNetwork = __PROD__ ? BSC_NETWORK.MAINNET : BSC_NETWORK.TESTNET;
    } else {
      contractAddress = __DEBUG__
        ? "0x5964E193b4cFb79A3bed974Ff693dB7712a4d359"
        : __STAGING__
        ? (signInfo.contract_address || "0xC47AB2A4BD0482F94267C648A85554182FBE4026")
        : undefined;
      contractNetwork = __PROD__ ? Network.mainnet : Network.sepolia;
    }

    const contract = new TokenWithdrawal(contractAddress);

    contract
      .initialize({
        network: contractNetwork,
        provider
      })
      .then(async () => {
        dispatch(setWithdrawProcessing(true));
        try {
          const transaction = await contract.sdk.withdrawBySignature(
            signInfo.erc20_address,
            signInfo.to_address,
            signInfo.amount,
            signInfo.account_uuid,
            signInfo.request_id,
            signature
          );

          if (transaction) {
            dispatch(setFailedTx(null));
            dispatch(slice.actions.withdrawSuccess());
            dispatch(getDMAInfo());
            dispatch(
              setNotification({
                title: "Transaction Submitted",
                type: NOTIF_SUCCESS,
                description: sdk.successful()
              })
            );
          }
        } catch (e) {
          console.log(e);
          dispatch(getFailedTx());
          dispatch(slice.actions.setWithdrawalDialogOpen(false));
          dispatch(
            setNotification({
              title: "Transaction Failed",
              type: NOTIF_FAIL,
              description: sdk[e] || e.message || sdk["failed"]
            })
          );
          dispatch(setWithdrawProcessing(false));
        }
      });
  };
}

export function checkUnselectedRewards() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/referral/check_unselected_rewards", {
        baseURL: DMA_BASE_URL
      });
      if (parseInt(data.data?.unselected_rewards_amount) > 0) {
        const {
          rewards_usdt_amount,
          rewards_loyalty_score_amount,
          estimate_agi_increased_rate_tomorrow
        } = data.data;
        dispatch(
          setNotification({
            type: NOTIF_SUCCESS,
            title: "New DMA Referral",
            description: (
              <Typography variant="caption">
                {data.data.unselected_rewards_amount} Referrals has been made successfully!{"\n"}
                <Typography
                  // -1 id is given when we want to select reward type of ALL rewards options together
                  onClick={() => {
                    ReactGA.event("select_reward_method_clicked");
                    dispatch(
                      slice.actions.setRewardMethodSelecting({
                        dialogOpen: true,
                        id: -1,
                        cashbackAmount: rewards_usdt_amount,
                        loyaltyScoreAmount: rewards_loyalty_score_amount,
                        increasePercentage: estimate_agi_increased_rate_tomorrow
                      })
                    );
                  }}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  variant="caption"
                  color="primary">
                  Select your Rewards Method.
                </Typography>
              </Typography>
            )
          })
        );
      }
    } catch (error) {
      dispatch(
        setNotification({
          title: "Operation Failed",
          type: NOTIF_FAIL,
          description: error?.message
        })
      );
    }
  };
}

export function selectRewardType({ id, rewardType }) {
  return async (dispatch) => {
    try {
      await axios.post(
        "/referral/select_rewards",
        { dma_id: id, selected_rewards_type: rewardType },
        { baseURL: DMA_BASE_URL }
      );
      dispatch(slice.actions.setRewardMethodSuccess(rewardType));
    } catch (error) {
      dispatch(
        setNotification({
          title: "Operation Failed",
          type: NOTIF_FAIL,
          description: error?.message
        })
      );
    }
  };
}

export function selectAllRewardTypes({ rewardType }) {
  return async (dispatch) => {
    try {
      await axios.post(
        "/referral/select_all_rewards",
        { selected_rewards_type: rewardType },
        { baseURL: DMA_BASE_URL }
      );
      dispatch(slice.actions.setRewardMethodSuccess(rewardType));
    } catch (error) {
      dispatch(
        setNotification({
          title: "Operation Failed",
          type: NOTIF_FAIL,
          description: error?.message
        })
      );
    }
  };
}

export function spinToWin({ agi_to_spend = "100", spin_number = 1 }) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/agi/spin_to_win",
        { agi_to_spend: String(agi_to_spend), spin_number },
        { baseURL: DMA_BASE_URL }
      );
      return data?.data || []
    } catch (error) {
      dispatch(
        setNotification({
          title: "Spin Failed",
          type: NOTIF_FAIL,
          description: error?.message
        })
      );
      throw error;
    }
  };
}

export function spinToWinTest({ agi_to_spend = "100", spin_number = 1 }) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/agi/spin_to_win_for_test",
        { agi_to_spend, spin_number },
        { baseURL: DMA_BASE_URL, headers: {
          'api-key': "x-test-for-spin-to-win"
        } }
      );
      return data?.data || []
    } catch (error) {
      dispatch(
        setNotification({
          title: "Spin Failed",
          type: NOTIF_FAIL,
          description: error?.message
        })
      );
      throw error;
    }
  };
}

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export function signDeposit(body) {
  return async (dispatch) => {
    try {
      const { sdk } = withdrawNotifs;
      const { data } = await axios.post(
        "/deposit/sign",
        { ...body },
        { baseURL: DMA_BASE_URL }
      );
      dispatch(
        setNotification({
          title: "Deposit Processing",
          type: NOTIF_PROGRESSING,
          description: sdk.deposit_processing
        })
      );
      return data.data;
    } catch (error) {
      const { api, sdk } = withdrawNotifs;
      dispatch(
        setNotification({
          title: "Purchase Failed",
          type: NOTIF_FAIL,
          description: api[error?.code] || error?.message || sdk["failed"]
        })
      );
      throw error
    }
  };
}

export function deposit({ provider, signData, callback, onError }) {
  return async (dispatch) => {
    const { sdk } = transNotifs;

    const contractNetwork = __PROD__ ? BSC_NETWORK.MAINNET : BSC_NETWORK.TESTNET;
    const contract = new TokenWithdrawal(signData.contract_address);

    const success = async () => {
      await sleep(500)
      await dispatch(getRewardsBalance())
      dispatch(
        setNotification({
          title: "Transaction Submitted",
          type: NOTIF_SUCCESS,
          description: sdk.deposit_successful()
        })
      );
      callback()
    }

    if (signData.all_done_by_balance) {
      return success()
    }

    contract
      .initialize({
        network: contractNetwork,
        provider
      })
      .then(async () => {
        try {
          const transaction = await contract.sdk.depositWithSignature(
            signData.erc20_address,
            signData.amount,
            signData.account_uuid,
            signData.request_id,
            signData.data,
            signData.signature
          );

          if (transaction) {
            success()
          }
        } catch (e) {
          onError()
          // dispatch(slice.actions.setStakeDialogOpen(false));
          dispatch(
            setNotification({
              title: "Transaction Failed",
              type: NOTIF_FAIL,
              description: sdk[e] || e.message || sdk["deposit_failed"]
            })
          );
          throw e
        }
      });
  };
}

export default slice.reducer;
