import { Box, Typography } from "@mui/material";
import { PATH_HISTORY, PATH_STAKE } from "../routes/paths";

const transactionNotifs = {
  api: {
    103001: "You have and unfinished withdrawal transaction",
    103002: "Insufficient balance",
    103003: "user should at least bind one eth wallet",
    103004: "Transaction failed, please try again later",
    103005: "It is not yet time to open the withdrawal function"
  },
  sdk: {
    stake_processing:
    "Your staking transaction is processing and will be completed shortly. You will be notified when it is completed.",
    deposit_processing:
      "Your deposit transaction is processing and will be completed shortly. You will be notified when it is completed.",
    processing:
      "Your withdrawal transaction is processing and will be completed shortly. You will be notified when it is completed.",
    processing_approval:
      "Please wait while your approval is being processed. This may take a couple of seconds.",
    successful: () => (
      <Typography>
        Your rewards have been withdrawn, you can check the transaction{" "}
        <Box
          sx={{
            cursor: "pointer",
            textDecoration: "none",
            color: (theme) => theme.palette.primary.main
          }}
          component="a"
          href={PATH_HISTORY.rewards}>
          Activity History.
        </Box>
      </Typography>
    ),
    stake_successful: () => (
      <Typography>
        Your balance have been staked, you can check the transaction{" "}
        <Box
          sx={{
            cursor: "pointer",
            textDecoration: "none",
            color: (theme) => theme.palette.primary.main
          }}
          component="a"
          href={PATH_STAKE.myPositions}>
          My Positions
        </Box>
      </Typography>
    ),
    deposit_successful:  () => (
      <Typography>
        Your deposit has been successful, you can check the transaction{" "}
        <Box
          sx={{
            cursor: "pointer",
            textDecoration: "none",
            color: (theme) => theme.palette.primary.main
          }}
          component="a"
          href={PATH_HISTORY.rewards}>
          Activity History.
        </Box>
      </Typography>
    ),
    failed: "Your withdrawal has been failed, please try again later.",
    stake_failed: "Your staking has been failed, please try again later.",
    deposit_failed: "Your deposit has been failed, please try again later.",
    failed_network:
      "Your wallet is connected to another network. Please change your network to Matic Mainnet.",
    Wallet_InsufficientBalance:
      "Your balance is not sufficient for this transaction. Please consider adding more tokens to your wallet.",
    failed_wallet: "Please connect your wallet first and set network as polygon mainnet."
  }
};

export default transactionNotifs
