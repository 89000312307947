import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../config";
import axios from "../../utils/axios";
import { __PROD__ } from "../../utils/dev";
import { getRewardsBalance } from './DMA'

const BASE_URL = config.DMA_API_URL;

const initialState = {
  isRefreshingPrice: false,
  isLoadingPoolsInfo: true,
  stakeDialogOpen: false,
  stakeProcessing: false,
  stakeSingleDialogOpen: false,
  stakeSingleProcessing: false,
  balance: 0,
  dmaPrice: {
    agi_price: 5000
  },
  ticketOverview: {
    active_tickets: 0,
    my_tickets: 0,
  },
  ticketReferralOverview: {
    self_hold_ticket: false,
    my_referral_tickets: 0,
    total_active_referrals: 0,
  },
  referrerCode: window.localStorage.getItem('referrerCode'),
  myReferrerCode: null,
  aprs: [],
  pools: [],
  poolsInfo: {
    balance: 0,
    earned: 0,
    total_deposited: 0,
    total_deposited_usd: 0,
    apr: 0,
    tvl: 0
  },
  stakeOverview: {},
  stakeConfig: {},
  singlePoolsInfo: {
    balance: 0,
    earned: 0,
    total_deposited: 0,
    total_deposited_usd: 0,
    apr: 0,
    tvl: 0
  },
};

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const slice = createSlice({
  name: "lottery",
  initialState,
  reducers: {
    setIsLoadingPoolsInfo(state, action) {
      state.isLoadingPoolsInfo = action.payload;
    },

    setBalance(state, action) {
      state.balance = action.payload;
    },

    setPools(state, action) {
      state.pools = action.payload;
      state.poolsInfo = state.pools[1]
      state.singlePoolsInfo = state.pools[0]
    },

    setReferrerCode(state, action) {
      localStorage.setItem('referrerCode', action.payload)
      state.referrerCode = action.payload;
    },

    setMyReferrerCode(state, action) {
      state.myReferrerCode = action.payload;
    },

    setRefreshingPrice(state, action) {
      state.isRefreshingPrice = action.payload;
    },

    setTicketOverview(state, action) {
      state.ticketOverview = action.payload
    },

    setTicketReferralOverview(state, action) {
      state.ticketReferralOverview = action.payload
    },

    setStakeConfig(state, action) {
      state.stakeConfig = action.payload
    },

    setAprs(state, action) {
      state.aprs = action.payload;
    },

    setStakeProcessing(state, action) {
      state.stakeProcessing = action.payload;
    },

    stakeSuccess(state, action) {
      state.stakeDialogOpen = false;
      state.stakeProcessing = false;
    },

    setStakeDialogOpen(state, action) {
      state.stakeDialogOpen = action.payload;
    },

    setStakeSingleProcessing(state, action) {
      state.stakeSingleProcessing = action.payload;
    },

    stakeSingleSuccess(state, action) {
      state.stakeSingleDialogOpen = false;
      state.stakeSingleProcessing = false;
    },

    setStakeSingleDialogOpen(state, action) {
      state.stakeSingleDialogOpen = action.payload;
    },
  }
});

export const {
  setIsLoadingPoolsInfo,
  setReferrerCode,
  setStakeProcessing,
  setStakeDialogOpen,
  setStakeSingleProcessing,
  setStakeSingleDialogOpen,
} = slice.actions;

export function getTicketOverview() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setRefreshingPrice(true));
      const { data } = await axios.get("/ticket/overview", {
        baseURL: BASE_URL
      });
      await dispatch(getRewardsBalance())
      dispatch(slice.actions.setTicketOverview(data.data))
    } catch (error) {
      console.error(error);
    }
    dispatch(slice.actions.setRefreshingPrice(false));
  };
}

export function getTicketReferralOverview() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/ticket/referral_overview", {
        baseURL: BASE_URL
      });
      dispatch(slice.actions.setTicketReferralOverview(data.data))
      dispatch(getTicketReferralCode())
    } catch (error) {
      console.error(error);
    }
    dispatch(slice.actions.setRefreshingPrice(false));
  };
}

export function getTicketReferralCode() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/ticket/code", {
        baseURL: BASE_URL
      });
      dispatch(slice.actions.setMyReferrerCode(data.data.code))
    } catch (error) {
      console.error(error);
    }
    dispatch(slice.actions.setRefreshingPrice(false));
  };
}

export function buyTicketByAGI({
  amount,
  locked_period,
  code
}) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`/ticket/buy`, {
        amount,
        locked_period,
        code
      }, {
        baseURL: config.DMA_API_URL,
      });
      await dispatch(getTicketOverview())
    } catch (error) {
      throw error
    }
  };
}

export function lockTicketByAGI({
  amount,
  locked_period,
  code
}) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`/ticket/lock`, {
        amount,
        locked_period,
        code
      }, {
        baseURL: config.DMA_API_URL,
      });
      await dispatch(getTicketOverview())
    } catch (error) {
      throw error
    }
  };
}

export function sellTicketByAGI({
  amount
}) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`/ticket/sell`, {
        amount,
      }, {
        baseURL: config.DMA_API_URL,
      });
      await dispatch(getTicketOverview())
    } catch (error) {
      throw error
    }
  };
}

export default slice.reducer;
