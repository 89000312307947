import { useState } from "react";
import PasswordField from "../Fields/Input/Password";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomButton from "../Buttons/Button";
import { useSelector } from "react-redux";

export default function ResetPasswordForm({ handleSubmit }) {
  const [passwordHasError, setPasswordHasError] = useState(false);
  const { isLoading } = useSelector((state) => state.account);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: yup.object({
      password: yup.string().required("Required"),
      confirmPassword: yup
        .string()
        .required("Required")
        .oneOf([yup.ref("password"), null], "Passwords must match")
    })
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <PasswordField
        label="Password"
        name="password"
        value={formik.values.password}
        handleChange={formik.handleChange}
        validate={true}
        errors={formik.errors.password}
        touched={formik.touched.password}
        hasError={passwordHasError}
        setHasError={(hasError) => setPasswordHasError(hasError)}
        handleBlur={formik.handleBlur}
      />
      <PasswordField
        label="Confirm Password"
        name="confirmPassword"
        placeholder="re-enter password"
        errors={formik.errors.confirmPassword}
        touched={formik.touched.confirmPassword}
        value={formik.values.confirmPassword}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
      />
      <CustomButton
        isLoading={isLoading}
        disabled={passwordHasError || !formik.isValid}
        augmented="br-clip"
        type="submit">
        Confirm
      </CustomButton>
    </form>
  );
}
