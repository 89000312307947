import { PATH_ERROR } from "./paths";
import PageNotFound from "../views/Error/NotFound";

// ----------------------------------------------------------------------

const ErrorRoutes = {
  path: PATH_ERROR.NOT_FOUND,
  component: () => <PageNotFound />,
  exact: true
};

export default ErrorRoutes;
