import {
  CircularProgress,
  Divider,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyText from "../../../components/CopyText";
import { shortenString, equalsIgnoringCase, toFixed } from "../../../utils/strings";
import { Columns, EmptyRecords } from "./Table";
import { Box } from "@mui/system";
import { formatDate } from "../../../utils/time";
import useBreakpoints from "../../../hooks/useBreakpoints";
import {
  setWalletDialogOpen,
  WALLET_CONFIG,
  WALLET_CONNECTION_TYPES,
  wrongWalletAlert,
  wrongNetworkAlert
} from "../../../redux/slices/wallet";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert.svg";
import { ReactComponent as BinanceIcon } from "../../../assets/icons/wallet/binance.svg";
import { ReactComponent as EthIcon } from "../../../assets/icons/wallet/eth.svg";
import Pagination from "../../../components/Pagination";
import { getRewards } from "../../../redux/slices/history";
import { handleChangeNetwork, withdraw, setSelectedNetwork, setSelectedCurrency } from "../../../redux/slices/DMA";
import useIsWrongNetwork from "../../../hooks/useIsWrongNetwork";
import { currencies } from "../../../constants/currencies";
import { networks, NETWORK_TYPE } from "../../../constants/network";

const COLUMNS = ["Transaction", "Wallet Address", "Date", "TxID", "Amount", "Balance", "Network", "Status"];
const EMPTY_RECORDS = "No Rewards Record";

const NETWORK_NAME = {
  ETH: 'Ethereum',
  BSC: 'BSC'
}

const ORDER_TYPES = {
  airdrop: "Airdrop",
  pointAirdrop: "KOKORO Airdrop",
  withdrawal: "Withdrawal",
  reward: "Referral Rewards",
  daily: "Daily Rewards",
  campaignCashback: "USDT Cashback",
  sixMonth: "6-Month $AGI Reward",
  stake: "Stake",
  unstake: "Unstake",
  harvest: "Harvest",
  principalReturn: "Principal Returned",
  vest: "Vested Amount",
  bouns: "Bonus",
  spinCost: "Wheel Spent",
  spinReward: "Wheel Reward",
  deposit: "Deposit",
  buyDma: "Buy DMA",
  ticketPurchase: "Ticket Purchase",
  ticketRefund: "Ticket Refund",
  ticketReward: "Ticket Reward",
};

const copyTextSx = {
  width: "18px",
  height: "18px",
  "& svg": {
    transform: "scale(0.75)"
  },
  ml: 0.75
};

function getOrderType(type) {
  if (type === 0) return ORDER_TYPES.reward;
  if (type === 1) return ORDER_TYPES.withdrawal;
  if (type === 5) return ORDER_TYPES.campaignCashback;
  if (type === 6) return ORDER_TYPES.sixMonth;
  if (type === 7) return ORDER_TYPES.airdrop;
  if (type === 8) return ORDER_TYPES.stake;
  if (type === 9) return ORDER_TYPES.unstake;
  if (type === 10) return ORDER_TYPES.harvest;
  if (type === 11) return ORDER_TYPES.bouns;
  if (type === 12) return ORDER_TYPES.spinCost;
  if (type === 13) return ORDER_TYPES.spinReward;
  if (type === 14) return ORDER_TYPES.principalReturn;
  if (type === 15) return ORDER_TYPES.vest;
  if (type === 16) return ORDER_TYPES.deposit;
  if (type === 17) return ORDER_TYPES.buyDma;
  if (type === 18) return ORDER_TYPES.ticketPurchase;
  if (type === 19) return ORDER_TYPES.ticketRefund;
  if (type === 20) return ORDER_TYPES.ticketReward;
  if (type === 21) return ORDER_TYPES.pointAirdrop;
  else return ORDER_TYPES.daily;
}

function getFinalCurrency (currency) {
  if (currency === "DES") {
    return currencies.native
  }

  if (currency.toLowerCase() === 'point') {
    return 'KOKORO'
  }

  return currency
}

const TryAgain = ({ transaction, sx, ...props }) => {
  const dispatch = useDispatch();
  const isWrongNetwork = useIsWrongNetwork();
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { withdrawProcessing } = useSelector((state) => state.DMA);
  const { useIsActive, useAccount, useProvider } = WALLET_CONFIG[selectedWallet]?.hook;
  const provider = useProvider();
  const isActive = useIsActive();
  const account = useAccount();
  const correctWallet = transaction?.sign_data?.to_address;
  const isWrongWallet = account && !equalsIgnoringCase(correctWallet, account);

  useEffect(() => {
    dispatch(setSelectedNetwork(transaction?.chain))
    dispatch(setSelectedCurrency(transaction?.currency))
  }, [transaction])

  async function handleCompleteTransaction() {
    if (!isActive) {
      dispatch(setWalletDialogOpen({ open: true, type: WALLET_CONNECTION_TYPES.connect }));
      return;
    }

    if (isWrongWallet) {
      dispatch(wrongWalletAlert(correctWallet));
      return;
    }

    if (isWrongNetwork) {
      const switchSuccess = await handleChangeNetwork(transaction.chain);
      if (!switchSuccess) {
        dispatch(wrongNetworkAlert(networks[transaction.chain]));
      }
      return;
    }

    dispatch(withdraw({ provider, signData: transaction }));
  }

  return (
    <Typography
      size="small"
      variant="text"
      color="primary"
      onClick={!withdrawProcessing && handleCompleteTransaction}
      sx={{
        textDecoration: "underline",
        fontWeight: "bold",
        height: "initial",
        ml: 5,
        cursor: "pointer",
        ...sx
      }}
      {...props}>
      {withdrawProcessing ? (
        <CircularProgress style={{ width: "20px", height: "20px" }} />
      ) : (
        "Try Again"
      )}
    </Typography>
  );
};

function NetworkIcon({ type }) {
  const Icon = type === 'BSC' ? BinanceIcon : EthIcon

  return (
    <Icon
      style={{
        marginLeft: "8px",
        width: "16px",
        height: "16px"
      }}
    />
  )
}

function RewardRecordCard({ record }) {
  const RecordRow = ({ children, ...props }) => (
    <Box
      {...props}
      sx={{
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        ...props?.sx
      }}>
      {children}
    </Box>
  );

  return (
    <Box
      sx={{ backgroundColor: (theme) => theme.palette.grey[10], my: 0.5, width: "100%", p: 1.5 }}>
      <RecordRow>
        <Typography variant="caption">{formatDate(record.updated_at)}</Typography>

        <Box display="flex" alignItems="center">
          {record.status === 0 ? (
            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.palette.error.dark, fontWeight: 600 }}>
              Unfinished
            </Typography>
          ) : (
            <Typography variant="caption" sx={{ color: "#7FFAE2", fontWeight: 600 }}>
              {record.status === 1 ? "Completed" : "Pending"}
            </Typography>
          )}
          <NetworkIcon type={ record.chain } />
        </Box>
      </RecordRow>

      <RecordRow sx={{ mt: 3 }}>
        <Typography sx={{ fontSize: "18px" }}>{getOrderType(record.type)}</Typography>
        <Typography sx={{ fontSize: "18px" }}>
          {toFixed(record.amount)} {getFinalCurrency(record.currency)}
        </Typography>
      </RecordRow>

      <RecordRow>
        <Typography
          sx={{ display: "flex", flexFlow: "row nowrap", opacity: 0.5 }}
          color="textPrimary"
          variant="caption">
          {record?.wallet_address?.length > 0 && (
            <>
              Address: {shortenString(record.wallet_address, 4, 4)}{" "}
              <CopyText buttonSx={copyTextSx} label="" textToCopy={record.wallet_address} />
            </>
          )}
        </Typography>
        <Typography sx={{ opacity: 0.5 }} color="textPrimary" variant="caption">
          Balance: {toFixed(record.balance)}{" "}
          {getFinalCurrency(record.currency)}
        </Typography>
      </RecordRow>

      {getOrderType(record.type) === ORDER_TYPES.withdrawal && <Divider sx={{ my: 2 }} />}

      <RecordRow>
        <Typography
          sx={{ display: "flex", flexFlow: "row nowrap", opacity: 0.5 }}
          color="textPrimary"
          variant="caption">
          {record.transaction_hash > 0 && (
            <>
              TxID: {shortenString(record.transaction_hash, 4, 4)}{" "}
              <CopyText buttonSx={copyTextSx} label="" textToCopy={record.transaction_hash} />
            </>
          )}
        </Typography>
        {record.status === 0 && <TryAgain transaction={record} />}
      </RecordRow>
    </Box>
  );
}

function StatusComponent({ data }) {
  if (data.status === 0) {
    return (
      <Box display="flex" flexWrap="nowrap" alignItems="center">
        <Typography variant="body2" sx={{ color: (theme) => theme.palette.error.dark }}>
          Unfinished
        </Typography>
        <TryAgain transaction={data} />
      </Box>
    );
  } else
    return (
      <Typography variant="body2" sx={{ color: "#7FFAE2" }}>
        {data.status === 1 ? "Completed" : "Pending"}
      </Typography>
    );
}

const flexCellSx = {
  display: "flex",
  alignItems: "center",
  border: "none"
};

function Rewards() {
  const PAGE_SIZE = 10;
  const dispatch = useDispatch();
  const upXs = useBreakpoints("up", "xs");
  const { isLoading, rewards, failedTx } = useSelector((state) => state.history);
  const isEmpty = !isLoading && rewards?.data?.length === 0;
  const [page, setPage] = useState(1);

  function handleChangePage(e, value) {
    setPage(value);
  }

  useEffect(() => {
    dispatch(getRewards({ page_size: PAGE_SIZE, page }));
  }, [dispatch, page, failedTx]);

  return (
    <>
      {upXs ? (
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="Rewards Table">
            <Columns columnList={COLUMNS} />
            <TableBody>
              {!isLoading &&
                rewards?.data?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{getOrderType(row.type)}</TableCell>
                      <TableCell sx={flexCellSx}>
                        {row?.wallet_address?.length > 0 ? (
                          <>
                            {shortenString(row.wallet_address, 4, 4)}
                            <CopyText
                              buttonSx={copyTextSx}
                              label=""
                              textToCopy={row.wallet_address}
                            />
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {formatDate(row.updated_at)}
                      </TableCell>
                      <TableCell sx={flexCellSx}>
                        {row?.transaction_hash?.length > 0 ? (
                          <>
                            {shortenString(row.transaction_hash, 4, 4)}
                            <CopyText
                              buttonSx={copyTextSx}
                              label=""
                              textToCopy={row.transaction_hash}
                            />
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell>{`${toFixed(row.amount)} ${
                        getFinalCurrency(row.currency)
                      }`}</TableCell>
                      <TableCell>{`${toFixed(row.balance)} ${
                        getFinalCurrency(row.currency)
                      }`}</TableCell>
                      <TableCell>
                        { (row.chain && NETWORK_NAME[row.chain]) || '-' }
                      </TableCell>
                      <TableCell>
                        <StatusComponent data={row} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          my={2.5}
          minHeight={!isLoading && !isEmpty && rewards?.data?.length < 4 && "65vh"}>
          {!isLoading &&
            rewards?.data?.map((row, index) => {
              return <RewardRecordCard key={index} record={row} />;
            })}
        </Box>
      )}

      {isLoading && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      )}

      {isEmpty && (
        <EmptyRecords
          sx={{ mt: 0, minHeight: { xxs: "60vh", xs: "initial" } }}
          title={EMPTY_RECORDS}
        />
      )}

      <Box
        sx={{
          display: "flex",
          flexFlow: { xxs: "column wrap", md: "row nowrap" },
          justifyContent: "space-between",
          alignItems: "flex-end"
        }}>
        <Box sx={{ width: "100%", display: "flex", flexFlow: "row nowrap", alignItems: "center" }}>
          <AlertIcon />
          <Typography fontSize={12}>
            <Typography fontSize="inherit" component="span" color="primary">
              Rewards{" "}
            </Typography>
            is One-time Referral Rewards;
            <Typography fontSize="inherit" component="span" color="primary">
              {" "}
              Daily Rewards{" "}
            </Typography>
            is Daily Referral Rewards
          </Typography>
        </Box>
        {!isEmpty && !isLoading && (
          <Pagination onChange={handleChangePage} count={rewards?.total_page} page={page} />
        )}
      </Box>
    </>
  );
}

export default Rewards;
