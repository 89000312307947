import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Container from "../../components/Container";
import StakeDialog from "../../components/Dialogs/StakeDialog";
import DepositDialog from "../../components/Dialogs/DepositDialog";
import StakeSingleDialog from "../../components/Dialogs/StakeSingleDialog";
import { PATH_STAKE } from "../../routes/paths";
import { CONTRACT_ADDRESS } from "../../constants/contracts";
import useBreakpoints from "../../hooks/useBreakpoints";
import { getBalance, getStakeConfig, getPoolsInfo } from "../../redux/slices/stake";

const activeStyle = {
  opacity: 1,
  "--border-opacity": 1,
  fontWeight: 500
};

function Tab({ to = "", title }) {
  const upXs = useBreakpoints("up", "xs");

  return (
    <Box
      activeStyle={activeStyle}
      sx={{
        "--border-opacity": 0,
        borderBottom: "0px solid transparent",
        color: "#fff",
        textDecoration: "none",
        textAlign: "center",
        fontSize: "16px",
        fontWeight: { xxs: "600", xs: "300" },
        py: "10px",
        ml: "20px",
        position: "relative",
        mr: { xxs: 1.5, xs: 1 },
        opacity: 0.7,
        transition: "all 0.3s ease",

        "&:before": {
          content: `""`,
          position: "absolute",
          width: "100%",
          height: "3px",
          bottom: "0",
          background: (theme) => theme.palette.primary.main,
          opacity: "var(--border-opacity)"
        }
      }}
      to={to}
      component={NavLink}>
      {title}
    </Box>
  );
}

function StakeIntro() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        mt: { xxs: "0", sm: "58px" },
        mb: { xxs: "23px", sm: "36px" }
      }}>
      <Typography
        sx={{
          fontSize: { xxs: "24px", sm: "48px" },
          lineHeight: 62 / 48,
          fontWeight: 700,
          mb: { xxs: "8px", sm: "22px" }
        }}>
        Staking
      </Typography>
      <Typography
        sx={{
          fontSize: { xxs: "14px", sm: "24px" },
          mb: { xxs: "8px", sm: "22px" },
          maxWidth: { sm: "100vw" }
        }}>
        Stake & Earn Higher Returns on your $AGI
      </Typography>
      <Box
        sx={{
          fontSize: { xxs: "10px", sm: "16px" },
          color: "rgba(255, 255, 255, 0.5);"
        }}>
        <Typography sx={{ display: { xxs: "none", sm: "block" } }}>
          $AGI contract address:
          <br />
          Ethereum: {CONTRACT_ADDRESS.AGI_ETH}
          <br />
          BNB Smart Chain: {CONTRACT_ADDRESS.AGI_BNB}
          <br />
          <Typography sx={{ color: "#FF7C6080" }} >*Please do not send anything directly to these addresses</Typography>
        </Typography>
        <Typography
          sx={{ display: { xxs: "block", sm: "none" }, fontSize: "10px", textAlign: "center" }}>
          $AGI contract address:
          <br />
          ETH: {CONTRACT_ADDRESS.AGI_ETH}
          <br />
          BSC: {CONTRACT_ADDRESS.AGI_BNB}
          <br />
          <Typography sx={{ color: "#FF7C6080", fontSize: "10px", }} >*Please do not send anything directly to these addresses</Typography>
        </Typography>
      </Box>
    </Box>
  );
}

const StakeLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getBalance(user?.wallets.ethWallet, CONTRACT_ADDRESS.LP_TOKEN));
    dispatch(getPoolsInfo());
  }, [dispatch, user]);

  return (
    <Container
      sx={{
        fontFamily: "Chakra Petch, sans-serif",
        flexShrink: 0,
        width: { xxs: "100vw", sm: "100%" },
        minHeight: "100%",
        pb: 0,
        display: "flex",
        flexFlow: "column nowrap",
        mx: { xxs: "-20px", sm: 0 },
        pb: { xxs: "20px", sm: "50px" }
      }}>
      <StakeIntro />
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          mt: { xxs: 0, xs: 4 },
          borderBottom: "0.5px solid rgba(255, 255, 255, 0.5)",
          mb: { xxs: "16px", sm: "58px" }
        }}>
        <Tab to={PATH_STAKE.pools} title="Pools" />
        <Tab to={PATH_STAKE.myPositions} title="My Positions" />
      </Box>
      <Box sx={{ px: "20px" }}>{children}</Box>

      <StakeDialog />
      <DepositDialog />
      <StakeSingleDialog />
    </Container>
  );
};

export default StakeLayout;
