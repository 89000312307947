import { Typography } from "@mui/material";

export function SectionTitle({ label, color = "textPrimary", ...props }) {
  return (
    <Typography
      fontSize={{ xxs: 14, sm: 16, md: 18, lg: 20 }}
      fontWeight={500}
      mt={{ xxs: 2.5, md: 7.5 }}
      mb={{ xxs: 1.875, md: 3 }}
      textTransform="uppercase"
      color={color}
      sx={props?.sx}>
      {label}
    </Typography>
  );
}
