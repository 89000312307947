import { alpha } from "@mui/material";

// ----------------------------------------------------------------------

export default function Pagination({ theme }) {
  return {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: alpha(theme.palette.text.primary, 0.5),
          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.2)
          },

          "&.Mui-selected": {
            backgroundColor: "transparent"
          }
        }
      }
    }
  };
}
