import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setBindWalletNotice,
  setWalletDialogOpen,
  WALLET_CONNECTION_TYPES
} from "../../redux/slices/wallet";
import CustomButton from "../Buttons/Button";
import OutlinedButton from "../Buttons/OutlinedButton";
import CustomDialog from "./Dialog";

function BindWalletDialog() {
  const dispatch = useDispatch();
  const { bindWalletNoticeOpen } = useSelector((state) => state.wallet);

  return (
    <CustomDialog
      PaperProps={{ sx: { width: { xxs: "101%", xs: 470 }, px: 4.5, py: 2 } }}
      open={bindWalletNoticeOpen}
      setClose={() => dispatch(setBindWalletNotice(false))}>
      <Box>
        <Typography mb={2.5} textAlign="center" variant="h5">
          Bind Your Wallet
        </Typography>
        <Typography fontSize={{ xxs: 14, xs: 16 }} textAlign="center">
          Please go to Account Settings and bind your wallet before withdrawing your rewards.
        </Typography>
        <Box display="flex" justifyContent="center" mt={{ xxs: 2.5, xs: 5 }}>
          <OutlinedButton
            label="Cancel"
            classname="augmented-button-primary"
            color="primary"
            handleClick={() => dispatch(setBindWalletNotice(false))}
            sx={{
              width: 104,
              height: { xxs: 28, xs: 30 },
              mr: { xxs: 2.5, xs: 3.75 },
              fontSize: { xxs: 12, xs: 14 }
            }}
          />
          <CustomButton
            augmented="tl-clip br-clip"
            sx={{
              mt: 0,
              width: 104,
              height: { xxs: 28, xs: 30 },
              fontSize: 14
            }}
            handleClick={() => {
              dispatch(setWalletDialogOpen({ open: true, type: WALLET_CONNECTION_TYPES.bind }));
              dispatch(setBindWalletNotice(false));
            }}>
            Bind Wallet
          </CustomButton>
        </Box>
      </Box>
    </CustomDialog>
  );
}
export default BindWalletDialog;
