import { Box, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialog from "./Dialog";
import ReactGA from "react-ga4";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as BinanceIcon } from "../../assets/icons/wallet/binance.svg";
import { ReactComponent as EthSvgIcon } from "../../assets/icons/wallet/eth.svg";
import EthPng from "../../assets/icons/wallet/eth.png";
import { ReactComponent as AlertIcon } from "../../assets/icons/tooltip_alert.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import OutlinedButton from "../Buttons/OutlinedButton";
import CustomButton from "../Buttons/Button";
import useBreakpoints from "../../hooks/useBreakpoints";
import { currencies, getBalance } from "../../constants/currencies";
import { WALLET_CONFIG, wrongWalletAlert, wrongNetworkAlert } from "../../redux/slices/wallet";
import {
  checkNetwork,
  handleChangeNetwork,
  signWithdraw,
  withdraw,
  setSelectedNetwork
} from "../../redux/slices/DMA";
import useIsWrongNetwork from "../../hooks/useIsWrongNetwork";
import { setBindGoogle2FADialogOpen, setBindGoogle2FACallback, setCurrentFlow, setCurrentDialogStep } from "../../redux/slices/account";
import { NOTIF_FAIL, setNotification } from "../../redux/slices/notifications";
import { getCorrectChain } from "../../utils/chains";
import { equalsIgnoringCase } from "../../utils/strings";
import { ethers } from "ethers";
import { networks, NETWORK_TYPE } from "../../constants/network";
import { isCompositeComponent } from "react-dom/test-utils";
import Google2FAVerifyDialog from "./Google2FAVerifyDialog";
import BindConfirmDialog from "./BindConfirmDialog";

function InfoRow({ title, Icon = null, info, rowSx }) {
  return (
    <Box
      sx={{
        display: "flex",
        mt: 4,
        flexFlow: "row nowrap",
        ...rowSx
      }}>
      <Typography sx={{ fontSize: 14, minWidth: 115, color: (theme) => theme.palette.grey[60] }}>
        {title}
      </Typography>

      <Typography
        sx={{ fontSize: 14, display: "flex", alignItems: "center", wordBreak: "break-word" }}>
        {Icon && <Icon />} {info}
      </Typography>
    </Box>
  );
}
const InputTextSx = {
  color: (theme) => theme.palette.grey[0],
  fontSize: { xxs: 18, xs: 24 },
  fontWeight: 600,
  py: 1.25,
  pt: { xxs: 1, xs: 1.25 }
};

const RowContainerSx = {
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: { xxs: "center", xs: "center" }
};

const InputSx = {
  height: { xxs: "40px", xs: "51px" },
  input: { ...InputTextSx },
  "& input[type=number]": {
    MozAppearance: "textfield"
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none"
    }
  }
};

function NetworkRow({ setAmount }) {
  const dispatch = useDispatch();
  const downXs = useBreakpoints("down", "xs");

  const { selectedNetwork, selectedCurrency } = useSelector((state) => state.DMA);

  if (selectedCurrency === currencies.USDT) {
    return (
      <InfoRow title="Network" info={ networks['BSC'] } Icon={
        () => <BinanceIcon style={{
          width: downXs ? 20 :24,
          height: downXs ? 20 : 24,
          marginRight: 6
       }} />
      } />
    )
  }

  return (
    <Box mt={4} sx={RowContainerSx}>
      <InfoRow title="Network" rowSx={{ mt: 0 }} />
      <CustomSelectNetwork
        value={selectedNetwork}
        setValue={(value) => {
          dispatch(setSelectedNetwork(value));
          setAmount(0);
        }}
      />
    </Box>
  )
}

function CustomSelect({ value, setValue }) {
  const { withdraw } = useSelector((state) => state.DMA);

  return (
    <Box width={"100%"}>
      <FormControl fullWidth variant="standard">
        <Select
          variant="outlined"
          data-augmented-ui="tl-clip br-clip border"
          sx={{
            fontWeight: 600,
            position: "relative",
            width: "100%",
            fontSize: "20px",
            height: { xxs: "40px", xs: "51px" },
            "&:hover": {
              border: "none"
            },
            "&.Mui-focused ": {
              color: (theme) => theme.palette.text.primary,
              "& .MuiOutlinedInput-notchedOutline": {
                color: (theme) => theme.palette.text.primary,
                border: "transparent",
                borderWidth: 0
              }
            }
          }}
          inputProps={{
            sx: { display: "flex", alignItems: "center" }
          }}
          value={value}
          IconComponent={() => (
            <ArrowIcon style={{ position: "absolute", width: 10, height: "0.45rem", right: 20 }} />
          )}
          MenuProps={{ PaperProps: { sx: { mt: 1 } } }}
          onChange={(e) => setValue(e.target.value)}
          style={getAugmentedStyle({ color: "grey", borderSize: "1.5px" })}>
          <MenuItem
            disabled={!withdraw.usdt}
            disableRipple
            sx={{ fontSize: "20px", fontWeight: 600 }}
            value={currencies.USDT}>
            {currencies.USDT}
          </MenuItem>
          <MenuItem
            disabled={!withdraw.des}
            disableRipple
            sx={{ fontSize: "20px", fontWeight: 600 }}
            value={currencies.native}>
            {currencies.native}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

function NetworkIcon({ type }) {
  const Icon = type === 'BSC' ? BinanceIcon : EthSvgIcon

  return (
    <Icon
      style={{
        marginRight: "8px",
        width: "20px",
        height: "20px"
      }}
    />
  )
}

function CustomSelectNetwork({ value, setValue }) {
  const { network } = useSelector((state) => state.DMA);
  const menuStyleOverrides = {
    fontSize: { xxs: 14, sm: 18 },
    fontWeight: 400,
    "&.Mui-selected": {
      backgroundColor: '#181818',
    }
  }

  const renderOption = (type, isSelected) => {
    return (
      <Box
        display="flex"
        alignItems="center"
      >
        <NetworkIcon
          type={type}
        />
        { networks[type] }
        {isSelected && <CheckIcon
          style={{
            width: "16px",
            height: "16px",
            position: "absolute",
            right: 20
          }}
        />}
      </Box>
    )
  }

  return (
    <Box width={"100%"}>
      <FormControl fullWidth variant="standard">
        <Select
          variant="outlined"
          data-augmented-ui="tl-clip br-clip border"
          sx={{
            fontWeight: 400,
            position: "relative",
            width: "100%",
            fontSize: { xxs: 14, sm: 18 },
            height: { xxs: "40px", xs: "51px" },
            "&:hover": {
              border: "none"
            },
            "&.Mui-focused ": {
              color: (theme) => theme.palette.text.primary,
              "& .MuiOutlinedInput-notchedOutline": {
                color: (theme) => theme.palette.text.primary,
                border: "transparent",
                borderWidth: 0
              }
            }
          }}
          inputProps={{
            sx: { display: "flex", alignItems: "center" }
          }}
          value={value}
          renderValue={() => renderOption(value)}
          IconComponent={() => (
            <ArrowIcon style={{ position: "absolute", width: 15, height: 15, right: 20 }} />
          )}
          MenuProps={{ PaperProps: { sx: { mt: 1 } } }}
          onChange={(e) => setValue(e.target.value)}
          style={getAugmentedStyle({ color: "grey", borderSize: "1.5px" })}>
          <MenuItem
            disabled={!network.eth}
            disableRipple
            sx={{ ...menuStyleOverrides }}
            value={NETWORK_TYPE.ETH}>
            {renderOption(NETWORK_TYPE.ETH, value === NETWORK_TYPE.ETH)}
          </MenuItem>
          <MenuItem
            disabled={!network.bsc}
            disableRipple
            sx={{ ...menuStyleOverrides }}
            value={NETWORK_TYPE.BSC}>
              {renderOption(NETWORK_TYPE.BSC, value === NETWORK_TYPE.BSC)}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

function CustomInputField({ value, setValue, helperText = "", maxValue, minValue = 0 }) {
  function handleChangeQuantity(value) {
    if (value.length === 0) setValue("");
    else {
      const floatValue = parseFloat(value);

      if (value < minValue && value.length !== 0) setValue((prevState) => prevState);
      else setValue((prevState) => (floatValue <= maxValue ? floatValue : prevState));
    }
  }

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" width="100%">
      <TextField
        fullWidth
        type="number"
        variant="outlined"
        value={value}
        onChange={(e) => handleChangeQuantity(e.target.value)}
        data-augmented-ui="tl-clip br-clip border"
        InputProps={{
          inputProps: { max: maxValue, step: "0.01" }
        }}
        sx={InputSx}
        style={getAugmentedStyle({ color: "grey", borderSize: "1.5px" })}
      />
      <Box mt={0.5} display="flex" flexWrap="nowrap" justifyContent="space-between">
        <Typography color="textSecondary" variant="caption">
          {helperText}
        </Typography>
        <Typography
          color="primary"
          variant="caption"
          onClick={() => handleChangeQuantity(maxValue)}
          sx={{ textDecoration: "underline", fontSize: 14, lineHeight: 1, cursor: "pointer" }}>
          Max
        </Typography>
      </Box>
    </Box>
  );
}

function EthIcon() {
  return (
    <Box mr={1} width={20} height={20} display="flex" justifyContent="center">
      <Box
        sx={{
          width: 12.56,
          height: 19.17,
          backgroundSize: "100%",
          backgroundImage: `url(${EthPng})`
        }}></Box>
    </Box>
  );
}

function WithdrawalDialog({ open, setClose }) {
  const dispatch = useDispatch();
  // const correctChain = getCorrectChain();
  const isWrongNetwork = useIsWrongNetwork();
  const { user } = useSelector((state) => state.auth);
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { balance, withdrawProcessing, selectedCurrency, selectedNetwork } = useSelector((state) => state.DMA);
  const { useAccount, useProvider } = WALLET_CONFIG[selectedWallet].hook;
  // const connector = WALLET_CONFIG[selectedWallet].connector;
  const provider = useProvider();
  const account = useAccount();
  const [amount, setAmount] = useState(0);
  const downXs = useBreakpoints("down", "xs");
  const correctWallet = user?.wallets?.ethWallet;
  const isWrongWallet = account && !equalsIgnoringCase(correctWallet, account);
  const selectedBalance = getBalance(balance, selectedCurrency);
  const [isShowBind2FAConfirm, setIsShowBind2FAConfirm] = useState(false);
  const [isShowVerifyDialog, setIsShowVerifyDialog] = useState(false);

  useEffect(() => {
    if (open && isWrongWallet) {
      dispatch(wrongWalletAlert(correctWallet));
    }
  }, [open, isWrongWallet, dispatch, correctWallet]);

  async function handleWithdraw(code) {
    if (isWrongWallet) {
      dispatch(wrongWalletAlert(correctWallet));
      return;
    }

    if (isWrongNetwork) {
      const switchSuccess = await handleChangeNetwork(selectedNetwork);
      if (!switchSuccess) {
        dispatch(wrongNetworkAlert(networks[selectedNetwork]));
      }
      return;
    }

    // @Case: No binding 2FA
    if (!user.authenticator.bound) {
      setIsShowBind2FAConfirm(true)
      return
    };

    // @Case: Bound 2FA & No verify code
    if (
      user.authenticator.bound &&
      user.authenticator.enabled &&
      !code
    ) {
      setIsShowVerifyDialog(true)
      return
    };

    return dispatch(signWithdraw(amount.toString(), selectedCurrency, selectedNetwork, String(code))).then((signData) => {
      setIsShowVerifyDialog(false);
      dispatch(withdraw({ provider, signData }));
    });
  }

  return (
    <CustomDialog
      open={open}
      hasCloseButton={false}
      setClose={setClose}
      PaperProps={{
        sx: {
          width: { xxs: "101%", sm: 710 },
          height: { xxs: "520px", xs: "initial" },
          padding: { xxs: 0, xs: "0 6px" },
          margin: { xxs: 0, xs: 2 },
          left: -2,
          bottom: -2,
          background: { xxs: "#181818", xs: "black" },

          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            display: "none"
          }
        },
        style: {
          position: downXs ? "absolute" : "relative",
          "--aug-tr1": "12px",
          "--aug-tr-inset2": "calc(var(--aug-tr1) * 14.5)"
        },
        "data-augmented-ui": downXs ? "tr-clip-x border" : "bl-clip-y br-clip border"
      }}>
      <Box sx={{ pt: { xs: 3 }, pl: { xs: 7 }, pr: { xs: 11.5 }, pb: { xs: 5 } }}>
        <Typography fontSize={24} fontWeight={700}>
          Withdraw Rewards
        </Typography>
        <NetworkRow setAmount={setAmount} />
        <InfoRow title="Wallet Address" info={user?.wallets?.ethWallet} />
        <Box mt={4} sx={RowContainerSx}>
          <InfoRow title="Asset" rowSx={{ mt: 0 }} />
          <Box
            data-augmented-ui="tl-clip br-clip"
            sx={{
              background: "white",
              color: "black",
              padding: { xxs: "5.5px 10px", sm: "8px 15px" },
              fontSize: { xxs: "18px", sm: "20px" },
            }}
            style={{
              "--aug-tl": downXs ? '5px' : "7px",
              "--aug-br": downXs ? '5px' : "7px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600
              }}
            >
              { selectedBalance?.currency }
            </Typography>
          </Box>
        </Box>
        <Box mt={4} sx={RowContainerSx}>
          <InfoRow title="Amount" rowSx={{ mt: 0, mb: { xxs: "18px", sm: 0 } }} />
          <CustomInputField
            maxValue={selectedBalance?.amount}
            helperText={`${selectedBalance?.amount || 0} ${
              selectedBalance?.currency || ""
            } available`}
            value={amount}
            setValue={setAmount}
          />
        </Box>

        <Box
          sx={{ display: "flex", flexFlow: "column nowrap", alignItems: "center", width: "100%" }}
          mt={4}>
          <Box display="flex" flexWrap="nowrap" justifyContent="flex-start">
            <OutlinedButton
              size="medium"
              sx={{ fontSize: "16px", width: "116px", height: "37px", mr: 3 }}
              handleClick={setClose}
              label="Cancel"
              color="primary"
              disabled={withdrawProcessing}
              needCircular={false}
            />
            <CustomButton
              isLoading={withdrawProcessing}
              disabled={amount <= 0 || amount > balance}
              handleClick={() => handleWithdraw()}
              sx={{ fontSize: "16px", width: "116px", height: "37px", mt: 0 }}
              augmented="tl-clip br-clip">
              Withdraw
            </CustomButton>
          </Box>

          <Typography sx={{ display: "flex", mt: 1 }} variant="caption" color="textSecondary">
            <AlertIcon style={{ marginRight: "6px" }} />
            Gas fees need to be provided for withdrawal
          </Typography>
        </Box>
      </Box>
      <BindConfirmDialog
        open={isShowBind2FAConfirm}
        setClose={() => setIsShowBind2FAConfirm(false)}
        tips={<>
          For your account safety, <br/>please bind with&nbsp;
          <Box component="span" sx={{ color: (theme) => theme.palette.primary.main }}>Google Authenticator.</Box>
        </>}
        confirmHandler={() => {
          ReactGA.event('try_dmawithdrawAGI_2FA_bind')
          dispatch(setBindGoogle2FADialogOpen(true))
          dispatch(setBindGoogle2FACallback(null))
          setIsShowBind2FAConfirm(false)
        }}
      />
      <Google2FAVerifyDialog
        title={"Security Verification"}
        open={isShowVerifyDialog}
        setClose={() => {
          setIsShowVerifyDialog(false);
        }}
        onPrimaryClick={(code) => {
          return handleWithdraw(code);
        }}
      />
    </CustomDialog>
  );
}

export default WithdrawalDialog;
