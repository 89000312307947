import {
  Toolbar,
  Box,
  AppBar,
  Container,
  Button,
  Typography,
  IconButton,
  useTheme
} from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as UserWhiteIcon } from "../../../assets/icons/user_white.svg";
import { ReactComponent as WalletIcon } from "../../../assets/icons/wallet/wallet.svg";
import { ReactComponent as WalletWhiteIcon } from "../../../assets/icons/wallet/wallet_white.svg";
import { PAGES } from "../../../constants/pages";
import { isWalletBrowser } from "../../../utils/isMobile";
import { shortenString } from "../../../utils/strings";
import WalletPopover from "./WalletPopover";
import { useState } from "react";
import { WALLET_CONFIG } from "../../../redux/slices/wallet";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";
import AccountPopover from "./AccountPopover";
import { __STAGING__ } from "../../../utils/dev";

const CDN_ASSETS = {
  LOGO: __STAGING__
    ? "https://storage.delysium.com/ui/delysium_logo_staging.png"
    : "https://storage.delysium.com/ui/delysium_logo.png"
};

const appBarSX = {
  position: "relative",
  width: "initial",
  mt: "20px",
  backgroundImage: `none`,
  backgroundColor: "transparent",
  color: (theme) => theme.palette.text.primary
};

const underlineSX = {
  content: "''",
  position: "absolute",
  bottom: "-3px",
  left: 0,
  right: 0,
  borderBottom: (theme) => `2px solid ${theme.palette.text.primary}`,
  transition: "all 0.2s ease-in-out",
  opacity: 0
};

const navLinkSx = {
  position: "relative",
  display: "block",
  height: "100%",
  textDecoration: "none",
  textTransform: "uppercase",
  transition: "all 0.2s ease-in-out",
  mx: "15px",

  "&::before": underlineSX,
  "&:hover": {
    background: "none",
    "&::before": { ...underlineSX, opacity: 1 }
  }
};

const activeNavLinkSx = (isActive) => {
  if (!isActive) return;
  return {
    "&::before": { ...underlineSX, opacity: 1 }
  };
};

const buttonSX = {
  padding: "5px",
  width: 120,
  height: 32,
  mr: 1.25,
  display: "inline-flex",

  "&:hover": {
    backgroundColor: "transparent"
  },
  "& > p": {
    fontSize: "12px"
  },

  "& > span": {
    marginRight: "0"
  }
};

export const UserWalletAddress = () => {
  const { user } = useSelector((state) => state.auth);
  const address = user.wallets?.ethWallet;
  if (!address) return;

  return (
    <Box
      sx={{
        fontSize: { xxs: "14px", sm: "14px" },
        color: "rgba(255, 255, 255, 0.5);"
      }}
      title={address}>
      {shortenString(address, 5, 4)}
    </Box>
  );
};

const Navbar = () => {
  const location = useLocation();
  const { push } = useHistory();
  const { primary, text } = useTheme().palette;
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useIsActive, useAccount } = WALLET_CONFIG[selectedWallet].hook;
  const account = useAccount();
  const isActive = useIsActive();
  const [accountPopoverOpen, setAccountPopoverOpen] = useState(false);
  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const [anchorElWal, setAnchorElWal] = useState(null);
  const [isWalletPopoverOpen, setIsWalletPopoverOpen] = useState(false);

  function handleWalletPopover(event, status) {
    if (status) setAnchorElWal(status ? event.currentTarget : null);
    setIsWalletPopoverOpen(status);
  }
  function handleAccountPopover(event, status) {
    if (status) setAnchorElAccount(status ? event.currentTarget : null);
    setAccountPopoverOpen(status);
  }

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        display: "flex",
        flexFlow: "column nowrap",
        zIndex: (theme) => theme.zIndex.modal - 1,
        justifyContent: "center",
        alignItems: "space-between"
      }}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
        <AppBar sx={appBarSX} position="static">
          <Container sx={{ ml: "16px", pr: 0, px: 2 }} maxWidth="xl">
            <Toolbar style={{ height: "53px", minHeight: "53px" }} disableGutters>
              <Box
                src={CDN_ASSETS.LOGO}
                component="img"
                onClick={() =>
                  isWalletBrowser()
                    ? window.location.assign("https://delysium.com/")
                    : window.open("https://delysium.com/", "_blank")
                }
                sx={{
                  cursor: "pointer",
                  mr: "6px",
                  width: "108px",
                  display: "flex"
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  height: "39px",
                  alignItems: "center",
                  ml: 2.5
                }}>
                {PAGES.map(({ title, url, active }, index) => (
                  <Box
                    key={index}
                    onClick={() => push(url)}
                    sx={{
                      ...navLinkSx,
                      ...activeNavLinkSx(active(location.pathname))
                    }}>
                    <Button
                      disableRipple
                      sx={{
                        textTransform: "uppercase",
                        px: 0,
                        color: (theme) => theme.palette.text.primary,
                        height: "100%",
                        fontSize: 14,
                        fontWeight: 400,
                        minWidth: 0,
                        "&:hover": {
                          background: "none"
                        }
                      }}>
                      {title}
                    </Button>
                  </Box>
                ))}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Box
          sx={{
            flexGrow: 0,
            zIndex: (theme) => theme.zIndex.modal + 1,
            mr: 3.75,
            gap: { xxs: "8px", sm: 0 },
            mt: "18px",
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "space-evenly"
          }}>
          {isActive && (
            <Button
              variant="outlined"
              color="primary"
              data-augmented-ui="bl-clip border"
              style={{
                ...getAugmentedStyle({
                  color: isWalletPopoverOpen ? primary.main : text.primary,
                  bl1: "0px"
                })
              }}
              sx={buttonSX}
              disableRipple
              startIcon={isWalletPopoverOpen ? <WalletIcon /> : <WalletWhiteIcon />}
              onMouseOver={(e) => handleWalletPopover(e, true)}>
              <Typography
                sx={{ textTransform: "initial" }}
                color={isWalletPopoverOpen ? "primary" : "textPrimary"}>
                {account?.substr(0, 6)}...{account?.substr(account?.length - 4, 4)}
              </Typography>
            </Button>
          )}
          <IconButton
            disableRipple
            variant="outlined"
            color="primary"
            size="small"
            sx={{
              transition: "none",
              borderRadius: 0,
              marginLeft: "0.5rem",
              height: "32px",
              width: "32px",
              cursor: "pointer"
            }}
            data-augmented-ui="tl-clip br-clip border"
            style={{
              ...getAugmentedStyle({ color: accountPopoverOpen ? primary.main : text.main })
            }}
            onMouseOver={(e) => handleAccountPopover(e, true)}>
            {accountPopoverOpen ? <UserIcon /> : <UserWhiteIcon />}
          </IconButton>
        </Box>
      </Box>

      <AccountPopover
        isOpen={accountPopoverOpen}
        anchorEl={anchorElAccount}
        handleClose={() => handleAccountPopover(null, false)}
      />

      <WalletPopover
        isWalletOpen={isActive && isWalletPopoverOpen}
        anchorElWal={anchorElWal}
        handleClose={() => handleWalletPopover(null, false)}
      />
    </Box>
  );
};
export default Navbar;
