import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { alpha } from "@mui/system";
import { Grid, Button, Tooltip, styled } from "@mui/material";
import { Typography, CircularProgress, Fade } from "@mui/material";
import CustomButton from "../../../components/Buttons/Button";
import OutlinedButton from "../../../components/Buttons/OutlinedButton";
import { ReactComponent as CopyIcon } from "../../../assets/copy.svg";
import { ReactComponent as AgiIcon } from "../../../assets/icons/agi.svg";
import { ReactComponent as LotteryIntro } from "../../../assets/referral-intro.svg";
import { ReactComponent as LotteryIntroMobile } from "../../../assets/referral-intro-mobile.svg";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";
import { getPoolsInfo, setStakeDialogOpen, setLimitedStakeSingleDialogOpen } from "../../../redux/slices/stake";
import { setWalletDialogOpen, WALLET_CONNECTION_TYPES } from "../../../redux/slices/wallet";
import { setAccountNotification } from "../../../redux/slices/accountNotifications";
import { toFixed } from "../../../utils/strings";
import { PATH_HISTORY } from "../../../routes/paths";
import LotteryIntroChart from "./ReferralIntroChart";
import TicketCheckoutDialog from "../../../components/Dialogs/TicketRefundDialog";
import LotteryTitle from "./LotteryTitle";
import copyTextToClipboard from "copy-to-clipboard";
import ReferralIntro from "./ReferralIntro";

const DMA_TITLE = "$AGI DIMAOND HAND TICKETS";
const buyButtonSX = {
  display: "flex",
  flexFlow: "row nowrap",
  textTransform: "uppercase",
  width: "100%",
  fontSize: 16
};

const buttonContainerSX = {
  position: { xxs: "sticky", xs: "relative" },
  bottom: 0,
  left: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "stretch",
  width: "100%",
  maxWidth: "418px",
  flexFlow: "column nowrap",
  mt: { xxs: 1, xs: 2.5 },
  background: (theme) => {
    return {
      xxs: `linear-gradient(${alpha(theme.palette.background.default, 0.5)}, ${
        theme.palette.background.default
      })`
    };
  },
  pb: { xxs: 2, xs: 0 }
};

function Pools() {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { isLoadingPoolsInfo } = useSelector((state) => state.stake);
  const { ticketOverview, ticketReferralOverview, myReferrerCode } = useSelector((state) => state.lottery);
  const { DMA, isLoading, submittedReferralCode, isRefreshingPrice, dmaPrice } = useSelector(
    (state) => state.DMA
  );
  const [isBuyOpen, setIsBuyOpen] = useState(false);
  const downXs = useBreakpoints("down", "xs");

  // Computed
  const stockQuantity = ticketOverview.my_tickets;
  const buyButtonText = "Copy Referral Link";

  const isDisabledBuyButtonConds = [
    () => ticketOverview.my_tickets <= 0,
    () => isLoading
  ];

  function handleClickOnBuy() {
    dispatch(
      setAccountNotification({
        message: "The link copied to your clipboard."
      })
    );
    copyTextToClipboard(`${window.location.origin}/lottery/pool?referrer=${myReferrerCode}`)
  }

  return (
    <Box>
      <Grid
        md={6}
        item
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "flex-start",
          justifyContent: "space-evenly",
          maxWidth: "418px",
        }}>
        <LotteryTitle title={'Referral Tickets'} sx={{ display: { xxs: "none", xs: "initial" } }} />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            mt: { xxs: 1, xs: 3}
          }}>
          <Box>
            <Typography sx={{ mb: { xs: 0 } }} component="h3" fontWeight={500} fontSize={16} color="primary">
              My Referral tickets
            </Typography>

            <Typography color="textPrimary" variant="caption" component="p" sx={{
              fontWeight: 600,
              fontSize: "24px"
            }}>
              {Number(ticketReferralOverview.my_referral_tickets).toLocaleString()}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            mt: { xxs: 1, xs: 3}
          }}>
          <Box>
            <Typography sx={{ mb: { xs: 0 } }} component="h3" fontWeight={500} fontSize={16} color="primary">
              Total Active Referral tickets
            </Typography>

            <Typography color="textPrimary" variant="caption" component="p" sx={{
              fontWeight: 600,
              fontSize: "24px"
            }}>
              {Number(ticketReferralOverview.total_active_referrals).toLocaleString()}
            </Typography>
          </Box>
        </Box>

        {/* ------------- INTRO -------------   */}

        <Box sx={{ mt: { xxs: 1, xs: 3 }, width: "100%" }}>
          <Typography sx={{ mb: "2px", fontSize: 16 }} fontWeight={500}  component="h3" color="primary">
          Bonus Prize Pool
          </Typography>
           <LotteryIntroChart />
        </Box>

        <ReferralIntro sx={{
          mb: "20px",
        }}
        rootSx={{
          display: { xxs: "block", xs: 'none' }
        }}
        />

        {/* ------------- TRADE/BUY/SALE -------------   */}
        <Box sx={buttonContainerSX}>
          <Box sx={{ display: "flex", flexFlow: "row nowrap" }}>
            <Button
              disableRipple
              data-augmented-ui="tl-clip br-clip"
              variant="contained"
              color="primary"
              size="large"
              startIcon={<CopyIcon/>}
              disabled={isDisabledBuyButtonConds.some((fn) => fn())}
              onClick={handleClickOnBuy}
              sx={buyButtonSX}>
              {buyButtonText}
            </Button>
          </Box>
        </Box>
      </Grid>
      <TicketCheckoutDialog
        isOpen={isBuyOpen}
        setClose={() => setIsBuyOpen(false)}
        isPublicSale={true}
      />
    </Box>
  );
}

export default Pools;
