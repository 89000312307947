// ----------------------------------------------------------------------

export default function Slider({ theme }) {
  return {
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.MuiSlider-root.Mui-disabled": {
            color: theme.palette.action.disabled
          },
          '& .MuiSlider-thumb': {
            height: 16,
            width: 16,
            backgroundColor: '#fff',
            borderRadius: 0,
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
            '&:before': {
              display: 'none',
            },
          },
          '& .MuiSlider-track': {
            background: "rgba(217, 217, 217, 0.5)"
          },
          '& .MuiSlider-rail': {
            background: "rgba(217, 217, 217, 0.3)",
            borderRadius: 0,
          },
          ".MuiSlider-valueLabel": {
            backgroundColor: "white",
            fontSize: "14px",
            color: "#000",
            borderRadius: 0,

            "&:before": {
              display: "none"
            }
          }
        },
        markLabel: {
          fontSize: 13,
          color: theme.palette.text.disabled
        },
      }
    }
  };
}
