import { Typography, CircularProgress, Grid } from "@mui/material";
import { alpha, Box } from "@mui/system";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeInDown } from "../../components/Animate";
import { PATH_HISTORY } from "../../routes/paths";
import { PageHeading } from "../../components/PageHeading";
import Container from "../../components/Container";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import { useHistory } from "react-router-dom";
import { displayNum } from "../../utils/strings";
import InfoCard from "../../components/InfoCard";
import useBreakpoints from "../../hooks/useBreakpoints";
import { ReactComponent as TierIcon } from "../../assets/icons/tier.svg";
import { ReactComponent as Triangle } from "../../assets/triangle.svg";
import { getDMAInfo, getSuperRewardStats } from "../../redux/slices/DMA";
import ReferralCodeBox from "../../components/ReferralCodeBox";
import { currencies } from "../../constants/currencies";
import { SectionTitle } from "../../components/SectionTitle";

function FootNoteTip({ children }) {
  return (
    <Typography color="primary" sx={{ fontSize: { xxs: 8, md: 12 }, mb: 0.75 }}>
      {children}
    </Typography>
  );
}

function FootNoteListItem({ children }) {
  return (
    <Typography
      component="li"
      sx={{
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "flex-start",
        width: "100%",
        my: 0.75,
        fontSize: { xxs: 10, md: 12 }
      }}>
      {children}
    </Typography>
  );
}

const greekNum = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VII"
};

const vestingBoxesContainerSX = {
  width: "100%",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-around",
  borderTop: `1px solid ${alpha("#fff", 0.3)}`
};

const VestingBox = ({ data = {}, index }) => {
  let date = null;

  const containerSx = {
    width: 88,
    height: 79,
    p: 1.375,
    opacity: data?.release_success ? 1 : 0.4,
    borderBottom: `2px solid ${true ? "#FF9C07" : "transparent"}`,
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    background:
      "linear-gradient(90.24deg, rgba(255, 156, 7, 0.1) 0.21%, rgba(255, 156, 7, 0) 120.16%);"
  };

  if (data?.unlock_at) {
    // To avoid time calculation, strictly accessing the month (date[1]) and day (date[2][0] + date[2][1]) for display
    date = data?.unlock_at.split("-");
    date = `${date[1]}.${date[2][0]}${date[2][1]}`;
  } else {
    date = `${index}${index === 1 ? "st" : index === 2 ? "nd" : index === 3 ? "rd" : "th"} month`;
  }

  return (
    <Box sx={containerSx}>
      {data.release_success && <Triangle style={{ position: "absolute", left: -3, top: -3 }} />}
      <Typography
        variant="body2"
        color="textPrimary"
        sx={{ fontWeight: 600, color: "#FF9C07", whiteSpace: "nowrap" }}>
        {greekNum[index]}
      </Typography>
      <Typography variant="body2" color="textPrimary" sx={{ opacity: 0.5, whiteSpace: "nowrap" }}>
        {date}
      </Typography>
      <Typography
        variant="body2"
        color="textPrimary"
        sx={{ fontWeight: 600, color: "#FF9C07", whiteSpace: "nowrap" }}>
        {data.amount || parseInt(data?.desReward) / 6} {currencies.native}
      </Typography>
    </Box>
  );
};

function SuperReward() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const downSm = useBreakpoints("down", "sm");
  const { isLoading, superReward } = useSelector((state) => state.DMA);
  const {
    tierName,
    referralsNeeded,
    referralsCompleted,
    usdtReward,
    desReward,
    lockupTokens,
    dmaCashbackUnlocked,
    tokenRewardsUnlocked
  } = superReward;

  useEffect(() => {
    dispatch(getDMAInfo());
    dispatch(getSuperRewardStats());
  }, [dispatch]);

  return (
    <Container>
      <PageHeading title="2022 End-of-Year Wrap-Up Super Reward" />
      {isLoading ? (
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", mt: 7.5 }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <AnimatePresence>
          <Box component={motion.div} {...varFadeInDown}>
            <SectionTitle color="primary" label="Get Rewards through referral" />
            <ReferralCodeBox />

            <Box sx={{ display: "flex", flexFlow: "row nowrap", justifyContent: "space-between" }}>
              <SectionTitle label="Your referral system" />
              <SectionTitle
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <TierIcon style={{ marginRight: "7px" }} /> Tier: {tierName}
                  </Box>
                }
              />
            </Box>

            <InfoCard
              ToolTipComponent={<FootNoteTip>[1]</FootNoteTip>}
              title="Your Referral"
              bodyDynamic={referralsCompleted}
              primaryButtonText="View History"
              handlePrimaryClick={() => push(PATH_HISTORY.referral)}
              bodyDynamicAlert={
                <Box
                  sx={{
                    display: "flex",
                    flexFlow: "column nowrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start"
                  }}>
                  <Typography
                    color="primary"
                    sx={{
                      fontWeight: 700,
                      lineHeight: { xxs: "10px", md: 1.2 },
                      fontSize: { xxs: 16, lg: 18 }
                    }}>
                    DMA
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xxs: "12px", md: "14px" },
                      lineHeight: { lg: 2.1 }
                    }}>
                    <Typography
                      sx={{ fontSize: { xxs: "12px", md: "14px" } }}
                      color="primary"
                      component="span">
                      / {referralsNeeded}{" "}
                    </Typography>
                    {downSm
                      ? "done to unlock rewards"
                      : `done to unlock your USDT Rewards and ${currencies.native}`}
                  </Typography>
                </Box>
              }
            />

            <Grid
              sx={{
                display: "grid",
                columnGap: 0.5,
                gridTemplateColumns: { xxs: "1fr", md: "1fr 1fr" }
              }}>
              <InfoCard
                ToolTipComponent={<FootNoteTip>[2]</FootNoteTip>}
                title="Your DMA Cashback"
                bodyDynamicUnit="USDT"
                bodyDynamic={displayNum(usdtReward)}
                bodyDynamicUnitSx={{ opacity: dmaCashbackUnlocked ? 1 : 0.3 }}
                bodyDynamicSx={{ opacity: dmaCashbackUnlocked ? 1 : 0.3 }}
              />
              <InfoCard
                ToolTipComponent={<FootNoteTip>[3]</FootNoteTip>}
                title={`Your ${currencies.native} Rewards`}
                bodyDynamicUnit={currencies.native}
                bodyDynamic={displayNum(desReward)}
                bodyDynamicUnitSx={{ opacity: tokenRewardsUnlocked ? 1 : 0.3 }}
                bodyDynamicSx={{ opacity: tokenRewardsUnlocked ? 1 : 0.3 }}
              />
            </Grid>

            <Box
              data-augmented-ui="tl-clip br-clip"
              style={getAugmentedStyle({})}
              sx={{
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "space-between",
                py: 3.75,
                px: 3,
                backgroundColor: `rgba(255, 255, 255, 0.07)`
              }}>
              <Typography fontSize={{ xxs: 14, sm: 16, lg: 18 }} fontWeight={500}>
                {currencies.native} Vesting 6-month timeline
              </Typography>
              <Typography variant="caption" color="textPrimary" sx={{ opacity: 0.5 }}>
                Start the 1st month vesting 1 month after you finish your referral tasks.{" "}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: { xxs: "row wrap", md: "row nowrap" },
                  justifyContent: "center",
                  px: 0.5,
                  mt: 2
                }}>
                {console.log(desReward)}
                <Box sx={vestingBoxesContainerSX}>
                  {lockupTokens.length === 0
                    ? [1, 2, 3].map((i) => <VestingBox data={{ desReward }} index={i} />)
                    : lockupTokens.map(
                        (data, i) =>
                          i < 3 && <VestingBox data={{ ...data, desReward }} index={i + 1} />
                      )}
                </Box>
                <Box sx={{ ...vestingBoxesContainerSX, mt: { xxs: 2, md: 0 } }}>
                  {lockupTokens.length === 0
                    ? [4, 5, 6].map((i) => <VestingBox data={{ desReward }} index={i} />)
                    : lockupTokens.map(
                        (data, i) =>
                          i >= 3 && <VestingBox data={{ ...data, desReward }} index={i + 1} />
                      )}
                </Box>
              </Box>
            </Box>

            <Box
              data-augmented-ui="tl-clip br-clip"
              style={getAugmentedStyle({})}
              sx={{
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "space-between",
                backgroundColor: `rgba(255, 255, 255, 0.07)`,
                mt: "5px",
                py: 2.625,
                px: 3
              }}>
              <Box component="ul" sx={{ listStyle: "none", p: "initial", my: 0, opacity: 0.5 }}>
                <FootNoteListItem>
                  <Typography
                    component="span"
                    color="primary"
                    sx={{ mr: { xxs: "4px", md: 1 }, fontSize: "inherit", minWidth: "14px" }}>
                    [1]
                  </Typography>
                  <Typography fontSize="inherit">
                    Once you finish all the DMA referral activities. You can receive “Your DMA
                    Cashback” and “6-month {currencies.native} Rewards”.{" "}
                    <Typography component="span" fontSize="inherit" color="primary">
                      (Last date to finish the tasks: 30 June 2023)
                    </Typography>
                  </Typography>
                </FootNoteListItem>

                <FootNoteListItem>
                  <Typography
                    component="span"
                    color="primary"
                    sx={{ mr: { xxs: "4px", md: 1 }, fontSize: "inherit", minWidth: "14px" }}>
                    [2]
                  </Typography>
                  <Typography fontSize="inherit">
                    In order to get the one-time USDT cashback, you will need to have at least 1 DMA
                    by yourself. Once you finish all the DMA referral activities, you can see your
                    one-time USDT reward in your balance on the DMA dashboard and can withdraw them
                    right away!{" "}
                    <Typography component="span" fontSize="inherit" color="primary">
                      (Last date to finish the tasks: 30 June 2023)
                    </Typography>
                  </Typography>
                </FootNoteListItem>

                <FootNoteListItem>
                  <Typography
                    component="span"
                    color="primary"
                    sx={{ mr: { xxs: "4px", md: 1 }, fontSize: "inherit", minWidth: "14px" }}>
                    [3]
                  </Typography>
                  <Typography fontSize="inherit">
                    Once you finish all the DMA referral activities, you can see your monthly
                    6-month {currencies.native} rewards in your balance on the DMA Dashboard and can
                    withdraw them on the{" "}
                    <Typography component="span" fontSize="inherit" color="primary">
                      1st day of the next month
                    </Typography>{" "}
                    for 6 months.{" "}
                    <Typography component="span" fontSize="inherit" color="primary">
                      (Last date to finish the tasks: 30 June 2023)
                    </Typography>
                  </Typography>
                </FootNoteListItem>
              </Box>
            </Box>
          </Box>
        </AnimatePresence>
      )}
    </Container>
  );
}
export default SuperReward;
