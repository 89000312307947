import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  message: "This is a notification"
};

export const slice = createSlice({
  name: "accountNotifications",
  initialState,
  reducers: {
    setOpen(state) {
      state.isOpen = true;
    },
    setClose(state) {
      state.isOpen = false;
    },
    setNotification(state, action) {
      state.message = action.payload;
    }
  },
  extraReducers: (builder) => {}
});

function getErrorMessage(error) {
  switch (error.code) {
    case 4001:
      return "Signing failed.";
    case 4000001:
      return "Failed to login through Discord.";
    case 400501:
      return "Verification code error.";
    case 400505:
      return "Verification failed. Incorrect Google Authenticator Code, please re-enter to verify.";
    case 400601:
      return "Your verification code has expired. Please resend a new code.";
    case 400302:
      return "Failed to login through wallet.";
    case 403801:
      return "Your action is too fast. Please wait.";
    case 404102:
      return "Unrecognized email, please register.";
    case 404115:
      return "Discord Authorization expired, please refresh the website.";
    case 400502:
      return "Token expired, please refresh.";
    case 409201:
      return "This email you entered is associated with an existing account.";
    case 409204:
      return "This email you entered is associated with an existing account.";
    case 409205:
      return "This email address is already associated with this account.";
    case 409206:
      return "A Discord account is already bound to the account.";
    default:
      return typeof error?.message === "string"
        ? error?.message
        : "Error occured please try again later.";
  }
}

let _accountNotificationTimer
export function setAccountNotification(error, time = 3000) {
  return async (dispatch) => {
    const message = typeof error === 'string' ? error : getErrorMessage(error);
    dispatch(slice.actions.setNotification(message));
    dispatch(slice.actions.setOpen());
    clearTimeout(_accountNotificationTimer);
    _accountNotificationTimer = setTimeout(() => {
      dispatch(slice.actions.setClose());
    }, time);
  };
}

export default slice.reducer;
export const { setClose } = slice.actions;
