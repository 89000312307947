import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Typography,
  alpha
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PageHeading } from "../../../components/PageHeading";
import { SectionTitle } from "../../../components/SectionTitle";
import ShowcaseBox from "../../../components/ShowcaseBox";
import { ReactComponent as ExpandMoreIcon } from "../../../assets/icons/arrow.svg";
import { currencies } from "../../../constants/currencies";
import { ReactComponent as LoyaltyIcon } from "../../../assets/icons/loyalty.svg";
import { ReactComponent as AGIIcon } from "../../../assets/icons/agi2.svg";
import { ReactComponent as ReferralsIcon } from "../../../assets/icons/referrals.svg";
import { ReactComponent as GalxeIcon } from "../../../assets/icons/galxe.svg";
import { ReactComponent as EngageIcon } from "../../../assets/icons/Engagement_Icon.svg";
import Container from "../../../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { getLoyaltyFAQ, getLoyaltyStats } from "../../../redux/slices/loyalty";
import { ReactComponent as BetaHighlightedIcon } from "../../../assets/icons/beta_highlighted_large.svg";

const IS_SHOW_COMING_SOON = false

function ComingSoonTips () {
  return (
    <Box sx={{
      pt: { xxs: "20px", sm: "60px" },
      fontFamily: "Chakra Petch, sans-serif",
    }}>
      <Box sx={{
        fontWeight: 500,
        fontSize: { xxs: 14, sm: 20 },
        marginBottom: { xxs: "4px", sm: "8px" }
      }}>Coming Soon</Box>
      <Box sx={{
        fontSize: { xxs: 12, sm: 16 },
        opacity: 0.5
      }}>
      Thanks to the participation and feedback from Delysium members, the Loyalty Program Beta has been turned into an update on 8/16/2023. Loyalty Score is no longer associated with any direct rewards and is only used to record and evaluate user activity and engagement. Stay active, engage more with the content and experiences in the Delysium ecosystem, and become a more loyal member!
      </Box>
    </Box>
  )
}

function QAItem({ question, answer, key }) {
  return (
    <div>
      <Accordion
        sx={{
          py: { xxs: 0.5, sm: 1.25 },
          borderBottom: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.1)}`
        }}>
        <AccordionSummary
          sx={{
            my: 0,
            pl: 0,
            fontSize: { xxs: 12, sm: 18 },
            fontWeight: "500",
            textTransform: "uppercase",
            fontFamily: "Chakra Petch, sans-serif"
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${key}a-content`}
          id={`panel${key}a-header`}>
          {question}
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 0 }}>
          <Typography
            dangerouslySetInnerHTML={{ __html: answer }}
            sx={{
              fontSize: { xxs: 12, sm: 16 },
              color: (theme) => alpha(theme.palette.text.primary, 0.6),
              "& > a": {
                color: (theme) => alpha(theme.palette.primary.main, 0.6),
                transition: "all 0.3s ease",
                "&:hover": {
                  color: (theme) => theme.palette.primary.main
                }
              }
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
const showcaseContainerSx = {
  display: "flex",
  flexFlow: { xxs: "column nowrap", md: "row nowrap" },
  gap: { md: 0.625 }
};

const LoyaltyProgram = () => {
  const dispatch = useDispatch();
  const [FAQs, setFAQs] = useState([]);
  const {
    isLoading,
    stats
  } = useSelector((state) => state.loyalty);

  useEffect(() => {
    dispatch(getLoyaltyStats());
    getLoyaltyFAQ().then((faqs) => setFAQs(faqs));
  }, []);

  if (IS_SHOW_COMING_SOON) {
    return (
      <Container>
        <PageHeading title="Loyalty Program" subtitle="Record your growth in Delysium ecosystem" />
        <ComingSoonTips />
      </Container>
    )
  }

  return (
    <Container>
      <PageHeading
        Label={() => <BetaHighlightedIcon style={{ marginBottom: "7px" }} />}
        title="Loyalty Program"
        subtitle="Record your growth in Delysium ecosystem"
      />

      {isLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            mt: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <>
          <SectionTitle label="Loyalty Score" />
          <Box display="flex" flexDirection="column" flexWrap="wrap">
            <Box sx={showcaseContainerSx}>
              <ShowcaseBox
                Icon={LoyaltyIcon}
                title="Your Loyalty Score"
                hideSubtitle
                amount={stats.points_total}
              />
            </Box>
            <Box sx={showcaseContainerSx}>
              <ShowcaseBox
                hideSubtitle
                Icon={ReferralsIcon}
                title="From DMA Holding / Referral"
                amount={stats.points_dma}
                amountColor="textPrimary"
              />
              <ShowcaseBox
                Icon={AGIIcon}
                title={`From LP Staking`}
                hideSubtitle
                amount={stats.points_lp}
                amountColor="textPrimary"
                dataAugmentedUI="tr-clip bl-clip border"
              />
            </Box>
            <Box sx={showcaseContainerSx}>
              <ShowcaseBox
                noValueFormatting
                Icon={EngageIcon}
                title="Engagement Score"
                hideSubtitle
                amount={stats.points_static}
                amountColor="textPrimary"
              />

              <ShowcaseBox
                noValueFormatting
                Icon={GalxeIcon}
                title="From Galxe OAT Holding"
                hideSubtitle
                amount={stats.points_oat}
                amountColor="textPrimary"
              />
            </Box>
            <ShowcaseBox
                Icon={AGIIcon}
                title={`From AGI Holding`}
                hideSubtitle
                amount={parseFloat(stats.points_agi)}
                amountColor="textPrimary"
                dataAugmentedUI="tr-clip bl-clip border"
              />
          </Box>

          {FAQs?.length > 0 && <><SectionTitle label="FAQS" sx={{ mb: { xxs: 0.5, md: 1 } }} />
          <Divider sx={{ borderColor: "#45494C", mt: 3, mb: 3.5 }} /></>}
          {FAQs?.map(({ title, content }, i) => (
            <QAItem question={title} answer={content} key={i} />
          ))}
        </>
      )}
    </Container>
  );
};

export default LoyaltyProgram;
