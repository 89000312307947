// ----------------------------------------------------------------------

const breakpoints = {
  values: {
    xxs: 0,
    xs: 576,
    sm: 768,
    md: 960,
    lg: 1440,
    xl: 1920
  }
};

export default breakpoints;
