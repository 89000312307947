import { useEffect, useState } from "react";
import { alpha, Box, TextField, Typography, Slider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import BigNumber from "bignumber.js";
import OutlinedButton from "./Buttons/OutlinedButton";
import CustomButton from "./Buttons/Button";
import WalletHandler from "./WalletHandler";
import { getAugmentedStyle } from "../utils/augmentedStyles";
import { WALLET_CONFIG } from "../redux/slices/wallet";
import { getLimitedStakeOverview, signLimitedStakeSingle, stakeSingle, setLimitedStakeSingleDialogOpen, getBalance, getAprs } from "../redux/slices/stake";
import { getRewardsBalance, setDepositDialogOpen } from "../redux/slices/DMA";
import useBreakpoints from "../hooks/useBreakpoints";
import { ReactComponent as AgiIcon } from "../assets/icons/agi.svg";
import { ReactComponent as CautionIcon } from "../assets/icons/caution.svg";
import { ReactComponent as RefreshIcon } from "../assets/icons/refresh.svg";
import RefreshIconUrl from "../assets/refresh.png";
import { CONTRACT_ADDRESS } from "../constants/contracts";
import { isWalletBrowser } from "../utils/isMobile";
import { currencies } from "../constants/currencies";
import { toFixed } from "../utils/strings";
import { getStakePhaseInfo } from "../utils/stake";

const InputTextSx = {
  color: (theme) => theme.palette.grey[0],
  fontSize: { xxs: 18, xs: 24 },
  fontWeight: 600,
  py: 1
};

const InputSx = {
  height: { xxs: "40px", xs: "51px" },
  input: { ...InputTextSx },
  "& input[type=number]": {
    MozAppearance: "textfield"
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none"
    }
  }
};

function AmountInputField({
  value,
  setValue,
  placeholder,
  type = "number",
  maxValue,
  minValue = 0
}) {
  function handleChangeQuantity(value) {
    if (value.length === 0) setValue("");
    else {
      const floatValue = parseFloat(value);

      if (value < minValue && value.length !== 0) setValue((prevState) => prevState);
      else setValue((prevState) => (floatValue <= maxValue ? floatValue : prevState));
    }
  }

  const augStyle = getAugmentedStyle({ color: "rgba(255, 255, 255, 0.3)", borderSize: "1px" });

  const valueOptionSx = (isActive) => ({
    flex: 1,
    height: "30px",
    lineHeight: "30px",
    textAlign: "center",
    cursor: "pointer",
    fontSize: { xxs: "14px", sm: "14px" },
    color: (theme) => (isActive ? theme.palette.primary.main : "white"),
    "--aug-border-bg": (theme) => isActive && `${alpha(theme.palette.primary.main, 1)} !important`
  });

  const handleOptionClick = (ratio) => {
    setValue(ratio * maxValue);
  };

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" width="100%">
      <TextField
        fullWidth
        type={type}
        placeholder={placeholder}
        variant="outlined"
        value={value}
        onChange={(e) => handleChangeQuantity(e.target.value)}
        data-augmented-ui="tl-clip br-clip border"
        InputProps={{
          inputProps: { max: maxValue, step: "0.01" }
        }}
        sx={InputSx}
        style={augStyle}
      />
      <Box mt="12px" display="flex" flexWrap="nowrap" justifyContent="space-between" gap="10px">
        <Box
          sx={valueOptionSx(value && value === maxValue * 0.25)}
          data-augmented-ui="tl-clip border"
          style={augStyle}
          onClick={() => handleOptionClick(0.25)}>
          25%
        </Box>
        <Box
          sx={valueOptionSx(value && value === maxValue * 0.5)}
          data-augmented-ui="border"
          style={augStyle}
          onClick={() => handleOptionClick(0.5)}>
          50%
        </Box>
        <Box
          sx={valueOptionSx(value && value === maxValue * 0.75)}
          data-augmented-ui="border"
          style={augStyle}
          onClick={() => handleOptionClick(0.75)}>
          75%
        </Box>
        <Box
          sx={valueOptionSx(value && value === maxValue)}
          data-augmented-ui="br-clip border"
          style={augStyle}
          onClick={() => handleOptionClick(1)}>
          MAX
        </Box>
      </Box>
    </Box>
  );
}

function LockMonthsControl({ value, setValue }) {
  const [isCustom, setIsCustom] = useState(true);
  const { ticketOverview } = useSelector(
    (state) => state.lottery
  )
  const { valid_lock_period, boost_rate } = ticketOverview

  const valueTexts = [
    'flexible',
    '1 week',
    '2 weeks',
    '3 weeks',
    '1 month',
    '2 months',
    '3 months',
  ]

  let minValue = 1;
  let maxValue = valid_lock_period.length - 1

  const valueOptionSx = (isActive) => ({
    flex: 1,
    height: "30px",
    lineHeight: "30px",
    maxWidth: { xss: "100%", sm: "105px" },
    textAlign: "center",
    cursor: "pointer",
    fontSize: { xxs: "12px", sm: "14px" },
    color: (theme) => (isActive ? "black" : "white"),
    background: (theme) => isActive && `${alpha(theme.palette.primary.main, 1)} !important`,
    "--aug-border-bg": (theme) => isActive && `${alpha(theme.palette.primary.main, 0.3)} !important`
  });

  const augStyle = getAugmentedStyle({ color: "rgba(255, 255, 255, 0.3)", borderSize: "1px" });

  const handleOptionClick = (months) => {
    setIsCustom(false);
    setValue(months);
  };

  const handleCustomMonthsClick = () => {
    setValue(maxValue);
    setIsCustom(true);
  };

  const valueLabelFormat = (val) => {
    if (val === 0) return 'Flexible'
    return valueTexts[val]
  }

  return (
    <Box>
      <Box mt="12px" display="none" flexWrap="nowrap" justifyContent="flexStart" gap="10px">
      <Box
        sx={valueOptionSx(value === 1 && !isCustom)}
        data-augmented-ui="tl-clip border"
        style={augStyle}
        onClick={() => handleOptionClick(1)}>
        1 Week
      </Box>
      <Box
        sx={valueOptionSx(value === 4 && !isCustom)}
        data-augmented-ui="tl-clip border"
        style={augStyle}
        onClick={() => handleOptionClick(4)}>
        1 Month
      </Box>
        <Box
          sx={valueOptionSx(value === maxValue && !isCustom)}
          data-augmented-ui="tl-clip border"
          style={augStyle}
          onClick={() => handleOptionClick(maxValue)}>
          {valueTexts[maxValue]}
        </Box>
        <Box
          sx={valueOptionSx(isCustom)}
          data-augmented-ui="br-clip border"
          style={augStyle}
          onClick={() => handleCustomMonthsClick()}>
          Custom
        </Box>
      </Box>
      {isCustom && (
        <Slider
          sx={{
            m: "0 auto",
            width: { xxs: "80%", sm: "100%" },
            mt: "48px",
            display: { xxs: "block", sm: "inline-block" }
          }}
          value={value}
          step={1}
          min={0}
          max={maxValue}
          size="small"
          valueLabelDisplay="on"
          valueLabelFormat={valueLabelFormat}
          onChange={(e, val) => {
            setValue(val);
          }}
        />
      )}
    </Box>
  );
}

function InfoRow({ title, Icon = null, info, rowSx, needTransparent }) {
  return (
    <Box
      sx={{
        ...rowSx
      }}>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: 14,
          minWidth: 140,
          color: (theme) => theme.palette.grey[60]
        }}>
        {Icon && <Icon style={{ marginRight: "5px" }} />}
        {title}
      </Typography>

      <Typography
        sx={{
          fontSize: 12,
          mt: "12px",
          display: "flex",
          alignItems: "flex-end",
          color: (theme) => theme.palette.grey[60]
        }}>
        {info}
      </Typography>
    </Box>
  );
}

function BalanceInfoRow({ title, Icon = null, balance, rowSx }) {
  const dispatch = useDispatch()
  const [ needTransparent, setNeedTransparent ] = useState(false)

  const handleRefreshClick = async () => {
    if (needTransparent) return
    setNeedTransparent(true)
    await dispatch(getRewardsBalance())
    setNeedTransparent(false)
  }

  return (
    <Box
      sx={{
        mt: 4,
        mb: "12px",
        ...rowSx
      }}>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: 14,
          minWidth: 140,
          color: (theme) => theme.palette.grey[60]
        }}>
        {Icon && <Icon style={{ marginRight: "5px" }} />}
        {title}
      </Typography>

      <Box
        sx={{
          fontSize: 12,
          mt: "12px",
          display: "flex",
          alignItems: "center",
          color: (theme) => theme.palette.grey[60]
        }}>
        Balance: &nbsp;<Box sx={{
          transition: "318ms",
          opacity: needTransparent ? 0 : 1
        }}>
          { balance }
        </Box>&nbsp;$AGI
        <Box
          sx={{
            cursor: "pointer",
            marginLeft: "6px",
            backgroundImage: `url(${RefreshIconUrl})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            aspectRatio: '1/1',
            width: { xxs: "16px", sm: "20px" }
          }}
          onClick={ () => handleRefreshClick() }
        />
      </Box>
    </Box>
  );
}

const strip = (num, precision = 12) => {
  return +parseFloat(num.toPrecision(precision));
};

const getEstimatedAPR = (aprs, month) => {
  const apr = aprs[month - 1];

  if (typeof apr !== 'undefined') return Number(apr);

  if (!month) return 0;

  if (month <= 12) {
    return 207 + 18.92 * (month - 5);
  }

  return 320.52 + 21.67 * (month - 11);
};

export function LockUpPeriod({
  lockMonths,
  setLockMonths
}) {
  const dispatch = useDispatch();
  const downXs = useBreakpoints("down", "xs");
  const { limitedStakeSingleDialogOpen, limitedStakeSingleProcessing, limitedStakeOverview, stakeConfig, poolsInfo, aprs } = useSelector(
    (state) => state.stake
  );
  const { balance } = useSelector(
    (state) => state.DMA
  );
  const { ticketOverview } = useSelector(
    (state) => state.lottery
  )
  const { valid_lock_period, boost_rate } = ticketOverview
  const nativeBalance = Number(
    balance.find((item) => item.currency === currencies.native)?.amount || 0
  );
  const { user } = useSelector((state) => state.auth);
  const setClose = () => dispatch(setLimitedStakeSingleDialogOpen(false));
  const [amount, setAmount] = useState(0);
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useProvider, useAccount } = WALLET_CONFIG[selectedWallet].hook;
  const provider = useProvider();
  const account = useAccount();

  // @TODO：动态计算 APR
  const estimatedAPR = `${boost_rate[lockMonths]}%`;
  const estimatedAPRInfo = `Lock tickets up to 3 months; disabled if lock-up exceeds event duration.`;

  return (
      <Box>
        <InfoRow title="Lock-up period" />
        <LockMonthsControl value={lockMonths}  setValue={setLockMonths} />
        <InfoRow title={`Boost: ${estimatedAPR}`} Icon={CautionIcon} rowSx={{
          mt: 4
        }} info={estimatedAPRInfo} />
      </Box>
  );
}

export default LockUpPeriod;
