import { Box, TableCell, TableHead, TableRow, Typography } from "@mui/material";

export function Columns({ columnList = [] }) {
  return (
    <TableHead>
      <TableRow>
        {columnList.map((item, index) => (
          <TableCell key={index} align={index > 0 ? "left" : "left"} sx={item?.sx}>
            {item?.title || item}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function EmptyRecords({ title = "No Records", sx = {} }) {
  return (
    <Box
      sx={{ width: "100%", height: "75%", display: "flex", alignItems: "center", mt: 30, ...sx }}>
      <Typography
        sx={{
          width: "100%",
          opacity: 0.3,
          textAlign: "center",
          fontSize: "28px",
          fontWeight: 600
        }}
        color="textPrimary">
        {title}
      </Typography>
    </Box>
  );
}
