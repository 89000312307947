/* eslint-disable no-useless-escape */
export function shortenString(value = "", start = 5, end = 11) {
  return `${value.substr(0, start)}...${value.substr(value.length - end, end)}`;
}

export function censorWord(str) {
  return str[0] + "*".repeat(Math.max(1, str.length - 3)) + (str.length > 2 ? str.slice(-2) : "");
}

export function censorEmail(email) {
  const arr = email.split("@");
  return censorWord(arr[0]) + "@" + arr[1];
}

export function toFixed(value = 0, decimal = 2) {
  return parseFloat(value).toFixed(decimal);
}

export function withComma(num = 0) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function displayNum(num = 0) {
  if (num == 0) return 0;
  return withComma(toFixed(num));
}

export function equalsIgnoringCase(a, b) {
  if (!a) {
    return false
  }
  return a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0;
}
