import { __PROD__, __STAGING__ } from "./utils/dev";

export const config = {
  GA_MEASUREMENT_ID: "G-K5HWHPL62Y",
  DMA_ARK_CONFIG: __PROD__
    ? "https://ark.entermetaverse.fun/static/config/16ba9b9d-ed81-4100-8564-988eff81bac2.json"
    : "https://ark.entermetaverse.fun/static/config/8b47b963-aeaf-4c2c-8672-3d5cd320a5c5.json",
  LOYALTY_ARK_FAQ:
    "https://ark.entermetaverse.fun/static/config/7bfafc0c-241d-47af-81e5-faee8f460f0c.json",
  DELYSIUM_CLIENT_URL: __PROD__ ? "https://www.delysium.com/" : "https://www-staging.delysium.com/",
  MARKETPLACE_CLIENT_URL: __PROD__
    ? "https://marketplace.delysium.com/"
    : __STAGING__
    ? "https://marketplace-staging.delysium.com/"
    : "https://marketplace-dev.delysium.com/",
  SSO_CLIENT_URL: __PROD__
    ? "https://auth.delysium.com"
    : __STAGING__
    ? "https://auth-staging.delysium.com"
    : "https://auth-dev.delysium.com",
  DASHBOARD_UI: __PROD__
    ? "https://dashboard.delysium.com/"
    : __STAGING__
    ? "https://dashboard-staging.delysium.com/"
    : "https://dashboard-dev.delysium.com/",
  SSO_API_URL: __PROD__
    ? "https://sso.delysium.com"
    : __STAGING__
    ? "https://sso-stg.delysium.com"
    : "https://sso-dev.delysium.com",
  MARKET_API_URL: __PROD__
    ? "https://market-api.delysium.com/api/v1"
    : __STAGING__
    ? "https://market-api-staging.delysium.com/api/v1"
    : "https://market-api-dev.delysium.com/api/v1",
  SYNCER_API_URL: __PROD__
    ? "https://syncer-bsc-api.delysium.com/api/v1"
    : "https://syncer-api-staging.delysium.com/api/v1",
  DMA_API_URL: __PROD__
    ? "https://dma-api.delysium.com/api/v1"
    : __STAGING__
    ? "https://dma-api-staging.delysium.com/api/v1"
    : "https://dma-api-dev.delysium.com/api/v1"
};
