import { Box, Button, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { varZoomInOut } from "./Animate";
import copyTextToClipboard from "copy-to-clipboard";
import { ReactComponent as CopyIcon } from "../assets/icons/copy.svg";
import { ReactComponent as BlackCopyIcon } from "../assets/icons/copy-black.svg";
import { ReactComponent as CopiedIcon } from "../assets/icons/copied.svg";
import { ReactComponent as BlackCopiedIcon } from "../assets/icons/copied-black.svg";

function CopyText({
  label,
  textToCopy,
  buttonVariant = "text",
  buttonSx = {},
  color = "white",
  iconStyle = {},
  captionSx = {},
  captionText = "Copied"
}) {
  const [copied, setCopied] = useState(false);
  const style = {
    margin: 0,
    cursor: "pointer",
    ...iconStyle
  };
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  return (
    <Box display="flex" alignItems="center">
      <Button
        disableRipple
        variant={buttonVariant}
        sx={{
          p: 0,
          mr: 1,
          ml: 0.5,
          minWidth: "max-content",
          "& .MuiButton-endIcon": { m: 0 },
          "&:hover": {
            background: "none"
          },
          ...buttonSx
        }}
        onClick={() => {
          setCopied(true);
          copyTextToClipboard(textToCopy);
        }}
        endIcon={
          copied ? (
            color === "black" ? (
              <BlackCopiedIcon style={style} />
            ) : (
              <CopiedIcon style={style} />
            )
          ) : color === "black" ? (
            <BlackCopyIcon style={style} />
          ) : (
            <CopyIcon style={style} />
          )
        }>
        {label}
      </Button>
      <AnimatePresence>
        {copied && (
          <Typography
            variant="caption"
            component={motion.span}
            sx={{
              color: (theme) => theme.palette.grey[color === "white" ? 70 : 900],
              ...captionSx
            }}
            {...varZoomInOut}>
            {captionText}
          </Typography>
        )}
      </AnimatePresence>
    </Box>
  );
}

export default CopyText;
